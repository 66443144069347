import React from 'react'
import { withRegistration } from '../../shared/containers/containerRegistration'
import ValidateErrorMessage from '../../shared/items/static/validateErrorMessage'
import styles from './registrationValidateResuld.module.scss'

const RegistrationValidateResuld = props => {
  const { errorsModel } = props

  return !!errorsModel
    ? <div className={styles.container_validateModel}><ValidateErrorMessage title={errorsModel} /></div>
    : null
}

export default withRegistration(React.memo(RegistrationValidateResuld))