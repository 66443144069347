import React from "react";
import MultypleCheckbox from "./employeesMultypleCheckbox";
import styles from "./employeesListItem.module.scss";

const EmployeesMultipleSelectItem = (props) => {
  const { data } = props;

  return (
    <div className={`${styles.employeesListItemWrapper} ${data.isCheck ? styles.employeesListItem_is_mSelect : ""}`}>
      <div className={styles.employeesListItem}>
        <div className={styles.container_employeeContent}>
          <div className={styles.container_name}>
            <span>{data.title}</span>
          </div>
        </div>
      </div>
      <div className={styles.container_mSelect}>
        <MultypleCheckbox employee={data} isCheck={data.isCheck} />
      </div>
    </div>
  );
};

export default React.memo(EmployeesMultipleSelectItem);
