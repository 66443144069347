const REQUEST_EMPLOYEE_MULTIPE_SELECT = "REQUEST_EMPLOYEE_MULTIPE_SELECT";
const RESPONSE_EMPLOYEE_MULTIPE_SELECT = "RESPONSE_EMPLOYEE_MULTIPE_SELECT";

const requestMultipleSelectAction = () => {
  return {
    type: REQUEST_EMPLOYEE_MULTIPE_SELECT,
  };
};

const responseMultipleSelectAction = (id, data, type) => {
  return {
    type: RESPONSE_EMPLOYEE_MULTIPE_SELECT,
    employeeMultipleSelect: {
      id: id,
      type: type,
      selected: data,
    },
  };
};

const getEmployeeMultipleSelect = (id, data) => {
  const item = data.find((item) => {
    return item.id === id;
  });

  return getItems(item.employees);
};

const getItems = (employees) => {
  return employees.map((employee) => {
    return {
      id: employee.id,
      title: employee.title,
      isCheck: true,
    };
  });
};

const multipleSelect = (id, data = [], type = null) => {
  return (dispatch) => {
    dispatch(requestMultipleSelectAction());
    data = id !== null ? (!data.employees ? getEmployeeMultipleSelect(id, data) : getItems(data.employees)) : [];
    dispatch(responseMultipleSelectAction(id, data, type));
  };
};

export {
  requestMultipleSelectAction,
  multipleSelect,
  REQUEST_EMPLOYEE_MULTIPE_SELECT,
  RESPONSE_EMPLOYEE_MULTIPE_SELECT,
};
