import GeoZoneServices from "../shared/services/geoZoneServices";
import EmployeeMultipleSelectService from "../shared/services/employeeMultipleSelectService";
import { multipleSelect } from "./employeeMultipleSelectAction";

const RESET_REQUEST_EMPLOYEE_GEO_ZONES = "RESET_REQUEST_EMPLOYEE_GEO_ZONES";
const REQUEST_EMPLOYEE_GEO_ZONES = "REQUEST_EMPLOYEE_GEO_ZONES";
const RESPONSE_EMPLOYEE_GEO_ZONES = "RESPONSE_EMPLOYEE_GEO_ZONES";
const REQUEST_EMPLOYEE_GEO_ZONES_FAILURE = "REQUEST_EMPLOYEE_GEO_ZONES_FAILURE";

const requestEmployeeGeoZonesAction = () => {
  return {
    type: REQUEST_EMPLOYEE_GEO_ZONES,
  };
};

const responseEmployeeGeoZonesAction = (data) => {
  return {
    type: RESPONSE_EMPLOYEE_GEO_ZONES,
    lastStates: {
      employeeGeoZones: data.geoZones,
    },
  };
};

const fail = (errors) => {
  return {
    type: REQUEST_EMPLOYEE_GEO_ZONES_FAILURE,
    errors,
  };
};

const getEmployeeGeoZones = (selectGeoZoneId = null) => {
  return (dispatch) => {
    dispatch(requestEmployeeGeoZonesAction());

    GeoZoneServices.getEmployeeGeozones()
      .then((response) => {
        dispatch(responseEmployeeGeoZonesAction(response.data));
        dispatch(
          multipleSelect(selectGeoZoneId, response.data.geoZones, EmployeeMultipleSelectService.type.employeeGeozones)
        );
      })
      .catch((error) => {
        dispatch(fail(error.response));
      });
  };
};

const createGeoZone = (options) => {
  return (dispatch) => {
    return GeoZoneServices.create(options)
      .then((response) => {
        return dispatch(getEmployeeGeoZones(response.data.id));
      })
      .catch((error) => {
        dispatch(fail(error.response));
      });
  };
};

const updateGeoZone = (options) => {
  return (dispatch) => {
    GeoZoneServices.update(options)
      .then(() => {
        return dispatch(getEmployeeGeoZones(options.id));
      })
      .catch((error) => {
        dispatch(fail(error.response));
      });
  };
};

const deleteGeoZone = (id) => {
  return (dispatch) => {
    return GeoZoneServices.delete(id)
      .then(() => {
        return dispatch(getEmployeeGeoZones());
      })
      .catch((error) => {
        dispatch(fail(error.response));
      });
  };
};

const addEmployee = (id, employeeId) => {
  return (dispatch) => {
    GeoZoneServices.addEmployee(id, employeeId)
      .then(() => {
        return dispatch(getEmployeeGeoZones(id));
      })
      .catch((error) => {
        dispatch(fail(error.response));
      });
  };
};

const deleteEmployee = (id, employeeId) => {
  return (dispatch) => {
    GeoZoneServices.deleteEmployee(id, employeeId)
      .then(() => {
        return dispatch(getEmployeeGeoZones(id));
      })
      .catch((error) => {
        dispatch(fail(error.response));
      });
  };
};

const requestReset = () => {
  return {
    type: RESET_REQUEST_EMPLOYEE_GEO_ZONES,
  };
};

const reset = () => {
  return (dispatch) => {
    dispatch(requestReset());
  };
};

export {
  getEmployeeGeoZones,
  createGeoZone,
  updateGeoZone,
  deleteGeoZone,
  addEmployee,
  deleteEmployee,
  reset,
  requestEmployeeGeoZonesAction,
  responseEmployeeGeoZonesAction,
  RESPONSE_EMPLOYEE_GEO_ZONES,
  RESET_REQUEST_EMPLOYEE_GEO_ZONES,
  REQUEST_EMPLOYEE_GEO_ZONES,
  REQUEST_EMPLOYEE_GEO_ZONES_FAILURE,
};
