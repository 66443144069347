import React from "react";
import GeoJsonBuilding from './geoJsonBuilding';

const GeoJsonBuildings = (props) => {
    const { features } = props;

    const employeeBuildings = features.map((feature) => (
        <li key={feature.id} >
            <GeoJsonBuilding key={feature.id} feature={feature} />
        </li>
    ));

    return (<ul>{employeeBuildings}</ul>
    )
};

export default React.memo(GeoJsonBuildings);