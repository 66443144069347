import {
  REQUEST_LOGIN,
  RESPONSE_LOGIN,
  REQUEST_LOGIN_FAILURE,
  RESPONSE_LOGOUT,
  REQUEST_LOGOUT_FAILURE,
  SET_LOGIN,
  SET_LOGOUT,
  SET_REGISTRATION,
  CLEAR_AUTH_ERRORS,
} from "../actions/authActions";

import { states as queriesStates } from "./queriesStates";

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  data: {},
  permissions: [],
  authState: false,
  errors: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REGISTRATION:
      return Object.assign({}, state, {
        data: action.data,
        authState: false,
        registration: true,
        errors: [],
      });
    case REQUEST_LOGIN:
      return Object.assign({}, state, {
        dataState: queriesStates.REQUEST,
      });
    case RESPONSE_LOGIN:
      return Object.assign({}, state, {
        dataState: queriesStates.READY,
        permissions: action.data.permissions,
        data: action.data,
        authState: true,
        errors: [],
      });
    case REQUEST_LOGIN_FAILURE:
      return Object.assign({}, state, {
        dataState: queriesStates.FAILURE,
        authState: false,
        errors: action.errors,
      });
    case RESPONSE_LOGOUT:
      return Object.assign({}, state, {
        dataState: queriesStates.READY,
        data: {},
        permissions: [],
        authState: false,
        errors: [],
      });
    case REQUEST_LOGOUT_FAILURE:
      return Object.assign({}, state, {
        dataState: queriesStates.FAILURE,
        authState: false,
        errors: action.errors,
      });

    case SET_LOGIN:
      return Object.assign({}, state, {
        dataState: queriesStates.READY,
        data: action.data,       
        authState: true,
        errors: [],
      });
    case SET_LOGOUT:
      return state.authState ? Object.assign({}, state, initialState) : state;
    case CLEAR_AUTH_ERRORS:
      return Object.assign({}, state, {
        errors: [],
      });
    default:
      return state;
  }
};

export default authReducer;
