import React, { Component } from "react";

import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import Icon from "./../static/icon";
import { ThemeProvider } from "@material-ui/styles";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "./static.inputDateTime";
import { datepickerTheme } from "./static.modalDateTime";

import inputStyles from "./input.module.scss";
import dateTime from "../../services/date.service";

class InputTime extends Component {
  rountFive(number) {
    return Math.ceil(number / 5) * 5;
  }

  componentDidMount() {
    const { onHandleChangeStore, valueStore, name } = this.props;
    const date = new Date();
    const thisDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      0,
      0
    );

    if (!!valueStore[name]) {
      if (typeof valueStore[name].getFullYear === "undefined") {
        onHandleChangeStore(valueStore[name]);
        return;
      }

      const thisValueStore = new Date(
        valueStore[name].getFullYear(),
        valueStore[name].getMonth(),
        valueStore[name].getDate(),
        valueStore[name].getHours(),
        valueStore[name].getMinutes(),
        0,
        0
      );

      if (
        thisDate.getTime() !== thisValueStore.getTime() &&
        thisValueStore.getTime() !== dateTime.withEmptyTimeUtcNumber(valueStore)
      ) {
        onHandleChangeStore(thisValueStore);
      }
    } else {
      onHandleChangeStore(thisDate);
    }
  }

  render() {
    const { classes, onHandleChangeStore, valueStore, name, disabled } = this.props;
    const date = new Date();
    const thisDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      0
    );

    return (
      <ThemeProvider theme={datepickerTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            className={clsx(classes.root, inputStyles.inputStyle)}
            ampm={false}
            value={!!valueStore[name] ? valueStore[name] : thisDate}
            onChange={onHandleChangeStore}
            inputVariant="outlined"
            keyboardIcon={<Icon name="Subtract" />}
            invalidDateMessage="Неверный формат"
            okLabel="Выбрать"
            cancelLabel="Отмена"
            disabled={!!disabled ? disabled : false}
            autoOk={true}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(InputTime);
