import React from "react";

import styles from "./formItemsInlineGroup.module.scss";

const FormItemsInlineGroup = (props) => {
  return props && props.children && props.children.length ? (
    <div className={styles.container_formItemsInlineGroup}>
      {props.children.map((item, index) => {
        return (
          <div className={styles.container_formItemInlineGroupItem} key={index}>
            {item}
          </div>
        );
      })}
    </div>
  ) : (
    <div className={styles.container_formItemInlineGroupItem} >
      {props.children}
    </div>
  );
};

export default FormItemsInlineGroup;
