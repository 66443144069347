import React from "react";
import IntelligenceInTimeRangeTable from "./intelligenceInTimeRangeTable";
import Notification from "../../../shared/items/static/notification";
import Preloader from "../../../shared/items/static/preloader";
import messages from "../../../shared/static.messages";
import { states } from "../../../reducers/queriesStates";
import { withTimerangeIntellegence } from "../../../shared/containers/containerTimerangeIntellegence";

import styles from "./intelligenceInTimeRangeTable.module.scss";

const IntelligenceTimeRangeTableWrapper = (props) => {
  const { timerangeIntellegence } = props;

  if (timerangeIntellegence.dataState === states.READY) {
    if (timerangeIntellegence.data.length > 0) {
      return <IntelligenceInTimeRangeTable />;
    } else {
      return (
        <Notification type="warning">
          <b>{`Данные не найдены.`}</b>
        </Notification>
      );
    }
  } else if (timerangeIntellegence.dataState === states.REQUEST) {
    return (
      <div className={styles.container_preloader}>
        <Preloader />
      </div>
    );
  } else if (timerangeIntellegence.dataState === states.FAILURE) {
    return (
      <Notification type="error">
        <p>{messages.error}</p>
      </Notification>
    );
  } else {
    return null;
  }
};

export default withTimerangeIntellegence(IntelligenceTimeRangeTableWrapper);
