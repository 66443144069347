import {
  PLAY_ROUTE,
  PAUSE_ROUTE,
  SET_ROUTE_LENGTH,
  SET_ROUTE_DATES,
  SET_CURRENT_POINT,
  CLEAR_PLAYER_DATA
} from './../actions/intellegenceTimeRangePlayerActions'

const initialState = {
  isPlay: false,
  routeLength: 0,
  routeDates: [],
  currentPointIndex: 0
}

const intellegenceTimeRangePlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAY_ROUTE:
      return Object.assign(
        {},
        state,
        {
          isPlay: true
        }
      )
    case PAUSE_ROUTE:
      return Object.assign(
        {},
        state,
        {
          isPlay: false
        }
      )
    case SET_ROUTE_LENGTH:
      return Object.assign(
        {},
        state, 
        {
          routeLength: action.data
        }
      )
    case SET_ROUTE_DATES:
      return Object.assign(
        {},
        state,
        {
          routeDates: action.data
        }
      )
    case SET_CURRENT_POINT:
      return Object.assign(
        {},
        state,
        {
          currentPointIndex: action.data
        }
      )
    case CLEAR_PLAYER_DATA:
      return Object.assign(
        {},
        state,
        initialState
      )
    default:
      return state
  }
}

export default intellegenceTimeRangePlayerReducer