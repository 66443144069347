import React, { Component } from 'react';

import { withDashboards } from '../../../shared/containers/containerDashboards'
import { withDashboard } from '../../../shared/containers/containerDashboard'
import { withChoosedEmployee } from '../../../shared/containers/containerChoosedEmployee'

import DeviceItem from './deviceItem'
import Preloader from '../../../shared/items/static/preloader'
import { getDashboardData } from './getDashboardData'
import Notification from '../../../shared/items/static/notification'
import messages from '../../../shared/static.messages'

import styles from './employeeDevicesMain.module.scss'

class EmployeeDevicesMain extends Component {
  componentDidMount() {
    const { dashboards, onGetDashboards } = this.props

    if (dashboards.dataState !== "STATE_READY"){
      onGetDashboards()
    }     
  }

  componentDidUpdate(prevProps) {
    const { choosedEmployee, onGetDashboard } = this.props

    if (choosedEmployee.employeeId !== prevProps.choosedEmployee.employeeId) {
      onGetDashboard(choosedEmployee.employeeId)
    }
  }

  render() {
    const { dashboards, dashboard } = this.props
    let dashboardData = getDashboardData(dashboards.data, dashboard.data)

    return (
      <div className={styles.employeeDevicesMain}>
        {
          dashboard.dataState === "STATE_READY"
            ? Object.keys(dashboard.data).length !== 0
              ? dashboardData.map((item, index) => {
                return <div className={styles.container_deviceItem} key={index}>
                  <DeviceItem
                    title={item.title}
                    value={item.value}
                    iconName={item.icon}
                  />
                </div>
              })
              : <div className={styles.container_message}>
                <Notification type="warning">
                  <p>{messages.noData}</p>
                </Notification>
              </div>
            : <div className={styles.container_loader}><Preloader /></div>
        }
      </div>
    )
  }
}

export default withChoosedEmployee(withDashboard(withDashboards(EmployeeDevicesMain)));
