import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import variables from './../../../values.scss'

const StyledCircularProgress = withStyles({
  root: {
    color: variables.preloaderColor,
  },
})(CircularProgress);

const Preloader = props => {
  const { size } = props

  return (
    <StyledCircularProgress size={!!size ? size : 40} />
  )
}

export default Preloader