import React, { Component } from "react";

import Icon from "../static/icon";

import styles from "./dropDownSelect.module.scss";

class DropDownSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      count: 0, //TODO: кастыль
    };

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleDropDownToggle() {
    var count = this.state.count + 1;
    this.setState({ count: count === 2 ? 0 : 1 });

    if (count === 2) {
      if (!this.state.open) {
        document.addEventListener("click", this.handleOutsideClick, false);
      } else {
        document.removeEventListener("click", this.handleOutsideClick, false);
      }

      this.setState({ count: 0 });
    }

    this.setState({ open: !this.state.open });
  }

  handleOutsideClick() {
    this.handleDropDownToggle();
  }

  render() {
    const { buttonTitle, content } = this.props;
    return (
      <div className={styles.dropDownSelect}>
        <button
          className={this.state.open ? styles.dropDownSelectButton_open : styles.dropDownSelectButton}
          onClick={(e) => {
            this.handleDropDownToggle(e);
          }}
        >
          {buttonTitle}
          <div className={styles.container_sortIcon}>
            <Icon name="direction-desc" />
          </div>
        </button>

        <div
          className={this.state.open ? styles.dropDownSelectItems_open : styles.dropDownSelectItems_close}
          onClick={(e) => {
            this.handleDropDownToggle(e);
          }}
        >
          {content}
        </div>
      </div>
    );
  }
}

export default DropDownSelect;
