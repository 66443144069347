import React, { Component } from "react";
import MenuItem from "@mui/material/MenuItem";

class NotificationsSettingItmems extends Component {
  render() {
    const { notificationTypes, unsubscribes, subscribes } = this.props;

    return notificationTypes.map((item) => {
      const id = "notificationTypeId_" + item.id;

      const onChange = (e) => {
        e.stopPropagation();
        if (item.userSubscribed) {
          unsubscribes(item.id);
        } else {
          subscribes(item.id);
        }
      };

      return (
        <MenuItem>
          <input id={id} type={"checkbox"} checked={item.userSubscribed} onChange={onChange}></input>
          <label for={id} onClick={onChange}>
            {item.title}
          </label>
        </MenuItem>
      );
    });
  }
}

export default NotificationsSettingItmems;
