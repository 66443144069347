import { connect } from "react-redux";
import get from "lodash/get";

import {
  getEmployeeSettings as onGetEmployeeSettings,
  addEmployeeSettings as onAddEmployeeSettings,
  updateEmployeeSettings as onUpdateEmployeeSettings,
  employeeReprocces as onEmployeeReprocces,
} from "../../actions/employeeSettingsAction";

const mapStateToProps = (state) => ({
  employeeSettings: {
    dataState: state.employeeSettings.dataState,
    reproccesDataState: state.employeeSettings.reproccesDataState,
    settings: get(state, ["employeeSettings", "settings"], []),
    reprocces: get(state, ["employeeSettings", "reprocces"], null),
  },
});

const mapDispatchToProps = {
  onGetEmployeeSettings,
  onAddEmployeeSettings,
  onUpdateEmployeeSettings,
  onEmployeeReprocces,
};

const withEmployeeSettings = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withEmployeeSettings };
