import {
  REQUEST_EMPLOYEES,
  RESPONSE_EMPLOYEES,
  REQUEST_EMPLOYEES_FAILURE,
  STATE_NOT_REQUESTED,
  RESET_REQUEST_EMPLOYEES,
} from "../actions/employeesActions";

import { states as queriesStates } from "./queriesStates";

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  units: [],
  total: 0,
  filterCount: 0,
};

const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_REQUEST_EMPLOYEES: {
      return Object.assign({}, state, initialState);
    }
    case REQUEST_EMPLOYEES: {
      return Object.assign({}, state, {
        dataState: queriesStates.REQUEST,
        errors: [],
      });
    }
    case STATE_NOT_REQUESTED: {
      return Object.assign({}, state, {
        dataState: queriesStates.NOT_REQUESTED,
        errors: [],
      });
    }
    case RESPONSE_EMPLOYEES: {
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY,
        },
        action.data
      );
    }
    case REQUEST_EMPLOYEES_FAILURE: {
      return Object.assign({}, state, {
        dataState: queriesStates.FAILURE,
        errors: action.errors,
      });
    }
    default:
      return state;
  }
};

export default employeesReducer;
