import React from 'react';
import TitleBarWrapper from '../../../shared/titleBarWrapper'
import FirstTitle from '../../../shared/items/static/firstTitle'
import NewUserForm from './newUserForm'

const USERS_BAR_TITLE = "Пользователи"

const UsersTitleBar = props => {

  return (

    <TitleBarWrapper>
      <FirstTitle>
        {USERS_BAR_TITLE}
        <div >
          <NewUserForm />
        
        </div>
      </FirstTitle>
    </TitleBarWrapper>
  )
}

export default React.memo(UsersTitleBar)
