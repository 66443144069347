import { requestEmployeesNotRequestAction } from "./employeesActions";
import { requestFilialsNotRequestAction } from "./employeesCompaniesActions";
import CompaniesService from "../shared/services/companiesService";
import DeviceService from "../shared/services/deviceService";
import UsersService from "../shared/services/usersService";
import configuration from "./../config.json";
import CookieService from "../shared/services/cookiesService";

const REQUEST_COMPANIES = "REQUEST_COMPANIES";
const RESPONSE_COMPANIES = "RESPONSE_COMPANIES";
const REQUEST_COMPANIES_FAILURE = "REQUEST_COMPANIES_FAILURE";

const requestGetCompaniesAction = () => {
  return {
    type: REQUEST_COMPANIES,
  };
};

const responseGetCompaniesAction = (data) => {
  return {
    type: RESPONSE_COMPANIES,
    data,
  };
};

const requestGetCompaniesfailureAction = (errors) => {
  return {
    type: REQUEST_COMPANIES_FAILURE,
    errors,
  };
};

const _getCompanies = (dispatch, сompanyOptions, deviceInfo = null, error = null) => {
  CompaniesService.getCompanies(сompanyOptions)
    .then((result) => {
      if (сompanyOptions) {
        result.data.getCompanyOptions = сompanyOptions;
      }
      result.data.deviceInfo = deviceInfo;
      result.data.error = error;
      dispatch(responseGetCompaniesAction(result.data, error));
      dispatch(requestEmployeesNotRequestAction());
      dispatch(requestFilialsNotRequestAction());
    })
    .catch((error) => {});
};

const getCompanies = (options) => {
  return (dispatch) => {
    dispatch(requestGetCompaniesAction());
    _getCompanies(dispatch, options);
  };
};

const enterCompany = (options, companyOptions) => {
  return (dispatch) => {
    CompaniesService.enterCompany(options)
      .then((result) => {
        const authCookie = JSON.parse(CookieService.getCookie(configuration.cookieName));
        authCookie.title = options.title;
        CookieService.setCookie(configuration.cookieName, JSON.stringify(authCookie));
        _getCompanies(dispatch, companyOptions);
      })
      .catch((error) => {});
  };
};

const updateCompanyDevice = (options, companyOptions) => {
  return (dispatch) => {
    dispatch(requestGetCompaniesAction());
    DeviceService.update(options)
      .then((result) => {
        _getCompanies(dispatch, companyOptions);
      })
      .catch((error) => {});
  };
};

const commandCompanyDevices = (options, companyOptions) => {
  return (dispatch) => {
    dispatch(requestGetCompaniesAction());
    DeviceService.command(options)
      .then((result) => {
        _getCompanies(dispatch, companyOptions);
      })
      .catch((error) => {});
  };
};

const transferCompanyDevices = (options, companyOptions) => {
  return (dispatch) => {
    DeviceService.transfer(options)
      .then((result) => {
        enterCompany({ id: options.id }, companyOptions);

        //     _getCompanies(dispatch, companyOptions);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

const infoDevice = (options, companyOptions) => {
  return (dispatch) => {
    dispatch(requestGetCompaniesAction());
    DeviceService.getInfo(options)
      .then((result) => {
        _getCompanies(dispatch, companyOptions, result.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

const createOwnerUser = (options, companyOptions) => {
  return (dispatch) => {
    dispatch(requestGetCompaniesAction());
    options.roleType = 1;
    UsersService.newUser(options)
      .then((result) => {
        _getCompanies(dispatch, companyOptions);
      })
      .catch((error) => {
        _getCompanies(dispatch, companyOptions, null, "Не удалось");
      });
  };
};

export {
  getCompanies,
  enterCompany,
  requestGetCompaniesAction,
  responseGetCompaniesAction,
  requestGetCompaniesfailureAction,
  updateCompanyDevice,
  commandCompanyDevices,
  transferCompanyDevices,
  createOwnerUser,
  infoDevice,
  REQUEST_COMPANIES,
  RESPONSE_COMPANIES,
  REQUEST_COMPANIES_FAILURE,
};
