import React from "react";

import SideContentInner from "../../../shared/sideContentInner";
import TabsNavbar from "../../../shared/tabsNavbar";
import { withIntellegenceTab } from "../../../shared/containers/containerIntellegenceTab";
import { intellegenceTabs } from "../../sections/employees/static.intellegenceTabs";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

import { withEmployeeMapState } from "../../../shared/containers/containerEmployeeMapState";
import { withIntellegenceRangeType } from "../../../shared/containers/containerIntellegenceRangeType";

import RadioItem from "../../../shared/items/contols/radioItem";
import { styles } from "../../../shared/items/contols/static.radioGroup";

import TimeRange from "../employees/static.timeRange";
import { mapState } from "../employees/static.employeeMapState";
import CookieService from "../../../shared/services/cookiesService";

const handleChange = (props, e) => {
  const { onChangeIntellegenceRangeType, onChangeEmployeeMapState } = props;

  e.target.value === TimeRange.intimerange.value
    ? onChangeEmployeeMapState("mapSize", mapState.fullWidth.name)
    : onChangeEmployeeMapState("mapSize", mapState.baseSize.name);

  onChangeIntellegenceRangeType("rangeType", e.target.value);
};

const IntelligenceEmployeesNavbar = (props) => {
  const { activeTabName, onSetIntellegenceTab, intellegenceRangeType, classes } = props;
  const logPermmisions = CookieService.getPermissions("permissionTN").includes(30001);
  return (
    <div>
      <SideContentInner>
        <TabsNavbar data={intellegenceTabs} active={activeTabName} handleChange={onSetIntellegenceTab} />
        {activeTabName === "intellegence" ? (
          <RadioGroup
            row
            className={classes.row}
            value={intellegenceRangeType.rangeType}
            onChange={handleChange.bind(this, props)}
          >
            <FormControlLabel
              value={TimeRange.intimerange.value}
              control={<RadioItem />}
              label={TimeRange.intimerange.title}
            />
            <FormControlLabel style={{ display: "none" }} control={<RadioItem />} />
            {logPermmisions ? (
              <FormControlLabel
                value={TimeRange.deviceLog.value}
                control={<RadioItem />}
                label={TimeRange.deviceLog.title}
              />
            ) : null}
          </RadioGroup>
        ) : null}
      </SideContentInner>
    </div>
  );
};

export default withStyles(styles)(
  withEmployeeMapState(withIntellegenceTab(withIntellegenceRangeType(React.memo(IntelligenceEmployeesNavbar))))
);
/* 
export default withIntellegenceTab(React.memo(IntelligenceEmployeesNavbar)); */
