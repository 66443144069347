import {
  SET_TIMERANGE_INTELLIGENCE_DATA,
  CLEAR_TIMERANGE_INTELLIGENCE_DATA,
} from "../actions/timerangeIntelligenceDataActions";

const initialState = {
  data: [],
  features: [],
  groupStatus: false,
};

const timerangeIntelligenceDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMERANGE_INTELLIGENCE_DATA:
      return Object.assign({}, state, {
        data: action.data,
        features: action.features,
        groupStatus: action.groupStatus,
      });
    case CLEAR_TIMERANGE_INTELLIGENCE_DATA:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
};

export default timerangeIntelligenceDataReducer;
