import React, { Component } from "react";
import { withTimerangeIntellegence } from "../../../shared/containers/containerTimerangeIntellegence";
import { withChoosedEmployee } from "../../../shared/containers/containerChoosedEmployee";
import CookieService from "../../../shared/services/cookiesService";
import ButtonMediumDefault from "../../../shared/items/contols/buttonMediumDefault";
import dateTime from "../../../shared/services/date.service";

class IntelligenceInTimeRangeContentControls extends Component {
  getNextDayIntellegence() {
    const { onGetTimerangeIntellegence, onSetTimerangeDates, timerangeIntellegence, choosedEmployee } = this.props;
    var currentDate = new Date(timerangeIntellegence.currentDate);
    currentDate.setDate(currentDate.getDate() + 1);
    const date = dateTime.withEmptyTime(currentDate);

    const logPermmisions = CookieService.getPermissions("permissionTN").includes(30001);

    onGetTimerangeIntellegence({
      date,
      employeeId: choosedEmployee.employeeId,
      rangeStartDate: timerangeIntellegence.rangeStartDate,
      rangeEndDate: timerangeIntellegence.rangeEndDate,
      locationNotProcessed: logPermmisions,
    });

    onSetTimerangeDates({
      currentDate: date,
      rangeStartDate: timerangeIntellegence.rangeStartDate,
      rangeEndDate: timerangeIntellegence.rangeEndDate,
      locationNotProcessed: logPermmisions,
    });
  }

  getPrevDayIntellegence() {
    const { onGetTimerangeIntellegence, onSetTimerangeDates, timerangeIntellegence, choosedEmployee } = this.props;
    var currentDate = new Date(timerangeIntellegence.currentDate);
    currentDate.setDate(currentDate.getDate() - 1);
    const date = dateTime.withEmptyTime(currentDate);
    const logPermmisions = CookieService.getPermissions("permissionTN").includes(30001);

    onGetTimerangeIntellegence({
      date,
      employeeId: choosedEmployee.employeeId,
      rangeStartDate: timerangeIntellegence.rangeStartDate,
      rangeEndDate: timerangeIntellegence.rangeEndDate,
      locationNotProcessed: logPermmisions,
    });

    onSetTimerangeDates({
      currentDate: date,
      rangeStartDate: timerangeIntellegence.rangeStartDate,
      rangeEndDate: timerangeIntellegence.rangeEndDate,
      locationNotProcessed: logPermmisions,
    });
  }

  render() {
    const { timerangeIntellegence } = this.props;

    return null;

    // if (timerangeIntellegence.dataState === "STATE_READY") {
    //   return (
    //     <div>
    //       {
    //         <table style={{ width: "100%", marginTop: "25px" }}>
    //           <tr>
    //             <td style={{ width: "40%" }}>
    //               <div style={{ display: "flex" }}>
    //                 {dateTime.withEmptyTimeUtcNumber(timerangeIntellegence.rangeStartDate) !==
    //                 dateTime.withEmptyTimeUtcNumber(timerangeIntellegence.currentDate) ? (
    //                   <div style={{ paddingRight: "10px" }}>
    //                     <ButtonMediumDefault
    //                       title="Предыдущий день"
    //                       handleClick={this.getPrevDayIntellegence.bind(this)}
    //                     />
    //                   </div>
    //                 ) : null}
    //                 <b>{dateTime.toFormat2(timerangeIntellegence.currentDate)}</b>
    //                 {dateTime.withEmptyTimeUtcNumber(timerangeIntellegence.rangeEndDate) !==
    //                 dateTime.withEmptyTimeUtcNumber(timerangeIntellegence.currentDate) ? (
    //                   <div style={{ paddingLeft: "10px" }}>
    //                     <ButtonMediumDefault
    //                       title="Следующий день"
    //                       handleClick={this.getNextDayIntellegence.bind(this)}
    //                     />
    //                   </div>
    //                 ) : null}
    //               </div>
    //             </td>
    //           </tr>
    //         </table>
    //       }
    //     </div>
    //   );
    // } else return null;
  }
}

export default withChoosedEmployee(withTimerangeIntellegence(IntelligenceInTimeRangeContentControls));
