import React, { Component } from "react";
import { getDaysWeekState, getDaysWeekUTC } from "./DaysWeekEnum";
import { withObjectives } from "../../../shared/containers/containerObjectives";
import InputDate from "../../../shared/items/contols/inputDate";
import InputTime from "../../../shared/items/contols/inputTime";
import ScheduleIntervalPanel from "./objectiveSсheduleIntervalPanel";
import dateTime from "../../../shared/services/date.service";
import ButtonPrimary from "../../../shared/items/contols/buttonPrimary";
import Notification from "../../../shared/items/static/notification";

class SchedulePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schedule: props.schedule
        ? props.schedule
        : {
            daysWeek: 0,
            dateStart: new Date(),
            timeStart: "00:00",
            dateEnd: new Date(),
            timeEnd: "23:59",
          },
      dayCheked: getDaysWeekState(props.schedule),
      daysWeek: getDaysWeekState(props.schedule),
      objectiveType: props.objectiveType,
      valueStore: props.valueStore ? props.valueStore : this.getEmptyValueStore(),
    };
  }

  componentDidMount() {
    const { schedule } = this.state;

    this.setState({
      schedule: schedule,
      daysWeek: getDaysWeekState(schedule),
      dayCheked: getDaysWeekState(schedule),
    });
  }

  getEmptyDateTime(hours = 0, minutes = 0) {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0, 0);
  }

  getEmptyValueStore() {
    const now = new Date();
    return {
      dateStart: this.getEmptyDateTime(),
      timeStart: this.getEmptyDateTime(now.getHours(), now.getMinutes() + 5),
      dateEnd: this.getEmptyDateTime(),
      timeEnd: this.getEmptyDateTime(23, 59),
    };
  }

  onHandleChangeStore(e, value, name) {
    if (value && name) {
      if (value.includes("_")) {
        return;
      }

      const { valueStore, schedule } = this.state;
      valueStore[name] = e;

      if (name.includes("time")) {
        const values = value.split(":");
        valueStore[name] = this.getEmptyDateTime(values[0], values[1]);

        if (schedule.daysWeek !== 0 && valueStore.timeStart > valueStore.timeEnd) {
          valueStore.timeStart = valueStore.timeEnd;
        }
      }

      if (schedule.daysWeek === 0) {
        const dateStart = dateTime.concat(valueStore.dateStart, valueStore.timeStart);
        const dateEnd = dateTime.concat(valueStore.dateEnd, valueStore.timeEnd);

        if (valueStore.dateStart - new Date() < 0) {
          valueStore.timeStart = valueStore.dateStart = new Date();
          valueStore.timeStart = new Date(valueStore.timeStart.setMinutes(valueStore.timeStart.getMinutes() + 5));
        }

        if (dateEnd - dateStart >= 86400000) {
          if (name === "dateEnd") {
            valueStore.timeStart = valueStore.dateStart = new Date(dateEnd - 86100000);
          } else if (name === "dateStart") {
            valueStore.timeEnd = valueStore.dateEnd = new Date().setDate(valueStore.timeStart.getDate() + 1);
          }
        }

        if (dateStart > dateEnd) {
          valueStore.dateEnd = valueStore.dateStart;
          valueStore.timeEnd = valueStore.timeStart;
        }
      }

      this.setState({
        valueStore: valueStore,
      });
    }
  }

  onDayClick(day, add) {
    const { schedule } = this.state;

    if (schedule) {
      if (!schedule.daysWeek) {
        schedule.daysWeek = 0;
      }

      if (add) {
        schedule.daysWeek += day;
      } else schedule.daysWeek -= day;

      this.setState({ schedule: schedule, dayCheked: getDaysWeekState(schedule) });
    }
  }

  onCreateSchedule() {
    const { valueStore, schedule, objectiveType } = this.state;
    const { onCreateSchedule, objectiveId, daysWeekType } = this.props;

    schedule.timeStart = dateTime.onlyTimeString(valueStore.timeStart);
    schedule.timeEnd = dateTime.onlyTimeString(valueStore.timeEnd);

    if (daysWeekType) {
      schedule.dateStart = null;
      schedule.dateEnd = null;
      schedule.daysWeek = getDaysWeekUTC(schedule);
    } else {
      schedule.dateStart = dateTime.onlyDateString(dateTime.concat(valueStore.dateStart, valueStore.timeStart));
      schedule.dateEnd = dateTime.onlyDateString(dateTime.concat(valueStore.dateEnd, valueStore.timeEnd));
      schedule.daysWeek = 0;
    }

    onCreateSchedule(objectiveId, schedule, objectiveType);
  }

  render() {
    const { schedule, valueStore } = this.state;
    const { daysWeekType, objectives } = this.props;
    if (!schedule) {
      return null;
    }

    const onHandleChangeStore = (e, value, name) => {
      this.onHandleChangeStore(e, value, name);
    };

    const dayClick = (day, add) => {
      this.onDayClick(day, add);
    };

    const createSchedule = () => {
      this.onCreateSchedule();
    };
    let invalidLabel = null;
    if (objectives.errors && objectives.errors["dateStart"]) {
      invalidLabel = objectives.errors["dateStart"][0];
    }

    return (
      <div className={"schedulePanel" + (!schedule.id ? "Created" : "")}>
        {!daysWeekType ? (
          <div>
            <div className={"sheduleDateTimeInput"}>
              <span>Дата начала</span>
              <InputDate
                disabled={schedule.id !== undefined}
                name="dateStart"
                disablePast={true}
                onHandleChangeStore={(e, value) => {
                  onHandleChangeStore(e, value, "dateStart");
                }}
                valueStore={valueStore}
              />
            </div>
            <div className={"sheduleDateTimeInput"}>
              <span>Дата завершения</span>
              <InputDate
                disabled={schedule.id !== undefined}
                name="dateEnd"
                disablePast={true}
                onHandleChangeStore={(e, value) => {
                  onHandleChangeStore(e, value, "dateEnd");
                }}
                valueStore={valueStore}
              />
            </div>
          </div>
        ) : null}
        {daysWeekType ? (
          <div className="sheduleDaysWeek">
            <ScheduleIntervalPanel
              dayClick={!schedule.id ? dayClick : () => {}}
              dayCheked={getDaysWeekState(schedule)}
            />
          </div>
        ) : null}
        <div>
          <div className={"sheduleDateTimeInput"}>
            <span>Время начала</span>
            <InputTime
              disabled={schedule.id !== undefined}
              name={"timeStart"}
              onHandleChangeStore={(e, value) => {
                onHandleChangeStore(e, value, "timeStart");
              }}
              valueStore={valueStore}
            />
          </div>
          <div className={"sheduleDateTimeInput"}>
            <span>Время завершения</span>
            <InputTime
              disabled={schedule.id !== undefined}
              name={"timeEnd"}
              onHandleChangeStore={(e, value) => {
                onHandleChangeStore(e, value, "timeEnd");
              }}
              valueStore={valueStore}
            />
          </div>
        </div>
        <div className={"objectiveButtonWrapper"}>
          {invalidLabel !== null ? (
            <Notification type="error">
              <b>{invalidLabel}</b>
            </Notification>
          ) : null}
          <ButtonPrimary
            disabled={daysWeekType && schedule.daysWeek === 0}
            handleClick={createSchedule.bind(this)}
            title="Новое расписание"
          />
        </div>
      </div>
    );
  }
}

export default withObjectives(SchedulePanel);
