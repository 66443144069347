
const states = {
    NOT_REQUESTED: 'STATE_NOT_REQUESTED',
    REQUEST: 'STATE_REQUEST',
    READY: 'STATE_READY',
    FAILURE: 'STATE_FAILURE'
}

export {
    states
}