import React from 'react';

import { withIntellegenceModalForm } from '../../../shared/containers/containerIntellegenceModalForm'
import { withSensors } from '../../../shared/containers/containerSensors'
import { withDashboards } from '../../../shared/containers/containerDashboards'

import FormItemsInline from '../../../shared/items/static/formItemsInline'
import FormItem from '../../../shared/items/static/formItem'
import InputDate from '../../../shared/items/contols/inputDate'
import InputTime from '../../../shared/items/contols/inputTime'
import DeviceItem from '../employees/deviceItem'
import ValidateErrorMessage from '../../../shared/items/static/validateErrorMessage'
import { getDashboardData } from '../employees/getDashboardData'
import Preloader from '../../../shared/items/static/preloader'

import styles from './intellegenceEmployeesModal.module.scss'

const IntellegenceEmployeeModal = props => {
  const { onHandleChangeStore, intellegenceModalForm, sensors, dashboards } = props
  const valueStore = intellegenceModalForm.intellegenceModalForm
  const sensorsData = getDashboardData(dashboards.data, sensors.data.sensors)

  return (
    <div className={styles.intellegenceEmployeesModal}>
      <div className={styles.container_settings}>
        <div className={styles.container_dates}>
          <FormItemsInline>
            <div className={styles.container_dateItem}>
              <FormItem title="Дата">
                <InputDate
                  name="momentDate"
                  onHandleChangeStore={onHandleChangeStore.bind(this, "momentDate")}
                  valueStore={valueStore}
                  disabled={true}
                />
              </FormItem>
            </div>
            <div className={styles.container_dateItem}>
              <FormItem title="Время">
                <InputTime
                  name="momentTime"
                  onHandleChangeStore={onHandleChangeStore.bind(this, "momentTime")}
                  valueStore={valueStore}
                  disabled={true}
                />
              </FormItem>
            </div>
          </FormItemsInline>
        </div>
        {/* <div className="container-buttons">
            <div className="buttonsBlock">
              <div className="container-exportItem">
                <ButtonMediumDefault handleClick={this.handleExportToPDF}><Icon name="export" />Экспорт в PDF</ButtonMediumDefault>
              </div>
              <div className="container-exportItem">
                <ButtonMediumDefault handleClick={this.handleExportToXLS}><Icon name="export" />Экспорт в XLS</ButtonMediumDefault>
              </div>
              <div className="container-exportItem">
                <ButtonMediumDefault handleClick={this.handleSendToEmail}><Icon name="envelope" />Отправить на EMAIL</ButtonMediumDefault>
              </div>
              <div className="container-exportItem">
                <ButtonMediumDefault handleClick={this.handlePrint}><Icon name="file" />Печать</ButtonMediumDefault>
              </div>
            </div>
          </div> */}
      </div>
      <div className={styles.conatiner_info}>
        <span className={styles.title}>Основная информация</span>
        {
          sensors.dataState === "STATE_READY"
            ? <div className={styles.container_deviceItems}>
              {
                sensorsData.map((item, index) => {
                  return <DeviceItem
                    title={item.title}
                    value={item.value}
                    key={index}
                  />
                })
              }npm 
            </div>
            : sensors.dataState === "STATE_FAILURE"
              ? <ValidateErrorMessage title={sensors.errors.data.dateTime} />
              : <div className={styles.container_preloader}><Preloader /></div>
        }
      </div>
    </div>
  )
}

export default withDashboards(withSensors(withIntellegenceModalForm(React.memo(IntellegenceEmployeeModal))))