import {
  RESPONSE_EMPLOYEE_GROUPS,
  REQUEST_EMPLOYEE_GROUPS_FAILURE,
} from '../actions/employeeGroupsActions'

import { states as queriesStates } from './queriesStates'

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  groups: []
}

const employeeGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESPONSE_EMPLOYEE_GROUPS: {
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY,
          groups: action.data
        }
      )
    }
    case REQUEST_EMPLOYEE_GROUPS_FAILURE: {
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.FAILURE,
          errors: action.errors,
        }
      )
    }
    default:
      return state
  }
}

export default employeeGroupsReducer
