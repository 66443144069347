import React from 'react';

import DropDownItem from '../../../shared/items/contols/dropDownItem'
import { sortEmployeesTypes } from './static.sortEmployeesTypes'

const SortEmployeesDropdownMenu = props => {
  const { handleChooseType } = props

  return (
    sortEmployeesTypes.map((item, key) => {
      return (
        <DropDownItem key={key}>
          <button onClick={handleChooseType.bind(this, item.type)}>{item.title}</button>
        </DropDownItem>
      )
    })
  )
}

export default React.memo(SortEmployeesDropdownMenu)
