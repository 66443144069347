const READY_CHANGE_STORE_INTELLEGENCE_MODAL_FORM = 'READY_CHANGE_STORE_INTELLEGENCE_MODAL_FORM'

const readyChangeStoreIntellegenceModalFormAction = (name, value) => {
  return {
    type: READY_CHANGE_STORE_INTELLEGENCE_MODAL_FORM,
    payload: { name, value }
  }
}

const handleChangeIntellegenceNodalForm = (name, value) => {
  return (dispatch) => {
    dispatch(readyChangeStoreIntellegenceModalFormAction(name, value))
  }
}

export {
  handleChangeIntellegenceNodalForm,
  readyChangeStoreIntellegenceModalFormAction,
  READY_CHANGE_STORE_INTELLEGENCE_MODAL_FORM
}