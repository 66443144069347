import React from 'react'

import Toggle from 'react-toggle'
import "react-toggle/style.css"

import './checkbox.scss'

const Checkbox = props => {
  const { id, name, handleChange, disabled, valueStore } = props

  return (
    <div className='container-checkbox'>
      <Toggle
        id={!!id ? id : name}
        name={name}
        icons={false}
        className='checkbox-styles'
        checked={!!valueStore[name] ? valueStore[name] : false}
        disabled={!!disabled ? disabled : false}
        onChange={handleChange.bind(this, name, !valueStore[name])}
      />
    </div>
  )
}

export default React.memo(Checkbox)