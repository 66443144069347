import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  setEmployeeBookmark as onSetEmployeeBookmark
} from '../../actions/setEmployeeBookmarkActions'

const mapStateToProps = (state) => ({
  setEmployeeBookmark: {
    dataState: state.setEmployeeBookmark.dataState,
    errors: get(state, ['setEmployeeBookmark', 'errors', 'data', null])
  }
})

const mapDispatchToProps = {
  onSetEmployeeBookmark
}

const withSetEmployeeBookmark = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withSetEmployeeBookmark }