import * as signalR from "@microsoft/signalr";
import WithToken from "../../../shared/services/tokenService";
import config from "../../../shared/services/apiConfig";

const HUB_PATH = "/Employees/LastStatesHub";
const PARAMETR_NAME = "baseURL";
const TOKEN_NAME = "authTNToken";
const SIGNALR_GROUP_NAME = "AddUserToGroup";
const state = signalR.HubConnectionState;

export const SignalRService = (() => {
  return {
    resiveEmployees: false,
    hubConnection: null, 
    newHubConnection: function() {
      if (this.hubConnection === null) {
        this.hubConnection = createHubConnection();
        this.hubConnection.onclose((error) => {
          if (error !== undefined) {
            setTimeout(() => this.start(), 5000);
          }
        });
      }

      function createHubConnection() {
        return new signalR.HubConnectionBuilder()
          .withUrl(config.get(PARAMETR_NAME) + HUB_PATH, {
            accessTokenFactory: () => WithToken.getToken(TOKEN_NAME),
          })
          .withAutomaticReconnect(getRetryDelay(50))
          .configureLogging(signalR.LogLevel.Warning)
          .build();
      }

      function getRetryDelay(tryCount) {
        const retryDelay = [1000];
        for (let i = 1; i < tryCount - 1; ++i) {
          retryDelay.push(Math.round(retryDelay[i - 1] * 1.3));
        }

        return retryDelay;
      }
    },
    start: function () {
      this.newHubConnection();
      if (this.hubConnection.state === state.Disconnected) {
        this.hubConnection
          .start()
          .then(() => {
            console.log(this.hubConnection.state);
      
            this.hubConnection.send(SIGNALR_GROUP_NAME, WithToken.getCompanyTitle(TOKEN_NAME));
          })
          .catch((err) => {
            this.running = false;           
            console.error(this.hubConnection.state, err);
            setTimeout(() => this.start(), 1000);
          });
      }
    },
  };
})();
