import React, { PureComponent } from 'react';

import { withAppState } from './../../shared/containers/containerAppState'

import Icon from './../../shared/items/static/icon'

import styles from './sideBar.module.scss'

class SideBar extends PureComponent {
  componentDidMount() {
    const {
      isEmployeesSecondSideBarOpen,
      onCloseEmployeesSecondSideBar
    } = this.props

    if (isEmployeesSecondSideBarOpen) {
      onCloseEmployeesSecondSideBar()
    }
  }

  handleSideBarClose() {
    const {
      isEmployeesSecondSideBarOpen,
      isDriversSecondBarOpen,
      onCloseSideBarAction,
      onCloseEmployeesSecondSideBar,
      onCloseDriversSecondSideBar
    } = this.props

    onCloseSideBarAction()
    if (isEmployeesSecondSideBarOpen) {
      onCloseEmployeesSecondSideBar()
    }

    if (isDriversSecondBarOpen) {
      onCloseDriversSecondSideBar()
    }
  }

  render() {
    const { isSideBarOpen, onOpenSideBarAction, TitleClosed } = this.props

    return (
      <div className={isSideBarOpen ? styles.sideBar_open : styles.sideBar_closed}>
        <button
          className={isSideBarOpen ? styles.buttonToggleOpen_barOpen : styles.buttonToggleOpen_barClosed}
          onClick={isSideBarOpen ? this.handleSideBarClose.bind(this) : onOpenSideBarAction}
        >
          <div className={styles.container_sideBarIcon}>
            <Icon name="forward" />
          </div>
        </button>
        <div className={styles.container_title}>
          {TitleClosed}
        </div>
        <div className={styles.container_sideBarInner}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default withAppState(SideBar)
