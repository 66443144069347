import api from "./apiService.js";
import WithToken from "./tokenService";
import configuration from "../../config.json";

const NotificationServices = {
  get: function () {
    return api.get("Notifications", WithToken.headersToken({}, configuration.cookieName));
  },
  getTypes: function () {
    return api.get("Notifications/Types", WithToken.headersToken({}, configuration.cookieName));
  },
  read: function (id) {
    return api.post(`Notifications/${id}/Read`, {}, WithToken.headersToken({}, configuration.cookieName));
  },
  readAll: function () {
    return api.post(`Notifications/ReadAll`, {}, WithToken.headersToken({}, configuration.cookieName));
  },
  unsubscribes: function (id) {
    return api.put(`Notifications/Types/${id}/Unsubscribes`, {}, WithToken.headersToken({}, configuration.cookieName));
  },
  subscribes: function (id) {
    return api.put(`Notifications/Types/${id}/Subscribes`, {}, WithToken.headersToken({}, configuration.cookieName));
  },
};

export default NotificationServices;
