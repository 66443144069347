import React from 'react'

import styles from './secondSideBarInner.module.scss'

const SecondSideBarInner = props => {
  return (
    <div className={styles.secondSideBarInner}>
      {props.children}
    </div>
  )
}

export default React.memo(SecondSideBarInner)