import {
  OPEN_SIDE_BAR,
  CLOSE_SIDE_BAR,
  OPEN_EMPLOYEES_SECOND_SIDE_BAR,
  CLOSE_EMPLOYEES_SECOND_SIDE_BAR,
  OPEN_DRIVERS_SECOND_SIDE_BAR,
  CLOSE_DRIVERS_SECOND_SIDE_BAR,
} from '../actions/appStateActions'

const initialState = {
  isSideBarOpen: true,
  isEmployeesSecondSideBarOpen: false,
  isDriversSecondSideBarOpen: false,
}

const appStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SIDE_BAR:
      return Object.assign(
        {},
        state,
        {
          isSideBarOpen: true,
        }
      )
    case CLOSE_SIDE_BAR:
      return Object.assign(
        {},
        state,
        {
          isSideBarOpen: false
        }
      )
    case OPEN_EMPLOYEES_SECOND_SIDE_BAR:
      return Object.assign(
        {},
        state,
        {
          isEmployeesSecondSideBarOpen: true,
        }
      )
    case CLOSE_EMPLOYEES_SECOND_SIDE_BAR:
      return Object.assign(
        {},
        state,
        {
          isEmployeesSecondSideBarOpen: false
        }
      )
    case OPEN_DRIVERS_SECOND_SIDE_BAR:
      return Object.assign(
        {},
        state,
        {
          isDriversSecondSideBarOpen: true,
        }
      )
    case CLOSE_DRIVERS_SECOND_SIDE_BAR:
      return Object.assign(
        {},
        state,
        {
          isDriversSecondSideBarOpen: false
        }
      )
    default:
      return state
  }
}

export default appStateReducer
