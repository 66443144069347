import React, { Component } from "react";
import { withFilterEmployees } from "../../../shared/containers/containerFilterEmployees";
import { withEmployeesCompanies } from "../../../shared/containers/containerEmployeesCompanies";
import MultiSelects from "../../../shared/items/contols/multiSelect";
import FilterEmployeesItems from "./static.filterEmployeesItems";
import { getSelectOptions } from "../../../shared/services/service.selectOptions";

class FiliasFilter extends Component {
  componentDidMount() {
    const { onGetEmployeesCompanies, employeesCompanies } = this.props;

    if (employeesCompanies.dataState === "STATE_NOT_REQUESTED") {
      onGetEmployeesCompanies();
    }
  }

  render() {
    const { onHandleChangeStore, filterEmployees, employeesCompanies } = this.props;
    const name = FilterEmployeesItems.companies.name;

    return (
      <MultiSelects
        name={name}
        valueStore={filterEmployees.filterItems} 
        options={getSelectOptions(employeesCompanies[name])}
        handleChange={onHandleChangeStore.bind(this, name)}
      />
    );
  }
}

export default withEmployeesCompanies(withFilterEmployees(FiliasFilter));
