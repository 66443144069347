import cloneDeep from "lodash/cloneDeep"
import { getRouteDates } from '../shared/services/service.intellegence'

const PLAY_ROUTE = 'PLAY_ROUTE'
const PAUSE_ROUTE = 'PAUSE_ROUTE'
const SET_ROUTE_LENGTH = "SET_ROUTE_LENGTH"
const SET_ROUTE_DATES = 'SET_ROUTE_DATES'
const SET_CURRENT_POINT = 'SET_CURRENT_POINT'
const CLEAR_PLAYER_DATA = 'CLEAR_PLAYER_DATA'

const playRouteAction = () => {
  return {
    type: PLAY_ROUTE
  }
}

const pauseRouteAction = () => {
  return {
    type: PAUSE_ROUTE
  }
}

const playRoute = () => {
  return (dispatch) => {
    dispatch(playRouteAction())
  }
}

const pauseRoute = () => {
  return (dispatch) => {
    dispatch(pauseRouteAction())
  }
}

const setRouteLengthAction = (data) => {
  return {
    type: SET_ROUTE_LENGTH,
    data
  }
}

const setRouteLength = (data) => {
  return (dispatch) => {
    dispatch(setRouteLengthAction(data))
  }
}

const setRouteDatesAction = (data) => {
  return {
    type: SET_ROUTE_DATES,
    data
  }
}

const setRouteDates = (data) => {
  return (dispatch) => {
    const intelligence = cloneDeep(data)
    const dates = getRouteDates(intelligence)

    dispatch(setRouteDatesAction(dates))
  }
}

const setCurrentPointAction = (data) => {
  return {
    type: SET_CURRENT_POINT,
    data
  }
}

const setCurrentPoint = (data) => {
  return (dispatch) => {
    dispatch(setCurrentPointAction(data))
  }
}

const clearPlayerDataAction = () => {
  return {
    type: CLEAR_PLAYER_DATA
  }
}

const clearPlayerData = () => {
  return (dispatch) => {
    dispatch(clearPlayerDataAction())
  }
}

export {
  playRoute,
  playRouteAction,
  PLAY_ROUTE,

  pauseRoute,
  pauseRouteAction,
  PAUSE_ROUTE,
  
  setRouteLength,
  setRouteLengthAction,
  SET_ROUTE_LENGTH,

  setRouteDates,
  setRouteDatesAction,
  SET_ROUTE_DATES,

  setCurrentPoint,
  setCurrentPointAction,
  SET_CURRENT_POINT,

  clearPlayerData,
  clearPlayerDataAction,
  CLEAR_PLAYER_DATA
}