import React from "react";
import { withRouter } from "react-router";
import EmployeesListItem from "./employeesListItem";
import EmployeesMultipleSelectItem from "./employeesMultipleSelectItem";
import EmployeesListSelectedItem from "./employeesListSelectedItem";
import EmployeeContent from "./employeListItemSelectContent";
import styles from "./employeesList.module.scss";
import { getEmployeesTypeName } from "./static.employeesTypes";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import stylesTooltip from "./../../../shared/filterEmployeesTooltip.module.scss";
import { v4 as uuid_v4 } from "uuid";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "none",
    color: "none",
    border: "0px solid transparent",
    padding: "0px",
    borderRadius: "none",
  },
}))(Tooltip);

const EmployeesListItems = (props) => {
  const { handleScrollTo, data, match, isMultipleSelect } = props;

  return data.map((item) => {
    return (
      <div key={"listItemEmployee" + item.id}>
        {!isMultipleSelect ? (
          match.params.id === item.id ? (
            <div kye={uuid_v4()} className={`${styles.container_employeesListItem} selected`}>
              <EmployeesListSelectedItem kye={uuid_v4()} data={item} handleScrollTo={handleScrollTo} />
            </div>
          ) : (
            <HtmlTooltip
              key={"listItemEmployeeHtmlTooltip" + item.id}
              title={
                <div kye={uuid_v4()} className={stylesTooltip.tooltipTemplate}>
                  <EmployeeContent kye={uuid_v4()} data={item} />
                </div>
              }
              placement="right-end"
            >
              <div kye={uuid_v4()} className={getStatusStyles(item)} key={item.id}>
                <EmployeesListItem kye={uuid_v4()} data={item} />
              </div>
            </HtmlTooltip>
          )
        ) : (
          <div
            key={"EmployeesMultipleSelectItem" + item.id}
            className={
              item.isCheck
                ? styles.container_employeesListItem_multipleselected
                : styles.container_employeesListItem_not_multipleselected
            }
          >
            <EmployeesMultipleSelectItem kye={item.id + "_" + item.isCheck} data={item} />
          </div>
        )}
      </div>
    );
  });
};

const getStatusStyles = function (data) {
  switch (getEmployeesTypeName(data.status.id)) {
    case "noData":
      return styles.container_employeesListItem_noData;
    case "sleep":
      return styles.container_employeesListItem_inSleep;
    case "work":
      return styles.container_employeesListItem_work;
    case "charging":
      return styles.container_employeesListItem_charging;
    case "offSleep":
      return styles.container_employeesListItem_offSleep;
    case "alarm":
      return styles.container_employeesListItem_alarm;
    case "poweredFrom":
      return styles.container_employeesListItem_poweredFrom;
    default:
      return null;
  }
};

export default withRouter(React.memo(EmployeesListItems));
