import {
  REQUEST_GET_INMOMENT_INTELLEGENCE,
  RESPONSE_GET_INMOMENT_INTELLEGENCE,
  REQUEST_GET_INMOMENT_INTELLEGENCE_FAILURE,

  REQUEST_UPDATE_INMOMENT_INTELLEGENCE,
  RESPONSE_UPDATE_INMOMENT_INTELLEGENCE,

  SET_INMOMENT_DATE,
  CLEAR_INMOMENT_INTELLEGENCE
} from '../actions/inmomentIntellegenceActions'

import { states } from './queriesStates'

const thisDate = new Date()

const initialState = {
  dataState: states.NOT_REQUESTED,
  data: {},
  errors: {},
  inmomentDate: thisDate
}

const inmomentIntellegenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_INMOMENT_INTELLEGENCE: {
      return Object.assign(
        {},
        state,
        {
          dataState: states.REQUEST,
        }
      )
    }
    case RESPONSE_GET_INMOMENT_INTELLEGENCE: {
      return Object.assign(
        {},
        state,
        {
          dataState: states.READY,
          data: action.data,
          errors: {},
        }
      )
    }
    case REQUEST_GET_INMOMENT_INTELLEGENCE_FAILURE: {
      return Object.assign(
        {},
        state,
        {
          dataState: states.FAILURE,
          errors: action.errors
        }
      )
    }
    case REQUEST_UPDATE_INMOMENT_INTELLEGENCE: {
      return Object.assign(
        {},
        state,
        {
          dataState: states.REQUEST + '_UPDATE'
        }
      )
    }
    case RESPONSE_UPDATE_INMOMENT_INTELLEGENCE: {
      return Object.assign(
        {},
        state,
        {
          dataState: states.READY + '_UPDATE'
        }
      )
    }
    case SET_INMOMENT_DATE: {
      return Object.assign(
        {},
        state,
        {
          inmomentDate: action.data
        }
      )
    }
    case CLEAR_INMOMENT_INTELLEGENCE: {
      return Object.assign(
        {},
        state,
        initialState
      )
    }
    default:
      return state
  }
}

export default inmomentIntellegenceReducer
