import React from 'react'

import ReactPaginate from 'react-paginate';

import './pagination.scss'

const handlePageClick = (props, object) => {
  const { onHandleChangeStore } = props

  onHandleChangeStore(object.selected + 1)
}

const Pagination = props => {
  const { totalCount, count, page } = props
  const allPages = Math.trunc(totalCount / count) + ((totalCount % count) > 0 ? 1 : 0);

  return count < 1
    ? <div className="pagination disabled"></div>
    : <div className="pagination">
      <ReactPaginate
        previousLabel={''}
        nextLabel={''}
        breakLabel={'...'}
        breakClassName={'break-me'}
        forcePage={page - 1}
        pageCount={allPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick.bind(this, props)}
        activeClassName={'active'}
        activeLinkClass={'active-link'}
        nextLinkClassName={'icon-arr'}
        previousLinkClassName={'icon-arr1'}
        hideDisabled={true}
        hideFirstLastPages={true}
      />
    </div>
}

export default React.memo(Pagination)