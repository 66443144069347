import {
  REQUEST_GET_DASHBOARD,
  RESPONSE_GET_DASHBOARD,
  REQUSET_GET_DASHBOARD_FAILURE,
  SET_DASHBOARD
} from '../actions/dashboardActions'

import { states } from './queriesStates'

const initialState = {
  dataState: states.NOT_REQUESTED,
  data: [],
  errors: {},
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_DASHBOARD:
      return Object.assign(
        {},
        state,
        {
          dataState: states.REQUEST
        }
      )
    case RESPONSE_GET_DASHBOARD:
      return Object.assign(
        {},
        state,
        {
          dataState: states.READY
        }
      )
    case REQUSET_GET_DASHBOARD_FAILURE:
      return Object.assign(
        {},
        state,
        {
          dataState: states.FAILURE,
          errors: action.errors
        }
      )
    case SET_DASHBOARD:
      return Object.assign(
        {},
        state,
        {
          dataState: states.READY,
          data: action.data,
        }
      )
    default:
      return state
  }
}

export default dashboardReducer