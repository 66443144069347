import React, { PureComponent } from "react";
import { withPermissions } from "../../../shared/containers/containerPermissions.js";
import { withUsers } from "../../../shared/containers/containerUsers";
import { states } from "../../../reducers/queriesStates";
import { v4 as uuid_v4 } from "uuid";
import UserPermissions from "./userPermissions.js";
import "./usersBlock.modeule.scss";

class UsersBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      permissions: props.permissions.permissions,
      users: props.users.users,
      selectUserId: props.users.selectUserId,
      selectUser: null,
    };
  }

  componentDidMount() {
    const { onGetPermissions } = this.props;

    onGetPermissions();
  }

  componentDidUpdate(prevProps) {
    const { permissions, users } = this.props;

    if (prevProps.permissions.dataState === states.REQUEST && permissions.dataState === states.READY) {
      this.setState({
        permissions: permissions.permissions,
      });
    }

    if (prevProps.users.dataState === states.REQUEST && users.dataState === states.READY) {
      const selectUser =
        users.selectUserId !== null
          ? users.users.filter((item) => {
              return item.id === users.selectUserId;
            })
          : [null];

      this.setState({
        users: users.users,
        selectUser: selectUser[0],
      });
    }

    if (prevProps.users.selectUserId !== users.selectUserId) {
      const selectUser = users.users.filter((item) => {
        return item.id === users.selectUserId;
      });

      this.setState({
        selectUserId: users.selectUserId,
        selectUser: selectUser[0],
      });
    }
  }

  getPermissionItem(item) {
    return (
      <li key={item.id}>
        <span>{item.title}</span>
      </li>
    );
  }

  render() {
    const { permissions, selectUser } = this.state;

    return (
      <div className={"usersContainer"} key={uuid_v4()}>
        <div className={"usersInnerContainer"}>
          {selectUser !== null ? (
            <UserPermissions user={selectUser} permissions={permissions} />
          ) : (
            <div>
              <span>Ваши разрешения:</span>
              <ul>
                {permissions.map((item) => {
                  return this.getPermissionItem(item);
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withUsers(withPermissions(UsersBlock));
