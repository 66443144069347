import React from 'react';

import { withFilterEmployees } from './../../../shared/containers/containerFilterEmployees'
import { withAppState } from '../../../shared/containers/containerAppState'

import SearchEmployeesString from './searchEmployeesString'
import SortEmployeesDropdown from './sortEmployeesDropdown'
import ButtonMediumDefault from '../../../shared/items/contols/buttonMediumDefault'
import ButtonMediumDefaultPrevIcon from '../../../shared/items/contols/buttonMediumDefaultPrevIcon'
import ButtonCounter from '../../../shared/items/static/buttonCounter'
import EmployeesBookmarkCounter from './employeesBookmarkCounter'
import EmployeesBookmarkCounterWrapper from './employeesBookmarkCounterWrapper'
import FilterEmployeesTooltip from './../../../shared/filterEmployeesTooltip'
import Icon from '../../../shared/items/static/icon'
import ResponsiveWrapper from '../../../shared/responsiveWrapper'

import styles from './employeesSortPanel.module.scss'

const EmployeesSortPanel = props => {
  const { onHandleChangeStore, isEmployeesSecondSideBarOpen, onOpenEmployeesSecondSideBar, onCloseEmployeesSecondSideBar } = props
  const { filterItems } = props.filterEmployees

  return (
    <div className={styles.employeesPanel}>

      <SearchEmployeesString />

      <div className={styles.container_sortFilter}>
        <SortEmployeesDropdown
          onHandleChangeStore={onHandleChangeStore}
          filterItems={filterItems}
        />
        <div className={styles.container_filter}>

          <ResponsiveWrapper device="desktop">
            <ButtonMediumDefault
              className={filterItems.isBookmark ? 'active' : ''}
              handleClick={onHandleChangeStore.bind(this, 'isBookmark', !!filterItems.isBookmark ? !filterItems.isBookmark : true)}
            >
              <ButtonMediumDefaultPrevIcon>
                <Icon name="fav" />
              </ButtonMediumDefaultPrevIcon>
              Избранное
              <EmployeesBookmarkCounter />
            </ButtonMediumDefault>
          </ResponsiveWrapper>

          <ResponsiveWrapper device="desktop" invert={true}>
            <ButtonMediumDefault
              className={filterItems.isBookmark ? 'active' : ''}
              handleClick={onHandleChangeStore.bind(this, 'isBookmark', !!filterItems.isBookmark ? !filterItems.isBookmark : true)}
            >
              <ButtonMediumDefaultPrevIcon>
                <Icon name="fav" />
              </ButtonMediumDefaultPrevIcon>
              <EmployeesBookmarkCounterWrapper>
                <EmployeesBookmarkCounter />
              </EmployeesBookmarkCounterWrapper>
            </ButtonMediumDefault>
          </ResponsiveWrapper>

          <ResponsiveWrapper device="desktop">
            <FilterEmployeesTooltip>
              <ButtonMediumDefault
                className={isEmployeesSecondSideBarOpen ? 'active' : ''}
                handleClick={isEmployeesSecondSideBarOpen ? onCloseEmployeesSecondSideBar : onOpenEmployeesSecondSideBar}
              >
                <ButtonMediumDefaultPrevIcon>
                  <Icon name="filter" />
                </ButtonMediumDefaultPrevIcon>
                Фильтр
                {!!filterItems.filterCount ? <ButtonCounter count={filterItems.filterCount} /> : null}
              </ButtonMediumDefault>
            </FilterEmployeesTooltip>
          </ResponsiveWrapper>

          <ResponsiveWrapper device="desktop" invert={true}>
            <FilterEmployeesTooltip>
              <ButtonMediumDefault
                className={isEmployeesSecondSideBarOpen ? 'active' : ''}
                handleClick={isEmployeesSecondSideBarOpen ? onCloseEmployeesSecondSideBar : onOpenEmployeesSecondSideBar}
              >
                <ButtonMediumDefaultPrevIcon>
                  <Icon name="filter" />
                </ButtonMediumDefaultPrevIcon>
                {
                  !!filterItems.filterCount
                    ? <EmployeesBookmarkCounterWrapper>
                      <ButtonCounter count={filterItems.filterCount} />
                    </EmployeesBookmarkCounterWrapper>
                    : null
                }
              </ButtonMediumDefault>
            </FilterEmployeesTooltip>
          </ResponsiveWrapper>
        </div>
      </div>
    </div >
  )
}

export default withAppState(withFilterEmployees(React.memo(EmployeesSortPanel)))
