import React, { Component } from "react";
import { withIntellegenceTimeRangePlayer } from "../../../shared/containers/containerIntellegenceTimeRangePlayer";
import { withSensors } from "../../../shared/containers/containerSensors";
import { withChoosedEmployee } from "../../../shared/containers/containerChoosedEmployee";
import { withTimerangeIntellegence } from "./../../../shared/containers/containerTimerangeIntellegence";
import { withTimerangeIntelligenceData } from "./../../../shared/containers/containerTimerangeIntelligenceData";
import CookieService from "../../../shared/services/cookiesService";
import configuration from "../../../config.json";
import ViewTable from "../../../shared/viewTable";
import ViewObjectivesTable from "../../../shared/viewObjectivesTable";
import intellegenceTableData from "../../../shared/services/intellegence.data.service";
import tableEventObjectivesData from "../../../shared/services/intellegence.eventObjectives.service";
import { v4 as uuid_v4 } from "uuid";
import TabButton from "../../../components/sections/contentBlocks/tabButton";
import styles from "./intelligenceInTimeRangeTable.module.scss";
import "./intelligenceInTimeRangeTable.scss";

const viewObjectivesItems = {
  route: "route",
  objectives: "objectives",
};

class IntelligenceInTimeRangeTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewObjectives: viewObjectivesItems.route,
      timerangeIntelligenceData: props.timerangeIntelligenceData,
      timerangeIntellegence: props.timerangeIntellegence,
      permissions: CookieService.getCookie(configuration.permissionsCookie),
      intellegenceTable: null,
      pointIndex: null,
      scrollTo: 0,
    };
  }

  componentDidMount() {
    const { timerangeIntellegence, choosedEmployee, onGetEventObjectives } = this.props;
    const { permissions } = this.state;

    if (timerangeIntellegence.actionType === null && permissions.includes(70001)) {
      onGetEventObjectives({
        employeeId: choosedEmployee.employeeId,
        startDate: timerangeIntellegence.rangeStartDate,
        endDate: timerangeIntellegence.rangeEndDate,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { timerangeIntelligenceData, currentPointIndex } = this.props;

    const { pointIndex } = this.state;
    if (
      timerangeIntelligenceData.data.length > 0 &&
      (currentPointIndex !== pointIndex ||
        prevProps.timerangeIntelligenceData.groupStatus !== timerangeIntelligenceData.groupStatus)
    ) {
      const intellegenceTable = intellegenceTableData(this.setActivePoint(timerangeIntelligenceData.data));
      intellegenceTable.key = uuid_v4();
      this.setState({ intellegenceTable, pointIndex: currentPointIndex });
    }
  }

  openViewBlock(viewObjectives) {
    this.setState({ viewObjectives: viewObjectives });
  }

  onScroll(e) {
    this.setState({ scrollTo: e.scrollTop });
  }

  getSensors(index) {
    const { onSetCurrentPoint } = this.props;

    onSetCurrentPoint(index);
  }

  getRowIndex(data) {
    const { currentPointIndex } = this.props;
    let rowId;

    data.map((item) => {
      if (!!item.startIndex || item.startIndex === 0) {
        if (currentPointIndex >= item.startIndex && currentPointIndex <= item.finishIndex) {
          rowId = item.rowId;
        }
      } else if (currentPointIndex === item.index) {
        rowId = item.rowId;
      }

      return null;
    });

    return rowId;
  }

  setActivePoint(data) {
    const { currentPointIndex } = this.props;

    data.map((item) => {
      if (!!item.startIndex || item.startIndex === 0) {
        item.isActive = currentPointIndex >= item.startIndex && currentPointIndex < item.finishIndex;
      } else {
        item.isActive = currentPointIndex === item.index;
      }

      return null;
    });

    return data;
  }

  render() {
    const { intellegenceTable, scrollTo } = this.state;
    if (!intellegenceTable) {
      return null;
    }

    const { timerangeIntelligenceData, timerangeIntellegence, isPlay } = this.props;

    const objectivesTable = tableEventObjectivesData(
      timerangeIntelligenceData.eventObjectives,
      timerangeIntellegence.data
    );

    const { viewObjectives, permissions } = this.state;

    return (
      <div>
        {permissions.includes(70001) ? (
          <div className={"tabButtonContainer"}>
            <TabButton
              title={`Маршрут ${timerangeIntellegence.distance/1000} км`}
              currentView={viewObjectivesItems.route}
              checkedView={viewObjectives}
              handlerClick={this.openViewBlock.bind(this)}
            />
            <TabButton
              title={"Задания"}
              currentView={viewObjectivesItems.objectives}
              checkedView={viewObjectives}
              handlerClick={this.openViewBlock.bind(this)}
            />
          </div>
        ) : null}
        <div className={isPlay ? styles.intellegenceTimeRangeTable_scrolled : ""}>
          {viewObjectives === viewObjectivesItems.route ? (
            <div>
              
              <ViewTable
                distance={timerangeIntellegence.distance/1000}
                key={intellegenceTable.key}
                headItems={intellegenceTable.headItems}
                contentItems={intellegenceTable.contentItems}
                onScroll={this.onScroll.bind(this)}
                handleClickRow={!isPlay ? this.getSensors.bind(this) : null}
                scrollTo={scrollTo}
              />
            </div>
          ) : null}

          {viewObjectives === viewObjectivesItems.objectives ? (
            <ViewObjectivesTable
              headItems={objectivesTable.headItems}
              contentItems={objectivesTable.contentItems}
              handleClickRow={!isPlay ? this.getSensors.bind(this) : null}
              //     scrollTo={nextScrollRow}
              styleClass="viewObjectivesTable"
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withTimerangeIntellegence(
  withTimerangeIntelligenceData(
    withChoosedEmployee(withSensors(withIntellegenceTimeRangePlayer(IntelligenceInTimeRangeTable)))
  )
);
