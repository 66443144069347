import variables from "./../../../values.scss";

const styles = {
  root: {
    border: "solid 1.5px " + variables.radioBorder,
    width: "14px",
    height: "14px",
    padding: 0,
    marginLeft: "25px",
    transition: "border " + variables.animateTransition,
    marginRight: "7px",
    "&.Mui-checked": {
      border: "solid 4px " + variables.radioCheckedBorder,
      "&:hover": {
        background: "none",
      },
    },
    "&.Mui-disabled": {
      opacity: 0.6,
    },
    "&:hover": {
      background: "none",
    },
  },
};

export { styles };
