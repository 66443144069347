import React, { Component } from "react";
import { withUsers } from "../../../shared/containers/containerUsers";
import UsersService from "../../../shared/services/usersService";
import TextField from "@material-ui/core/TextField";

let timeout = null;

class UserPermissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      permissions: props.permissions,
    };
  }

  onChangePermission(e) {
    const { user } = this.state;
    const { onAddUserPermission, onDeleteUserPermission, permissions } = this.props;

    const permission = permissions.filter((item) => {
      return item.id === e.target.id;
    });

    if (e.target.checked) {
      onAddUserPermission(user.id, e.target.id);

      user.permissions.push(permission[0]);
    } else {
      onDeleteUserPermission(user.id, e.target.id);
      user.permissions = user.permissions.filter((item) => {
        return item.id !== permission[0].id;
      });
    }

    this.setState({ user: user });
  }

  getPermissionItem(item, value = true, disabled = true) {
    return (
      <li style={{ marginLeft: "20px!important" }} key={item.id}>
        <input
          id={item.id}
          disabled={disabled}
          checked={value}
          className="custom-checkbox"
          type={"checkbox"}
          onChange={this.onChangePermission.bind(this)}
        ></input>
        <label> {item.title}</label>
      </li>
    );
  }

  getRoleItem(item) {
    return (
      <li key={item.id}>
        <p>
          <b>{item.title}</b>
        </p>
        <p>Разрешения роли:</p>
        <ul>
          {item.permissions.map((permission) => {
            return this.getPermissionItem(permission);
          })}
        </ul>
      </li>
    );
  }

  onChangeTitle(e) {
    const { user } = this.state;

    clearTimeout(timeout, e.target.value);

    timeout = setTimeout(() => {
      UsersService.putUser(user.id, user.isBlocked, e.target.value)
        .then((result) => {
          user.title = e.target.value;
          this.setState({ user: user });
        })
        .catch((error) => {});
    }, 400);
  }

  render() {
    const { user, permissions } = this.state;
    if (user === undefined) {
      return null;
    }
    
    const permissionsNotAdd = permissions.map((permission) => {
      let include = user.roles.some((role) => {
        return role.permissions.some((rolePermission) => {
          return rolePermission.id === permission.id;
        });
      });

      if (!include) {
        include = user.permissions.some((userPermission) => {
          return userPermission.id === permission.id;
        });

        return !include ? permission : null;
      }

      return null;
    });

    return (
      <div>
        <p>
          Логин: <b>{user.email}</b>
        </p>

        <TextField
          label="ФИО"
          margin="dense"
          style={{ width: 500 }}
          id="outlined-title"
          variant="outlined"
          defaultValue={user.title}
          onChange={this.onChangeTitle.bind(this)}
        />

        <p>Роли:</p>
        <ul>
          {user.roles.map((item) => {
            return this.getRoleItem(item);
          })}
        </ul>
        <p>Разрешения пользователя:</p>
        <ul>
          {user.permissions.map((item) => {
            return this.getPermissionItem(item, true, false);
          })}
          {permissionsNotAdd.map((item) => {
            return item != null ? this.getPermissionItem(item, false, false) : null;
          })}
        </ul>
      </div>
    );
  }
}

export default withUsers(UserPermissions);
