import React from "react";

import { withIntellegenceTab } from "../../../shared/containers/containerIntellegenceTab";
import EmployeesIntellegence from "./employeesIntellegence";
import EmployeeShortInformation from "../info/employeeShortInformation";
import EmployeeSettings from "../info/employeeSettings";
import EmployeeDeviceSettings from "../info/employeeDeviceSettings";

const IntelligenceEmployeesContent = (props) => {
  const { activeTabName } = props;

  return (
    <React.Fragment>
      {activeTabName === "intellegence" ? <EmployeesIntellegence /> : null}
      {activeTabName === "information" ? <EmployeeShortInformation /> : null}
      {activeTabName === "settings" ? (
        <div style={{ width: "100%" }}>
          <EmployeeDeviceSettings />
          <EmployeeSettings />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default withIntellegenceTab(React.memo(IntelligenceEmployeesContent));
