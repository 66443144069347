import React from 'react';

import { withEmployees } from '../../../shared/containers/containerEmployees'

import Counter from '../../../shared/items/static/counter'

const EmployeesCounterWrapper = props => {
  const { filterCount } = props.employees

  return !!filterCount || filterCount === 0
    ? <Counter count={filterCount} />
    : null
}

export default withEmployees(React.memo(EmployeesCounterWrapper))