const OPEN_SIDE_BAR = 'OPEN_SIDE_BAR'
const CLOSE_SIDE_BAR = 'CLOSE_SIDE_BAR'
const OPEN_EMPLOYEES_SECOND_SIDE_BAR = 'OPEN_EMPLOYEES_SECOND_SIDE_BAR'
const CLOSE_EMPLOYEES_SECOND_SIDE_BAR = 'CLOSE_EMPLOYEES_SECOND_SIDE_BAR'
const OPEN_DRIVERS_SECOND_SIDE_BAR = 'OPEN_DRIVERS_SECOND_SIDE_BAR'
const CLOSE_DRIVERS_SECOND_SIDE_BAR = 'CLOSE_DRIVERS_SECOND_SIDE_BAR'

const openSideBarAction = () => {
  return {
    type: OPEN_SIDE_BAR,
  }
}

const closeSideBarAction = () => {
  return {
    type: CLOSE_SIDE_BAR,
  }
}

const openEmployeesSecondSideBar = () => {
  return {
    type: OPEN_EMPLOYEES_SECOND_SIDE_BAR,
  }
}

const closeEmployeesSecondSideBar = () => {
  return {
    type: CLOSE_EMPLOYEES_SECOND_SIDE_BAR,
  }
}

const openDriversSecondSideBar = () => {
  return {
    type: OPEN_DRIVERS_SECOND_SIDE_BAR,
  }
}

const closeDriversSecondSideBar = () => {
  return {
    type: CLOSE_DRIVERS_SECOND_SIDE_BAR,
  }
}

export {
  openSideBarAction,
  closeSideBarAction,
  openEmployeesSecondSideBar,
  closeEmployeesSecondSideBar,
  openDriversSecondSideBar,
  closeDriversSecondSideBar,
  OPEN_SIDE_BAR,
  CLOSE_SIDE_BAR,
  OPEN_EMPLOYEES_SECOND_SIDE_BAR,
  CLOSE_EMPLOYEES_SECOND_SIDE_BAR,
  OPEN_DRIVERS_SECOND_SIDE_BAR,
  CLOSE_DRIVERS_SECOND_SIDE_BAR,
}
