import { connect } from 'react-redux'
import get from 'lodash/get';

import {
    getEmail as onGetEmail,
    registration as onRegistration,
    setRegistration as onSetRetistration,
    clearRegistrationErrors as onClearRegistrationErrors,
    handleChangeStoreRegistration as onHandleChangeStore
} from '../../actions/registrationActions'

const mapStateToProps = (state) => ({
    dataState: state.registration.dataState,
    data: get(state, ['registration', 'data'], []),
    registrationItems:  get(state, ['registration', 'registrationItems'], []),
    errors: get(state, ['registration', 'errors', 'data'], null),
    errorsModel: get(state, ['registration', 'errors', 'data', 'model'], null),
});

const mapDispatchToProps = {
    onGetEmail,
    onRegistration,
    onSetRetistration,
    onClearRegistrationErrors,
    onHandleChangeStore
}

const withRegistration = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withRegistration }