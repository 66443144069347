import React, { Component } from "react";
import { withChoosedEmployee } from "../../../shared/containers/containerChoosedEmployee";
import { withDevices } from "../../../shared/containers/containerDevices";
import { states } from "../../../reducers/queriesStates";
import "./employeeDeviceSettings.css";
import ButtonPrimary from "../../../shared/items/contols/buttonPrimary";
import CookieService from "../../../shared/services/cookiesService";
import configuration from "../../../config.json";

class EmployeeDeviceSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devices: null,
      device: null,
      otherId: null,
      permissions: JSON.parse(CookieService.getCookie(configuration.permissionsCookie)),
    };
  }

  componentDidMount() {
    const { onGetDevices } = this.props;
    const { permissions } = this.state;

    if (permissions.includes(30007)) {
      onGetDevices();
    }
  }

  componentDidUpdate(prevProps) {
    const { devices, choosedEmployee, onGetDevices } = this.props;

    if (choosedEmployee.employeeId !== prevProps.choosedEmployee.employeeId) {
      onGetDevices();
    }

    if (prevProps.devices.dataState === states.REQUEST && devices.dataState === states.READY) {
      const device = devices.devices.filter((item) => {
        return item.employeeId === choosedEmployee.employeeId;
      })[0];

      this.setState({ devices: devices.devices, device: device });
    }
  }

  onChangeSelect(e) {
    this.setState({ otherId: e.target.value === "- - -" ? null : e.target.value });
  }

  deviceReplace() {
    const { onReplaceDevices } = this.props;
    const { device, otherId } = this.state;

    onReplaceDevices(device.id, otherId);
  }

  render() {
    const { devices, device, otherId, permissions } = this.state;

    if (!permissions.includes(30007)) {
      return null;
    }

    if (device === null || devices === null || devices.length === 0) {
      return null;
    }

    return (
      <div className={"employeeDeviceSettings_container"}>
        <h2>Замена устройства для сотрудника</h2>
        <div className={"employeeDeviceSettings_containerImei"}>
          <span style={{ marginRight: "20px" }}>
            {device.inActive ? "Устройство не активно" : "Устройство активно"}
          </span>

          {device.phone === null || device.phone === "" ? null : (
            <span style={{ marginRight: "20px" }}>
              <b>Номер SIM:</b> {device.phone}
            </span>
          )}

          <span style={{ marginRight: "20px" }}>
            <b>IMEI:</b> {device.imei}
          </span>

          {permissions.includes(30006) === false ? null : (
            <div style={{ display: "flex", marginTop: "10px" }}>
              <select
                className={"selectImei"}
                style={{ borderRadius: "5px" }}
                name="imeis"
                id="deviceReplaceSelect"
                onChange={this.onChangeSelect.bind(this)}
              >
                <option value={null}>- - -</option>
                {devices.map((item) => {
                  return <option value={item.id}>{item.imei}</option>;
                })}
              </select>
              <div className={"employeeDeviceSettings_button"}>
                <ButtonPrimary
                  disabled={otherId === null}
                  handleClick={this.deviceReplace.bind(this)}
                  title={<span>Заменить</span>}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withDevices(withChoosedEmployee(EmployeeDeviceSettings));
