import React from 'react';

import { withEmployees } from '../../../shared/containers/containerEmployees'
import { withFilterEmployees } from '../../../shared/containers/containerFilterEmployees'

import Pagination from '../../../shared/items/contols/pagination'

const EmployeesPaginationWrapper = props => {
  const { onHandleChangeStore, filterEmployees } = props
  const { filterCount } = props.employees
  const { filterItems } = filterEmployees

  return filterItems.count <= filterCount
    ? <Pagination
      totalCount={filterCount}
      count={filterItems.count}
      page={filterItems.page}
      onHandleChangeStore={onHandleChangeStore.bind(this, 'page')}
    />
    : null
}

export default withEmployees(withFilterEmployees(React.memo(EmployeesPaginationWrapper)))


