import configuration from '../config.json'
import EmployeesService from '../shared/services/employeesService'
import CookieService from '../shared/services/cookiesService'
import { setLogout } from './authActions'

const REQUEST_DELETE_EMPLOYEE_BOOKMARK = 'REQUEST_DELETE_EMPLOYEE_BOOKMARK'
const RESPONSE_DELETE_EMPLOYEE_BOOKMARK = 'RESPONSE_DELETE_EMPLOYEE_BOOKMARK'
const REQUEST_DELETE_EMPLOYEE_BOOKMARK_FAILURE = 'REQUEST_DELETE_EMPLOYEE_BOOKMARK_FAILURE'

const requestDeleteEmployeeBookmarkAction = () => {
  return {
    type: REQUEST_DELETE_EMPLOYEE_BOOKMARK
  }
}

const responseDeleteEmployeeBookmarkAction = () => {
  return {
    type: RESPONSE_DELETE_EMPLOYEE_BOOKMARK
  }
}

const requestDeleteEmployeeBookmarkFailureAction = (errors) => {
  return {
    type: REQUEST_DELETE_EMPLOYEE_BOOKMARK_FAILURE,
    errors
  }
}

const deleteEmployeeBookmark = (id) => {
  return (dispatch) => {
    dispatch(requestDeleteEmployeeBookmarkAction())

    EmployeesService.deleteBookmark(id)
      .then((result) => {
        CookieService.updateExpires(configuration.cookieName, 24)

        dispatch(responseDeleteEmployeeBookmarkAction())
      })
      .catch((error) => {
        dispatch(requestDeleteEmployeeBookmarkFailureAction(error.response))

        dispatch(setLogout())
      })
  }
}

export {
  deleteEmployeeBookmark,
  requestDeleteEmployeeBookmarkAction,
  responseDeleteEmployeeBookmarkAction,
  requestDeleteEmployeeBookmarkFailureAction,
  REQUEST_DELETE_EMPLOYEE_BOOKMARK,
  RESPONSE_DELETE_EMPLOYEE_BOOKMARK,
  REQUEST_DELETE_EMPLOYEE_BOOKMARK_FAILURE
}