import configuration from "./../config.json";
import AccountService from "./../shared/services/accountService";
import CookieService from "./../shared/services/cookiesService";
import { handleClearStoreSingIn } from "./signInActions";

const REQUEST_LOGIN = "REQUEST_LOGIN";
const RESPONSE_LOGIN = "RESPONSE_LOGIN";
const REQUEST_LOGIN_FAILURE = "REQUEST_LOGIN_FAILURE";

const RESPONSE_LOGOUT = "RESPONSE_LOGOUT";
const REQUEST_LOGOUT_FAILURE = "REQUEST_LOGOUT_FAILURE";

const SET_REGISTRATION = "SET_REGISTRATION";

const SET_LOGIN = "SET_LOGIN";
const SET_LOGOUT = "SET_LOGOUT";

const CLEAR_AUTH_ERRORS = "CLEAR_AUTH_ERRORS";

const requestLogInAction = () => {
  return {
    type: REQUEST_LOGIN,
  };
};

const responseLogInAction = (data) => {
  return {
    type: RESPONSE_LOGIN,
    data,
  };
};

const requestLogInfailureAction = (errors) => {
  return {
    type: REQUEST_LOGIN_FAILURE,
    errors,
  };
};

const logIn = (data) => {
  return (dispatch) => {
    dispatch(requestLogInAction());

    AccountService.logIn(data)
      .then((result) => responseLogIn(result.data, dispatch))
      .catch((error) => {
        dispatch(requestLogInfailureAction(error.response));
      });
  };
};

const logInDemo = () => {
  return (dispatch) => {
    dispatch(requestLogInAction());

    AccountService.logInDemo()
      .then((result) => responseLogIn(result.data, dispatch))
      .catch((error) => {
        dispatch(requestLogInfailureAction(error.response));
      });
  };
};

const responseLogIn = (data, dispatch) => {
  const permissions = data.permissions;
  CookieService.setCookie(configuration.permissionsCookie, JSON.stringify(permissions));
  CookieService.updateExpires(configuration.permissionsCookie, 24);
  delete data.permissions;

  CookieService.setCookie(configuration.cookieName, JSON.stringify(data));
  CookieService.updateExpires(configuration.cookieName, 24);

  dispatch(responseLogInAction(data));
  dispatch(handleClearStoreSingIn());
};

const responseLogOutAction = () => {
  return {
    type: RESPONSE_LOGOUT,
  };
};

const requestLogOutfailureAction = (errors) => {
  return {
    type: REQUEST_LOGOUT_FAILURE,
    errors,
  };
};

const logOut = () => {
  return (dispatch) => {
    dispatch(responseLogOutAction());
    CookieService.deleteCookie(configuration.cookieName);
    CookieService.deleteCookie(configuration.permissionsCookie);
    // AccountService.logOut(data)
    //   .then((result) => {
    //     dispatch(responseLogOutAction(result.response))
    //   })
    //   .catch((error) => {
    //     dispatch(requestLogOutfailureAction(error.response))
    //   })
  };
};

const setRegistrationAction = (data) => {
  return {
    type: SET_REGISTRATION,
    data,
  };
};

const setRegistration = (options) => {
  return (dispatch) => {
    dispatch(setRegistrationAction(options));
  };
};

const setLoginAction = (data) => {
  return {
    type: SET_LOGIN,
    data,
  };
};

const setLogin = (options) => {
  return (dispatch) => {
    dispatch(setLoginAction(options));
  };
};

const setLogoutAction = () => {
  return {
    type: SET_LOGOUT,
  };
};

const setLogout = () => {
  return (dispatch) => {
    CookieService.deleteCookie(configuration.cookieName);
    CookieService.deleteCookie(configuration.permissionsCookie);
    dispatch(setLogoutAction());
  };
};
const clearAuthErrors = () => {
  return {
    type: CLEAR_AUTH_ERRORS,
  };
};

export {
  logIn,
  logInDemo,
  requestLogInAction,
  responseLogInAction,
  requestLogInfailureAction,
  REQUEST_LOGIN,
  RESPONSE_LOGIN,
  REQUEST_LOGIN_FAILURE,
  logOut,
  responseLogOutAction,
  requestLogOutfailureAction,
  RESPONSE_LOGOUT,
  REQUEST_LOGOUT_FAILURE,
  setLoginAction,
  setLogin,
  SET_LOGIN,
  setLogout,
  setLogoutAction,
  SET_LOGOUT,
  setRegistrationAction,
  setRegistration,
  SET_REGISTRATION,
  clearAuthErrors,
  CLEAR_AUTH_ERRORS,
};
