import React, { Component } from "react";
import { withEmployeeMultipleSelect } from "../../../shared/containers/containerEmployeeMultipleSelect";
import { withEmployeeGeoZones } from "../../../shared/containers/containerEmployeeGeoZones";
import { withObjectives } from "../../../shared/containers/containerObjectives";
import EmployeeMultipleSelectService from "../../../shared/services/employeeMultipleSelectService";
import Icon from "../../../shared/items/static/icon";
import styles from "./employeesBookmark.module.scss";

const type = EmployeeMultipleSelectService.type;
class MultypleCheckbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChdeck: props.isChdeck,
      employee: props.employee,
      objectives: props.objectives,
      employeeMultipleSelect: props.employeeMultipleSelect,
    };
  }

  setSchedule(schedule, objectives) {
    for (const objective of objectives) {
      for (const i in objective.schedules) {
        if (objective.schedules[i].id === schedule.id) {
          objective.schedules[i] = schedule;
        }
      }
    }

    this.setState({ objectives: objectives });
  }

  getSchedule(id, objectives) {
    for (const objective of objectives) {
      for (const schedule of objective.schedules) {
        if (schedule.id === id) {
          return {
            objectiveId: objective.id,
            schedule,
          };
        }
      }
    }

    return null;
  }

  addEmployee(employeeMultipleSelect, onAddEmployee, employee, addScheduleEmployee, objectives) {
    if (type.employeeGeozones === employeeMultipleSelect.type) {
      onAddEmployee(employeeMultipleSelect.id, employee.id);
    }

    if (type.objectiveShedules === employeeMultipleSelect.type) {
      const objectiveSchedule = this.getSchedule(employeeMultipleSelect.id, objectives);
      objectiveSchedule.schedule.employees.push(employee);

      addScheduleEmployee(objectiveSchedule.objectiveId, objectiveSchedule.schedule, employee);
    }
  }

  deleteEmployee(employeeMultipleSelect, onDeleteEmployee, employee, onDeleteScheduleEmployee, objectives) {
    if (type.employeeGeozones === employeeMultipleSelect.type) {
      onDeleteEmployee(employeeMultipleSelect.id, employee.id);
    }

    if (type.objectiveShedules === employeeMultipleSelect.type) {
      const objectiveSchedule = this.getSchedule(employeeMultipleSelect.id, objectives);

      objectiveSchedule.schedule.employees = objectiveSchedule.schedule.employees.filter((x) => {
        return x.id !== employee.id;
      });

      onDeleteScheduleEmployee(objectiveSchedule.objectiveId, objectiveSchedule.schedule, employee);
    }
  }

  render() {
    const {
      onAddEmployee,
      onDeleteEmployee,
      onAddScheduleEmployee,
      onDeleteScheduleEmployee,
      isCheck,
      employee,
    } = this.props;

    const { employeeMultipleSelect, objectives } = this.state;

    const deleteEmployee = () => {
      this.deleteEmployee(
        employeeMultipleSelect,
        onDeleteEmployee,
        employee,
        onDeleteScheduleEmployee,
        objectives.objectives
      );
    };

    const addEmployee = () => {
      this.addEmployee(employeeMultipleSelect, onAddEmployee, employee, onAddScheduleEmployee, objectives.objectives);
    };

    return (
      <div
        key={employee.id + "_" + employeeMultipleSelect.id}
        className={styles.employeesBookmark}
        onClick={(e) => {
          e.stopPropagation();
          if (isCheck) {
            deleteEmployee();
          } else {
            addEmployee();
          }
        }}
      >
        <Icon name="icon-check-activ" />
      </div>
    );
  }
}

export default withObjectives(withEmployeeGeoZones(withEmployeeMultipleSelect(MultypleCheckbox)));
