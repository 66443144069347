import React, { Component } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, MapConsumer, TileLayer, Polygon, Circle } from "react-leaflet";
import L from "leaflet";
import "./objectivesBlock.modeule.scss";

class GeoZoneMapView extends Component {
  getCircle(geoZone) {
    const center = JSON.parse(geoZone.properties["CPOI"]);

    return (
      <Circle geozone={true} center={[center[1], center[0]]} radius={Number(geoZone.properties["RADIUS"])}></Circle>
    );
  }

  getPolygon(geozone) {
    const geoJson = JSON.parse(geozone.geometry);
    geoJson.coordinates.slice(geoJson.coordinates.length - 1, 1);
    const latLangs = [];
    latLangs.push(
      geoJson.coordinates[0].map((item) => {
        const LatLng = new L.LatLng(item[1], item[0]);
        return LatLng;
      })
    );

    return <Polygon geozone={true} positions={latLangs} />;
  }

  getGoezonelayer() {
    const { geozone } = this.props;

    return geozone.properties["TYPE"] === "circle" ? this.getCircle(geozone) : this.getPolygon(geozone);
  }

  render() {
    const { geozone } = this.props;
    if (!geozone) {
      return null;
    }

    return (
      <MapContainer
        key={geozone.id}
        zoom={13}
        center={[0, 0]}
        fadeAnimation={true}
        dragging={false}
        attributionControl={false}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        zoomControl={false}
      >
        <MapConsumer>
          {(map) => {
            map.on("layeradd", (e) => {
              if (e.layer.options.geozone) {
                map.fitBounds(e.layer.getBounds());
              }
            });

            return null;
          }}
        </MapConsumer>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {this.getGoezonelayer()}
      </MapContainer>
    );
  }
}

export default GeoZoneMapView;
