import React from 'react'
import dateTime from './../../../shared/services/date.service'
import EmployeeStatusTitle from './../../../shared/items/static/employeeStatusTitle'


const orderCodes = ['USTA', "TI", 'ADD']

const getDashboardData = (param, data) => {
  let dashboards = []

  if (param.length > 0) {

    orderCodes.map((item, index) => {
      var i = param.findIndex(p => {
        if (p.codes.length === 0) {
          return false;
        }

        return item === p.codes[0];
      })

      if (i !== -1) {
        var statusItem = param[i];
        param.splice(index, 0, statusItem)
        param.splice(i + 1, 1)
      }

      return null;
    })
  }

  param.map((item) => {
    if (item.codes.length > 1) {
      var complexItem = getComplexValueItem(data, item);
      if (complexItem) {
        dashboards.push(complexItem);
      }
    } else {
      Object.keys(data).map((sensorCode) => {
        if (item.codes[0] === sensorCode) {
          dashboards.push({
            value: appendBeforeAfter(item, data[sensorCode]),
            title: item.title,
            icon: item.icon,
          })
        }

        return null;
      })
    }

    return null;
  })

  return dashboards
}

const getComplexValueItem = function (data, item) {
  let codeValue = []

  item.codes.map((code) => {
    Object.keys(data).map((sensorCode) => {
      if (code === sensorCode) {
        appendBeforeAfter(item, data[sensorCode])
        codeValue.push(data[sensorCode])
      }

      return null;
    })

    return null;
  })

  codeValue = checkComplexValueType(codeValue, item.type)
  var complexValueItem = null;
  if (codeValue.length > 0) {
    complexValueItem = {
      value: codeValue,
      title: item.title,
      icon: item.icon,
    };
  }

  return complexValueItem;
}

const checkSimpleValueType = (value, type) => {
  switch (type) {
    case 0: {
      return value
    }
    case 1: {
      return value
    }
    case 2: {
      let localDate = dateTime.toLocal(value)

      return dateTime.toFormat3(localDate)
    }
    case 3: {
      if (value.toLowerCase() === "true") {
        return "Покой"
      } else {
        return "Движение"
      }
    }
    case 4: {
      return <EmployeeStatusTitle status={parseInt(value)} />
    }
    default:
      return value
  }
}

const appendBeforeAfter = (param, value) => {
  let valueBefore = !!param.valueBefore ? `${param.valueBefore} ` : ''
  let valueAfter = !!param.valueAfter ? ` ${param.valueAfter}` : ''
  let checkedValue = checkSimpleValueType(value, param.type)

  if (typeof (checkedValue) === 'object') {
    return checkedValue
  } else {
    return valueBefore + checkedValue + valueAfter
  }
}

const checkComplexValueType = (value, type) => {
  switch (type) {
    case 1:
      value = value.map((intem) => {
        return intem.replace(",", ".");
      });

      return value.join(', ')
    default:
      return value
  }
}

export { getDashboardData }