import React from "react";

import { withChoosedEmployee } from "../../../shared/containers/containerChoosedEmployee";
import { withItemsIntellegence } from "../../../shared/containers/containerItemsIntellegence";
import { withGetIntellegence } from "../../../shared/containers/containerGetIntellegence";
import { withDashboard } from "./../../../shared/containers/containerDashboard";
import { withSensors } from "./../../../shared/containers/containerSensors";

import ButtonPrimary from "../../../shared/items/contols/buttonPrimary";
import TimeMomentIntellegence from "./timeMomentIntellegence";
import dateTime from "./../../../shared/services/date.service";
import Notification from "./../../../shared/items/static/notification";
import SnackbarWrapper from "./../../../shared/items/static/snackbarWrapper";
import CookieService from "../../../shared/services/cookiesService";
import styles from "./employeesIntellegence.module.scss";

const getIntellegenceData = (props) => {
  const { itemsIntellegence, choosedEmployee, onGetInmomentIntellegence, onSetInmomentDate, onGetSensors } = props;
  const valueStore = itemsIntellegence.itemsIntellegence;

  var date = new Date(valueStore.momentDate);
  var dateTime = new Date(valueStore.momentTime);
  date.setHours(dateTime.getHours());
  date.setMinutes(dateTime.getMinutes());
  date.setSeconds(59);

   const logPermmisions = CookieService.getPermissions("permissionTN").includes(30001);
  const options = {
    date,
    employeeId: choosedEmployee.employeeId,
    locationNotProcessed: logPermmisions,
  };

  if (!!choosedEmployee.employeeId) {
    onGetInmomentIntellegence(options);
    onGetSensors(options);
    onSetInmomentDate(date);
  }
};

const IntellegenceMomentForm = (props) => {
  const { onHandleChangeStore, itemsIntellegence, choosedEmployee } = props;

  const momentDate = itemsIntellegence.itemsIntellegence.momentDate;
  const momentTime = itemsIntellegence.itemsIntellegence.momentTime;
  const date = dateTime.withEmptyTimeUtcNumber(momentDate);
  const currentDate = dateTime.withEmptyTimeUtcNumber(new Date());
  let currentTime = new Date();
  currentTime.setSeconds(0);
  currentTime.setMilliseconds(0);

  const isValidTime = date === currentDate && dateTime.toUtcNumber(momentTime) > dateTime.toUtcNumber(currentTime);

  const isButtonDisabled = isNaN(momentDate) || isNaN(momentTime) || date > currentDate || isValidTime || !choosedEmployee.employeeId;

  return (
    <div className={styles.container_itemsTimeIntellegence}>
      <TimeMomentIntellegence onHandleChangeStore={onHandleChangeStore} valueStore={itemsIntellegence.itemsIntellegence} />
      <div className={styles.container_itemsTimeButtom}>
        <ButtonPrimary handleClick={getIntellegenceData.bind(this, props)} title="Показать" disabled={isButtonDisabled} />
      </div>
      <SnackbarWrapper isOpen={isValidTime}>
        <Notification type="error">
          <p>{`Вы выбрали время (${dateTime.toTimeFormat1(momentTime)}) позже текущего (${dateTime.toTimeFormat1(new Date())}).`}</p>
        </Notification>
      </SnackbarWrapper>
    </div>
  );
};

export default withSensors(
  withDashboard(withChoosedEmployee(withItemsIntellegence(withGetIntellegence(React.memo(IntellegenceMomentForm)))))
);
