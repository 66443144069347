import React, { Component } from "react";

import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Icon from "./../static/icon";
import { ThemeProvider } from "@material-ui/styles";

import { withStyles } from "@material-ui/core/styles";
import { styles } from "./static.inputDateTime";
import { datepickerTheme } from "./static.modalDateTime";

import inputStyles from "./input.module.scss";

class InputDate extends Component {
  componentDidMount() {
    const { onHandleChangeStore, valueStore, name } = this.props;

    const date = new Date();
    const thisDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);

    if (!!valueStore[name]) {
      const thisValueStore = new Date(
        valueStore[name].getFullYear(),
        valueStore[name].getMonth(),
        valueStore[name].getDate(),
        0,
        0,
        0
      );

      if (thisDate.getTime() !== thisValueStore.getTime()) {
        onHandleChangeStore(thisValueStore);
      }
    } else {
      onHandleChangeStore(thisDate);
    }
  }

  render() {
    const {
      classes,
      onHandleChangeStore,
      valueStore,
      name,
      minDate,
      disabled,
      maxDate,
      invalidLabel,
      disablePast,
    } = this.props;
    let thisValueStore = new Date();

    if (!!valueStore[name]) {
      thisValueStore = new Date(valueStore[name]);
    }

    return (
      <ThemeProvider theme={datepickerTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <KeyboardDatePicker
            className={clsx(classes.root, inputStyles.inputStyle)}
            ampm={false}
            value={thisValueStore}
            onChange={onHandleChangeStore}
            inputVariant="outlined"
            keyboardIcon={<Icon name="Subtract1" />}
            mask="__.__.____"
            format="dd.MM.yyyy"
            invalidDateMessage="Неверный формат"
            invalidLabel={invalidLabel !== undefined ? invalidLabel : null}
            maxDateMessage="Вы выбрали дату позже максимальной"
            minDateMessage="Вы выбрали очень раннюю дату"
            okLabel="Выбрать"
            cancelLabel="Отмена"
            showTodayButton
            todayLabel="Сегодня"
            minDate={minDate}
            disabled={!!disabled ? disabled : false}
            disablePast={!!disablePast ? disablePast : false}
            maxDate={maxDate}
            animateYearScrolling={true}
            autoOk={true}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(InputDate);
