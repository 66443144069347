import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  getDashboard as onGetDashboard,
  setDashboard as onSetDashboard
} from '../../actions/dashboardActions'

const mapStateToProps = (state) => ({
  dashboard: {
    dataState: state.dashboard.dataState,
    data: get(state, ['dashboard', 'data'], {}),
    feature: get(state, ['sensors', 'feature'], {}),
    errors: get(state, ['dashboard', 'errors'], [])
  }
})

const mapDispatchToProps = {
  onGetDashboard,
  onSetDashboard
}

const withDashboard = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withDashboard }