import React, { Component } from "react";
import { withCompanies } from "../../../shared/containers/containerCompanies";
import Preloader from "../../../shared/items/static/preloader";
import Notification from "../../../shared/items/static/notification";
import CompaniesListItem from "./companiesListItem";
import { states } from "../../../reducers/queriesStates";
import "../employees/employeesList.module.scss";
import styles from "../employees/employeesList.module.scss";
import employeesBarStyles from "./../sideBars/employeesBar.module.scss";
import configuration from "../../../config.json";
import CookieService from "../../../shared/services/cookiesService";
import { uuid } from "uuidv4";

class CompaniesList extends Component {
  componentDidMount() {
    const { onGetCompanies, companies } = this.props;
    const { dataState } = this.props.companies;

    if (dataState === states.NOT_REQUESTED) {
      onGetCompanies(companies.getCompanyOptions);
    }
  }

  componentDidUpdate(prevProps) {
    const { companies, handleScrollTo } = this.props;

    if (prevProps.companies.dataState !== states.READY && companies.dataState === states.READY) {
      handleScrollTo(0);
    }
  }

  getItems(companies, handleScrollTo, enterCompany = false) {
    const items = companies.map((item) => {
      return <CompaniesListItem key={uuid()} data={item} handleScrollTo={handleScrollTo} enterCompany={enterCompany} />;
    });

    return items;
  }

  render() {
    const { handleScrollTo } = this.props;
    const { companies, dataState } = this.props.companies;
    const enterCompany = CookieService.getCookie(configuration.permissionsCookie);

    if (dataState === states.READY) {
      return companies.length > 0 ? (
        <div className={styles.employeesListItem}>{this.getItems(companies, handleScrollTo, enterCompany)}</div>
      ) : (
        <Notification type="warning">
          <p>Компании не найдены.</p>
        </Notification>
      );
    } else if (dataState !== states.READY) {
      return (
        <div className={employeesBarStyles.container_preloader}>
          <Preloader />
        </div>
      );
    }
  }
}

export default withCompanies(React.memo(CompaniesList));
