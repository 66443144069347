import React, { Component } from "react";

import { withAuth } from "../../shared/containers/containerAuth";
import { withSignInForm } from "../../shared/containers/containerSignInForm";

import ButtonPrimary from "../../shared/items/contols/buttonPrimary";
import PreloaderLight from "./../../shared/items/static/preloaderLight";
import { states } from "./../../reducers/queriesStates";

import styles from "./buttonLoginWrapper.module.scss";

const BUTTON_LOGIN_NAME = "Войти";

class ButtonLoginWrapper extends Component {
  componentDidMount() {
    document.addEventListener("keydown", this.clickEnter);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.clickEnter);
  }

  clickEnter = (e) => {};

  render() {
    const { signInForm, onLogIn, dataState } = this.props;

    return (
      <ButtonPrimary handleClick={onLogIn.bind(this, signInForm.signInItems)}>
        {dataState === states.REQUEST ? (
          <div className={styles.container_preloader}>
            <PreloaderLight />
          </div>
        ) : (
          BUTTON_LOGIN_NAME
        )}
      </ButtonPrimary>
    );
  }
}

export default withAuth(withSignInForm(ButtonLoginWrapper));
