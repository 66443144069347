import React from "react";
import { withRouter } from "react-router";
import styles from "../users/usersListItem.module.scss";
import UserBlock from "./userBlock";
import UserDelete from "./userDelete";

const UsersListItem = (props) => {
  const { data } = props;

  return (
    <div
      className={`${styles.usersListItemWrapper} ${data.isBlocked ? styles.usersListItem_isBookmark : ""}`}
      style={{ height: "50px" }}
    >
      <div>
        <div className={styles.container_userContent}>
          <div className={styles.container_name}>
            <b>{data.email}</b>
            <p>{data.title}</p>
          </div>
          <div className={styles.container_bookmark}>
            <UserBlock id={data.id} isBlocked={data.isBlocked} title={data.title} />
          </div>
          <div className={styles.container_bookmark} style={{ top: "30px" }}>
            <UserDelete id={data.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(React.memo(UsersListItem));
