import React, { Component } from "react";
import { withObjectives } from "../../../shared/containers/containerObjectives";
import TabButton from "./tabButton";
import ObjectivesGeozonesContent from "./objectivesGeozonesContent";
import ObjectivesBuildingsContent from "./objectivesBuildingsContent"
import "./objectivesBlock.modeule.scss";

const viewObjectivesItems = {
  kalend: "kalend",
  geozoneObjectives: "geozoneObjectives",
  buildObjectives: "buildObjectives",
};

class ObjectivesBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewObjectives: viewObjectivesItems.geozoneObjectives,
    };
  }

  openViewBlock(viewObjectives) {
    this.setState({ viewObjectives: viewObjectives });
  }

  render() {
    const { viewObjectives } = this.state;

    return (
      <div className={"objectivesContainer"}>
        <div className={"tabObjectives"}>
          {/* <TabButton
            title={"Календарь"}
            currentView={viewObjectivesItems.kalend}
            checkedView={viewObjectives}
            handlerClick={this.openViewBlock.bind(this)}
          /> */}
          <TabButton
            title={"Геозоны"}
            currentView={viewObjectivesItems.geozoneObjectives}
            checkedView={viewObjectives}
            handlerClick={this.openViewBlock.bind(this)}
          />
       {/*    <TabButton
            title={"Строения"}
            currentView={viewObjectivesItems.buildObjectives}
            checkedView={viewObjectives}
            handlerClick={this.openViewBlock.bind(this)}
          /> */}
        </div>
        {/* {viewObjectives === viewObjectivesItems.kalend ? <KalendPanel /> : null} */}

        {viewObjectives === viewObjectivesItems.geozoneObjectives ? <ObjectivesGeozonesContent /> : null}

        {viewObjectives === viewObjectivesItems.buildObjectives ?  <ObjectivesBuildingsContent />: null}
      </div>
    );
  }
}

export default withObjectives(ObjectivesBlock);
