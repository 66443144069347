import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./navigationItem.module.scss";
import RepositoriesService from "./services/repositoriesService";
import { withEmployeesLastState } from "../shared/containers/containerEmployeeLastState";
import { withEmployeeGeoZones } from "../shared/containers/containerEmployeeGeoZones";
import { withEmployees } from "../shared/containers/containerEmployees";

const configuratorDownload = function name() {
  RepositoriesService.GetConfigurator();
};

const manualDownload = function name() {
  RepositoriesService.GetManual();
};

const NavigationItem = (props) => {;
  const { title, link } = props.content;

  if (title === "Скачать кофигуратор") {
    return (
      <NavLink onClick={() => configuratorDownload()} to={link} className={styles.navigationItem}>
        <b>{title}</b>
      </NavLink>
    );
  } else if (title === "Руководство по эксплуатации") {
    return (
      <NavLink onClick={() => manualDownload()} to={link} className={styles.navigationItem}>
        <b>{title}</b>
      </NavLink>
    );
  } else
    return (
      <NavLink to={link} className={styles.navigationItem}>
        <b>{title}</b>
      </NavLink>
    );
};

export default withEmployeeGeoZones(withEmployeesLastState(withEmployees(React.memo(NavigationItem))));
