import React from "react";
import L from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { employeeStatuses } from "../../components/sections/employees/static.employeeStatuses";
import { getMarker } from "./getMarker";
import PositionService from "./../services/positionsService";
import "./popupStyles.scss";
import "./mapMarker.scss";

const getMarkerState = function (status) {
  var markerStatus;

  Object.values(employeeStatuses).map((item) => {
    if (status === item.id) {
      markerStatus = item.name;
    }
    return null;
  });

  return markerStatus;
};

function onClickMarker(e, popup) {
  if (popup.props.children.props.children[2].length === 0) {
    const latLng = L.latLng(e.latlng);

    PositionService.Address(latLng).then((response) => {
      const address = response.data.address ? "<b>Адрес:</b> " + response.data.address : "";
      const content = e.target.options.poupupContent ? e.target.options.poupupContent : "";

      L.popup({ className: "black-popup" })
        .setLatLng(latLng)
        .setContent(content + address)
        .openOn(e.target._map);
    });
  }
}

const MapPopupMarker = (props) => {
  const { position, status, number, popup, poupupContent } = props;

  const markerIcon = L.divIcon({
    iconSize: [36, 40],
    iconAnchor: [17, 40],
    popupAnchor: [0, 0],
    className: "mapMarker " + getMarkerState(status),
    html: getMarker("inner", number !== undefined ? number : "SOS"),
  });

  if (position[0] === 0 || position[1] === 0) {
    return null;
  }

  return (
    <Marker
      position={position}
      icon={markerIcon}
      poupupContent={poupupContent}
      eventHandlers={{
        click: (e) => {
          onClickMarker(e, popup);
        },
      }}
    >
      {popup !== undefined && popup.props.children.props && popup.props.children.props.children[2].length > 0 ? (
        <Popup className={"sensors-black-popup"}>{<p>{popup.props.children}</p>}</Popup>
      ) : null}
    </Marker>
  );
};

export default React.memo(MapPopupMarker);
