import React from "react";
import styles from "./tabsNavbar.module.scss";
import CookieService from "./services/cookiesService";

const TabsNavbar = (props) => {
  const { data, active, handleChange } = props;
  const employeeInfo = CookieService.getPermissions("permissionTN").includes(40001);

  return (
    <div className={styles.tabsNavbar}>
      {data.map((item) => {
        if (item.title === "Информация о сотруднике" && !employeeInfo) {
          return null;
        }

        return (
          <div
            className={item.name === active ? styles.tabsNavbarItem_activeTab : styles.tabsNavbarItem}
            key={item.name}
            onClick={item.name !== active ? handleChange.bind(this, item.name) : null}
          >
            <span>{item.title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(TabsNavbar);
