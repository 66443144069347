import React from 'react'

import PhoneLink from './../items/static/phoneLink'

import styles from './footer.module.scss'

const SignInFooter = () => {
  return (
    <footer className={styles.signInFooter}>
      <p className={styles.copy}>GPS/GLONASS система мониторинга Транснавигатор © {new Date().getFullYear()}</p>
      <p className={styles.support}>
        Техническая поддержка:
        <PhoneLink title="+375 (17) 282-00-92" link="+375172820092" />,
        <PhoneLink title="+375 (29) 148-31-70" link="+375291483170" />,
        <PhoneLink title="+375 (29) 190-64-55" link="+375291906455" />
      </p>
      <p className={styles.support}>
        Отдел продаж:
        <PhoneLink title="+375 (17) 277-23-83" link="+375172772383" />,
        <PhoneLink title="+375 (29) 114-36-50" link="+375291143650" />
      </p>
      <p className={styles.support}>
        Связь по электронной почте: info@armproject.by;
      </p>
    </footer>
  )
}

export default SignInFooter