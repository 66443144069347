import React from "react";

import { withIntellegenceRangeType } from "../../../shared/containers/containerIntellegenceRangeType";
import EmployeeMapMoment from "./employeeMapMoment";
import EmployeeMapTimeRange from "./employeeMapTimeRange";
import TimeRange from "./static.timeRange";

import styles from "./employeeShortMap.module.scss";

const EmployeeShortMap = (props) => {
  const { intellegenceRangeType, onChangeIntellegenceRangeType } = props;

  return (
    <div className={styles.employeeShortMap}>
      {intellegenceRangeType.rangeType === TimeRange.intimerange.value ? (
        <EmployeeMapTimeRange
          notProcessedRefresh={{
            name: TimeRange.intimerange.value,
            collback: onChangeIntellegenceRangeType,
          }}
        />
      ) : (
        <EmployeeMapMoment />
      )}
    </div>
  );
};

export default withIntellegenceRangeType(React.memo(EmployeeShortMap));
