import React from "react";

import Icon from "../../../shared/items/static/icon";
import DropDownSelect from "../../../shared/items/contols/dropDownSelect";
import SortEmployeesDropdownMenu from "./sortEmployeesDropdownMenu";
import { getSortEmployeesTypeTitle } from "./static.sortEmployeesTypes";
import { sortDirections } from "./static.sortDirections";

import styles from "./sortEmployeesDropdown.module.scss";

const SortEmployeesDropdown = (props) => {
  const { onHandleChangeStore, filterItems } = props;

  return (
    <div className={styles.sortEmployees}>
      <div className={styles.container_direction}>
        <div
          className={
            filterItems.orderDesc === sortDirections.asc
              ? styles.container_direction_asc_active
              : styles.container_direction_asc
          }
          onClick={onHandleChangeStore.bind(this, "orderDesc", sortDirections.asc)}
        >
          <Icon name="direction-asc" />
        </div>
        <div
          className={
            filterItems.orderDesc === sortDirections.desc
              ? styles.container_direction_desc_active
              : styles.container_direction_desc
          }
          onClick={onHandleChangeStore.bind(this, "orderDesc", sortDirections.desc)}
        >
          <Icon name="direction-desc" />
        </div>
      </div>
      <DropDownSelect
        buttonTitle={getSortEmployeesTypeTitle(filterItems.orderBy)}
        content={<SortEmployeesDropdownMenu handleChooseType={onHandleChangeStore.bind(this, "orderBy")} />}
      />
    </div>
  );
};

export default React.memo(SortEmployeesDropdown);
