'use stric'

const mapState = {
  baseSize: {
    name: 'baseSize'
  },
  fullSize: {
    name: 'fullSize'
  },
  fullWidth: {
    name: 'fullWidth'
  }
}

export {
  mapState
}