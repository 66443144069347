import React from 'react';
import Collapsible from 'react-collapsible';

import CollapsibleHeader from './collapsibleHeader'

import './collapsibleWrapper.scss'

const CollapsibleWrapper = props => {
  const { title } = props

  return (
    <Collapsible
      className="collapsibleWrapper"
      trigger={<CollapsibleHeader title={title} />}
      transitionTime={300}
      transitionCloseTime={300}
      open={true}
      overflowWhenOpen='visible'
    >
      {props.children}
    </Collapsible>
  )
}

export default React.memo(CollapsibleWrapper)
