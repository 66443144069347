import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import { withFilterEmployees } from "./containers/containerFilterEmployees";
import FilterEmployeesItems from "./../components/sections/employees/static.filterEmployeesItems";

import styles from "./filterEmployeesTooltip.module.scss";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "none",
    color: "none",
    border: "0px solid transparent",
    padding: "0px",
    borderRadius: "none",
  },
}))(Tooltip);

const DEFAULT_TITLE = "Фильтр не выбран";

const getItemsTooltip = (props) => {
  const { filterEmployees } = props;
  const filterItems = filterEmployees.filterItems;
  var statuses = [];
  var counter = 0;
  var insideItems = [];

  const getLiFilterItems = function (filterItems, filterItem, counter) {
    const items = filterItems[filterItem].map((item, index) => {
      return (
        <li key={counter + "." + index}>
          <span className={styles.contentTooltip}>{item.label}</span>
        </li>
      );
    });

    return items;
  };

  Object.keys(filterItems).map((filterItem) => {
    switch (filterItem) {
      case FilterEmployeesItems.companies.name: {
        if (!!filterItems[filterItem] && filterItems[filterItem].length > 0) {
          insideItems.push(
            <li key={counter}>
              {<span className={styles.titleTooltip}>{FilterEmployeesItems.companies.title}:</span>}
              <ui>{getLiFilterItems(filterItems, filterItem, counter)}</ui>
            </li>
          );

          counter++;
        }

        return null;
      }

      case FilterEmployeesItems.groups.name: {
        if (!!filterItems[filterItem] && filterItems[filterItem].length > 0) {
          insideItems.push(
            <li key={counter}>
              {<span className={styles.titleTooltip}>{FilterEmployeesItems.groups.title}:</span>}
              <ui>{getLiFilterItems(filterItems, filterItem, counter)}</ui>
            </li>
          );

          counter++;
        }

        return null;
      }

      case FilterEmployeesItems.workPositions.name: {
        if (!!filterItems[filterItem] && filterItems[filterItem].length > 0) {
          insideItems.push(
            <li key={counter}>
              <span className={styles.titleTooltip}>{FilterEmployeesItems.workPositions.title}:</span>
              <ui>{getLiFilterItems(filterItems, filterItem, counter)}</ui>
            </li>
          );

          counter++;
        }

        return null;
      }

      case FilterEmployeesItems.noData.name: {
        if (filterItems[filterItem]) {
          statuses.push(FilterEmployeesItems.noData.title);
          counter++;
        }

        return null;
      }
      case FilterEmployeesItems.inSleep.name: {
        if (filterItems[filterItem]) {
          statuses.push(FilterEmployeesItems.inSleep.title);
          statuses.push(FilterEmployeesItems.offSleep.title);
          counter += 2;
        }

        return null;
      }
      case FilterEmployeesItems.work.name: {
        if (filterItems[filterItem]) {
          statuses.push(FilterEmployeesItems.work.title);
          counter++;
        }

        return null;
      }
      case FilterEmployeesItems.charging.name: {
        if (filterItems[filterItem]) {
          statuses.push(FilterEmployeesItems.charging.title);
          statuses.push(FilterEmployeesItems.poweredFrom.title);        
        }

        return null;
      }
/* 
      case FilterEmployeesItems.offSleep.name: {
        if (filterItems[filterItem]) {
          statuses.push(FilterEmployeesItems.offSleep.title);
          counter++;
        } 

        return null;
      }*/
      case FilterEmployeesItems.alarm.name: {
        if (filterItems[filterItem]) {
          statuses.push(FilterEmployeesItems.alarm.title);
          counter++;
        }

        return null;
      }

      case FilterEmployeesItems.poweredFrom.name: {
        if (filterItems[filterItem]) {
          statuses.push(FilterEmployeesItems.poweredFrom.title);
          counter++;
        }

        return null;
      }
      default:
        return null;
    }
  });

  if (statuses.length > 0) {
    insideItems.push(
      <li key={counter}>
        {<span className={styles.titleTooltip}>Статус трекера:</span>}
        <ui>
          {statuses.map((item, index) => {
            return (
              <li key={counter + "." + index}>
                <span className={styles.contentTooltip}>{item}</span>
              </li>
            );
          })}
        </ui>
      </li>
    );

    counter++;
  }

  return (
    <ul className={styles.tooltipTemplate}>
      {insideItems.length > 0 ? insideItems.map((item) => item) : DEFAULT_TITLE}
    </ul>
  );
};

const FilterEmployeesTooltip = (props) => {
  return ( 
    <HtmlTooltip title={getItemsTooltip(props)} placement="right">
      <div>{props.children}</div>
    </HtmlTooltip>
  );
};

export default withFilterEmployees(React.memo(FilterEmployeesTooltip));
