import {
  READY_CHANGE_STORE_INTELLEGENCE_MODAL_FORM
} from '../actions/intellegenceModalFormActions'

import { states } from './queriesStates'

const initialState = {
  dataState: states.NOT_REQUESTED,
  intellegenceModalForm: {}
}

const intellegenceModalFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case READY_CHANGE_STORE_INTELLEGENCE_MODAL_FORM:
      const { name, value } = action.payload
      var intellegenceModalForm = Object.assign({}, state.intellegenceModalForm)

      typeof (name) === 'object'
        ? name.map((item, index) => {
          intellegenceModalForm[item] = value[index]
          return null;
        })
        : intellegenceModalForm[name] = value

      return Object.assign(
        {},
        state,
        {
          dataState: states.READY,
          intellegenceModalForm: intellegenceModalForm
        }
      )
    default:
      return state
  }
}

export default intellegenceModalFormReducer

