import React from 'react'

const Icon = props => {
  const { name, title } = props

  return (
    <span cursor="pointer">{title ? (title + ' ') : ''}
      <span className={`icon icon-${name}`} />

    </span>

  )
}

export default React.memo(Icon)