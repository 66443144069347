import React from "react";

import { withIntellegenceRangeType } from "../../../shared/containers/containerIntellegenceRangeType";

import IntellegenceResultWrapper from "./intellegenceResultWrapper";
import ContentInner from "../../../shared/contentInner";
import IntellegenceMomentForm from "./intellegenceMomentForm";
import IntellegenceInTimeRangeForm from "./intellegenceInTimeRangeForm";
import DeviceLogForm from "./deviceLogForm";
import TimeRange from "../employees/static.timeRange";
import CookieService from "../../../shared/services/cookiesService";
import styles from "./employeesIntellegence.module.scss";

const EmployeesIntellegence = (props) => {
  const { intellegenceRangeType } = props;
  const logPermmisions = CookieService.getPermissions("permissionTN").includes(30001);
  
  return (
    <div className={styles.employeesIntellegence}>
      <ContentInner>
        {/*   <div className={styles.container_dateTimeRangeWrapper}>
          <IntellegenceRangeControl />
        </div> */}

        <div className={styles.container_dateTimeWrapper}>
          {intellegenceRangeType.rangeType === TimeRange.intimerange.value ? (
            <React.Fragment>
              <div className={styles.container_itemsTimeIntellegence}>
                <IntellegenceInTimeRangeForm />
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </ContentInner>

      <ContentInner>
        <IntellegenceResultWrapper rangeType={intellegenceRangeType.rangeType} />
      </ContentInner>
      <React.Fragment>
        {intellegenceRangeType.rangeType === TimeRange.deviceLog.value && logPermmisions ? <DeviceLogForm /> : null}
      </React.Fragment>
    </div>
  );
};

export default withIntellegenceRangeType(React.memo(EmployeesIntellegence));
