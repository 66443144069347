import React from 'react'

import styles from './buttonMediumDefaultPrevIcon.module.scss'

const ButtonMediumDefaultPrevIcon = props => {
  return (
    <span className={styles.buttonPrevIcon}>
      {props.children}
    </span>
  )
}

export default ButtonMediumDefaultPrevIcon