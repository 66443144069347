const lessTen = function (n) {
  return n < 10 ? "0" + n : n;
};

const dateTime = {
  parseStringToDate: function (date) {
    var thisDate = new Date(date);
    return this.parseDate(thisDate);
  },
  parseDate: function (thisDate) {
    return {
      day: thisDate.getDate(),
      month: thisDate.getMonth(),
      year: thisDate.getFullYear(),
      hour: thisDate.getHours(),
      min: thisDate.getMinutes(),
      sec: thisDate.getSeconds(),
    };
  },
  add: function (date, timeOfDay) {
    const timesOfDay = timeOfDay.split(":");
    const resultDate = new Date(date);

    resultDate.setHours(date.getHours() + parseInt(timesOfDay[0]));
    resultDate.setMinutes(date.getMinutes() + parseInt(timesOfDay[1]));
    resultDate.setSeconds(date.getSeconds() + parseInt(timesOfDay[2]));

    return resultDate;
  },
  stringISODate: function (sendDate) {
    const date = new Date(sendDate);
    date.setMilliseconds(0);
    const ISOStringDate = date.toISOString();

    return ISOStringDate.replace(".000Z", "");
  },
  toUtc: function (date) {
    return date.replace("Z", " +00:00");
  },
  fromUtc: function (dateUtc) {
    return new Date(dateUtc);
  },
  toUtcNumber: function (date) {
    return Date.parse(date) ? date.getTime() : date;
  },
  toISOStringUtc: function (date) {
    return date.toISOString();
  },
  toFormat1: function (date) {
    const dates = this.parseStringToDate(date),
      monthName = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];

    return dates.day + " " + monthName[dates.month] + " " + dates.year;
  },
  toFormat2: function (date) {
    const dates = this.parseStringToDate(date);
    return lessTen(dates.day) + "." + lessTen(++dates.month) + "." + dates.year;
  },
  toFormat3: function (date) {
    const dates = this.parseStringToDate(date);
    return (
      lessTen(dates.day) +
      "." +
      lessTen(++dates.month) +
      "." +
      dates.year +
      " " +
      lessTen(dates.hour) +
      ":" +
      lessTen(dates.min) +
      ":" +
      lessTen(dates.sec)
    );
  },
  toFormat4: function (date) {
    const dates = this.parseStringToDate(date);
    return (
      lessTen(dates.day) +
      "." +
      lessTen(++dates.month) +
      "." +
      dates.year +
      ", " +
      lessTen(dates.hour) +
      ":" +
      lessTen(dates.min)
    );
  },
  toFormat5: function (date) {
    const dates = this.parseStringToDate(date);
    return (
      lessTen(dates.day) +
      "." +
      lessTen(++dates.month) +
      " " +
      "в" +
      " " +
      lessTen(dates.hour) +
      ":" +
      lessTen(dates.min) +
      ":" +
      lessTen(dates.sec)
    );
  },
  toFormat6: function (date) {
    const dates = this.parseStringToDate(date);
    return lessTen(dates.day) + "." + lessTen(++dates.month);
  },
  toFormat7: function (date) {
    const dates = this.parseStringToDate(date);
    return (
      lessTen(dates.day) +
      "." +
      lessTen(++dates.month) +
      "." +
      dates.year +
      " " +
      lessTen(dates.hour) +
      ":" +
      lessTen(dates.min)
    );
  },
  toUrlFormat: function (date) {
    const thisDate = new Date(date);
    thisDate.setHours(0, 0, 0, 0);

    return this.stringISODate(thisDate);
  },
  toLocal: function (date) {
    const utcDate = new Date(date.replace("Z", ""));
    const localDate = new Date(
      Date.UTC(
        utcDate.getFullYear(),
        utcDate.getMonth(),
        utcDate.getDate(),
        utcDate.getHours(),
        utcDate.getMinutes(),
        utcDate.getSeconds()
      )
    );

    return localDate;
  },
  toTimeFormat1: function (date) {
    const dates = this.parseStringToDate(date);

    return lessTen(dates.hour) + ":" + lessTen(dates.min);
  },
  toTimeFormat2: function (date) {
    const dates = this.parseStringToDate(date);
    return lessTen(dates.hour) + ":" + lessTen(dates.min) + ":" + lessTen(dates.sec);
  },
  toTimeFormat3: function (date) {
    const dates = this.parseStringToDate(date);
    dates.hour = lessTen(dates.hour);
    dates.min = lessTen(dates.min);
    dates.sec = lessTen(dates.sec);

    return dates;
  },
  onlyDateString(date) {
    return date.toISOString().split("T")[0];
  },
  onlyTimeString(date) {
    let time = date.toISOString().split("T")[1];
    if (time.includes("Z")) {
      time = time.substring(0, 8);
    }

    return time;
  },
  getLocalHoursOfTime(time) {
    const timeVlues = time.split(":");
    return Number(timeVlues[0]) - Number(new Date().getTimezoneOffset() / 60);
  },
  toLocalTime(time) {
    const timeVlues = time.split(":");
    let totlaHour = this.getLocalHoursOfTime(time);

    if (totlaHour >= 24) {
      totlaHour -= 24;
    }

    return `${lessTen(totlaHour)}:${timeVlues[1]}:${timeVlues[2]}`;
  },
  withEmptyTime: function (inDate) {
    const date = new Date(inDate);
    date.setHours(0, 0, 0, 0);

    return date;
  },
  withEmptyTimeUtcNumber: function (inDate) {
    const date = new Date(inDate);
    date.setHours(0, 0, 0, 0);

    return date.getTime();
  },
  withEmptySeconds: function (inDate) {
    const date = new Date(inDate);

    date.setSeconds(0);
    date.setMilliseconds(0);

    return date.getTime();
  },
  withEmptyMseconds: function (inDate) {
    const date = new Date(inDate);
    date.setMilliseconds(0);

    return date.getTime();
  },
  getEndDayTimeDate: function (inDate) {
    const date = new Date(inDate);
    date.setHours(23, 59, 59);

    return date;
  },
  concat: function (date, time) {
    const _date = this.parseStringToDate(date);
    const _time = this.parseStringToDate(time);

    return new Date(_date.year, _date.month, _date.day, _time.hour, _time.min, 0);
  },
};

export default dateTime;
