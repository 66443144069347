import React, { Component } from "react";
import { MapContainer, MapConsumer, ZoomControl, TileLayer, Marker, Popup } from "react-leaflet";

import Control from "react-leaflet-custom-control";
import { Button, ButtonGroup } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import dateTime from "../shared/services/date.service";
import L from "leaflet";
import SharedLocationMarker from "./sharedLocationMarker";
import "./sharedContent.scss";
import "leaflet/dist/leaflet.css";

class SharedMap extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(preProps) {}

  getMarkers() {
    const { events, eventId } = this.props;

    return events.map((x) => {
      const myCustomColour = x.id !== eventId ? "#583470" : "lime";

      const markerHtmlStyles = `
  background-color: ${myCustomColour};
  width: 2rem;
  height: 2rem;
  display: block;
  left: -1rem;
  top: -1rem;
  position: relative;
  border-radius: 2rem 2rem 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF;`;

      const icon = L.divIcon({
        className: "my-custom-pin",
        iconAnchor: [0, 24],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span style="${markerHtmlStyles}" />`,
      });

      return (
        <Marker
          key={x.id + "marker"}
          position={[x.location.lat, x.location.long]}
          icon={icon}
          zIndexOffset={x.id !== eventId ? 10 : 100}
        >
          <Popup>
            {dateTime.toFormat7(x.startAt)} - {dateTime.toFormat7(x.endAt)}
            <br />
            {x.title}
            <br />
            {x.information}
          </Popup>
        </Marker>
      );
    });
  }

  render() {
    const { eventId, events, onBackClick } = this.props;

    const currentEvent = events.filter((x) => {
      return x.id === eventId;
    })[0];

    let locate = (e) => {};

    return (
      <MapContainer
        zoom={18}
        center={[currentEvent.location.lat, currentEvent.location.long]}
        fadeAnimation={true}
        dragging={true}
        attributionControl={false}
        scrollWheelZoom={true}
        doubleClickZoom={false}
        zoomControl={false}
      >
        <MapConsumer>
          {(map) => {
            locate = map.locate.bind(map);

            // map.on("locationfound", (e) => {
            //   map.flyTo(e.latlng, 18);
            // });

            return null;
          }}
        </MapConsumer>
        <Control position="bottomleft">
          <ButtonGroup orientation="vertical" variant="contained">
           {/*  <Button
              onClick={(e) => {
                console.log(e);
                locate({});
              }}
              color="inherit"
            >
              <MyLocationIcon />
            </Button> */}

            <Button
              onClick={(e) => {
                onBackClick(e);
              }}
              color="inherit"
            >
              <ReplyAllIcon />
            </Button>
          </ButtonGroup>
        </Control>
        <ZoomControl position="bottomleft" />

        {this.getMarkers()}
        <SharedLocationMarker />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      </MapContainer>
    );
  }
}

export default SharedMap;
