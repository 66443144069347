import React from "react";
import statusService from "./../../services/statusServise"

const EmployeeStatusTitle = (props) => {
  const status = statusService.getStatusById(props.status);

  return <span className={statusService.getStyles(props.status)}>{status.title}</span>;
};

export default React.memo(EmployeeStatusTitle);
