import L from "leaflet";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuid_v4 } from "uuid";

const noCheckedBaseLayers = {
  OSM: false,
  "ArcGIS: спутник": false,
  "ArcGIS: улицы (черно-болая)": false,
  Яндекс: false,
  "Яндекс: спутник": false,
  "Яндекс: гибрид": false,
  WCS: false,
  "WCS: чёрно-белая": false,
  "WCS: пешеходные и велодорожки": false,
};

const mapLayerControl = {
  bounds: null,
  updateBound: false,
  mapZoom: 18,
  baselayerNames: {
    osm: "OSM",
    arcGisSat: "ArcGIS: спутник",
    arcGisSreet: "ArcGIS: улицы (черно-болая)",
    yandex: "Яндекс",
    yandexSat: "Яндекс: спутник",
    yandexHybrid: "Яндекс: гибрид",
    wcs: "WCS",
    wcsWB: "WCS: чёрно-белая",
    lwcsPedestrian: "WCS: пешеходные и велодорожки",
  },
  overlayerNames: {
    custom: "Пользовательская",
    geoZones: "Геозоны",
  },
  layerControl: {
    crs: L.CRS.EPSG3395,
    baseLayers: {
      OSM: false,
      "ArcGIS: спутник": false,
      "ArcGIS: улицы (черно-болая)": false,
      Яндекс: false,
      "Яндекс: спутник": true,
      "Яндекс: гибрид": true,
      WCS: false,
      "WCS: чёрно-белая": false,
      "WCS: пешеходные и велодорожки": false,
    },
    overlayers: {
      Пользовательская: true,
      Геозоны: true,
    },
  },
  maxZoom: function() {return this.layerControl.crs === L.CRS.EPSG900913 ? 19 : 18},
  baselayerchange: function (e) {
    var baseLayers = cloneDeep(noCheckedBaseLayers);
    this.layerControl.baseLayers = baseLayers;
    if (this.layerControl.baseLayers[e.name] !== undefined) {
      this.layerControl.baseLayers[e.name] = true;
      this.checkYandex(e.name);
    }
  },

  checkYandex(name) {
    if (name.includes(this.baselayerNames.yandex)) {
      this.layerControl.crs = L.CRS.EPSG3395;

      if (this.layerControl.baseLayers[this.baselayerNames.yandexHybrid]) {
        this.layerControl.baseLayers[this.baselayerNames.yandexSat] = true;
      }
    } else {
      this.layerControl.crs = L.CRS.EPSG900913;
    }
  },

  overlayadd: function (e) {
    this.key = uuid_v4();
    if (this.layerControl.overlayers[e.name] !== undefined) {
      this.layerControl.overlayers[e.name] = true;
    }
  },

  overlayremove: function (e) {
    this.key = uuid_v4();
    if (this.layerControl.overlayers[e.name] !== undefined) {
      this.layerControl.overlayers[e.name] = false;
    }
  },
};

export default mapLayerControl;
