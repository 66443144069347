import { connect } from 'react-redux'

import {
  getInmomentIntellegence as onGetInmomentIntellegence,
  setInmomentDate as onSetInmomentDate
} from '../../actions/inmomentIntellegenceActions'

import {
  getTimerangeIntellegence as onGetTimerangeIntellegence,
  setTimerangeDates as onSetTimerangeDates
} from './../../actions/timerangeIntellegenceActions'

const mapDispatchToProps = {
  onGetInmomentIntellegence,
  onGetTimerangeIntellegence,
  onSetTimerangeDates,
  onSetInmomentDate
}

const withGetIntellegence = Component => connect(() => ({}), mapDispatchToProps)(Component)

export { withGetIntellegence }