import React from "react";
import { Link } from "react-router-dom";

import { getEmployeesTypeName } from "./static.employeesTypes";
import EmployeesBookmark from "./employeesBookmark";
import Tooltip from "@material-ui/core/Tooltip";
import { v4 as uuid_v4 } from "uuid";
import styles from "./employeesListItem.module.scss";

const EmployeesListItem = (props) => {
  const { data } = props;
  const getStatusStyles = () => {
    switch (getEmployeesTypeName(data.status.id)) {
      case "noData":
        return styles.container_employeeNumber_noData;
      case "sleep":
        return styles.container_employeeNumber_inSleep;
      case "work":
        return styles.container_employeeNumber_work;
      case "charging":
        return styles.container_employeeNumber_charging;
      case "offSleep":
        return styles.container_employeeNumber_offSleep;
      case "alarm":
        return styles.container_employeeNumber_alarm;
      case "poweredFrom":
        return styles.container_employeeNumber_poweredFrom;
      default:
        return null;
    }
  };

  return (
    <div key={uuid_v4()} className={`${styles.employeesListItemWrapper} ${data.isBookmark ? styles.employeesListItem_isBookmark : ""}`}    >
      <Link kye={uuid_v4()} className={styles.linkWrapper} to={`/employees/${data.id}`}>
        <div key={uuid_v4()} className={styles.employeesListItem}>
          <Tooltip kye={uuid_v4()} title={data.status.title} placement="right-end">
            <div key={uuid_v4()} className={getStatusStyles()}>
              <span className={styles.title}>{data.number}</span>
            </div>
          </Tooltip>
          <div className={styles.container_employeeContent}>
            <div className={styles.container_name}>
              <p>
                {data.title}
              </p>
            </div>
         
          </div>
        </div>
      </Link>
      <div className={styles.container_bookmark}>
        <EmployeesBookmark employeeId={data.id} isBookmark={data.isBookmark} />
      </div>
    </div>
  );
};

export default React.memo(EmployeesListItem);
