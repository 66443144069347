import React, { Component } from "react";
import dateTime from "./../../shared/services/date.service";
import InfoIcon from "@mui/icons-material/Info";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Tooltip from "@mui/material/Tooltip";
import ListItemText from "@mui/material/ListItemText";

class NotificationsItmems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewId: null,
    };
  }

  view(id) {
    const { viewId } = this.state;

    this.setState({ viewId: viewId === id ? null : id });
  }

  notifactionTypeIcon(type) {
    switch (type) {
      case 1:
        return <AssignmentIcon />;
      default:
        return <InfoIcon />;
    }
  }

  notifactionLevelColor(level) {
    switch (level) {
      case 1:
        return "rgb(0 128 0 / 70%)";
      case 2:
        return "rgb(255 165 0 / 70%)";
      case 3:
        return "rgb(255 0 0 / 70%)";
      default:
        return "rgb(0 0 255 / 70%)";
    }
  }

  render() {
    const { notifications, onReadNotification } = this.props;
    const { viewId } = this.state;

    if (!notifications) {
      return null;
    }

    return notifications.map((item) => {
      const click = () => {
        this.view(item.id);
      };

      const color = this.notifactionLevelColor(item.level);
      const style = viewId !== item.id ? "notificationItem" : "notificationItemView";

      return (
        <div
          key={item.id}
          className={style}
          onClick={(e) => {
            click();
          }}
        >
          <div style={{ color: color }}>{this.notifactionTypeIcon(item.type)}</div>

          {viewId === item.id ? (
            <div style={{ width: "100%", marginBottom: "5px" }}>
              <div className={"notificationItemView_text"}>
                <div >
                  <b>{dateTime.toFormat7(dateTime.toLocal(item.date))}</b>
                  <Tooltip title="Прочитать уведомлпние">
                    <MarkEmailReadIcon className={"notificationClose"}
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onReadNotification(item.id);
                      }}
                    />
                  </Tooltip>
                </div>

                <span>{item.text}</span>
              </div>
            </div>
          ) : (
            <ListItemText>
              <div>{dateTime.toFormat7(dateTime.toLocal(item.date))} </div>
              <div className={"notificationTitle"}>
                <strong>{item.title}</strong>
              </div>
            </ListItemText>
          )}
        </div>
      );
    });
  }
}

export default NotificationsItmems;
