import EmployeesService from "../shared/services/employeesService";

const REQUEST_CHANGE_STORE_EMPLOYEE_UPDATE = 'REQUEST_CHANGE_STORE_EMPLOYEE_UPDATE'
const READY_CHANGE_STORE_EMPLOYEE_UPDATE = 'READY_CHANGE_STORE_EMPLOYEE_UPDATE'

const requestChangeStoreEmployeeUpdateAction = () => {
  return {
    type: REQUEST_CHANGE_STORE_EMPLOYEE_UPDATE
  }
}

const responseChangeStoreEmployeeUpdateAction = (name, value) => {
  return {
    type: READY_CHANGE_STORE_EMPLOYEE_UPDATE,
    payload: { name, value }
  }
}

const handleChangeStoreEmployeeUpdate = (id, title, phone, autoCreateGoeZone) => {
  return (dispatch) => {
    dispatch(requestChangeStoreEmployeeUpdateAction())
    EmployeesService.setEmployeeShortInfo(id, title, phone, autoCreateGoeZone)
      .then((result) => { })
      .catch((error) => { })
  }
}

export {
  handleChangeStoreEmployeeUpdate,
  requestChangeStoreEmployeeUpdateAction,
  responseChangeStoreEmployeeUpdateAction,
  REQUEST_CHANGE_STORE_EMPLOYEE_UPDATE,
  READY_CHANGE_STORE_EMPLOYEE_UPDATE,
}