import React from "react";

import styles from "./formItemsInline.module.scss";

const FormItemsInline = (props) => {
  const { inlineWidth } = props;

  return (
    <div
      className={styles.container_formItemsInline}
      style={{ width: inlineWidth !== undefined ? inlineWidth : "auto" }}
    >
      {props.children.length > 1 ? (
        props.children.map((item, index) => {
          return (
            <div className={styles.container_formItemInline} key={index}>
              {!!item ? item : null}
            </div>
          );
        })
      ) : (
        <div className={styles.container_formItemInline}>{props.children}</div>
      )}
    </div>
  );
};

export default React.memo(FormItemsInline);
