const RESPONSE_OPEN_ROUTE_MODAL = 'RESPONSE_OPEN_ROUTE_MODAL'
const RESPONSE_CLOSE_ROUTE_MODAL = 'RESPONSE_CLOSE_ROUTE_MODAL'
const SET_ROUTE_CURRENT_POINT = 'SET_ROUTE_CURRENT_POINT'

const responseOpenRouteModalAction = (data) => {
  return {
    type: RESPONSE_OPEN_ROUTE_MODAL,
    data
  }
}

const openRouteModal = (data) => {
  return (dispatch) => {
    dispatch(responseOpenRouteModalAction(data))
  }
}

const responseCloseRouteModalAction = () => {
  return {
    type: RESPONSE_CLOSE_ROUTE_MODAL
  }
}

const closeRouteModal = () => {
  return (dispatch) => {
    dispatch(responseCloseRouteModalAction())
  }
}

const setRouteCurrentPointAction = (data) => {
  return {
    type: SET_ROUTE_CURRENT_POINT,
    data
  }
}

const setRouteCurrentPoint = (data) => {
  return (dispatch) => {
    dispatch(setRouteCurrentPointAction(data))
  }
}

export {
  openRouteModal,
  responseOpenRouteModalAction,
  RESPONSE_OPEN_ROUTE_MODAL,

  closeRouteModal,
  responseCloseRouteModalAction,
  RESPONSE_CLOSE_ROUTE_MODAL,

  setRouteCurrentPoint,
  setRouteCurrentPointAction,
  SET_ROUTE_CURRENT_POINT
}
