import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  getEmployeeShortInfo as onGetEmployeeShortInfo,
  employeeSharedLink as onEmployeeSharedLink
} from '../../actions/employeeShortInfoActions'

const mapStateToProps = (state) => ({
  unitShortInfo: {
    dataState: state.unitShortInfo.dataState,
    title: get(state, ['unitShortInfo', 'data', 'title'], ''),
    phone: get(state, ['unitShortInfo', 'data', 'phone'], ''),
    workPositionId: get(state, ['unitShortInfo', 'data', 'workPositionId'], ''),
    employeeId: get(state, ['unitShortInfo', 'data', 'employeeId'], ''),
    sharedLink: get(state, ['unitShortInfo', 'data', 'sharedLink'], null),
  }
});

const mapDispatchToProps = {
  onGetEmployeeShortInfo,
  onEmployeeSharedLink
}

const withEmployeeShortInfo = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withEmployeeShortInfo }