import { connect } from "react-redux";
import get from "lodash/get";

import {
  getNotificationTypes as onGetNotificationTypes,
  subscribes as onSubscribes,
  unsubscribes as onUnsubscribes,
  getNotifications as onGetNotifications,
  readNotification as onReadNotification,
  readAllNotification as onReadAllNotification
} from "../../actions/notificatiponsActions";

const mapStateToProps = (state) => ({
  notifications: {
    dataState: state.notifications.dataState,
    count: get(state, ["notifications", "count"], 0),
    notifications: get(state, ["notifications", "notifications"], []),
    notificationTypes: get(state, ["notifications", "notificationTypes"], []),
  },
});

const mapDispatchToProps = {
  onGetNotificationTypes,
  onSubscribes,
  onUnsubscribes,
  onGetNotifications,
  onReadNotification,
  onReadAllNotification,
};

const withNotifications = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withNotifications };
