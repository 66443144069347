import React from "react";

import { withUserDelete } from "../../../shared/containers/containerUserDelete";
import Icon from "../../../shared/items/static/icon";

const UserDelete = (props) => {
  const { id, onUserDelete } = props;

  return (
    <div onClick={onUserDelete.bind(this, id)}>
      <Icon name={"user"} title={"удалить"} />
    </div>
  );
};

export default withUserDelete(React.memo(UserDelete));
