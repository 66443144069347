import {
  REQUEST_NEW_USER,
  RESPONSE_NEW_USER,
  REQUEST_NEW_USER_FAILURE,
  CLEAR_NEW_USER_ERRORS,
  READY_CHANGE_STORE_NEW_USER
} from '../actions/newUserActions.js'

import { states as queriesStates } from './queriesStates'

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  data: {},
  newUserItems: {},
  newUserState: false,
  errors: {
    data: {}
  }
}

const newUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case READY_CHANGE_STORE_NEW_USER: {
      const { name, value } = action.payload
      var newUserItems = Object.assign({}, state.newUserItems)

      newUserItems[name] = value

      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY,
          newUserItems: newUserItems
        }
      )
    }
    case REQUEST_NEW_USER:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.REQUEST,
        }
      )
    case RESPONSE_NEW_USER:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY,
          data: action.data,
          newUserState: true,
          errors: {
            data: null
          }
        }
      )
    case REQUEST_NEW_USER_FAILURE:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.FAILURE,
          newUserState: false,       
          errors: {
            data: action.errors.data
          }
        }
      )
    case CLEAR_NEW_USER_ERRORS:
      return Object.assign(
        {},
        state,
        {
          errors: []
        }
      )
    default:
      return state
  }
}

export default newUserReducer
