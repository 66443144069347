import React, { Component } from "react";
import { withEmployeeSettings } from "../../../shared/containers/containerEmployeeSettings";
import { withChoosedEmployee } from "../../../shared/containers/containerChoosedEmployee";
import InputDate from "../../../shared/items/contols/inputDate";
import InputTime from "../../../shared/items/contols/inputTime";
import ButtonPrimary from "../../../shared/items/contols/buttonPrimary";
import CookieService from "../../../shared/services/cookiesService";
import configuration from "../../../config.json";
import dateTime from "../../../shared/services/date.service";
import { states } from "../../../reducers/queriesStates";
import "./employeeDeviceSettings.css";

let timeoutid = null;

class EmployeeReprocces extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employeeId: props.employeeId,
      permissions: JSON.parse(CookieService.getCookie(configuration.permissionsCookie)),
      valueStore: { dateStart: this.getEmptyDateTime(), timeStart: this.getEmptyDateTime() },
      reprocces: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { employeeSettings, choosedEmployee } = this.props;

    if (choosedEmployee.employeeId !== prevProps.choosedEmployee.employeeId) {
      this.setState({ reprocces: null });
    }

    if (
      (prevProps.employeeSettings.reproccesDataState !== states.REQUEST &&
        employeeSettings.reproccesDataState === states.REQUEST) ||
      (prevProps.employeeSettings.dataState !== states.REQUEST && employeeSettings.dataState === states.REQUEST)
    ) {
      this.setState({ reprocces: null });
    }

    if (
      prevProps.employeeSettings.reproccesDataState === states.REQUEST &&
      employeeSettings.reproccesDataState === states.READY
    ) {
      this.setState({ reprocces: employeeSettings.reprocces });
    }
  }

  getEmptyDateTime(hours = 0, minutes = 0, date = new Date()) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0, 0);
  }

  onHandleChangeStore(e, value, name) {
    if (value && name) {
      if (value.includes("_")) {
        return;
      }

      const { valueStore } = this.state;
      valueStore[name] = e;

      if (name.includes("time")) {
        const values = value.split(":");
        valueStore[name] = this.getEmptyDateTime(values[0], values[1]);
      }

      this.setState({
        valueStore: valueStore,
      });
    }
  }

  onReprocces() {
    const { employeeId, valueStore } = this.state;
    const { onEmployeeReprocces } = this.props;
    const time = dateTime.stringISODate(dateTime.concat(valueStore.dateStart, valueStore.timeStart));

    onEmployeeReprocces(employeeId, time);
  }

  clearStateReprocces() {
    this.setState({ reprocces: null });
  }

  render() {
    const { permissions, valueStore, reprocces } = this.state;

    if (reprocces !== null) {
      clearTimeout(timeoutid);
      timeoutid = setTimeout(this.clearStateReprocces.bind(this), 4000);
    }

    if (!permissions.includes(40004)) {
      return null;
    }

    return (
      <div>
        <h2>Повторная обработка данных</h2>
        <div className={"employeeReproccesContainer"}>
          <div className={"sheduleDateTimeInput"}>
            <span>Дата</span>
            <InputDate
              name="dateStart"
              onHandleChangeStore={(e, value) => {
                this.onHandleChangeStore(e, value, "dateStart");
              }}
              valueStore={valueStore}
            />
          </div>
          <div className={"sheduleDateTimeInput"}>
            <span>Время</span>
            <InputTime
              name={"timeStart"}
              onHandleChangeStore={(e, value) => {
                this.onHandleChangeStore(e, value, "timeStart");
              }}
              valueStore={valueStore}
            />
          </div>
          <div style={{ paddingBottom: "5px" }}>
            <ButtonPrimary handleClick={this.onReprocces.bind(this)} title={<span>Обработать повторно</span>} />
          </div>
          {reprocces === null ? null : reprocces ? (
            <span style={{ color: "green", paddingLeft: "20px" }}>
              <b>ЗАПРОС НА ПОВТОРНУЮ ОБРАБОТКУ ДАННЫХ ОТПРАВЛЕН</b>
            </span>
          ) : (
            <span style={{ color: "red", paddingLeft: "20px" }}>
              <b>ОШИБКА</b>
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default withChoosedEmployee(withEmployeeSettings(EmployeeReprocces));
