import cloneDeep from "lodash/cloneDeep";
import {
  getPoints,
  getRouteCoordinates,
  groupPoints,
} from "../shared/services/service.intellegence";

const SET_ROUTE_POINTS = "SET_ROUTE_POINTS";
const SET_ROUTE = "SET_ROUTE";
const CLEAR_MAP_ROUTE = "CLEAR_MAP_ROUTE";

const setRoutePointsAction = (data) => {
  return {
    type: SET_ROUTE_POINTS,
    data,
  };
};

const setRouteAction = (data, features) => {
  return {
    type: SET_ROUTE,
    data,
    features, 
  };
};

const setRouteData = (data, features) => {
  const intelligence = cloneDeep(data);
  const _fetures = cloneDeep(features);
  const cropPoints = getPoints(intelligence);
  const groupedPoints = groupPoints(cropPoints);
  const route = getRouteCoordinates(
    groupedPoints,
    [groupedPoints[0].location],
    0
  );

  return (dispatch) => {
    dispatch(setRoutePointsAction(groupedPoints));
    dispatch(setRouteAction(route, _fetures));
  };
};

const clearMapRouteAction = () => {
  return {
    type: CLEAR_MAP_ROUTE,
  };
};

const clearMapRoute = () => {
  return (dispatch) => {
    dispatch(clearMapRouteAction());
  };
};

export {
  setRouteData,
  setRoutePointsAction,
  setRouteAction,
  SET_ROUTE_POINTS,
  SET_ROUTE,

  clearMapRoute,
  clearMapRouteAction,
  CLEAR_MAP_ROUTE,
};
