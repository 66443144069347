import React from 'react'

import { withRestorePasseord } from '../../shared/containers/containerRestorePasseord'
import LoginInput from '../../shared/items/contols/loginInput'
import styles from './restorePasswordForm.module.scss'

const RestorePasseordForm = props => {
  const { restorePasswordItems, errors, onHandleChangeStore, data } = props

  return (
    <React.Fragment>
      { <div>
        <LoginInput
          name="email"
          type="text"
          store={restorePasswordItems}
          handleChangeStore={onHandleChangeStore}
          placeholder="Электроный адрес"
          errors={errors}
        />
      </div>}
      {!!data.message ?
        <div>
          {
            <div className={styles.password_container}>
              <LoginInput
                name="password"
                type="password"
                store={restorePasswordItems}
                handleChangeStore={onHandleChangeStore}
                placeholder="Новый пароль"
                errors={errors}
              />
            </div>
          }
          {
            <div className={styles.password_container}>
              <LoginInput
              name="code"
              type="text"
              store={restorePasswordItems}
              handleChangeStore={onHandleChangeStore}
              placeholder="Код подтверждения"
              errors={errors}
            /></div>
          }
        </div>
        : null
      }
      {    <p style={{
        textAlign: 'center',
        padding: '15px',
        color: 'green',
        fontSize: '0.83rem'
      }}>{data.message}</p>
      }
    </React.Fragment>
  )
}

export default withRestorePasseord(React.memo(RestorePasseordForm))