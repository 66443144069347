import PermissionsService from "../shared/services/permissionsService";

const REQUEST_PERMISSIONS = "REQUEST_PERMISSIONS";
const RESPONSE_PERMISSIONS = "RESPONSE_PERMISSIONS";
const REQUEST_PERMISSIONS_FAILURE = "REQUEST_PERMISSIONS_FAILURE";

const requestGetPermissionsAction = () => {
  return {
    type: REQUEST_PERMISSIONS,
  };
};

const responseGetPermissionsAction = (data) => {
  return {
    type: RESPONSE_PERMISSIONS,
    data,
  };
};

const requestGetPermissionsfailureAction = (errors) => {
  return {
    type: REQUEST_PERMISSIONS_FAILURE,
    errors,
  };
};

const getPermissions = () => {
  return (dispatch) => {
    dispatch(requestGetPermissionsAction());

    PermissionsService.get()
      .then((result) => {
        dispatch(responseGetPermissionsAction(result.data));
      })
      .catch((error) => {
        dispatch(requestGetPermissionsfailureAction(error.response));
      });
  };
};

export {
  getPermissions,
  requestGetPermissionsAction,
  responseGetPermissionsAction,
  requestGetPermissionsfailureAction,
  REQUEST_PERMISSIONS,
  RESPONSE_PERMISSIONS,
  REQUEST_PERMISSIONS_FAILURE,
};
