"use stric";

const FilterEmployeesItems = {
  companies: {
    name: "companies",
    title: "Филиалы",
  },
  groups: {
    name: "groups",
    title: "Группы",
  },
  workPositions: {
    name: "workPositions",
    title: "Должность",
  },
  noData: {
    name: "noData",
    title: "Нет данных",
  },
  inSleep: {
    name: "inSleep",
    title: "Режим сна",
  },
  work: {
    name: "work",
    title: "Рабочий режим",
  },
  charging: {
    name: "charging",
    title: "Зарядка батареи",
  },
  offSleep: {
    name: "offSleep",
    title: "Выход из сна",
  },
  alarm: {
    name: "alarm",
    title: "Тревога",
  },
  poweredFrom: {
    name: "poweredFrom",
    title: "Питание от сети",
  },  
};

export default FilterEmployeesItems;
