import React, { Component } from "react";
import { withSharedContent } from "./../shared/containers/containerSharedContent";
import SharedKalendar from "./sharedKalendar";
import SharedMap from "./sharedMap";
import { states } from "../reducers/queriesStates";
import "./sharedContent.scss";
import { map } from "lodash";

class SharedContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: this.getEvents(props.sharedContent.shedules),
      onScheduleClick: this.scheduleClick.bind(this),
      onBackClick: this.backClick.bind(this),
      mapView: false,
      eventId: null,
    };
  }

  getEvents(schedules) {
    if (!schedules) {
      return null;
    }

    let id = 0;

    return schedules.map((x) => {
      const summary =
        x.information !== null
          ? `${x.title} 
${x.information}`
          : x.title;

      const item = {
        id: id + 1,
        startAt: x.start,
        endAt: x.end,
        summary: summary,
        timezoneStartAt: "Europe/Minsk",
        calendarID: "work",
        location: x.shceduleLocation,
        title: x.title,
        information: x.information,
      };

      ++id;

      return item;
    });
  }

  componentDidMount() {
    const { sharedContent, onGetSchedules } = this.props;

    if (sharedContent.dataState === states.NOT_REQUESTED) {
      onGetSchedules();
    }
  }

  componentDidUpdate(preProps) {
    const { sharedContent } = this.props;

    if (preProps.sharedContent.dataState === states.REQUEST && sharedContent.dataState === states.READY) {
      this.setState({ events: this.getEvents(sharedContent.schedules) });
    }
  }

  scheduleClick(e) {
    this.setState({ mapView: true, eventId: e.id });
  }

  backClick(e) {
    this.setState({ mapView: false, eventId: null });
  }

  render() {
    const { events, eventId, mapView, onScheduleClick, onBackClick } = this.state;

    return events === null ? null : (
      <div>
        {mapView ? (
          <div className={"sharedMap-container"} style={{ maxWidth: "400px", height:  "650px" }}>
            <SharedMap events={events} eventId={eventId} onBackClick={onBackClick} />
          </div>
        ) : null}

        {!mapView ? (
          <div className={"container"}>
            <SharedKalendar className={"bg"} events={events} onScheduleClick={onScheduleClick} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withSharedContent(SharedContent);
