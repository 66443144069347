import React, { Component } from "react";
import MapMarker from "./mapMarker";
import dateTime from "./../services/date.service";
import * as L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import employeesClasteringPosition from "./../services/employeesPositionService";
import distanceService from "./../services/distanceService";
import "./mapMarker.scss";
import { v4 as uuid_v4 } from "uuid";

class MapLastStatesMarkers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employees: props.employees,
      bounds: props.bounds,
      _key: uuid_v4(),
    };
  }
  componentDidMount() {
    const { employees, bounds } = this.props;
    this.setState({ employees, bounds, _key: uuid_v4() });
  }

  componentDidUpdate() {}

  render() {
    const { employees } = this.state;
    return getMapLastStatesMarkers(employees);
  }
}

function getMapLastStatesMarkers(employees, bounds = null) {
  const employeeSimpleMarkers = employees
    .filter((x) => {
      return x.alarmTime === null;
    })
    .map((employee) => getMarkerUiItem(employee));

  const employeeAlarmMarkers = employees
    .filter((x) => {
      return x.alarmTime !== null;
    })
    .map((employee) => getMarkerUiItem(employee));

  return (
    <div key={uuid_v4()}>
      [{employeeAlarmMarkers},{createMarkerClusterGroup(employeeSimpleMarkers, createClusterSimpleIcon)} ]
    </div>
  );
}

const getPoupupContent = function (employee) {
  var poupupContent = dateTime.toFormat3(employee.lastActive) + "<br/>";
  poupupContent += "№" + employee.number;

  if (employee.title !== null && employee.title.length > 0) {
    const linkToEmployee = `<a href="http://personal.armproject.by/employees/${employee.id}"><b>${employee.title}</b></a>`;
    poupupContent += " " + linkToEmployee + "<br/>";
  }

  if (employee.workPosition !== null && employee.workPosition.length > 0) {
    poupupContent += "Должность: " + employee.workPosition + "<br/>";
  }

  poupupContent += "<br/>";

  return poupupContent;
};

const removeEmojis = (string) => {
  const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g; // eslint-disable-line

  return string.replace(regex, "").replace(" ", "").replace("‍️", "");
};

function isOnlyEmoji(string) {
  var result = removeEmojis(string);

  return !result.length;
}

const getMarkerUiItem = function (employee) {
  var isEmoji = false;
  if (employee.workPosition !== null && isOnlyEmoji(employee.workPosition)) {
    isEmoji = true;
  }

  var workPosition = employee.workPosition;
  if (employee.workPosition !== null && !isEmoji) {
    workPosition = workPosition.substring(0, 1).toUpperCase();
  }

  return (
    <MapMarker
      key={uuid_v4()}
      position={[employee.lastLocation.lat, employee.lastLocation.long]}
      status={employee.status.id}
      number={employee.number}
      alarm={employee.alarmTime}
      poupupContent={getPoupupContent(employee)}
      workPosition={employee.workPosition === null ? undefined : workPosition}
    />
  );
};

const getClusterIcon = function (cluster, style) {
  var size = style === "simpleCluster" ? 30 : 50;
  return L.divIcon({
    html: `<span><b>${cluster.getChildCount()}</b></span>`,
    className: style,
    iconSize: L.point(size, size, false),
  });
};

const createClusterSimpleIcon = function (cluster) {
  return getClusterIcon(cluster, "simpleCluster");
};

const createClusterAlarmIcon = function (cluster) {
  return getClusterIcon(cluster, "alarmCluster");
};

function createMarkerClusterGroup(markers, iconCreateFunction, alarmCluster = false) {
  return (
    <MarkerClusterGroup
      key={uuid_v4()}
      chunkedLoading={true}
      iconCreateFunction={iconCreateFunction}
      animate={true}
      maxClusterRadius={alarmCluster ? 15 : 35}
    >
      {markers}
    </MarkerClusterGroup>
  );
}

export default MapLastStatesMarkers;
