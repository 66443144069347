import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import CompaniesList from './../users/companiesList'

class CompaniesListWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      scrollToCompaniesList: 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrollToCompaniesList } = this.state
    const { scrollbars } = this.refs

    if (scrollToCompaniesList !== prevState.scrollToCompaniesList) {
      scrollbars.scrollTop(scrollToCompaniesList)
    }
  }

  handleScrollTo(top) {
    this.setState({
        scrollToCompaniesList: top
    })
  }

  render() {
    return <Scrollbars
      style={{ height: "100%" }}
      autoHide={true}
      ref="scrollbars"
    >
      <CompaniesList
        handleScrollTo={this.handleScrollTo.bind(this)}
      />

    </Scrollbars>
  }
}

export default CompaniesListWrapper


