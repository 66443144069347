import { connect } from 'react-redux'
import get from 'lodash/get';

import { mapState } from '../../components/sections/employees/static.employeeMapState'

import {
  changeEmployeeMapState as onChangeEmployeeMapState
} from '../../actions/employeeMapStateActions'

const mapStateToProps = (state) => ({
  employeeMapState: {
    dataState: state.employeeMapState.dataState,
    mapSize: get(state, ['employeeMapState', 'mapSize'], mapState.baseSize.name),
  }
});

const mapDispatchToProps = {
  onChangeEmployeeMapState,
}

const withEmployeeMapState = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withEmployeeMapState }