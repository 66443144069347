import {
  RESPONSE_EMPLOYEE_SHORT_INFO,
} from '../actions/employeeShortInfoActions'

import { states as queriesStates } from './queriesStates'

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  data: {}
}

const employeeShortInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESPONSE_EMPLOYEE_SHORT_INFO: {
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY,
          data: action.data
        }
      )
    }
    default:
      return state
  }
}

export default employeeShortInfoReducer
