import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  userDelete as onUserDelete
} from '../../actions/userDeleteActions'

const mapStateToProps = (state) => ({
 
  users: {
    dataState: state.userDelete.dataState,    
    id: get(state, ['userDelete', 'data', 'id'], []),
    isBlocked: get(state, ['userDelete', 'data', 'isBlocked'], []),
    errors: get(state, ['userDelete', 'errors', 'data', null])
  }
});

const mapDispatchToProps = {
  onUserDelete
}

const withUserDelete = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withUserDelete }