import React from "react";
import { withFilterEmployees } from "./containers/containerFilterEmployees";
import styles from "./controlCountOnPage.module.scss";
const ControlCountOnPage = (props) => {
  return (
    <div className={styles.controlCountOnPage}>
      {/*  <DropDownSelect key={"filterCount_"+ filterEmployees.filterItems.count}
        buttonTitle={getCountOnPageTitle(filterEmployees.filterItems.count)}
        content={<CountOnPageDropdownMenu handleChooseCountOnPage={handleChangeSize.bind(this, props)} />}
      /> */}
    </div>
  );
};

export default withFilterEmployees(React.memo(ControlCountOnPage));
