'use stric'

const TimeRange = {
/*   inmoment:{
    title: 'В момент времени',
    value: 'inmoment',
    name: 'inmoment',
  }, */
  intimerange:{
    title: 'В промежуток времени',
    value: 'intimerange',
    name: 'intimerange',
  },
  deviceLog:{
    title: 'Лог устройства',
    value: 'deviceLog',
    name: 'deviceLog',
  }
}

export default TimeRange