import UsersService from "./../shared/services/usersService";

const REQUEST_NEW_USER = "REQUEST_NEW_USER";
const RESPONSE_NEW_USER = "RESPONSE_NEW_USER";
const REQUEST_NEW_USER_FAILURE = "REQUEST_NEW_USER_FAILURE";
const CLEAR_NEW_USER_ERRORS = "CLEAR_NEW_USER_ERRORS";
const READY_CHANGE_STORE_NEW_USER = "READY_CHANGE_STORE_NEW_USER";
const READY_CLEAR_STORE_NEW_USER = "READY_CLEAR_STORE_NEW_USER";

const responseChangeStoreNewUserAction = (name, value) => {
  return {
    type: READY_CHANGE_STORE_NEW_USER,
    payload: { name, value },
  };
};

const handleChangeStoreNewUser = (name, value) => {
  return (dispatch) => {
    dispatch(responseChangeStoreNewUserAction(name, value));
  };
};

const responseClearStoreNewUserAction = () => {
  return {
    type: READY_CLEAR_STORE_NEW_USER,
  };
};

const handleClearStoreNewUser = () => {
  return (dispatch) => {
    dispatch(responseClearStoreNewUserAction());
  };
};

const requestNewUserAction = () => {
  return {
    type: REQUEST_NEW_USER,
  };
};

const responseNewUserAction = (data) => {
  return {
    type: RESPONSE_NEW_USER,
    data,
  };
};

const requestNewUserFailureAction = (errors) => {
  return {
    type: REQUEST_NEW_USER_FAILURE,
    errors,
  };
};

const newUser = (email) => {
  return (dispatch) => {
    dispatch(requestNewUserAction());
    UsersService.newUser({
      email: email,
      roleType: 2,
    })
      .then((result) => {
        dispatch(responseNewUserAction());
      })
      .catch((error) => {
        dispatch(requestNewUserFailureAction(error.response));
      });
  };
};

const clearNewUserErrors = () => {
  return {
    type: CLEAR_NEW_USER_ERRORS,
  };
};

export {
  newUser,
  requestNewUserAction,
  responseNewUserAction,
  requestNewUserFailureAction,
  clearNewUserErrors,
  REQUEST_NEW_USER,
  RESPONSE_NEW_USER,
  REQUEST_NEW_USER_FAILURE,
  CLEAR_NEW_USER_ERRORS,
  handleChangeStoreNewUser,
  responseChangeStoreNewUserAction,
  READY_CHANGE_STORE_NEW_USER,
  handleClearStoreNewUser,
  responseClearStoreNewUserAction,
  READY_CLEAR_STORE_NEW_USER,
};
