import React, { PureComponent } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

class TableScrollWrapper extends PureComponent {
  componentDidMount() {
    this.refs.scrollbars.scrollTop(this.props.scrollTo);
  }

  onScroll() {
    const { onScroll } = this.props;
    if (!!onScroll) {
      onScroll(this.refs.scrollbars.getValues());
    }
  }

  render() {
    const { height } = this.props;

    return (
      <Scrollbars style={{ height: height }} autoHide={false} ref="scrollbars" onScroll={this.onScroll.bind(this)}>
        {this.props.children}
      </Scrollbars>
    );
  }
}

export default TableScrollWrapper;
