import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import ViewTableHead from "./viewTableHead";
import ViewTableContent from "./viewTableContent";
import TableScrollWrapper from "./items/static/tableScrollWrapper";
import styles from "./viewTable.module.scss";

const tableScrollWrapperHight = 305;

const ViewTable = (props) => {
  let printRef = useRef();
  const { headItems, responsive, scrollTo, contentItems, handleClickRow, onScroll, distance } = props;

  function onBeforeGetContentToPrint() {
    printRef.children[1].style.height = contentItems.length * 32 + 30 + "px";
    printRef.children[1].style.padding = "5mm";
  }

  function onAfterPrintContent() {
    printRef.children[1].style.height = tableScrollWrapperHight + "px";
    printRef.children[1].style.margin = null;
  }

  return (
    <div className={styles.viewTable}>
      <ReactToPrint
        trigger={() => <button className={styles.printerButton} title={"Распечатать таблицу"}></button>}
        content={() => printRef}
        onBeforeGetContent={() => onBeforeGetContentToPrint()}
        onAfterPrint={() => onAfterPrintContent()}
      />
      <div ref={(el) => (printRef = el)} className={styles.container_table}>
        <ViewTableHead headItems={headItems} responsive={!!responsive ? responsive : false} />
        <TableScrollWrapper height={tableScrollWrapperHight} scrollTo={scrollTo} onScroll={onScroll}>
          <ViewTableContent
            contentItems={contentItems}
            handleClickRow={handleClickRow}
            responsive={!!responsive ? responsive : false}
          />
        </TableScrollWrapper>
        <br />
        <b>Дистанция: {distance} км</b>
      </div>
    </div>
  );
};

export default React.memo(ViewTable);
