import React from "react";
import { withChoosedEmployee } from "./../../../shared/containers/containerChoosedEmployee";
import { withDashboard } from "../../../shared/containers/containerDashboard";
import { withDashboards } from "../../../shared/containers/containerDashboards";
import { withSensors } from "../../../shared/containers/containerSensors";
import { withEmployeesLastState } from "../../../shared/containers/containerEmployeeLastState";
import { withEmployeeGeoZones } from "../../../shared/containers/containerEmployeeGeoZones";
import { states } from "../../../reducers/queriesStates";
import GeoJsonBuilding from "../../../shared/map/geoJsonBuilding";
import { Circle } from "react-leaflet";
import MapWrapper from "../../../shared/map/mapWrapper";
import MapPopupMarker from "../../../shared/map/mapPopupMarker";
import dateTime from "../../../shared/services/date.service";
import { getDashboardData } from "./getDashboardData";
import { CustomLeafletControl } from "../../../shared/map/customLeafletControl";
import "./employeeMapMoment.scss";
import mapLayerControl from "../../../shared/map/mapLayerControl";
import { v4 as uuid_v4 } from "uuid";
import cloneDeep from "lodash/cloneDeep";

class EmployeeMapMoment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLayerControl: mapLayerControl,
      mapKey: uuid_v4(),
    };
  }

  layerControlChange(mapLayerControl) {
    this.setState({ mapLayerControl: cloneDeep(mapLayerControl), mapKey: uuid_v4() });
  }

  getIndex(employeeId, employees) {
    if (employeeId === null) {
      return -1;
    }

    return employees.findIndex((v) => {
      return v.id === employeeId;
    });
  }

  getPopupTavle(dashboardData) {
    const tablePoupup = (
      <div>
        <table className={"tableInPopup"}>
          <th className={"thInPopup1"} />
          <th className={"thInPopup3"} />
          {dashboardData.map((item, index) => {
            return (
              <tr key={index}>
                <td className={"tdInPopup"}>{item.title}:</td> <td className={"tdInPopup"}>{item.value}</td>
              </tr>
            );
          })}
        </table>
      </div>
    );

    return tablePoupup;
  }

  EmployeeMap() {
    const {
      choosedEmployee,
      dashboard,
      dashboards,
      sensors,
      lastStates,
      onGetEmployeesLastStates,
      employeeGeoZones,
      onGetEmployeeGeozones,
    } = this.props;

    const lastStateIndex = this.getIndex(choosedEmployee.employeeId, lastStates.employees);

    if (lastStateIndex === -1) {
      let render = true;
      if (lastStates.dataState === "STATE_NOT_REQUESTED") {
        onGetEmployeesLastStates();
        render = false;
      }

      if (employeeGeoZones.dataState === "STATE_NOT_REQUESTED") {
        onGetEmployeeGeozones();
        render = false;
      }
      if (!render) {
        return null;
      }

      return null;
    }

    const tablePopup = this.getPopupTavle(getDashboardData(dashboards.data, dashboard.data));
    const lastStaeEmployee = lastStates.employees[lastStateIndex];
    choosedEmployee.alarmTime = lastStaeEmployee.alarmTime;

    const lat = !!dashboard.data["COOY"]
      ? dashboard.data["COOY"].replace(",", ".")
      : !!lastStaeEmployee
      ? lastStaeEmployee.lastLocation.lat
      : 0;
    const long = !!dashboard.data["COOX"]
      ? dashboard.data["COOX"].replace(",", ".")
      : !!lastStaeEmployee
      ? lastStaeEmployee.lastLocation.long
      : 0;
    const state = !!dashboard.data["USTA"]
      ? parseInt(dashboard.data["USTA"])
      : !!lastStaeEmployee
      ? lastStaeEmployee.status.id
      : 0;
    const feature =
      sensors.data.feature !== null && sensors.data.feature.id !== undefined
        ? sensors.data.feature
        : !!lastStaeEmployee
        ? lastStaeEmployee.feature
        : undefined;

    return (
      <MapWrapper
        key={this.state.mapKey}
        position={[lat, long]}
        employeeGeoZones={employeeGeoZones.employeeGeoZones}
        employeeId={choosedEmployee.employeeId}
        mapLayerControl={this.state.mapLayerControl}
        onlayerControlChange={(mapLayerControl) => this.layerControlChange(mapLayerControl)}
      >
        {choosedEmployee.alarmTime !== null ? (
          <CustomLeafletControl
            kye={uuid_v4()}
            isAlarm={choosedEmployee.alarmTime !== null}
            alarmEmployees={[choosedEmployee.employeeId]}
            position="bottomleft"
          />
        ) : null}

        <MapPopupMarker
          id={choosedEmployee.employeeId}
          position={[lat, long]}
          status={state}
          number={choosedEmployee.number}
          popup={tablePopup}
          poupupContent={dateTime.toFormat3(choosedEmployee.lastActive) + "<br/>"}
        />
        {feature !== undefined && feature !== null ? <GeoJsonBuilding key={feature.id} feature={feature} /> : null}
        {sensors.data.state != null &&
        sensors.data.state.undiferendCoordinate !== undefined &&
        !!sensors.data.state.undiferendCoordinate ? (
          <Circle center={{ lat: lat, lng: long }} radius={70} />
        ) : null}
      </MapWrapper>
    );
  }

  render() {
    const { lastStates } = this.props;

    return lastStates.dataState !== states.NOT_REQUESTED ? this.EmployeeMap() : null;
  }
}

export default withSensors(
  withEmployeesLastState(
    withDashboard(withDashboards(withEmployeeGeoZones(withChoosedEmployee(React.memo(EmployeeMapMoment)))))
  )
);
