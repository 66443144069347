import React, { Component } from "react";
import CookieService from "../../../shared/services/cookiesService";
import DeviceService from "./../../../shared/services/deviceService";
import ButtonPrimary from "../../../shared/items/contols/buttonPrimary";
import { withItemsIntellegence } from "../../../shared/containers/containerItemsIntellegence";
import { withChoosedEmployee } from "../../../shared/containers/containerChoosedEmployee";
import { withTimerangeIntellegence } from "../../../shared/containers/containerTimerangeIntellegence";
import { withTimerangeIntelligenceData } from "./../../../shared/containers/containerTimerangeIntelligenceData";
import { withIntellegenceTimeRangePlayer } from "./../../../shared/containers/containerIntellegenceTimeRangePlayer";
import { withIntelligenceTimeRangeMap } from "./../../../shared/containers/containerIntelligenceTimeRangeMap";
import { states } from "../../../reducers/queriesStates";

class IntelligenceInTimeRangeButtonWrapper extends Component {
  componentDidUpdate(prevProps) {
    const {
      timerangeIntellegence,
      onSetCurrentPoint,
      onSetTimeRangeIntelligenceData,
      timerangeIntelligenceData,
      onClearIntellegence,
      onSetRouteLength,
      choosedEmployee,
      onSetRouteDates,
      onSetRouteData,
      onHandleChangeStore,
    } = this.props;

    const { data, features } = timerangeIntellegence;
    const { groupStatus, eventObjectives } = timerangeIntelligenceData;
    if (
      timerangeIntellegence.dataState === states.READY &&
      prevProps.timerangeIntellegence.dataState !== states.READY
    ) {
      onSetCurrentPoint(0);
      onSetTimeRangeIntelligenceData(data, features, groupStatus, eventObjectives);
      onSetRouteDates(data);
      onSetRouteLength(data.length);

      if (data.length > 0) {
        onSetRouteData(data, features);
      }
    }

    if (choosedEmployee.employeeId !== prevProps.choosedEmployee.employeeId) {
      onClearIntellegence();

      const endDate = new Date(choosedEmployee.lastActive);
      const endTime = new Date(choosedEmployee.lastActive);
      const startDate = new Date(endDate);
      startDate.setHours(0);
      startDate.setMinutes(0);
      onHandleChangeStore("rangeStartDate", startDate);
      onHandleChangeStore("rangeStartTime", startDate);
      onHandleChangeStore("rangeEndDate", endDate);
      onHandleChangeStore("rangeEndTime", endTime);
    }
  }

  getIntellegenceData() {
    const { itemsIntellegence, choosedEmployee, onGetTimerangeIntellegence, onSetTimerangeDates } = this.props;
    const valueStore = itemsIntellegence.itemsIntellegence;

    const date = new Date(valueStore.rangeStartDate);
    const dateTime = new Date(valueStore.rangeStartTime);
    date.setHours(dateTime.getHours());
    date.setMinutes(dateTime.getMinutes());

    const startDate = new Date(valueStore.rangeStartDate);
    const startTime = new Date(valueStore.rangeStartTime);
    startDate.setHours(startTime.getHours());
    startDate.setMinutes(startTime.getMinutes());

    const endDate = new Date(valueStore.rangeEndDate);
    const endTime = new Date(valueStore.rangeEndTime);
    endDate.setHours(endTime.getHours());
    endDate.setMinutes(endTime.getMinutes());
    const logPermmisions = CookieService.getPermissions("permissionTN").includes(30001);

    if (!!choosedEmployee.employeeId) {
      onGetTimerangeIntellegence({
        date,
        employeeId: choosedEmployee.employeeId,
        rangeStartDate: startDate,
        rangeEndDate: endDate,
        currentDate: startDate,
        locationNotProcessed: logPermmisions,
      });

      onSetTimerangeDates({
        rangeStartDate: startDate,
        rangeEndDate: endDate,
        currentDate: startDate,
        locationNotProcessed: logPermmisions,
      });
    }
  }

  getDeviceLog() {
    const { itemsIntellegence, choosedEmployee } = this.props;
    const valueStore = itemsIntellegence.itemsIntellegence;

    const startDate = new Date(valueStore.rangeStartDate);
    const startTime = new Date(valueStore.rangeStartTime);
    startDate.setHours(startTime.getHours());
    startDate.setMinutes(startTime.getMinutes());

    const endDate = new Date(valueStore.rangeEndDate);
    const endTime = new Date(valueStore.rangeEndTime);
    endDate.setHours(endTime.getHours());
    endDate.setMinutes(endTime.getMinutes());

    const deviceLogContainer = document.getElementById("deviceLogContainerId");
    deviceLogContainer.innerText = "Загружаю...";

    const byPackageTimeInput = document.getElementById("checkBox-locationNotProcessed");
    DeviceService.getLog({
      imei: choosedEmployee.imei,
      dateStart: dateToString(startDate),
      dateEnd: dateToString(endDate),
      byPacketTime: byPackageTimeInput.checked,
    })
      .then((result) => {
        deviceLogContainer.innerText = "";
        deviceLogContainer.innerText = JSON.stringify(result.data.deviceLogPackages, null, " ");
      })
      .catch((error) => {
        deviceLogContainer.innerText = error.message;
      });

    function dateToString(date) {
      const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
      const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
      const hours = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
      const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;

      return `${date.getFullYear()}-${month}-${day} ${hours}:${minutes}`;
    }
  }

  render() {
    const { disabled, choosedEmployee, logByPacketTime } = this.props;
    if (logByPacketTime !== undefined) {
      const label = document.getElementById("checkBox-locationNotProcessedLabel");
      label.textContent = "По времени приема сервером";
    }

    return (
      <ButtonPrimary
        handleClick={logByPacketTime !== undefined ? this.getDeviceLog.bind(this) : this.getIntellegenceData.bind(this)}
        title="Показать"
        disabled={disabled || !choosedEmployee.employeeId ? true : false}
      />
    );
  }
}

export default withIntelligenceTimeRangeMap(
  withIntellegenceTimeRangePlayer(
    withTimerangeIntelligenceData(
      withTimerangeIntellegence(withChoosedEmployee(withItemsIntellegence(IntelligenceInTimeRangeButtonWrapper)))
    )
  )
);
