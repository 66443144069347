import { connect } from "react-redux";
import get from "lodash/get";

import { getDevices as onGetDevices, replaceDevices as onReplaceDevices } from "../../actions/devicesActions";

const mapStateToProps = (state) => ({
  devices: {
    dataState: state.devices.dataState,
    devices: get(state, ["devices", "devices"], []),
  },
});

const mapDispatchToProps = {
  onGetDevices,
  onReplaceDevices,
};

const withDevices = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withDevices };
