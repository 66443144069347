import { connect } from 'react-redux'
import get from 'lodash/get'

import {
  openRouteModal as onOpenRouteModal,
  closeRouteModal as onCloseRouteModal,
  setRouteCurrentPoint as onSetRouteCurrentPoint
} from './../../actions/routeModalActions'

const mapStateToProps = (state) => ({
  routeModal: {
    isOpen: get(state, ['routeModal', 'isOpen'], false),
    data: get(state, ['routeModal', 'data'], []),
    currentPointIndex: get(state, ['routeModal', 'currentPointIndex'], 0)
  }
})

const mapDispatchToProps = {
  onOpenRouteModal,
  onCloseRouteModal,
  onSetRouteCurrentPoint
}

const withRouteModal = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withRouteModal }