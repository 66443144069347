import React from 'react';

import { withEmployees } from '../../../shared/containers/containerEmployees'

import ButtonCounter from '../../../shared/items/static/buttonCounter'

const EmployeesBookmarkCounter = props => {
  const { isBookmarkCount } = props.employees

  return (
    <ButtonCounter count={isBookmarkCount} />
  )
}

export default withEmployees(React.memo(EmployeesBookmarkCounter))
