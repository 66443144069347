import React from 'react'

import styles from './buttonCounter.module.scss'

const buttonCounter = function (props) {
  const { count } = props

  return (
    <span className={styles.buttonCounter}>{count}</span>
  )
}

export default React.memo(buttonCounter)