import React from 'react'

import { withAuth } from '../../shared/containers/containerAuth'

import ValidateErrorMessage from '../../shared/items/static/validateErrorMessage'

import styles from './signInValidateResuld.module.scss'

const SignInValidateResuld = props => {
  const { errorsModel } = props

  return !!errorsModel
    ? <div className={styles.container_validateModel}><ValidateErrorMessage title={errorsModel} /></div>
    : null
}

export default withAuth(React.memo(SignInValidateResuld))