import React, { Component } from "react";
import { debounce } from "throttle-debounce";
import InputMask from "react-input-mask";
import has from "lodash/has";

import ValidateErrorMessage from "./../static/validateErrorMessage";

import styles from "./input.module.scss";

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value === undefined ? "" : props.value,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeStore = debounce(500, this.props.handleChangeStore.bind(this));
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(event) {
    var value = event.target.value;

    if (value !== this.state.value) {
      this.setState({ value: value });
      this.handleChangeStore(this.props.name, value);
    }
  }

  handleBlur() {
    const defaultValue = this.props.default;
    const { value } = this.state;

    if (!!defaultValue && value.length === 0) {
      this.setState({ value: defaultValue });
      this.handleChangeStore(this.props.name, defaultValue);
    }
  }

  render() {
    const { type, id, name, disabled, errors, placeholder } = this.props;

    const isError = has(errors, name) ? "error" : "";

    const styleName = isError
      ? "inputStyle_error"
      : !!this.props.class
      ? `inputStyle_${this.props.class}`
      : "inputStyle";

    return (
      <div className={styles[styleName]}>
        <InputMask
          type={!!type ? type : "text"}
          id={!!id ? id : name}
          name={name}
          placeholder={!!placeholder ? placeholder : null}
          disabled={!!disabled ? true : false}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
        />
        {!!errors ? (
          <div className={styles.container_validateMessage}>
            <ValidateErrorMessage title={errors[name]} />
          </div>
        ) : null}

        {!!this.props.children ? this.props.children : null}
      </div>
    );
  }
}

export default Input;
