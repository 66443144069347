import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  getDashboards as onGetDashboards
} from '../../actions/dashboardsActions'

const mapStateToProps = (state) => ({
  dashboards: {
    dataState: state.dashboards.dataState,
    data: get(state, ['dashboards', 'data', 'items'], []),
    errors: get(state, ['dashboards', 'errors'], [])
  }
})

const mapDispatchToProps = {
  onGetDashboards
}

const withDashboards = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withDashboards }