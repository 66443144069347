import ObjectiveServices from "../shared/services/objectiveService";
import EmployeeMultipleSelectService from "../shared/services/employeeMultipleSelectService";
import { multipleSelect } from "./employeeMultipleSelectAction";

const multipleSelectType = EmployeeMultipleSelectService.type.objectiveShedules;

const REQUEST_OBJECTIVES = "REQUEST_OBJECTIVES";
const RESPONSE_OBJECTIVES = "RESPONSE_OBJECTIVES";
const REQUEST_OBJECTIVES_FAILURE = "REQUEST_OBJECTIVES_FAILURE";

const REQUEST_CREATE_OBJECTIVE = "REQUEST_CREATE_OBJECTIVE";
const RESPONSE_CREATE_OBJECTIVE = "RESPONSE_CREATE_OBJECTIVE";
const REQUEST_CREATE_OBJECTIVE_FAILURE = "REQUEST_CREATE_OBJECTIVE_FAILURE";

const REQUEST_DELETE_OBJECTIVE = "REQUEST_DELETE_OBJECTIVE";
const RESPONSE_DELETE_OBJECTIVE = "RESPONSE_DELETE_OBJECTIVE";
const REQUEST_DELETE_OBJECTIVE_FAILURE = "REQUEST_DELETE_OBJECTIVE_FAILURE";

const REQUEST_UPDATE_OBJECTIVE = "REQUEST_UPDATE_OBJECTIVE";
const RESPONSE_UPDATE_OBJECTIVE = "RESPONSE_UPDATE_OBJECTIVE";
const REQUEST_UPDATE_OBJECTIVE_FAILURE = "REQUEST_UPDATE_OBJECTIVE_FAILURE";

const REQUEST_CREATE_OBJECTIVE_SCHEDULE = "REQUEST_CREATE_OBJECTIVE_SCHEDULE";
const RESPONSE_CREATE_OBJECTIVE_SCHEDULE = "RESPONSE_CREATE_OBJECTIVE_SCHEDULE";
const REQUEST_CREATE_OBJECTIVE_SCHEDULE_FAILURE = "REQUEST_CREATE_OBJECTIVE_SCHEDULE_FAILURE";

const REQUEST_DELETE_OBJECTIVE_SCHEDULE = "REQUEST_DELETE_OBJECTIVE_SCHEDULE";
const RESPONSE_DELETE_OBJECTIVE_SCHEDULE = "RESPONSE_DELETE_OBJECTIVE_SCHEDULE";
const REQUEST_DELETE_OBJECTIVE_SCHEDULE_FAILURE = "REQUEST_DELETE_OBJECTIVE_SCHEDULE_FAILURE";

const REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE = "REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE";
const RESPONSE_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE = "RESPONSE_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE";
const REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE = "REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE";

const REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE = "REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE";
const RESPONSE_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE = "RESPONSE_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE";
const REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE = "REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE";

const REQUEST_UPDATE_OBJECTIVE_SCHEDULE = "REQUEST_UPDATE_OBJECTIVE_SCHEDULE";
const RESPONSE_UPDATE_OBJECTIVE_SCHEDULE = "RESPONSE_UPDATE_OBJECTIVE_SCHEDULE";
const REQUEST_UPDATE_OBJECTIVE_SCHEDULE_FAILURE = "REQUEST_UPDATE_OBJECTIVE_SCHEDULE_FAILURE";

const action = (type) => {
  return {
    type,
  };
};

const objectives = (type, objectives, objectiveType, errors = null) => {
  return {
    type,
    count: objectives ? objectives.length : 0,
    objectives: objectives,
    objectiveType: objectiveType,
    errors,
  };
};

const getObjectives = (otpions = { objectiveType: 0 }) => {
  return (dispatch) => {
    dispatch(action(REQUEST_OBJECTIVES));

    ObjectiveServices.get(otpions)
      .then((response) => {
        dispatch(objectives(RESPONSE_OBJECTIVES, response.data.objectives, otpions.objectiveType));
      })
      .catch((error) => {
        dispatch(objectives(REQUEST_OBJECTIVES_FAILURE, [], otpions.objectiveType, error));
      });
  };
};

const createObjective = (options, geoZoneId) => {
  return (dispatch) => {
    dispatch(action(REQUEST_CREATE_OBJECTIVE));
    options.id = geoZoneId;

    ObjectiveServices.create(options)
      .then((response) => {
        dispatch(action(RESPONSE_CREATE_OBJECTIVE));
        dispatch(getObjectives({ objectiveType: options.objectiveType }));
      })
      .catch((error) => {
        dispatch(objectives(REQUEST_CREATE_OBJECTIVE_FAILURE, null, options.objectiveType, error));
      });
  };
};

const deleteObjective = (objective) => {
  return (dispatch) => {
    dispatch(action(REQUEST_DELETE_OBJECTIVE));

    ObjectiveServices.delete(objective.id)
      .then((response) => {
        dispatch(action(RESPONSE_DELETE_OBJECTIVE));
        getObjectives({ objectiveType: objective.objectiveType });
      })
      .catch((error) => {
        dispatch(objectives(REQUEST_DELETE_OBJECTIVE_FAILURE, [], error));
      });
  };
};

const updateObjective = (objective) => {
  return (dispatch) => {
    ObjectiveServices.update(objective)
      .then((response) => {})
      .catch((error) => {
        dispatch(objectives(REQUEST_UPDATE_OBJECTIVE_FAILURE, null, error));
      });
  };
};

const createSchedule = (objectiveId, shedule, objectiveType) => {
  return (dispatch) => {
    ObjectiveServices.createSchedule(objectiveId, shedule)
      .then((response) => {
        dispatch(getObjectives({ objectiveType: objectiveType }));
      })
      .catch((error) => {
        dispatch(objectives(REQUEST_CREATE_OBJECTIVE_SCHEDULE_FAILURE, [], objectiveType, error.response.data));
      });
  };
};

const deleteSchedule = (objeciveId, id, objectiveType) => {
  return (dispatch) => {
    dispatch(action(REQUEST_DELETE_OBJECTIVE_SCHEDULE));

    ObjectiveServices.deleteSchedule(objeciveId, id)
      .then((response) => {
        dispatch(getObjectives({ objectiveType: objectiveType }));
      })
      .catch((error) => {
        dispatch(objectives(REQUEST_DELETE_OBJECTIVE_SCHEDULE_FAILURE, null, error));
      });
  };
};

const addScheduleEmployee = (objectiveId, schedule, employee) => {
  const options = {
    objectiveId: objectiveId,
    scheduleId: schedule.id,
    employeeId: employee.id,
  };

  return (dispatch) => {
    dispatch(action(REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE));

    ObjectiveServices.addScheduleEmployee(options)
      .then((response) => {
        dispatch(action(RESPONSE_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE));
        dispatch(multipleSelect(schedule.id, schedule, multipleSelectType));
      })
      .catch((error) => {
        dispatch(objectives(REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE, null, error));
      });
  };
};

const deleteScheduleEmployee = (objectiveId, schedule, employee) => {
  const options = {
    objectiveId: objectiveId,
    scheduleId: schedule.id,
    employeeId: employee.id,
  };

  return (dispatch) => {
    dispatch(action(REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE));

    ObjectiveServices.deleteScheduleEmployee(options)
      .then((response) => {
        dispatch(action(RESPONSE_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE));
        dispatch(multipleSelect(schedule.id, schedule, multipleSelectType));
      })
      .catch((error) => {
        dispatch(objectives(REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE, null, error));
      });
  };
};

const activeSchedule = (objectiveId, id, active) => {
  return (dispatch) => {
    //   dispatch(action(REQUEST_UPDATE_OBJECTIVE_SCHEDULE));

    ObjectiveServices.activeSchedule(objectiveId, id, active)
      .then((response) => {
        //а   dispatch(action(RESPONSE_UPDATE_OBJECTIVE_SCHEDULE));
        return dispatch(getObjectives({ objectiveType: 1 }));
      })
      .catch((error) => {
        dispatch(objectives(REQUEST_UPDATE_OBJECTIVE_SCHEDULE_FAILURE, null, error));
      });
  };
};

export {
  getObjectives,
  REQUEST_OBJECTIVES,
  RESPONSE_OBJECTIVES,
  REQUEST_OBJECTIVES_FAILURE,
  createObjective,
  REQUEST_CREATE_OBJECTIVE,
  RESPONSE_CREATE_OBJECTIVE,
  REQUEST_CREATE_OBJECTIVE_FAILURE,
  deleteObjective,
  REQUEST_DELETE_OBJECTIVE,
  RESPONSE_DELETE_OBJECTIVE,
  REQUEST_DELETE_OBJECTIVE_FAILURE,
  updateObjective,
  REQUEST_UPDATE_OBJECTIVE,
  RESPONSE_UPDATE_OBJECTIVE,
  REQUEST_UPDATE_OBJECTIVE_FAILURE,
  createSchedule,
  REQUEST_CREATE_OBJECTIVE_SCHEDULE,
  RESPONSE_CREATE_OBJECTIVE_SCHEDULE,
  REQUEST_CREATE_OBJECTIVE_SCHEDULE_FAILURE,
  deleteSchedule,
  REQUEST_DELETE_OBJECTIVE_SCHEDULE,
  RESPONSE_DELETE_OBJECTIVE_SCHEDULE,
  REQUEST_DELETE_OBJECTIVE_SCHEDULE_FAILURE,
  addScheduleEmployee,
  REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE,
  RESPONSE_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE,
  REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE,
  deleteScheduleEmployee,
  REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE,
  RESPONSE_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE,
  REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE,
  activeSchedule,
  REQUEST_UPDATE_OBJECTIVE_SCHEDULE,
  RESPONSE_UPDATE_OBJECTIVE_SCHEDULE,
  REQUEST_UPDATE_OBJECTIVE_SCHEDULE_FAILURE,
};
