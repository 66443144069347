import React from "react";
import ReactDOMServer from "react-dom/server";
import { MapContainer, MapConsumer, TileLayer, LayersControl, FeatureGroup } from "react-leaflet";
import L from "leaflet";
import config from "./../../shared/services/apiConfig";
import WithToken from "./../../shared/services/tokenService";
import styles from "./mapWrapper.module.scss";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-loading";
import "leaflet-loading/src/Control.Loading.css";
import BaseLayersControlWrapper from "./baseLayersControlWrapper";
import { v4 as uuid_v4 } from "uuid";
import cloneDeep from "lodash/cloneDeep";

const baseURL = config.get("baseURL");
const authTNToken = WithToken.getToken("authTNToken");
let featureGroup = null;

function getGeoZones(id, employeeGeoZones) {
  const geoZones = [];

  for (const geoZone of employeeGeoZones) {
    for (const item of geoZone.employees) {
      if (item.id === id) {
        geoZones.push(geoZone);
      }
    }
  }

  return geoZones;
}

const MapWrapper = (props) => {
  const { mapLayerControl, position, employeeId, onlayerControlChange, employeeGeoZones } = props;
  const geoZones = employeeGeoZones ? getGeoZones(employeeId, employeeGeoZones) : [];

  const IsMapOverlayer =
    WithToken.IsMapOverlayer("authTNToken") &&
    mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.yandex] === false &&
    mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.yandexSat] === false &&
    mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.yandexHybrid] === false;

  const maxZoom = mapLayerControl.layerControl.crs === L.CRS.EPSG900913 ? 19 : 18;

  return (
    <MapContainer
      zoom={mapLayerControl.mapZoom}
      zoomControl={true}
      maxZoom={maxZoom}
      minZoom={4}
      attributionControl={false}
      doubleClickZoom={false}
      scrollWheelZoom={true}
      dragging={true}
      animate={true}
      easeLinearity={0.7}
      className={styles.mapWrapper}
      loadingControl={true}
      crs={mapLayerControl.layerControl.crs}
    >
      <MapConsumer>
        {(map) => {
          if (position) {
            map.setView(position);
          }

          map.on("zoom", (e) => {
            mapLayerControl.mapZoom = map.getZoom();
          });

          map.on("baselayerchange", (e) => {
            mapLayerControl.baselayerchange(e);
            // mapLayerControl.bounds = map.getBounds();
            onlayerControlChange(cloneDeep(mapLayerControl));
          });

          map.on("overlayadd", (e) => {
            mapLayerControl.overlayadd(e);
            // mapLayerControl.bounds = map.getBounds();
            onlayerControlChange(cloneDeep(mapLayerControl));
          });

          map.on("overlayremove", (e) => {
            mapLayerControl.overlayremove(e);
            // mapLayerControl.bounds = map.getBounds();
            onlayerControlChange(cloneDeep(mapLayerControl));
          });

          return null;
        }}
      </MapConsumer>

      <LayersControl position="bottomright">
        <BaseLayersControlWrapper key={uuid_v4()} mapLayerControl={mapLayerControl} />

        {IsMapOverlayer ? (
          <LayersControl.Overlay
            checked={mapLayerControl.layerControl.overlayers[mapLayerControl.overlayerNames.custom]}
            name={mapLayerControl.overlayerNames.custom}            
          >
            <TileLayer opacity={0.65} url={baseURL + "/MapOverlayers/Tiles?X={x}&Y={y}&Z={z}&token=" + authTNToken} />
          </LayersControl.Overlay>
        ) : null}

        <LayersControl.Overlay        
          checked={mapLayerControl.layerControl.overlayers[mapLayerControl.overlayerNames.geoZones]}
          name={mapLayerControl.overlayerNames.geoZones}
        >
          <FeatureGroup
            ref={(reactFGref) => {
              onFeatureGroupReady(reactFGref, geoZones);
            }}
          ></FeatureGroup>
        </LayersControl.Overlay>
      </LayersControl>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" updateWhenIdle={true} maxZoom={19} />
      {props.children}
    </MapContainer>
  );
};

const createFeatureCollection = (geoZones) => {
  const featureCollection = {
    type: "FeatureCollection",
    features: [],
  };

  if (geoZones !== undefined) {
    featureCollection.features = geoZones.map((geoZone) => {
      return {
        type: "Feature",
        geometry: JSON.parse(geoZone.geometry),
        properties: {
          id: geoZone.id,
          title: geoZone.title,
          employees: geoZone.employees,
          parameters: geoZone.properties,
          opacity: geoZone.opacity,
          color: geoZone.color,
        },
      };
    });
  }

  return featureCollection;
};

const onFeatureGroupReady = (featureGroupRef, geoZones) => {
  L.EditToolbar.Delete.include({
    removeAllLayers: false,
  });

  if (featureGroupRef) {
    featureGroup = featureGroupRef;
    featureGroup.clearLayers();

    new L.GeoJSON(createFeatureCollection(geoZones), {
      onEachFeature: onEachFeature,
    });
  }
};

const PopupGeoZone = ({ properties }) => {
  if (!properties) {
    return null;
  }

  const parameterConvert = (item) => {
    // eslint-disable-next-line default-case
    switch (item[0]) {
      case "SQA":
        item[0] = "Площадь";
        item[1] = Math.round((item[1] / 1000) * 1000) / 1000 + " км²";
        break;
      case "RADIUS":
        item[0] = "Радиус";
        item[1] = Math.round((item[1] / 1000) * 1000) / 1000 + " км";
        break;
      case "TYPE":
        item[0] = "Тип";
        item[1] = item[1] === "circle" ? "Круг" : "Область";
        break;
      case "CPOI":
        item[0] = "Центр";
        break;
      case "PERI":
        item[0] = "Периметр";
        item[1] = Math.round((item[1] / 1000) * 1000) / 1000 + " км";
        break;
    }

    return item;
  };

  const getProerties = (properties) => {
    return Object.entries(properties.parameters).map((item) => {
      const convertValue = parameterConvert(item);
      return (
        <div>
          {convertValue[0]}: {convertValue[1]}
        </div>
      );
    });
  };

  return (
    <div>
      <span>Геозона: {properties.title}</span>
      <br />
      {getProerties(properties)}
    </div>
  );
};

const onEachFeature = (feature, layer) => {
  layer.options.fillOpacity =feature.properties.opacity;
  layer.options.fillColor = feature.properties.color;
  layer.options.color = feature.properties.color;

  const popupContent = ReactDOMServer.renderToString(<PopupGeoZone properties={feature.properties} />);
  layer.bindPopup(popupContent, { className: "black-popup" });
  featureGroup.addLayer(layer);
};

export default React.memo(MapWrapper);
