import { connect } from "react-redux";
import get from "lodash/get";

import { getEmployees as onGetEmployees, reset as onResetEmployees } from "../../actions/employeesActions";

const mapStateToProps = (state) => ({
  employees: {
    dataState: state.employees.dataState,
    employees: get(state, ["employees", "employees"], []),
    total: get(state, ["employees", "total"], 0),
    filterCount: get(state, ["employees", "filterCount"], 0),
    isBookmarkCount: get(state, ["employees", "isBookmarkCount"], 0),
  },
});

const mapDispatchToProps = {
  onGetEmployees,
  onResetEmployees,
};

const withEmployees = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withEmployees };
