import { connect } from 'react-redux'

import {
  handleChangeIntellegenceTab as onSetIntellegenceTab
} from '../../actions/intellegenceTabActions'

const mapStateToProps = (state) => ({
  activeTabName: state.intellegenceTab.activeTabName,
});

const mapDispatchToProps = {
  onSetIntellegenceTab,
}

const withIntellegenceTab = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withIntellegenceTab }