import React from "react";
import { withRouter } from "react-router";

import UsersListItem from "./usersListItem";
import UsersListSelectedItem from "./usersListSelectedItem";

import styles from "./usersList.module.scss";

const UsersListItems = (props) => {
  const { handleScrollTo, data, match, onSelectUser } = props;

  return data.map((item) => {
    return match.params.id === item.id ? (
      <div className={`${styles.container_usersListItem} selected`} key={item.id}>
        <UsersListSelectedItem data={item} handleScrollTo={handleScrollTo} />
      </div>
    ) : (
      <div className={styles.container_usersListItem} key={item.id} onClick={() => onSelectUser(item.id)}>
        <UsersListItem data={item} />
      </div>
    );
  });
};

export default withRouter(React.memo(UsersListItems));
