"use stric"
import configuration from '../../config.json'

var RepositoriesService = {
    GetConfigurator: function () {
        var req = new XMLHttpRequest();
        req.open("GET", configuration.baseURL + "/Repositories/Configurator", true);
        req.responseType = "blob";
        req.onload = function (event) {
            var blob = req.response;
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "FindER Configurator";
            link.click();
        };

        req.send();
    },
    GetManual: function () {
        var req = new XMLHttpRequest();
        req.open("GET", configuration.baseURL + "/Repositories/Manual", true);
        req.responseType = "blob";
        req.onload = function (event) {
            var blob = req.response;
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "FindER Manual";
            link.click();
        };

        req.send();
    },
}

export default RepositoriesService