import React, { Component } from "react";
import { withObjectives } from "../../../shared/containers/containerObjectives";
import { withEmployeeMultipleSelect } from "../../../shared/containers/containerEmployeeMultipleSelect";
import { getDaysWeekState, getDaysWeekLocal } from "./DaysWeekEnum";
import SchedulePanel from "./objectiveSchedulePanel";
import ScheduleIntervalPanel from "./objectiveSсheduleIntervalPanel";
import { Scrollbars } from "react-custom-scrollbars";
import EmployeeMultipleSelectService from "../../../shared/services/employeeMultipleSelectService";
import dateTime from "../../../shared/services/date.service";
import Icon from "../../../shared/items/static/icon";
import CloseIcon from "@mui/icons-material/Close";
import ObjectiveScheduleToggle from "./objectiveScheduleToggle";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

const objectiveShedules = EmployeeMultipleSelectService.type.objectiveShedules;
let _daysWeekType = null;

class ObjectiveSchedulesPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objective: props.objective,
      objectiveType: props.objectiveType,
      employeeMultipleSelect: props.employeeMultipleSelect,
      selectSchedule: {
        id: props.employeeMultipleSelect.type === objectiveShedules ? props.employeeMultipleSelect.id : null,
      },
      daysWeekType: _daysWeekType === null ? true : _daysWeekType,
    };
  }

  getObjective(id) {
    const { objectives } = this.props;

    for (const objective of objectives.objectives) {
      if (objective.id === id) return objective;
    }
  }

  onActiveSchedule(shcedule) {
    const { onActiveSchedule } = this.props;
    const { objective } = this.state;

    onActiveSchedule(objective.id, shcedule.id, !shcedule.active);
  }

  getSchedules() {
    const { selectSchedule, daysWeekType } = this.state;
    const { id } = this.state.objective;

    const objective = this.getObjective(id);

    if (!objective || !objective.schedules) {
      return null;
    }

    const schedules = objective.schedules.filter((item) => {
      return daysWeekType === (item.daysWeek !== 0);
    });

    let prevDate = null;

    return schedules.map((schedule) => {
      const prfix = selectSchedule.id === schedule.id ? "select_" : "";

      const selectShedule = () => {
        this.onSelectScheduele(selectSchedule.id === schedule.id ? { id: null } : schedule);
      };

      const deleteSchedule = () => {
        this.onDeleteSchedule(schedule.id);
      };

      if ((selectSchedule.id !== null && selectSchedule.id === schedule.id) || selectSchedule.id === null) {
        if (schedule.daysWeek !== 0) {
          return this.daysWeekRows(schedule, selectShedule, deleteSchedule, selectSchedule.id === null, prfix);
        }
        const currentDate = schedule.dateStart.replace("T00:00:00", "");
        const dateEnd = schedule.dateEnd.replace("T00:00:00", "");
        const postfix = currentDate !== dateEnd ? ` - ${dateEnd}` : "";

        if (prevDate !== `${currentDate}${postfix}`) {
          prevDate = `${currentDate}${postfix}`;

          return [
            <div>
              <b>{prevDate}</b>
            </div>,
            this.daySchedule(schedule, selectShedule, deleteSchedule, selectSchedule.id === null, prfix),
          ];
        }

        return this.daySchedule(schedule, selectShedule, deleteSchedule, selectSchedule.id === null, prfix);
      } else {
        return null;
      }
    });
  }

  onSelectScheduele(schedule) {
    const { onEmployeeMultipleSelect } = this.props;

    if (schedule) {
      onEmployeeMultipleSelect(schedule.id, schedule, objectiveShedules);
    } else onEmployeeMultipleSelect(null, {}, objectiveShedules);

    this.setState({ selectSchedule: schedule });
  }

  onDeleteSchedule(id) {
    const { objectiveType, objective } = this.state;
    const { onDeleteSchedule } = this.props;

    onDeleteSchedule(objective.id, id, objectiveType);
  }

  daySchedule(schedule, selectShedule, deleteSchedule, delViseble, prfix) {
    const dateStart = new Date(Date.parse(schedule.dateStart.replace("00:00:00", schedule.timeStart + ".000Z")));
    const dateEnd = new Date(Date.parse(schedule.dateEnd.replace("00:00:00", schedule.timeEnd + ".000Z")));

    return (
      <div
        key={"scheduleRow" + schedule.id}
        className={prfix + "schedulePanelView"}
        onClick={(e) => {
          e.stopPropagation();
          if (delViseble) {
            selectShedule();
          }
        }}
      >
        {delViseble ? (
          <div style={{ display: "flex" }}>
            <div className={"scheduleEmployeesCountContainer"}>
              <Icon name={"user"} title={"" + schedule.employees.length} />
            </div>
            <div className="containerDateInterval">
              {dateTime.toTimeFormat1(dateStart)} - {dateTime.toTimeFormat1(dateEnd)}
            </div>
          </div>
        ) : null}

        {delViseble ? (
          <Tooltip title="Удалить расписание">
            <div className={"objectiveRemoveButtonWrapper"}>
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  deleteSchedule();
                }}
              />
            </div>
          </Tooltip>
        ) : (
          <div style={{ display: "flex" }}>
            <div className={"scheduleEmployeesCountContainer"}>
              <Icon name={"user"} title={"" + schedule.employees.length} />
            </div>
            <div className="containerDateInterval">
              {dateTime.toFormat7(dateStart)} - {dateTime.toFormat7(dateEnd)}
            </div>

            <div style={{ marginLeft: "auto" }}>
              <CloseIcon
                className={"tableObjectivesRowConcat_close"}
                onClick={(e) => {
                  e.stopPropagation();
                  selectShedule(e);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  daysWeekRows(schedule, selectShedule, deleteSchedule, delViseble, prfix) {
    const _shedule = Object.assign({}, schedule);
    _shedule.daysWeek = getDaysWeekLocal(schedule);

    return (
      <div key={"scheduleRow" + schedule.id} style={{ display: "flex" }}>
        {delViseble ? (
          <div style={{ display: "flex" }}>
            <ObjectiveScheduleToggle
              shceduleId={schedule.id}
              active={schedule.active}
              onActiveSchedule={this.onActiveSchedule.bind(this)}
            />
            <div
              className={prfix + "schedulePanelView"}
              style={{ display: "flex", opacity: !schedule.active ? "35%" : "100%" }}
              onClick={(e) => {
                e.stopPropagation();
                if (delViseble) selectShedule();
              }}
            >
              <div
                className={"scheduleEmployeesCountContainer"} /*  style={{ marginLeft: "15px", marginTop: "5px" }} */
              >
                <Icon style={{ fontSize: "2rem" }} name={"user"} title={"" + schedule.employees.length} />
              </div>

              <ScheduleIntervalPanel scheduleId={schedule.id} dayCheked={getDaysWeekState(_shedule)} />
              <div className="containetTimeInterval" style={{ marginLeft: "15px" }}>
                {dateTime.toLocalTime(schedule.timeStart).replace(":00", "")} -{" "}
                {dateTime.toLocalTime(schedule.timeEnd).replace(":00", "")}
              </div>
            </div>
          </div>
        ) : null}

        {delViseble ? (
          <div style={{ display: "flex", marginLeft: "auto", paddingRight: "10px" }}>
            <Tooltip title="Удалить расписание">
              <div className={"objectiveRemoveButtonWrapper"}>
                <DeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteSchedule();
                  }}
                />
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className={prfix + "schedulePanelView"} style={{ display: "flex" }}>
            <div className={"scheduleEmployeesCountContainer"}>
              <Icon name={"user"} title={"" + schedule.employees.length} />
            </div>
            <ScheduleIntervalPanel scheduleId={schedule.id} dayCheked={getDaysWeekState(_shedule)} />
            <div className="containetTimeInterval">
              {dateTime.toLocalTime(schedule.timeStart).replace(":00", "")} -{" "}
              {dateTime.toLocalTime(schedule.timeEnd).replace(":00", "")}
            </div>

            <div style={{ marginLeft: "auto" }}>
              <CloseIcon
                className={"tableObjectivesRowConcat_close"}
                onClick={(e) => {
                  e.stopPropagation();
                  selectShedule(e);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  onChekedTypeSchedule(daysWeekType) {
    _daysWeekType = daysWeekType;
    this.setState({ daysWeekType: daysWeekType });
  }

  render() {
    const { objective, objectiveType, selectSchedule, daysWeekType } = this.state;

    const chekedTypeSchedule = (value) => {
      this.onChekedTypeSchedule(value);
    };

    return (
      <div>
        {objective.id ? (
          <div
            className={"scheduleRadioButtun"}
            onClick={() => {
              if (!daysWeekType) chekedTypeSchedule(true);
            }}
          >
            <input
              type={"radio"}
              id={"scheduleIntervalRadioId"}
              checked={daysWeekType}
              defaultChecked
              onChange={(e) => {}}
            />
            <label for="scheduleIntervalRadioId">День(-и) недели</label>
          </div>
        ) : null}

        {objective.id ? (
          <div
            className={"scheduleRadioButtun"}
            onClick={() => {
              if (daysWeekType) chekedTypeSchedule(false);
            }}
          >
            <input
              type={"radio"}
              id={"scheduleDateRadioId"}
              checked={!daysWeekType}
              defaultChecked
              onChange={(e) => {}}
            />
            <label for="scheduleDateRadioId">Дата</label>
          </div>
        ) : null}

        {objective.id && selectSchedule.id === null ? (
          <div>
            <SchedulePanel objectiveId={objective.id} objectiveType={objectiveType} daysWeekType={daysWeekType} />
            <h4> Список расписаний:</h4>
          </div>
        ) : null}

        <Scrollbars style={{ minHeight: "340px" }} autoHide={true}>
          <div className="listShedules">{this.getSchedules()}</div>
        </Scrollbars>
      </div>
    );
  }
}

export default withEmployeeMultipleSelect(withObjectives(ObjectiveSchedulesPanel));
