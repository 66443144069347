"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";
import configuration from "./../../config.json";

const EmployeesService = {
  getEmployees: function (options) {
    return api.post("employees", options, WithToken.headersToken({}, configuration.cookieName));
  },
  setBookmark: function (id) {
    return api.post("employees/" + id + "/Bookmarks", {}, WithToken.headersToken({}, configuration.cookieName));
  },
  deleteBookmark: function (id) {
    return api.delete("employees/" + id + "/Bookmarks", WithToken.headersToken({}, configuration.cookieName));
  },
  getEmployeeShortInfo: function (id) {
    return api.get("employees/" + id + "/information", WithToken.headersToken({}, configuration.cookieName));
  },
  setEmployeeShortInfo: function (id, title, phone, autoCreateGoeZone) {
    var options = {
      id: id,
      title: title,
      phone: phone,
      autoCreateGeoZone: autoCreateGoeZone,
    };

    return api.put("employees/" + id + "/information", options, WithToken.headersToken({}, "authTNToken"));
  },
  getEmployeesLastStates: function (options) {
    return api.post("employees/LastStates", options, WithToken.headersToken({}, configuration.cookieName));
  },
  takeNoteAlarm: function (id) {
    return api.post("employees/" + id + "/takenotealarm", {}, WithToken.headersToken({}, configuration.cookieName));
  },
  getGroups: function (id) {
    return api.get("employees/" + id + "/groups",  WithToken.headersToken({}, configuration.cookieName));
  },
  employeeSharedLink: function (id, createSharedLink) {
    return api.patch(
      "employees/" + id + "/SharedLink?CreateSharedLink=" + createSharedLink,
      {},
      WithToken.headersToken({}, configuration.cookieName)
    );
  },
  reprocces: function (id, time) {
    return api.put("employees/" + id + "/reprocces?time=" + time, {}, WithToken.headersToken({}, "authTNToken"));
  },
};

export default EmployeesService;
