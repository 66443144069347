'use stric'

const getSelectOptions= (array) => {
  var selectOptions = array.map((item) => {
    return {
      label: item.title,
      value: item.id
    }
  })

  return selectOptions
}

export { getSelectOptions }