import React from "react";
import IntelligenceInTimeRangeContent from "./intelligenceInTimeRangeContent";
import styles from "./intelligenceResultContent.module.scss";

const IntellegenceResultWrapper = (props) => {

  return (
    <div className={styles.intelligenceResultContent}>
      <div className={styles.container_viewTable}>
        <IntelligenceInTimeRangeContent />
      </div>
    </div>
  );
};

export default React.memo(IntellegenceResultWrapper);
