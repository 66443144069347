"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";
import configuration from "../../config.json";

const EmployeesCompaniesService = {
  getFilials: function () {
    return api.get('/Companies/Filials', WithToken.headersToken(
      {},
      configuration.cookieName
    ))
  },
};

export default EmployeesCompaniesService;
