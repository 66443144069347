"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";
import configuration from "../../config.json";

var EmployeesGroupsService = {
  getEmployeesGroups: function () {
    return api.get("/EmployeeGroups", WithToken.headersToken({}, configuration.cookieName));
  },
  addEmployeeToGroup: function (options) {
    return api.post(
      `/EmployeeGroups/${options.groupId}?employeeId=${options.employeeId}`,
      {},
      WithToken.headersToken({}, configuration.cookieName)
    );
  },
  removeEmployeeFromGroup: function (options) {
    return api.delete(
      `/EmployeeGroups/${options.groupId}?employeeId=${options.employeeId}`,
      WithToken.headersToken({}, configuration.cookieName)
    );
  },
  createGroup: function (options) {
    return api.post("/Groups", options, WithToken.headersToken({}, configuration.cookieName));
  },
};

export default EmployeesGroupsService;
