import AccountService from './../shared/services/accountService'

const REQUEST_REGISTRATION = 'REQUEST_REGISTRATION'
const RESPONSE_REGISTRATION = 'RESPONSE_REGISTRATION'
const REQUEST_REGISTRATION_FAILURE = 'REQUEST_REGISTRATION_FAILURE'
const SET_REGISTRATION = 'SET_REGISTRATION'
const CLEAR_REGISTRATION_ERRORS = 'CLEAR_REGISTRATION_ERRORS'
const READY_CHANGE_STORE_REGISTRATION = 'READY_CHANGE_STORE_REGISTRATION'
const READY_CLEAR_STORE_REGISTRATION = 'READY_CLEAR_STORE_REGISTRATION'

const responseChangeStoreRegistrationAction = (name, value) => {
  return {
    type: READY_CHANGE_STORE_REGISTRATION,
    payload: { name, value }
  }
}

const handleChangeStoreRegistration = (name, value) => {
  return (dispatch) => {
    dispatch(responseChangeStoreRegistrationAction(name, value))
  }
}

const responseClearStoreRegistrationAction = () => {
  return {
    type: READY_CLEAR_STORE_REGISTRATION,
  }
}

const handleClearStoreRegistration = () => {
  return (dispatch) => {
    dispatch(responseClearStoreRegistrationAction())
  }
}

const requestRegistrationAction = () => {
  return {
    type: REQUEST_REGISTRATION,
  }
}

const responseRegistrationAction = (data) => {
  return {
    type: RESPONSE_REGISTRATION,
    data,
  }
}

const requestRegistrationFailureAction = (errors) => {
  return {
    type: REQUEST_REGISTRATION_FAILURE,
    errors,
  }
}

const registration = (data) => {

  return (dispatch) => {
    dispatch(requestRegistrationAction())

    AccountService.registration(data)
      .then((result) => {
        window.location.href = '/';
        dispatch(handleClearStoreRegistration())
      })
      .catch((error) => {
        dispatch(requestRegistrationFailureAction(error.response))
      })
  }
}

const getEmail = (token) => {

  return (dispatch) => {
    dispatch(requestRegistrationAction())

    AccountService.getMail(token)
      .then((result) => {

        dispatch(responseRegistrationAction(result.data))
        dispatch(handleClearStoreRegistration())
      })
      .catch((error) => {
        dispatch(requestRegistrationFailureAction(error.response))
        window.location.href = '/';
      })
  }
}

const setRegistrationAction = (data) => {
  return {
    type: SET_REGISTRATION,
    data
  }
}

const setRegistration = (options) => {
  return (dispatch) => {
    dispatch(setRegistrationAction(options))
  }
}


const clearRegistrationErrors = () => {
  return {
    type: CLEAR_REGISTRATION_ERRORS
  }
}

export {
  getEmail,
  registration,
  requestRegistrationAction,
  responseRegistrationAction,
  requestRegistrationFailureAction,
  setRegistrationAction,
  setRegistration,
  clearRegistrationErrors,

  REQUEST_REGISTRATION,
  RESPONSE_REGISTRATION,
  REQUEST_REGISTRATION_FAILURE,
  SET_REGISTRATION,
  CLEAR_REGISTRATION_ERRORS,

  handleChangeStoreRegistration,
  responseChangeStoreRegistrationAction,
  READY_CHANGE_STORE_REGISTRATION,

  handleClearStoreRegistration,
  responseClearStoreRegistrationAction,
  READY_CLEAR_STORE_REGISTRATION,
}
