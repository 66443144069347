import React from 'react';

import styles from './sideContentBlock.module.scss'

const SideContentBlock = props => {
  return (
    <div className={styles.container_sideContentBlock}>
      {props.children}
    </div>
  )
}

export default React.memo(SideContentBlock)
