import dateTime from "./date.service";

const JAMGSM = "JAMGSM";
const JAMGPS = "JAMGPS";

const parseFeaturesIntellegence = function (data, dates, features = []) {
  data.map((item) => {
    var inRange = false;
    var rangeStartDate = dateTime.withEmptySeconds(dates.rangeStartDate);
    var rangeEndDate = dateTime.withEmptySeconds(dates.rangeEndDate);

    for (var i = 0; i < item.timeInFeatures.length; i++) {
      var timeIn = dateTime.toLocal(item.timeInFeatures[i].time);
      var timrDurationUtc = dateTime.withEmptySeconds(dateTime.add(timeIn, item.timeInFeatures[i].duration));

      if (timrDurationUtc > rangeStartDate && dateTime.withEmptySeconds(timeIn) < rangeEndDate) {
        inRange = true;
      } else {
        item.timeInFeatures.splice(i, 1);
        i--;
      }
    }

    if (inRange) {
      features.push(item);
    }

    return null;
  });

  return features;
};

function dataPerDay(data, dates) {
  return data.filter((item) => {
    const localTime = dateTime.toLocal(item.time);

    return localTime >= dates.rangeStartDate && localTime <= dates.rangeEndDate;
  });
}

const getNewGroupItem = function (item, index) {
  let groupedItem = item;
  groupedItem.index = index;
  groupedItem.startDate = item.time;
  groupedItem.startIndex = index;
  groupedItem.finishDate = item.time;
  groupedItem.finishIndex = index;
  groupedItem.jammingValue = getJammingValue(item.data[JAMGSM], item.data[JAMGPS]);

  return groupedItem;
};

function getGeozoneNames(geoZones) {
  let names = "";
  for (const geoZone of geoZones) {
    names += `${geoZone.title === null || geoZone.title === "" ? "Наименование не задано" : geoZone.title}; `;
  }

  return names;
}

const groupIntellegenceData = function (data, groupStatus) {
  const locationNotProcessed = document.getElementById("checkBox-locationNotProcessed");

  if (locationNotProcessed && locationNotProcessed.checked) {
    return data.map((item, index) => {
      const lastGroupItem = getNewGroupItem(data[index], index);
      lastGroupItem.rowId = index;
      lastGroupItem.state.duration = (new Date(lastGroupItem.finishDate) - new Date(lastGroupItem.startDate)) / 1000;

      return lastGroupItem;
    });
  }

  let groupedData = [];
  data.map((item, index) => {
    let lastGroupItem = groupedData[groupedData.length - 1];
    item.geozoneNames = getGeozoneNames(item.geoZones);

    if (lastGroupItem === undefined) {
      lastGroupItem = getNewGroupItem(item, index);
      groupedData.push(lastGroupItem);
    }

    lastGroupItem.finishDate = item.time;
    lastGroupItem.finishIndex = index;

    if (getJammingValue(item.data[JAMGSM], item.data[JAMGPS]) !== lastGroupItem.jammingValue) {
      groupedData.push(getNewGroupItem(item, index));

      return null;
    }

    if (groupStatus && item.state.status !== lastGroupItem.state.status) {
      groupedData.push(getNewGroupItem(item, index));
      return null;
    }

    if (item.state.address !== lastGroupItem.state.address || item.geozoneNames !== lastGroupItem.geozoneNames) {
      groupedData.push(getNewGroupItem(item, index));
      return null;
    }

    if (item.state.button || lastGroupItem.state.button) {
      groupedData.push(getNewGroupItem(item, index));
      return null;
    }

    return null;
  });

  groupedData.map((item, index) => {
    item.rowId = index;
    item.state.duration = (new Date(item.finishDate) - new Date(item.startDate)) / 1000;

    return null;
  });

  return groupedData;
};

function isNullOrEmptyStringOrUndefined(value) {
  if (value === undefined) {
    return true;
  }

  if (value === null) {
    return true;
  }

  if (value === "") {
    return true;
  }
}

function getJammingValue(jammingGSM, jammingGPS) {
  let jammingValue = null;
  if (!isNullOrEmptyStringOrUndefined(jammingGSM)) {
    jammingValue = jammingGSM;
  }

  if (!isNullOrEmptyStringOrUndefined(jammingGPS)) {
    jammingValue = jammingValue === null ? jammingGPS : "; " + jammingGPS;
  }

  return jammingValue;
}

const getPoints = function (data) {
  const points = data.map((item) => {
    const point = {
      location: [item.location.lat, item.location.long],
      locationNotProcessed: item.locationNotProcessed
        ? {
            lat: item.locationNotProcessed.lat,
            lng: item.locationNotProcessed.long,
          }
        : null,
      geozoneNames: item.geozoneNames,
      status: item.state.status,
      date: item.time,
      isOriginalCoordinate: item.state.isOriginalCoordinate,
      undiferendCoordinate: item.state.undiferendCoordinate,
      stopDuration: item.state.stopDuration,
      button: item.state.button,
      jammingValue: getJammingValue(item.data[JAMGSM], item.data[JAMGPS]),
    };

    return point;
  });

  return points;
};

const groupPoints = function (data) {
  let groupedData = [];

  data.forEach((item, index) => {
    if (!item.isOriginalCoordinate) {
      let groupedItem = item;
      groupedItem.index = index;
      groupedItem.startIndex = index;
      groupedItem.finishIndex = index;

      groupedData.push(groupedItem);
    } else {
      item.index = index;
      groupedData.push(item);
    }
  });

  const groupData = groupedData.map((item, index) => {
    item.id = index;
    return item;
  });

  return groupData;
};

const getRouteCoordinates = function (data, сoordinates, i) {
  data.map((item) => {
    const lastCoordinate = сoordinates[сoordinates.length - 1];

    if (item.location[0] !== lastCoordinate[0] && item.location[1] !== lastCoordinate[1]) {
      сoordinates.push(item.location);
    }

    return null;
  });

  return сoordinates;
};

const getRouteDates = function (data) {
  return data.map((item) => {
    return item.time;
  });
};

export {
  dataPerDay,
  parseFeaturesIntellegence,
  getPoints,
  getRouteCoordinates,
  groupIntellegenceData,
  groupPoints,
  getRouteDates,
};
