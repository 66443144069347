import { groupIntellegenceData } from "../shared/services/service.intellegence";

const SET_TIMERANGE_INTELLIGENCE_DATA = "SET_TIMERANGE_INTELLIGENCE_DATA";
const CLEAR_TIMERANGE_INTELLIGENCE_DATA = "CLEAR_TIMERANGE_INTELLIGENCE_DATA";

const setTimeRangeIntelligenceDataAction = (data, features, groupStatus, eventObjectives) => {
  return {
    type: SET_TIMERANGE_INTELLIGENCE_DATA,
    data,
    features,
    groupStatus,
    eventObjectives,
  };
};

const setTimeRangeIntelligenceData = (data, features, groupStatus, eventObjectives) => {
  return (dispatch) => {
    dispatch(setTimeRangeIntelligenceDataAction(groupIntellegenceData(data, groupStatus), features, groupStatus, eventObjectives));
  };
};

const clearTimeRangeIntelligenceDataAction = () => {
  return {
    type: CLEAR_TIMERANGE_INTELLIGENCE_DATA,
  };
};

const clearTimeRangeIntelligenceData = () => {
  return (dispatch) => {
    dispatch(clearTimeRangeIntelligenceDataAction());
  };
};

export {
  setTimeRangeIntelligenceData,
  setTimeRangeIntelligenceDataAction,
  SET_TIMERANGE_INTELLIGENCE_DATA,
  clearTimeRangeIntelligenceData,
  clearTimeRangeIntelligenceDataAction,
  CLEAR_TIMERANGE_INTELLIGENCE_DATA,
};
