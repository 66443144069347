import React from 'react';

import { withFilterEmployees } from '../../../shared/containers/containerFilterEmployees'
import Icon from '../../../shared/items/static/icon'
import Input from '../../../shared/items/contols/input'

import styles from './searchEmployeesString.module.scss'

const SearchEmployeesString = props => {
  const { onHandleChangeStore, filterEmployees } = props
  const { filterItems } = filterEmployees

  return (
    <div className={styles.searchEmployees}>
      <Input
        name="searchTerm"
        type="text"
        store={filterItems}
        handleChangeStore={onHandleChangeStore}
   
      />
      <div className={styles.container_searchBtn}>
        <Icon name="search" />
      </div>
    </div>
  )
}

export default withFilterEmployees(React.memo(SearchEmployeesString))
