import configuration from "../config.json";
import EmployeesService from "../shared/services/employeesService";
import CookieService from "../shared/services/cookiesService";
import { employeeStatuses } from "../components/sections/employees/static.employeeStatuses";
import { setLogout } from "./authActions";

const RESET_REQUEST_EMPLOYEES = "RESET_REQUEST_EMPLOYEES";
const REQUEST_EMPLOYEES = "REQUEST_EMPLOYEES";
const RESPONSE_EMPLOYEES = "RESPONSE_EMPLOYEES";
const REQUEST_EMPLOYEES_FAILURE = "REQUEST_EMPLOYEES_FAILURE";
const STATE_NOT_REQUESTED = "STATE_NOT_REQUESTED";

const requestOptionsEmployeesFilter = (options) => {
  var nextOptions = Object.assign({}, options, {
    types: [],
    brands: [],
    groups: [],
    statuses: [],
    workPositions: [],
    companies: [],
  });

  if (!!options.companies) {
    nextOptions.companies = options.companies.map((item) => item.value);
  }

  if (!!options.groups) {
    nextOptions.groups = options.groups.map((item) => item.value);
  }

  if (!!options.workPositions) {
    nextOptions.workPositions = options.workPositions.map((item) => item.value);
  }

  if (!!options[employeeStatuses.noData.name]) {
    nextOptions.statuses.push(employeeStatuses.noData.id);
  }

  if (!!options[employeeStatuses.inSleep.name]) {
    nextOptions.statuses.push(employeeStatuses.inSleep.id);
    nextOptions.statuses.push(employeeStatuses.offSleep.id);
  }

  if (!!options[employeeStatuses.work.name]) {
    nextOptions.statuses.push(employeeStatuses.work.id);
  }

  if (!!options[employeeStatuses.charging.name]) {
    nextOptions.statuses.push(employeeStatuses.charging.id);
    nextOptions.statuses.push(employeeStatuses.poweredFrom.id);
  }

  if (!!options[employeeStatuses.offSleep.name]) {
    nextOptions.statuses.push(employeeStatuses.offSleep.id);
  }

  if (!!options[employeeStatuses.alarm.name]) {
    nextOptions.statuses.push(employeeStatuses.alarm.id);
  }

  if (!!options[employeeStatuses.poweredFrom.name]) {
    nextOptions.statuses.push(employeeStatuses.poweredFrom.id);
  }

  return nextOptions;
};

const requestGetEmployeesAction = () => {
  return {
    type: REQUEST_EMPLOYEES,
  };
};

const requestEmployeesNotRequestAction = () => {
  return {
    type: STATE_NOT_REQUESTED,
  };
};

const responseGetEmployeesAction = (data) => {
  return {
    type: RESPONSE_EMPLOYEES,
    data,
  };
};

const requestGetEmployeesfailureAction = (errors) => {
  return {
    type: REQUEST_EMPLOYEES_FAILURE,
    errors,
  };
};

const getEmployees = (filterOptions) => {
  return (dispatch) => {
    dispatch(requestGetEmployeesAction());
    const filter = requestOptionsEmployeesFilter(filterOptions);

    EmployeesService.getEmployees(filter)
      .then((result) => {
        CookieService.updateExpires(configuration.cookieName, 12);

        dispatch(responseGetEmployeesAction(result.data));
      })
      .catch((error) => {
        dispatch(requestGetEmployeesfailureAction(error.response));

        dispatch(setLogout());
      });
  };
};

const requestReset = () => {
  return {
    type: RESET_REQUEST_EMPLOYEES,
  };
};

const reset = () => {
  return (dispatch) => {
    dispatch(requestReset());
  };
};

export {
  reset,
  getEmployees,
  requestGetEmployeesAction,
  responseGetEmployeesAction,
  requestEmployeesNotRequestAction,
  requestGetEmployeesfailureAction,
  requestOptionsEmployeesFilter,
  RESET_REQUEST_EMPLOYEES,
  REQUEST_EMPLOYEES,
  RESPONSE_EMPLOYEES,
  REQUEST_EMPLOYEES_FAILURE,
  STATE_NOT_REQUESTED,
};
