import React, { Component } from 'react'

import { withAuth } from '../../shared/containers/containerAuth'
import { withSignInForm } from '../../shared/containers/containerSignInForm'

import ButtonPrimary from '../../shared/items/contols/buttonPrimary'
import PreloaderLight from '../../shared/items/static/preloaderLight'
import { states } from "../../reducers/queriesStates"

import styles from './buttonLoginWrapper.module.scss'

const BUTTON_DEMO_NAME = "Demo"

class ButtonDemoWrapper extends Component {
  componentDidMount() {
 
  }

  componentWillUnmount() {
 
  }

  clickEnter = (e) => {

  }

  render() {
    const { onLogInDemo, dataState } = this.props
    return (
      <ButtonPrimary handleClick={onLogInDemo.bind(this)}>
        {
          dataState === states.REQUEST
            ? <div className={styles.container_preloader}><PreloaderLight /></div>
            : BUTTON_DEMO_NAME
        }
      </ButtonPrimary>
    )
  }
}

export default withAuth(withSignInForm(ButtonDemoWrapper))