import api from "./apiService.js";
import WithToken from "./tokenService";
import configuration from "../../config.json";

const ObjectiveServices = {
  get: function (otpions) {
    return api.get(
      "Objectives/?objectiveType=" + otpions.objectiveType,
      WithToken.headersToken({}, configuration.cookieName)
    );
  },
  create: function (options) {
    return api.post("Objectives", options, WithToken.headersToken({}, configuration.cookieName));
  },
  delete: function (id) {
    return api.delete(`Objectives/${id}`, WithToken.headersToken({}, configuration.cookieName));
  },
  update: function (options) {
    return api.put(`Objectives/${options.id}`, options, WithToken.headersToken({}, configuration.cookieName));
  },
  createSchedule: function (id, options) {
    return api.post(`Objectives/${id}/Schedules`, options, WithToken.headersToken({}, configuration.cookieName));
  },
  updateSchedule: function (id, options) {
    return api.put(`Objectives/${id}/Schedules`, options, WithToken.headersToken({}, configuration.cookieName));
  },
  deleteSchedule: function (objeciveId, id) {
    return api.delete(`Objectives/${objeciveId}/Schedules/${id}`, WithToken.headersToken({}, configuration.cookieName));
  },
  activeSchedule: function (objeciveId, id, active) {
    return api.put(
      `Objectives/${objeciveId}/Schedules/${id}?active=${active}`,
      {},
      WithToken.headersToken({}, configuration.cookieName)
    );
  },
  addScheduleEmployee: function (options) {
    return api.post(`ObjectiveSchedules`, options, WithToken.headersToken({}, configuration.cookieName));
  },
  deleteScheduleEmployee: function (options) {
    console.log("sd")
    return api.delete(`ObjectiveSchedules`, WithToken.headersToken({ data: options }, configuration.cookieName));
  },
};

export default ObjectiveServices;
