import React from "react";
import { Marker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import * as L from "leaflet";
import { employeeStatuses } from "../../../components/sections/employees/static.employeeStatuses";
import dateTime from "../../../shared/services/date.service";
import PositionService from "../../../shared/services/positionsService";
import { v4 as uuid_v4 } from "uuid";

const MarkerClusterGroupWrapper = (props) => {
  const { maxClusterRadius, points, markerType } = props;

  function onClickMarker(e) {
    const latLng = L.latLng(e.latlng);

    PositionService.Address(latLng).then((response) => {
      const address = response.data.address ? "<b>Адрес:</b> " + response.data.address : "";
      const content = e.target.options.poupupContent ? e.target.options.poupupContent : "";
      L.popup({ className: "black-popup" })
        .setLatLng(latLng)
        .setContent(content + address)
        .openOn(e.target._map);
    });
  }

  const markerStopIcon = L.divIcon({
    iconSize: [40, 49],
    iconAnchor: [20, 49],
    popupAnchor: [0, -48],
    className: "mapMarkerStop ",
    html: '<div class="inner"><b>STOP</b></div>',
  });

  const markerJammingIcon = L.divIcon({
    iconSize: [40, 49],
    iconAnchor: [20, 49],
    popupAnchor: [0, -48],
    className: "mapMarkerJamming ",
    html: '<div class="inner"><b>!</b></div>',
  });

  const markerIcon = L.divIcon({
    iconSize: [36, 40],
    iconAnchor: [17, 40],
    popupAnchor: [0, 0],
    className: "mapMarker " + employeeStatuses.alarm.name,
    html: '<div class="inner" style="font-size: 11px">SOS</div>',
  });

  const alarmMarkers = points.map((point) => {
    if (point.button === "SOS") {
      const alarmTime = dateTime.toFormat5(dateTime.toLocal(point.date.replace("T", " ")));
      const poupupContent = "<b>SOS: " + alarmTime + "</b><br/>";

      return (
        <Marker
          key={uuid_v4()}
          position={point.location}
          icon={markerIcon}
          poupupContent={poupupContent}
          zIndexOffset={950}
          eventHandlers={{
            click: (e) => {
              onClickMarker(e);
            },
          }}
        ></Marker>
      );
    }

    return null;
  });

  const stopMarkers = points.map((item) => {
    if (item.stopDuration === null) {
      return null;
    }

    return (
      <Marker
        key={item.Time}
        position={item.location}
        icon={markerStopIcon}
        poupupContent={
          dateTime.toFormat3(dateTime.toLocal(item.date)) +
          "<br/><b>Длительность стоянки:</b> " +
          item.stopDuration +
          "<br/>"
        }
        eventHandlers={{
          click: (e) => {
            onClickMarker(e);
          },
        }}
      ></Marker>
    );
  });

  function getJammingPoints() {
    const items = [];
    let prePoint = null;

    for (const point of points) {
      if (point.jammingValue !== null) {
        if (prePoint == null || point.jammingValue !== prePoint.jammingValue) {
          items.push(Object.assign({}, point, { duration: 0 }));
        } else {
          items[items.length - 1].duration =
            new Date(point.date.replace("Z", "")) - new Date(items[items.length - 1].date.replace("Z", ""));
        }
      }

      prePoint = point;
    }
    return items;
  }

  const jammingMarkers = getJammingPoints().map((item) => {
    return (
      <Marker
        position={item.location}
        icon={markerJammingIcon}
        poupupContent={`${dateTime.toFormat3(dateTime.toLocal(item.date))} ${
          item.duration !== 0 ? `Длительность: ${dateTime.onlyTimeString(new Date(item.duration))}` : ""
        }<br/><b>${item.jammingValue}</b><br/>`}
        eventHandlers={{
          click: (e) => {
            onClickMarker(e);
          },
        }}
      ></Marker>
    );
  });

  function getButtonIcon(param) {
    var styleIcon = getButtonIconStyle(param);
    return L.divIcon({
      iconAnchor: [15, 15],
      className: "mapMarkerButton" + styleIcon,
      html: '<div class="inner"><font color="white">' + getTextButtonIcon(param) + "</font></div>",
    });
  }

  const buttonMarkers = points.map((item) => {
    if (item.button !== null && item.button !== "SOS") {
      if (!isNaN(item.location[0]) && !isNaN(item.location[1])) {
        return (
          <Marker
            key={item.id + "_buttonMarker"}
            position={item.location}
            icon={getButtonIcon(item.button)}
            poupupContent={
              dateTime.toFormat3(dateTime.toLocal(item.date)) +
              "<br/>" +
              "<b>Нажатие кнопки:</b> " +
              item.button.replace("X", "✖") +
              "<br/>"
            }
            eventHandlers={{
              click: (e) => {
                onClickMarker(e);
              },
            }}
          ></Marker>
        );
      }
    }

    return null;
  });

  function getButtonIconStyle(param) {
    // eslint-disable-next-line default-case
    switch (param) {
      case "X":
        return " cancel";
      case "1":
        return " one";
      case "2":
        return " two";
      case "Включение":
        return " on";
      case "Выключение":
        return " off";
      case "Сброс устройства":
        return " reset";
    }

    return " cancel";
  }

  function getTextButtonIcon(param) {
    // eslint-disable-next-line default-case
    switch (param) {
      case "X":
        return "✖";
      case "1":
        return "1";
      case "2":
        return "2";
      case "Включение":
        return "☀";
      case "Выключение":
        return "☼";
      case "Сброс":
        return "♺";
      case "SOS":
        return "SOS";
    }

    return "";
  }

  const createClusterAlarmIcon = function (cluster) {
    return L.divIcon({
      html: `<span><b>${cluster.getChildCount()}</b></span>`,
      className: "alarmCluster",
      iconSize: L.point(50, 50, true),
    });
  };

  const createClasterIcon = function (cluster) {
    return L.divIcon({
      html: `<span><b>${cluster.getChildCount()}</b></span>`,
      className: "simpleClusterRoute",
      iconSize: L.point(35, 35, false),
    });
  };

  const createButtonClasterIcon = function (cluster) {
    return L.divIcon({
      html: `<span><b>${cluster.getChildCount()}</b></span>`,
      className: "simpleButtonCluster",
      iconSize: L.point(21, 21, false),
    });
  };

  function getMarkers() {
    if (markerType === "button") {
      return buttonMarkers;
    }

    if (markerType === "stopAndJamming") {
      return [stopMarkers, jammingMarkers];
    }

    if (markerType === "alarm") {
      return alarmMarkers;
    }

    return null;
  }

  return (
    <MarkerClusterGroup
      key={uuid_v4()}
      iconCreateFunction={
        markerType === "button"
          ? createButtonClasterIcon
          : markerType === "stopAndJamming"
          ? createClasterIcon
          : markerType === "alarm"
          ? createClusterAlarmIcon
          : null
      }
      animate={true}
      maxClusterRadius={maxClusterRadius}
      zIndexOffset={markerType === "alarm" ? 950 : 900}
    >
      {getMarkers()}
    </MarkerClusterGroup>
  );
};

export default React.memo(MarkerClusterGroupWrapper);
