var ResponsiveService = {
  getDevice: function () {
    if (document.body.clientWidth >= 1700)
      return "desktop"
    else if (document.body.clientWidth >= 992 && document.body.clientWidth < 1700)
      return "laptop"
    else if (document.body.clientWidth >= 768 && document.body.clientWidth < 992)
      return "tablet"
    else if (document.body.clientWidth >= 600 && document.body.clientWidth < 768)
      return "mobile_l"
    else if (document.body.clientWidth < 600)
      return "mobile"
  },
  setToState: function (context, subStore) {
    let store = context.state
    let currentDevice = this.getDevice()
    if (currentDevice !== store[subStore]) {
      store[subStore] = this.getDevice()
      context.setState(store)
    }
  },
  addResizeListener: function (context, subStore) {
    window.addEventListener(
      'resize',
      this.setToState.bind(this, context, subStore)
    )
  }
}

export default ResponsiveService