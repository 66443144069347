const RESPONSE_CHOOSE_EMPLOYEE = 'RESPONSE_CHOOSE_EMPLOYEE'

const responseChooseEmployeeAction = (data) => {
  return {
    type: RESPONSE_CHOOSE_EMPLOYEE,
    data,
  }
}

const chooseEmployee = (data) => {
  return (dispatch) => {
    dispatch(responseChooseEmployeeAction(data))
  }
}

export {
  chooseEmployee,
  responseChooseEmployeeAction,
  RESPONSE_CHOOSE_EMPLOYEE,
}
