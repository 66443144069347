import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { withAppState } from "./../shared/containers/containerAppState";
import SideHead from "./sides/sideHead";
import { EmployeesBar, UsersBar, CompaniesBar } from "./sections/sideBars/sideBarsContainer";
import {
  EmployeeData,
  EmployeeInfoData,
  EmployeesMap,
  CompanyInfo,
  ObjectivesBlock,
  UsersBlock,
} from "./sections/contentBlocks/contentBlocksContainer";
import styles from "./content.module.scss";
import configuration from "./../config.json";
import CookieService from "./../shared/services/cookiesService";

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissions: CookieService.getCookie(configuration.permissionsCookie) ?? [],
    };
  }

  render() {
    const { isSideBarOpen } = this.props;
    const { permissions } = this.state;

    const isCompanyInfo = permissions.includes(10001);

    return (
      <div className={styles.wrapper}>
        <SideHead />
        <div className={isSideBarOpen ? styles.content : styles.content_closedBar}>
          <div className={styles.container_sideBar}>
            <Switch>
              {isCompanyInfo ? (
                <Route exact path={["/", "/companies", "/companies/:id"]} component={CompaniesBar} />
              ) : null}

              <Route
                exact
                path={[
                  "/",
                  "/employees",
                  "/employees-info",
                  "/employees/:id",
                  "/objectives",
                  "/objectives/:id",
                  "/objectives/:id/shedules",
                  "/objectiveShedules",
                  "/objectiveShedules/:id",
                  "/objectiveShedules/:id/Emplouees",
                ]}
                component={EmployeesBar}
              />
              <Route exact path={["/users", "/users/:id"]} component={UsersBar} />
            </Switch>
          </div>

          <div className={styles.container_sideContent}>
            <Switch>
              {isCompanyInfo ? (
                <Route exact path={["/", "/companies", "/companies/:id"]} component={CompanyInfo} />
              ) : null}
              <Route exact path={["/users", "/users/:id"]} component={UsersBlock} />
              <Route exact path={["/", "/employees", "/users", "/devices"]} component={EmployeesMap} />
              <Route exact path="/employees/:id" component={EmployeeData} />
              <Route exact path="/employees-info/:id" component={EmployeeInfoData} />
              <Route exact path={["/objectives", "/objectives/:id"]} component={ObjectivesBlock} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default withAppState(React.memo(Content));
