import {
  RESPONSE_CHOOSE_EMPLOYEE
} from '../actions/choosedEmployeeActions'

import { states } from './queriesStates'

const initialState = {
  dataState: states.NOT_REQUESTED,
  data: {},
}

const choosedEmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESPONSE_CHOOSE_EMPLOYEE: {
      return Object.assign(
        {},
        state,
        {
          dataState: states.READY,
          data: action.data
        }
      )
    }
    default:
      return state
  }
}

export default choosedEmployeeReducer
