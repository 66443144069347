import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  openSideBarAction as onOpenSideBarAction,
  closeSideBarAction as onCloseSideBarAction,
  openEmployeesSecondSideBar as onOpenEmployeesSecondSideBar,
  closeEmployeesSecondSideBar as onCloseEmployeesSecondSideBar,
  openDriversSecondSideBar as onCpenDriversSecondSideBar,
  closeDriversSecondSideBar as onCloseDriversSecondSideBar,
} from '../../actions/appStateActions'

const mapStateToProps = (state) => ({
  isSideBarOpen: get(state, ['appState', 'isSideBarOpen'], true),
  isEmployeesSecondSideBarOpen: get(state, ['appState', 'isEmployeesSecondSideBarOpen'], false),
  isDriversSecondBarOpen: get(state, ['appState', 'isDriversSecondBarOpen'], false),
});

const mapDispatchToProps = {
  onOpenSideBarAction,
  onCloseSideBarAction,
  onOpenEmployeesSecondSideBar,
  onCloseEmployeesSecondSideBar,
  onCpenDriversSecondSideBar,
  onCloseDriversSecondSideBar,
}

const withAppState = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withAppState }