import React from 'react';

import styles from './titleClosedBarWrapper.module.scss'

const TitleClosedBarWrapper = props => {
  return (
    <div className={styles.titleClosedBarWrapper}>
      {props.children}
    </div>
  )
}

export default React.memo(TitleClosedBarWrapper)
