import React from "react";

import DropDownItem from "./items/contols/dropDownItem";
import { withAuth } from "./containers/containerAuth";
import { withEmployeesLastState } from "./containers/containerEmployeeLastState";

const UserStateDropDownMenu = (props) => {
  const { onLogOut } = props;

  function clearDataState() {
    onLogOut();
    window.location.href = window.location.origin;
  }

  return (
    <DropDownItem>
      <button onClick={clearDataState}>Выйти</button>
    </DropDownItem>
  );
};

export default withAuth(withEmployeesLastState(React.memo(UserStateDropDownMenu)));
