import { connect } from "react-redux";
import get from "lodash/get";

import {
  getUsers as onGetUsers,
  selectUser as onSelectUser,
  addUserPermission as onAddUserPermission,
  deleteUserPermission as onDeleteUserPermission,
} from "../../actions/usersActions";

const mapStateToProps = (state) => ({
  users: {
    dataState: state.users.dataState,
    selectUserId: get(state, ["users", "selectUserId"], null),
    users: get(state, ["users", "users"], []),
  },
});

const mapDispatchToProps = {
  onGetUsers,
  onSelectUser,
  onAddUserPermission,
  onDeleteUserPermission,
};

const withUsers = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withUsers };
