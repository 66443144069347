import React from 'react'
import { withNewUser } from '../../../shared/containers/containerNewUser'
import Input from '../../../shared/items/contols/input'
import styles from './buttonPrimary.module.scss'

const NewUserForm = props => {
  const { onNewUser, mapStateToProps } = props

  var message = '';
  if (props.newUser.newUserState) {
    var newUserEmailInput = document.getElementById("newUserEmailInput");
    message = 'Ссылка для регистрации отправлена на ' + newUserEmailInput.value;
    newUserEmailInput.value = '';
  } else if (props.newUser.errors !== null && props.newUser.errors.email && props.newUser.errors.email !== null) {
    message = props.newUser.errors.email;
  }

  function handleChange(name) { };

  function handleBlur() {
    var newUserEmailInput = document.getElementById("newUserEmailInput");
    if (newUserEmailInput) {
      onNewUser(newUserEmailInput.value)
    }
  };

  return (
    <React.Fragment>
      <div style={{ padding: "1px" }}>
        <input className={styles.buttonPrimary}        
          type="button" onClick={handleBlur} 
          value="Новый пользователь" />

        <div style={{ overflow: "hidden", paddingRight: ".5em" }}>
          <div>
            <Input
              style={{ width: "40%" }}
              id="newUserEmailInput"
              type="text"
              handleChangeStore={handleChange} />
          </div>
        </div>
        <span style={{ color: props.newUser.newUserState ? "green" : "red", fontSize: "11px" }} >{message}</span>
      </div>

    </React.Fragment >
  )
}

export default withNewUser(React.memo(NewUserForm))