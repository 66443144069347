"use stric";

const CookieService = {
  getPermissions: function (cookieName) {
    const cookie = this.getCookie(cookieName);

    return cookie ? JSON.parse(cookie) : [];
  },
  getCookie: function (name) {
    // eslint-disable-next-line no-useless-escape
    const matches = document.cookie.match(
      new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
    );

    return matches ? decodeURIComponent(matches[1]) : null;
  },
  setCookie: function (name, value, options) {
    options = options || { path: "/" };

    let expires = options.expires;

    if (typeof expires === "number" && expires) {
      const d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = name + "=" + value;

    for (const propName in options) {
      updatedCookie += "; " + propName;
      let propValue = options[propName];
      if (propValue !== true) {
        updatedCookie += "=" + propValue;
      }
    }

    document.cookie = updatedCookie;
  },
  deleteCookie: function (name) {
    CookieService.setCookie(name, "", {
      expires: -1,
      path: "/",
    });
  },
  updateExpires: function (name, h) {
    const dateNow = new Date();
    dateNow.setHours(dateNow.getHours() + h);

    CookieService.setCookie(name, CookieService.getCookie(name), {
      expires: dateNow,
      path: "/",
    });
  },
};

export default CookieService;
