import React, { Component } from 'react';
import { withFilterEmployees } from '../../../shared/containers//containerFilterEmployees'
import { withEmployeesGroups } from '../../../shared/containers/containerEmployeesGroups'
import MultiSelects from '../../../shared/items/contols/multiSelect'
import FilterEmployeesItems from './static.filterEmployeesItems'
import { getEmployeesGroupsSelectObj } from '../../../shared/services/service.employeesGroups'

class EmployeesGroupsFilter extends Component {
  componentDidMount() {
    const { onGetEmployeesGroups, employeesGroups } = this.props

    if (employeesGroups.dataState === 'STATE_NOT_REQUESTED') {
      onGetEmployeesGroups()
    }
  }

  render() {
    const { onHandleChangeStore, filterEmployees, employeesGroups } = this.props
    const name = FilterEmployeesItems.groups.name

    return (
      <MultiSelects
        name={name}
        valueStore={filterEmployees.filterItems}
        options={getEmployeesGroupsSelectObj(employeesGroups[name])}
        handleChange={onHandleChangeStore.bind(this, name)}
      />
    )
  }
}

export default withEmployeesGroups(withFilterEmployees(EmployeesGroupsFilter));