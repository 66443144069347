import {
  SET_ROUTE_POINTS,
  SET_ROUTE,
  CLEAR_MAP_ROUTE,
} from './../actions/intelligenceTimeRangeMapActions'

const initialState = {
  points: [],
  route: [],
  features: [],
}

const intelligenceTimeRangeMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUTE_POINTS:
      return Object.assign(
        {},
        state,
        {
          points: action.data
        }
      )
    case SET_ROUTE:
      return Object.assign(
        {},
        state,
        {
          route: action.data,
          features: action.features,
        }
      )
    case CLEAR_MAP_ROUTE:
      return Object.assign(
        {},
        state,
        initialState
      )
    default:
      return state
  }
}

export default intelligenceTimeRangeMapReducer