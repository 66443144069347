import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import UsersList from './../users/usersList'

class UsersListWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      scrollToUsersList: 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrollToUsersList } = this.state
    const { scrollbars } = this.refs

    if (scrollToUsersList !== prevState.scrollToUsersList) {
      scrollbars.scrollTop(scrollToUsersList)
    }
  }

  handleScrollTo(top) {
    this.setState({
        scrollToUsersList: top
    })
  }

  render() {
    return <Scrollbars
      style={{ height: "100%" }}
      autoHide={true}
      ref="scrollbars"
    >
      <UsersList
        handleScrollTo={this.handleScrollTo.bind(this)}
      />

    </Scrollbars>
  }
}

export default UsersListWrapper


