import React from "react";

import FormItemsInlineGroup from "../../../shared/items/static/formItemsInlineGroup";
import FormItemsInline from "../../../shared/items/static/formItemsInline";
import FormItem from "../../../shared/items/static/formItem";
import InputDate from "../../../shared/items/contols/inputDate";
import InputTime from "../../../shared/items/contols/inputTime";
import CookieService from "../../../shared/services/cookiesService";

const TimeMomentIntellegence = (props) => {
  const { onHandleChangeStore, valueStore } = props;
  const logPermmisions = CookieService.getPermissions("permissionTN").includes(30001);

  return (
    <React.Fragment>
      <FormItemsInlineGroup>
        <FormItemsInline>
          <div style={{ width: "130px" }}>
            <FormItem title="Дата">
              <InputDate
                name="momentDate"
                onHandleChangeStore={onHandleChangeStore.bind(this, "momentDate")}
                valueStore={valueStore}
                maxDate={new Date()}
              />
            </FormItem>
          </div>
          <div style={{ width: "110px" }}>
            <FormItem title="Время">
              <InputTime
                name="momentTime"
                onHandleChangeStore={onHandleChangeStore.bind(this, "momentTime")}
                valueStore={valueStore}
              />
            </FormItem>
          </div>
        </FormItemsInline>
      </FormItemsInlineGroup>
      {logPermmisions ? (
        <div>
          <input type="checkbox" id="checkBox-locationNotProcessed" checked={valueStore.locationNotProcessed}></input>
          <label id="checkBox-locationNotProcessedLabel" for="checkBox-locationNotProcessed">Не обработанные координаты</label>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default React.memo(TimeMomentIntellegence);
