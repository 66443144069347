"use stric"

import api from './apiService.js'

var AccountService = {
  logIn: function (options) {
    return api.post('accounts/signIn', options)
  },
  logInDemo: function () {
    return api.post('accounts/SignInDemo')
  },
  logOut: function (options) {
    return api.post('account/logout', { params: options })
  },
  registration: function (options) {
    return api.post('accounts/registration', options)
  },
  getMail: function (options) {
    return api.get('accounts/' + options + '/registration')
  },
  sendMailRestore: function (options) {
    return api.post('accounts/SendMailForRestorePassword', options)
  },
  restorePassword: function (options) {
    return api.post('accounts/RestorePassword', options)
  },
}

export default AccountService