"use stric"

import api from './apiService.js'
import WithToken from './tokenService'
import configuration from '../../config.json'

var WorkPositionsService = {
  getWorkPositions: function () {
    return api.get('/WorkPositions', WithToken.headersToken(
      {},
      configuration.cookieName
    ))
  },
  createWorkPosition: function (options) {
    return api.post('WorkPositions', options, WithToken.headersToken(
      {},
      configuration.cookieName
    ))
  },
}

export default WorkPositionsService