import { employeeStatuses } from "./../../components/sections/employees/static.employeeStatuses";
import styles from "./../../shared/items/static/employeeStatusTitle.module.scss";

var statusService = {
    getStatusById: function (id) {
        switch (parseInt(id)) {
            case employeeStatuses.inSleep.id:
                return employeeStatuses.inSleep;

            case employeeStatuses.work.id:
                return employeeStatuses.work;

            case employeeStatuses.charging.id:
                return employeeStatuses.charging;

            case employeeStatuses.offSleep.id:
                return employeeStatuses.offSleep;

            case employeeStatuses.alarm.id:
                return employeeStatuses.alarm;

            case employeeStatuses.poweredFrom.id:
                return employeeStatuses.poweredFrom;

            default:
                return employeeStatuses.noData;
        }
    },
    getStyles: function (id) {
        switch (parseInt(id)) {
            case employeeStatuses.inSleep.id:
                return styles.employeeStatusTitle_inSleep;

            case employeeStatuses.work.id:
                return styles.employeeStatusTitle_work;

            case employeeStatuses.charging.id:
                return styles.employeeStatusTitle_charging;

            case employeeStatuses.offSleep.id:
                return styles.employeeStatusTitle_offSleep;

            case employeeStatuses.alarm.id:
                return styles.employeeStatusTitle_alarm;

            case employeeStatuses.poweredFrom.id:
                return styles.employeeStatusTitle_poweredFrom;

            default:
                return styles.employeeStatusTitle_noData;
        }
    }
}

export default statusService;