import React from 'react';

import styles from './counter.module.scss'

const Counter = props => {
  return (
    <span className={styles.counter}>{props.count}</span>
  )
}

export default React.memo(Counter)
