import UsersService from "../shared/services/usersService";
import { getUsers } from './usersActions'
import { setLogout } from "./authActions";

const REQUEST_USER_DELETE = "REQUEST_USER_DELETE";
const RESPONSE_USER_DELETE = "RESPONSE_USER_DELETE";
const REQUEST_USER_DELETE_FAILURE = "REQUEST_USER_DELETE_FAILURE";

const requestUserDeleteAction = () => {
    return {
        type: REQUEST_USER_DELETE,
    };
};

const responseUserDeleteAction = (data) => {
    return {
        type: RESPONSE_USER_DELETE,
        data,
    };
};

const requestUserDeletefailureAction = (errors) => {
    return {    
        type: REQUEST_USER_DELETE_FAILURE,
        errors,
    };
};

const userDelete = (id) => {
    return (dispatch) => {
        dispatch(requestUserDeleteAction());

        UsersService.deleteUser(id).then((result) => {
            dispatch(responseUserDeleteAction(result.data));
            dispatch(getUsers());
        }).catch((error) => {
            dispatch(requestUserDeletefailureAction(error.response));
            dispatch(setLogout());
        });
    };
};

export {
    userDelete,
    requestUserDeleteAction,
    responseUserDeleteAction,
    requestUserDeletefailureAction,
    REQUEST_USER_DELETE,
    RESPONSE_USER_DELETE,
    REQUEST_USER_DELETE_FAILURE,
};
