import {
  REQUEST_GET_DASHBOARDS,
  RESPONSE_GET_DASHBOARDS,
  REQUSET_GET_DASHBOARDS_FAILURE
} from '../actions/dashboardsActions'

import { states } from './queriesStates'

const initialState = {
  dataState: states.NOT_REQUESTED,
  data: [],
  errors: {},
}

const dashboardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_DASHBOARDS:
      return Object.assign(
        {},
        state,
        {
          dataState: states.REQUEST
        }
      )
    case RESPONSE_GET_DASHBOARDS:
      return Object.assign(
        {},
        state,
        {
          dataState: states.READY,
          data: action.data
        }
      )
    case REQUSET_GET_DASHBOARDS_FAILURE:
      return Object.assign(
        {},
        state,
        {
          dataState: states.FAILURE,
          errors: action.errors
        }
      )
    default:
      return state
  }
}

export default dashboardsReducer