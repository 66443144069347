import React from 'react';

import { withEmployeeMapState } from '../../../shared/containers/containerEmployeeMapState'

import EmployeeDevicesMain from './employeeDevicesMain'
import EmployeeShortMap from './employeeShortMap'
import EmployeesShortMapControls from './employeesShortMapControls'

import styles from './employeesMainDevicesWrapper.module.scss'

const EmployeesMainDevicesWrapper = props => {
  const { employeeMapState } = props
  const getMapStyles = () => {
    switch (employeeMapState.mapSize) {
      case 'baseSize':
        return styles.fullWidth
      case 'fullWidth':
        return styles.fullWidth
      case 'fullSize':
        return styles.fullSize
      default:
        return null;
    }
  }

  return (
    <div className={styles.employeesMainDevicesWrapper}>
      {/*   <div className={styles.container_defaultSide}>
        <EmployeeDevicesMain />
      </div> */}
      <EmployeeDevicesMain visibility={'hidden'} />
      <div className={getMapStyles()}>
        <EmployeeShortMap />
      </div>
      <div className={styles.container_controls}>
        <EmployeesShortMapControls />
      </div>
    </div>
  )
}

export default withEmployeeMapState(React.memo(EmployeesMainDevicesWrapper))
