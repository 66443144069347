import configuration from '../config.json'
import EmployeesService from '../shared/services/employeesService'
import CookieService from '../shared/services/cookiesService'
import { setLogout } from './authActions'

const REQUEST_SET_EMPLOYEE_BOOKMARK = 'REQUEST_SET_EMPLOYEE_BOOKMARK'
const RESPONSE_SET_EMPLOYEE_BOOKMARK = 'RESPONSE_SET_EMPLOYEE_BOOKMARK'
const REQUEST_SET_EMPLOYEE_BOOKMARK_FAILURE = 'REQUEST_SET_EMPLOYEE_BOOKMARK_FAILURE'

const requestSetEmployeeBookmarkAction = () => {
  return {
    type: REQUEST_SET_EMPLOYEE_BOOKMARK
  }
}

const responseSetEmployeeBookmarkAction = () => {
  return {
    type: RESPONSE_SET_EMPLOYEE_BOOKMARK
  }
}

const requestSetEmployeeBookmarkFailureAction = (errors) => {
  return {
    type: REQUEST_SET_EMPLOYEE_BOOKMARK_FAILURE,
    errors
  }
}

const setEmployeeBookmark = (id) => {
  return (dispatch) => {
    dispatch(requestSetEmployeeBookmarkAction())

    EmployeesService.setBookmark(id)
      .then((result) => {
        CookieService.updateExpires(configuration.cookieName, 12)

        dispatch(responseSetEmployeeBookmarkAction())
      })
      .catch((error) => {
        dispatch(requestSetEmployeeBookmarkFailureAction(error.response))

        dispatch(setLogout())
      })
  }
}

export {
  setEmployeeBookmark,
  requestSetEmployeeBookmarkAction,
  responseSetEmployeeBookmarkAction,
  requestSetEmployeeBookmarkFailureAction,
  REQUEST_SET_EMPLOYEE_BOOKMARK,
  RESPONSE_SET_EMPLOYEE_BOOKMARK,
  REQUEST_SET_EMPLOYEE_BOOKMARK_FAILURE
}