import {
  REQUEST_GET_TIMERANGE_INTELLEGENCE,
  RESPONSE_GET_TIMERANGE_INTELLEGENCE,
  REQUEST_GET_TIMERANGE_INTELLEGENCE_FAILURE,
  SET_TIMERANGE_DATES,
  CLEAR_TIMERANGE_INTELLEGENCE,
  REQUEST_EVENT_OBJECTIVES,
  RESPONSE_EVENT_OBJECTIVES,
  REQUEST_EVENT_OBJECTIVES_FAILURE,
} from "./../actions/timerangeIntellegenceActions";

import { states } from "./queriesStates";

const initialState = {
  dataState: states.NOT_REQUESTED,
  actionType: null,
  data: {},
  errors: {},
  features: {},
  distance: 0,
  timerangeDates: {
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
    currentDate: new Date(),
  },
  currentPointIndex: 0,
  groupStatus: false,
  eventObjectives: [],
};

const timerangeIntellegenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_TIMERANGE_INTELLEGENCE:
      return Object.assign({}, state, {
        dataState: states.REQUEST,
      });
    case REQUEST_EVENT_OBJECTIVES:
      return Object.assign({}, state, {
        dataState: states.REQUEST,
      });
    case RESPONSE_GET_TIMERANGE_INTELLEGENCE:
      return Object.assign({}, state, {
        dataState: states.READY,
        data: action.data,
        actionType: null,
        features: action.features,
        errors: {},
        groupStatus: action.groupStatus,
        distance: action.distance
      });
    case RESPONSE_EVENT_OBJECTIVES:
      return Object.assign({}, state, {
        dataState: states.READY,
        eventObjectives: action.eventObjectives,
        actionType: action.actionType,
      });
    case REQUEST_GET_TIMERANGE_INTELLEGENCE_FAILURE:
      return Object.assign({}, state, {
        dataState: states.FAILURE,
        errors: action.errors,
      });
    case REQUEST_EVENT_OBJECTIVES_FAILURE:
      return Object.assign({}, state, {
        dataState: states.FAILURE,
        errors: action.errors,
      });

    case SET_TIMERANGE_DATES:
      let nextState = state;
      nextState.timerangeDates = Object.assign({}, nextState.timerangeDates, action.data);

      return Object.assign({}, state, nextState);
    case CLEAR_TIMERANGE_INTELLEGENCE:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
};

export default timerangeIntellegenceReducer;
