import React from 'react';
import EmployeesSecondSideBar from '../secondSideBars/employeesSecondSideBar'
import SideBarInner from '../../../shared/sideBarInner'
import EmployeesSortPanel from '../employees/employeesSortPanel'
import EmployeesListWrapper from '../employees/employeesListWrapper'
import SideBar from '../../sides/sideBar'
import EmployeesTitleClosedBar from '../employees/employeesTitleClosedBar'
import EmployeesTitleBar from '../employees/employeesTitleBar'
import EmployeesPaginationSettingsPanel from '../employees/employeesPaginationSettingsPanel'

import styles from './employeesBar.module.scss'

const EmployeesBar = () => {
  return (
    <div className={styles.sideBarsWrapper}>
      <SideBar TitleClosed={<EmployeesTitleClosedBar />}>

        <div className={styles.employeesBar}>
          <SideBarInner>
            <EmployeesTitleBar />
          </SideBarInner>

          <SideBarInner>
            <EmployeesSortPanel />
          </SideBarInner>

          <SideBarInner>
            <EmployeesPaginationSettingsPanel />

            <div className={styles.container_employeesList}>
              <EmployeesListWrapper />
            </div>
          </SideBarInner>
        </div>
      </SideBar>

      <EmployeesSecondSideBar />
    </div>
  )
}

export default React.memo(EmployeesBar)
