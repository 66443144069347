import {
  REQUEST_OBJECTIVES,
  RESPONSE_OBJECTIVES,
  REQUEST_OBJECTIVES_FAILURE,
  REQUEST_CREATE_OBJECTIVE,
  RESPONSE_CREATE_OBJECTIVE,
  REQUEST_CREATE_OBJECTIVE_FAILURE,
  REQUEST_DELETE_OBJECTIVE,
  RESPONSE_DELETE_OBJECTIVE,
  REQUEST_DELETE_OBJECTIVE_FAILURE,
  REQUEST_UPDATE_OBJECTIVE,
  RESPONSE_UPDATE_OBJECTIVE,
  REQUEST_UPDATE_OBJECTIVE_FAILURE,
  REQUEST_CREATE_OBJECTIVE_SCHEDULE,
  RESPONSE_CREATE_OBJECTIVE_SCHEDULE,
  REQUEST_CREATE_OBJECTIVE_SCHEDULE_FAILURE,
  REQUEST_DELETE_OBJECTIVE_SCHEDULE,
  RESPONSE_DELETE_OBJECTIVE_SCHEDULE,
  REQUEST_DELETE_OBJECTIVE_SCHEDULE_FAILURE,
  REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE,
  RESPONSE_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE,
  REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE,
  REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE,
  RESPONSE_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE,
  REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE,
  REQUEST_UPDATE_OBJECTIVE_SCHEDULE,
  RESPONSE_UPDATE_OBJECTIVE_SCHEDULE,
  REQUEST_UPDATE_OBJECTIVE_SCHEDULE_FAILURE,
} from "../actions/objectiveActions";

import { states as queriesStates } from "./queriesStates";

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  actionType: null,
  count: 0,
  objectives: [],
  errors: null,
};

const employeeGroupsReducer = (state = initialState, action) => {
  if (
    action.type === REQUEST_OBJECTIVES ||
    action.type === REQUEST_CREATE_OBJECTIVE ||
    action.type === REQUEST_DELETE_OBJECTIVE ||
    action.type === REQUEST_UPDATE_OBJECTIVE ||
    action.type === REQUEST_CREATE_OBJECTIVE_SCHEDULE ||
    action.type === REQUEST_DELETE_OBJECTIVE_SCHEDULE ||
    action.type === REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE ||
    action.type === REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE ||
    action.type === REQUEST_UPDATE_OBJECTIVE_SCHEDULE
  ) {
    return Object.assign({}, state, {
      dataState: queriesStates.REQUEST,
      actionType: action.type,
      count: 0,
      objectiveType: 0,
      objectives: state.objectives,
      errors: null,
    });
  }

  if (
    action.type === RESPONSE_OBJECTIVES ||
    action.type === RESPONSE_CREATE_OBJECTIVE ||
    action.type === RESPONSE_DELETE_OBJECTIVE ||
    action.type === RESPONSE_UPDATE_OBJECTIVE ||
    action.type === RESPONSE_CREATE_OBJECTIVE_SCHEDULE ||
    action.type === RESPONSE_DELETE_OBJECTIVE_SCHEDULE ||
    action.type === RESPONSE_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE ||
    action.type === RESPONSE_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE ||
    action.type === RESPONSE_UPDATE_OBJECTIVE_SCHEDULE
  ) {
    return Object.assign({}, state, {
      dataState: queriesStates.READY,
      actionType: action.type,
      count: action.count,
      objectiveType: action.objectiveType,
      objectives: action.objectives ? action.objectives : state.objectives,
    });
  }

  if (
    action.type === REQUEST_OBJECTIVES_FAILURE ||
    action.type === REQUEST_CREATE_OBJECTIVE_FAILURE ||
    action.type === REQUEST_DELETE_OBJECTIVE_FAILURE ||
    action.type === REQUEST_UPDATE_OBJECTIVE_FAILURE ||
    action.type === REQUEST_CREATE_OBJECTIVE_SCHEDULE_FAILURE ||
    action.type === REQUEST_DELETE_OBJECTIVE_SCHEDULE_FAILURE ||
    action.type === REQUEST_ADD_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE ||
    action.type === REQUEST_DELETE_OBJECTIVE_SCHEDULE_EMPLOYEE_FAILURE ||
    action.type === REQUEST_UPDATE_OBJECTIVE_SCHEDULE_FAILURE
  ) {
    return Object.assign({}, state, {
      dataState: queriesStates.FAILURE,
      actionType: action.type,
      errors: action.errors,
    });
  }

  return state;
};

export default employeeGroupsReducer;
