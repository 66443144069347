import React from 'react';

import Dialog from '@material-ui/core/Dialog'
import Icon from './icon'

import './modalWrapper.scss'
import styles from './modalWrapper.module.scss'

const ModalWrapper = props => {
  const { open, onClose, title } = props

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={styles.modalWrapper}>
        <div className={styles.headBlock}>
          <span className={styles.title}>{title}</span>
          <span className={styles.closeBtn} onClick={onClose}>
            <Icon name="x" />
          </span>
        </div>
        {props.children}
      </div>
    </Dialog>
  )
}

export default React.memo(ModalWrapper)