import React from 'react'
import isArray from 'lodash/isArray'

import styles from './validateErrorMessage.module.scss'

const ValidateErrorMessage = props => {
  const { title } = props

  return isArray(title)
    ? title.map((item, index) => {
      return <span className={styles.validateErrorMessage} key={index}>{item}</span>
    })
    : <span className={styles.validateErrorMessage}>{title}</span>
}

export default React.memo(ValidateErrorMessage)