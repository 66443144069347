import React, { Component } from "react";

import { withUsers } from "../../../shared/containers/containerUsers";

import Preloader from "../../../shared/items/static/preloader";
import Notification from "../../../shared/items/static/notification";
import UsersListItems from "./usersListItems";

import { states } from "../../../reducers/queriesStates";

import "./usersList.module.scss";
import styles from "./usersList.module.scss";
import usersBarStyles from "./../sideBars/usersBar.module.scss";

class UsersList extends Component {
  componentDidMount() {
    const { onGetUsers } = this.props;
    const { dataState } = this.props.users;

    if (dataState === states.NOT_REQUESTED) {
      onGetUsers();
    }
  }

  componentDidUpdate(prevProps) {
    const { users, handleScrollTo } = this.props;

    if (prevProps.users.dataState !== states.READY && users.dataState === states.READY) {
      handleScrollTo(0);
    }
  }

  onSelectUser(id) {
    const { onSelectUser } = this.props;

    onSelectUser(id);
  }

  render() {
    const { handleScrollTo } = this.props;
    const { users, dataState } = this.props.users;

    if (dataState === states.READY) {
      return users.length > 0 ? (
        <div className={styles.usersList}>
          <UsersListItems
            stule={{ padding: "5px" }}
            data={users}
            handleScrollTo={handleScrollTo}
            onSelectUser={this.onSelectUser.bind(this)}
          />
        </div>
      ) : (
        <Notification type="warning">
          <p>Пользователи не найдены.</p>
        </Notification>
      );
    } else if (dataState !== states.READY) {
      return (
        <div className={usersBarStyles.container_preloader}>
          <Preloader />
        </div>
      );
    }
  }
}

export default withUsers(UsersList);
