import React from 'react'

import Icon from './icon'

import styles from './notification.module.scss'

const getStyles = (type) => {
  switch (type) {
    case 'error':
      return styles.notification_error
    case 'success':
      return styles.notification_success
    case 'warning':
      return styles.notification_warning
    default:
      return null
  }
}

const Notification = props => {
  const { type } = props

  return (
    <div className={getStyles(type)} >
      {
        type === 'warning' || type === 'error'
          ? <div className={styles.container_icon}><Icon name="warning" /></div>
          : null
      }
      {props.children}
    </div >
  )
}

export default React.memo(Notification)