import React from 'react'
import { withRegistration } from '../../shared/containers/containerRegistration'
import { states } from './../../reducers/queriesStates'

const EmailLable = props => {
    const { token, onGetEmail, dataState,data } = props

    if (dataState === states.NOT_REQUESTED) {
        onGetEmail(token);
    }

    return (
        <React.Fragment>
            <div style={{padding: '1px',  fontSize: '0.93rem'}}>Логин: <b>{data.email}</b></div>
        </React.Fragment>
    )
}

export default withRegistration(React.memo(EmailLable))