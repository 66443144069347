import React from 'react'

import { withRegistration } from '../../shared/containers/containerRegistration'
import LoginInput from '../../shared/items/contols/loginInput'
import styles from './registrationForm.module.scss'

const RegistrationForm = props => {
  const { onHandleChangeStore, registrationItems, errors } = props

  if (props.data.title !== null) {
    var companyInput = document.getElementById("companyNameInput");
    if (companyInput) {
      companyInput.value = props.data.title;
      companyInput.disabled = true;
    }
  }

  return (
    <React.Fragment>
      <div>
        <LoginInput
          name="password"
          type="password"
          store={registrationItems}
          handleChangeStore={onHandleChangeStore}
          placeholder="Создайте пароль"
          errors={errors}
        />
      </div>

      <div className={styles.password_container}>
        <LoginInput
          id="companyNameInput"
          name="companyName"
          type="text"
          store={registrationItems}
          handleChangeStore={onHandleChangeStore}
          placeholder="Введите имя компании"
          errors={errors}
        />
      </div>

    </React.Fragment>
  )
}

export default withRegistration(React.memo(RegistrationForm))