import React from 'react';

import TitleBarWrapper from '../../../shared/titleBarWrapper'
import FirstTitle from '../../../shared/items/static/firstTitle'
import EmployeesCounterWrapper from './employeesCounterWrapper'

import styles from './employeesTitleBar.module.scss'

const EMPLOYEES_BAR_TITLE = "Сотрудники"

const EmployeesTitleBar = () => {
  return (
    <TitleBarWrapper>
      <FirstTitle>
        {EMPLOYEES_BAR_TITLE}
        <div className={styles.container_titleCounter}>
          <EmployeesCounterWrapper />
        </div>
      </FirstTitle>
    </TitleBarWrapper>
  )
}

export default React.memo(EmployeesTitleBar)
