import configuration from '../config.json'
import WorkPositionsService from '../shared/services/workPositionsService'
import EmployeeWorkPositionsService from '../shared/services/employeeWorkPositionsService'
import CookieService from '../shared/services/cookiesService'
import { setLogout } from './authActions'

const RESPONSE_EMPLOYEES_WORK_POSITIONS = 'RESPONSE_EMPLOYEES_WORK_POSITIONS'
const REQUEST_EMPLOYEES_WORK_POSITIONS_FAILURE = 'REQUEST_EMPLOYEES_WORK_POSITIONS_FAILURE'

const responseGetEmployeesWorkPositionsAction = (data) => {
  return {
    type: RESPONSE_EMPLOYEES_WORK_POSITIONS,
    data,
  }
}

const requestGetEmployeesWorkPositionsfailureAction = (errors) => {
  return {
    type: REQUEST_EMPLOYEES_WORK_POSITIONS_FAILURE,
    errors,
  }
}

const getEmployeesWorkPositions = () => {
  return (dispatch) => {
    WorkPositionsService.getWorkPositions()
      .then((result) => {
        CookieService.updateExpires(configuration.cookieName, 12)

        dispatch(responseGetEmployeesWorkPositionsAction(result.data.workPositions))
      })
      .catch((error) => {
        dispatch(requestGetEmployeesWorkPositionsfailureAction(error.response))

        dispatch(setLogout())
      })
  }
}

const deleteEmployeeWorkPosition = (id) => {
  return (dispatch) => {
    EmployeeWorkPositionsService.deleteEmployeeWorkPosition(id)
      .then((result) => { })
      .catch((error) => { })
  }
}

const addEmployeeWorkPosition = (id, workPositionId) => {
  return (dispatch) => {
    EmployeeWorkPositionsService.addEmployeeWorkPosition(id, workPositionId)
      .then((result) => { })
      .catch((error) => { })
  }
}

export {
  getEmployeesWorkPositions,
  responseGetEmployeesWorkPositionsAction,
  requestGetEmployeesWorkPositionsfailureAction,
  deleteEmployeeWorkPosition,
  addEmployeeWorkPosition,
  RESPONSE_EMPLOYEES_WORK_POSITIONS,
  REQUEST_EMPLOYEES_WORK_POSITIONS_FAILURE,
}
