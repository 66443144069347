import {
  RESPONSE_EMPLOYEE_MULTIPE_SELECT,
  REQUEST_EMPLOYEE_MULTIPE_SELECT,
} from "../actions/employeeMultipleSelectAction";

import { states as queriesStates } from "./queriesStates";

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  id: null,
  selected: [],
};

const mployeeMultipleReduser = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_EMPLOYEE_MULTIPE_SELECT: {
      return Object.assign({}, state, {
        dataState: queriesStates.REQUEST,
        id: null,
        type: null,
        selected: [],
      });
    }
    case RESPONSE_EMPLOYEE_MULTIPE_SELECT: {
      return Object.assign({}, state, {
        dataState: queriesStates.READY,
        id: action.employeeMultipleSelect.id,
        type: action.employeeMultipleSelect.type,
        selected: action.employeeMultipleSelect.selected,
      });
    }
    default:
      return state;
  }
};

export default mployeeMultipleReduser;
