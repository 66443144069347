import React from 'react';

import ViewTableContentRow from "./viewTableContentRow"

import styles from './viewTableContent.module.scss'

const ViewTableContent = props => {
  const { contentItems, handleClickRow, responsive, styleClass } = props
  return (
    <div className={styles.viewTableContent}>
      {
        Array.isArray(contentItems)
          ? contentItems.map((item) => {
            return (
              <ViewTableContentRow
                items={item.items}
                key={item.index}
                isActive={item.isActive}
                index={item.index}
                handleClickRow={handleClickRow}
                responsive={!!responsive ? responsive : false}
                styleClass={styleClass}
              />
            )
          })
          : <ViewTableContentRow
            items={contentItems.items}
            key={contentItems.index}
            isActive={contentItems.isActive}
            index={contentItems.index}
            responsive={!!responsive ? responsive : false}
            styleClass={styleClass}
          />
      }
    </div>
  )
}

export default React.memo(ViewTableContent)