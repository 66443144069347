import configuration from "../config.json";
import EmployeesGroupsService from "../shared/services/employeesGroupsService";
import CookieService from "../shared/services/cookiesService";
import { setLogout } from "./authActions";

const RESPONSE_EMPLOYEES_GROUPS = "RESPONSE_EMPLOYEES_GROUPS";
const REQUEST_EMPLOYEES_GROUPS_FAILURE = "REQUEST_EMPLOYEES_GROUPS_FAILURE";

const responseGetEmployeesGroupsAction = (data) => {
  return {
    type: RESPONSE_EMPLOYEES_GROUPS,
    data,
  };
};

const requestGetEmployeesGroupsfailureAction = (errors) => {
  return {
    type: REQUEST_EMPLOYEES_GROUPS_FAILURE,
    errors,
  };
};

const getEmployeesGroups = () => {
  return (dispatch) => {
    EmployeesGroupsService.getEmployeesGroups()
      .then((result) => {
        CookieService.updateExpires(configuration.cookieName, 12)
        
        dispatch(responseGetEmployeesGroupsAction(result.data.groups))
      })
      .catch((error) => {
        dispatch(requestGetEmployeesGroupsfailureAction(error.response))

        dispatch(setLogout())
      })
  }
}

export {
  getEmployeesGroups,
  responseGetEmployeesGroupsAction,
  requestGetEmployeesGroupsfailureAction,
  RESPONSE_EMPLOYEES_GROUPS,
  REQUEST_EMPLOYEES_GROUPS_FAILURE,
};
