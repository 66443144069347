import UsersService from "../shared/services/usersService";

const REQUEST_USERS = "REQUEST_USERS";
const RESPONSE_USERS = "RESPONSE_USERS";
const REQUEST_USERS_FAILURE = "REQUEST_USERS_FAILURE";

const SELECT_USERS = "SELECT_USERS";

const requestGetUsersAction = () => {
  return {
    type: REQUEST_USERS,
  };
};

const responseGetUsersAction = (data) => {
  return {
    type: RESPONSE_USERS,
    data,
  };
};

const selectUserAction = (selectUserId) => {
  return {
    type: SELECT_USERS,
    selectUserId,
  };
};

const requestGetUsersfailureAction = (errors) => {
  return {
    type: REQUEST_USERS_FAILURE,
    errors,
  };
};

const getUsers = () => {
  return (dispatch) => {
    dispatch(requestGetUsersAction());

    UsersService.getUsers()
      .then((result) => {
        dispatch(responseGetUsersAction(result.data));
      })
      .catch((error) => {
        dispatch(requestGetUsersfailureAction(error.response));
      });
  };
};

const selectUser = (id) => {
  return (dispatch) => {
    selectUserAction(id);
    dispatch(selectUserAction(id));
  };
};

const addUserPermission = (userId, permissionId) => {
  return (dispatch) => {
    UsersService.createUserPermmision(userId, permissionId)
      .then((result) => {
        dispatch(getUsers);
      })
      .catch((error) => {
        dispatch(getUsers);
      })
      .finally(() => {
        selectUser(userId);
      });
  };
};

const deleteUserPermission = (userId, permissionId) => {
  return (dispatch) => {
    UsersService.deleteUserPermmision(userId, permissionId)
      .then((result) => {
        dispatch(getUsers);
      })
      .catch((error) => {
        dispatch(getUsers);
      })
      .finally(() => {
        selectUser(userId);
      });
  };
};


export {
  selectUser,
  getUsers,
  requestGetUsersAction,
  responseGetUsersAction,
  requestGetUsersfailureAction,
  addUserPermission,
  deleteUserPermission,
  REQUEST_USERS,
  RESPONSE_USERS,
  REQUEST_USERS_FAILURE,
  SELECT_USERS,
};
