import UsersService from "../shared/services/usersService";
import { setLogout } from "./authActions";
import { getUsers } from "./usersActions";

const REQUEST_USER_UPDATE = "REQUEST_USER_UPDATE";
const RESPONSE_USER_UPDATE = "RESPONSE_USER_UPDATE";
const REQUEST_USER_UPDATE_FAILURE = "REQUEST_USER_UPDATE_FAILURE";

const requestGetUserUpdateAction = () => {
  return {
    type: REQUEST_USER_UPDATE,
  };
};

const responseGetUserUpdateAction = (data) => {
  return {
    type: RESPONSE_USER_UPDATE,
    data,
  };
};

const requestGetUserUpdatefailureAction = (errors) => {
  return {
    type: REQUEST_USER_UPDATE_FAILURE,
    errors,
  };
};

const userUpdate = (id, isBlocked, title) => {
  return (dispatch) => {
    dispatch(requestGetUserUpdateAction());

    UsersService.putUser(id, isBlocked, title === null ? "" : title)
      .then((result) => {
        dispatch(responseGetUserUpdateAction(result.data));
        dispatch(getUsers());
      })
      .catch((error) => {
        dispatch(requestGetUserUpdatefailureAction(error.response));
      });
  };
};

export {
  userUpdate,
  requestGetUserUpdateAction,
  responseGetUserUpdateAction,
  requestGetUserUpdatefailureAction,
  REQUEST_USER_UPDATE,
  RESPONSE_USER_UPDATE,
  REQUEST_USER_UPDATE_FAILURE,
};
