import React from "react";
import L from "leaflet";
import PositionService from "./../services/positionsService";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import { employeeStatuses } from "../../components/sections/employees/static.employeeStatuses";
import { getMarker } from "./getMarker";
import "./mapMarker.scss";
import { v4 as uuid_v4 } from "uuid";

const getMarkerState = function (status) {
  var markerStatus;

  Object.values(employeeStatuses).map((item) => {
    if (status === item.id) {
      markerStatus = item.name;
    }
    return null;
  });

  return markerStatus;
};

const MapMarker = (props) => {
  const { position, status, number, poupupContent, workPosition, id, zIndex } = props;

  const markerIcon = L.divIcon({
    iconSize: [36, 40],
    iconAnchor: [17, 40],
    popupAnchor: [0, 0],
    className: "mapMarker " + getMarkerState(status),
    html: getMarker("inner", number !== undefined ? (workPosition !== undefined ? workPosition : number) : "SOS"),
  });

  function onClickMarker(e) {
    const latLng = L.latLng(e.latlng);
    PositionService.Address(latLng).then((response) => {
      const address = response.data.address ? "<b>Адрес:</b> " + response.data.address : "";
      const content = e.target.options.poupupContent ? e.target.options.poupupContent : "";

      e.target._popup = L.popup({ className: "black-popup" })
        .setLatLng(latLng)
        .setContent(content + address)
        .openOn(e.target._map);
    });
  }

  return (
    <ReactLeafletDriftMarker
      key={uuid_v4()}
      id={id}
      typeObject={"marker"}
      duration={10}
      position={position}
      icon={markerIcon}
      poupupContent={poupupContent}
      zIndexOffset={zIndex ? zIndex : 10}
      eventHandlers={{
        click: (e) => {
          onClickMarker(e);
        },
        mouseover: (e) => {
          const openmarker = function () {
            onClickMarker(e);
          };
          e.target._timeOutId = setTimeout(openmarker, 600);
        },
        mouseout: (e) => {
          clearTimeout(e.target._timeOutId);
        },
      }}
    ></ReactLeafletDriftMarker>
  );
};

export default React.memo(MapMarker);
