import DeviceService from "../shared/services/deviceService";

const REQUEST_DEVICES = "REQUEST_DEVICES";
const RESPONSE_DEVICES = "RESPONSE_DEVICES";
const REQUEST_DEVICES_FAILURE = "REQUEST_DEVICES_FAILURE";

const requestGetDevicesAction = () => {
  return {
    type: REQUEST_DEVICES,
  };
};

const responseGetDevicesAction = (data) => {
  return {
    type: RESPONSE_DEVICES,
    data,
  };
};

const requestGetDevicesfailureAction = (errors) => {
  return {
    type: REQUEST_DEVICES_FAILURE,
    errors,
  };
};

const getDevices = () => {
  return (dispatch) => {
    dispatch(requestGetDevicesAction());

    DeviceService.getDevices()
      .then((result) => {
        dispatch(responseGetDevicesAction(result.data));
      })
      .catch((error) => {
        dispatch(requestGetDevicesfailureAction(error));
      });
  };
};

const replaceDevices = (id, otherId) => {
  return (dispatch) => {
    DeviceService.replace(id, otherId)
      .then(() => {
        dispatch(getDevices());
      })
      .catch((error) => {
        dispatch(requestGetDevicesfailureAction(error));
      });
  };
};

export { getDevices, replaceDevices, REQUEST_DEVICES, RESPONSE_DEVICES, REQUEST_DEVICES_FAILURE };
