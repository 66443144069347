import React from "react";
import EmployeesMapLastStates from "../../../shared/map/employeesMapLastStates";
import styles from "./employeesMap.module.scss";

const EmployeesMap = () => {
  return (
    <div className={styles.employeesMap}>
      <EmployeesMapLastStates />
    </div>
  );
};

export default React.memo(EmployeesMap);
