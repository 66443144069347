import React from 'react'

import styles from './sideBarInner.module.scss'

const SideBarInner = props => {
  return (
    <div className={styles.sideBarInner}>
      {props.children}
    </div>
  )
}

export default React.memo(SideBarInner)