import React from 'react';

import styles from './contentInner.module.scss'

const ContentInner = props => {
  return (
    <div className={styles.container_contentInner}>
      {props.children}
    </div>
  )
}

export default React.memo(ContentInner)
