import React, { Component } from "react";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsIcon from "@mui/icons-material/Settings";
import { withAuth } from "../containers/containerAuth";
import { withNotifications } from "../containers/containerNotifactions";
import { states } from "../../reducers/queriesStates";
import NotificationsSettingItmems from "./notificationsSettingsItems";
import NotificationsItmems from "./notificationsItems";
import { Scrollbars } from "react-custom-scrollbars";
import EmployeesLastStateService from "./../../components/sections/employees/employeesLastStatesService";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import CircularProgress from "@material-ui/core/CircularProgress";

import "react-notifications/lib/notifications.css";
import "./notificationsHeader.scss";

class NotificationsHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: props.notifications.count,
      notifications: props.notifications.notifications,
      notificationTypes: props.notifications.notificationTypes,
      menuOpen: false,
      settigsOpen: false,
      onMouseEnter: this.mouseEnter.bind(this),
      onSettingsClick: this.settingsClick.bind(this),
      onReadNotification: this.readNotification.bind(this),
      onReadAllNotification: this.readAllNotification.bind(this),
      onClickMenu: this.menuClick.bind(this),
      proccessReadAll: false,
    };
  }

  componentDidMount() {
    const { notifications, onGetNotifications, onGetNotificationTypes } = this.props;

    const lasStateService = EmployeesLastStateService.getInstance();
    lasStateService.setCallBack("addNotification", this.addNotification.bind(this));

    if (notifications.dataState === states.NOT_REQUESTED) {
      onGetNotifications();
    }

    if (notifications.notificationTypes.length === 0) {
      onGetNotificationTypes();
    }
  }

  componentDidUpdate(prevProps) {
    const { notifications } = this.props;

    if (
      (prevProps.notifications.dataState !== states.READY && notifications.dataState === states.READY) ||
      prevProps.notifications.count !== notifications.count
    ) {
      const { menuOpen, settigsOpen } = this.state;

      this.setState({
        count: notifications.count,
        notifications: notifications.notifications,
        notificationTypes: notifications.notificationTypes,
        menuOpen,
        settigsOpen,
        proccessReadAll: false,
      });
    }
  }

  addNotification(notification) {
    this.createNotification(notification);

    const { onGetNotifications } = this.props;
    onGetNotifications();
  }

  unsubscribes(id) {
    const { onUnsubscribes } = this.props;
    const { notificationTypes } = this.state;

    onUnsubscribes(id);

    for (const type of notificationTypes) {
      if (type.id === id) {
        type.userSubscribed = false;
      }
    }
    this.setState({ menuOpen: true, settigsOpen: true, notificationTypes: notificationTypes });
  }

  subscribes(id) {
    const { onSubscribes } = this.props;
    const { notificationTypes } = this.state;

    onSubscribes(id);
    for (const type of notificationTypes) {
      if (type.id === id) {
        type.userSubscribed = true;
      }
    }
    this.setState({ menuOpen: true, settigsOpen: true, notificationTypes: notificationTypes });
  }

  mouseEnter() {
    this.setState({ menuOpen: true });
  }

  menuClick() {
    const { menuOpen } = this.state;

    this.setState({ menuOpen: !menuOpen });
  }

  settingsClick() {
    const { settigsOpen } = this.state;
    this.setState({ settigsOpen: !settigsOpen });
  }

  readNotification(id) {
    const { onReadNotification } = this.props;

    onReadNotification(id);
  }

  readAllNotification() {
    const { onReadAllNotification } = this.props;

    onReadAllNotification();
    this.setState({ proccessReadAll: true });
  }

  createNotification = (notification) => {
    switch (notification.level) {
      case 1:
        NotificationManager.success(notification.text, notification.title, 0);
        break;
      case 2:
        NotificationManager.warning(notification.text, notification.title, 0);
        break;
      case 3:
        NotificationManager.error(notification.text, notification.title, 0);
        break;
      default:
        NotificationManager.info(notification.text, notification.title, 0);
        break;
    }
  };

  render() {
    const {
      count,
      notifications,
      notificationTypes,
      menuOpen,
      settigsOpen,
      onClickMenu,
      onSettingsClick,
      onReadNotification,
      onReadAllNotification,
      proccessReadAll,
    } = this.state;

    return (
      <div>
        <div className={"notificationHeder_container"}>
          <div onClick={onClickMenu}>
            {count > 0 ? <div className={"notificationHeder_number"}>{count}</div> : null}

            <NotificationsNoneIcon style={{ fontSize: "2.1rem" }} />
          </div>
          {menuOpen ? (
            <div className={"notificationMenu"}>
              <Paper className={"notificationHeder_menu"}>
                <MenuList>
                  <MenuItem className={"notificationHeder_settings"} onClick={onSettingsClick}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    {settigsOpen ? (
                      <Paper className={"notificationHeder_settings_list"}>
                        <MenuList>
                          <NotificationsSettingItmems
                            notificationTypes={notificationTypes}
                            unsubscribes={this.unsubscribes.bind(this)}
                            subscribes={this.subscribes.bind(this)}
                          />
                        </MenuList>
                      </Paper>
                    ) : (
                      <span>Настройки</span>
                    )}
                  </MenuItem>
                  <Divider />
                  <Scrollbars style={{ minHeight: "350px" }} autoHide={true}>
                    <NotificationsItmems notifications={notifications} onReadNotification={onReadNotification} />
                  </Scrollbars>
                  {notifications.length > 0 ? (
                    <div>
                      <Divider />
                      <div className={"notificationsReadAll_container"}>
                        <span>Прочитать все уведомления </span>
                        <div
                          className={"notificationsReadAll_icon"}
                          onClick={(e) => {
                            onReadAllNotification();
                          }}
                        >
                          {proccessReadAll ? (
                            <CircularProgress size="20px" style={{ marginLeft: "8px" }} />
                          ) : (
                            <MarkEmailReadIcon />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </MenuList>
              </Paper>
            </div>
          ) : null}
        </div>

        <NotificationContainer />
      </div>
    );
  }
}

export default withNotifications(withAuth(NotificationsHeader));
