import React, { Component } from 'react'

import Logo from '../../shared/logo'
import SignInFooter from '../../shared/footer/signinFooter'
import RegistrationForm from './registrationForm'
import EmailLable from './emailLable'

import ButtonRegistrationWrapper from './buttonRegistrationWrapper'
import RegistrationValidateResuld from './registrationValidateResuld'

import styles from './registration.module.scss'
class Registration extends Component {

  constructor(props) {
    super(props);

    this.state = {
      token: window.location.href.split('/')[4]
    }
  }

  render() {
    return (
      <div className={styles.registrationWrapper}>
        <div className={styles.registrationForm}>
          <div className={styles.logo_container}>
            <Logo />
          </div>
          <EmailLable token={this.state.token} />
          <RegistrationForm />

          <div className={styles.button_container}>
            <ButtonRegistrationWrapper token={this.state.token} />
          </div>

          <RegistrationValidateResuld />
        </div>

        <SignInFooter />
      </div>
    )
  }
}

export default Registration