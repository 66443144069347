"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";
import configuration from "../../config.json";

const DeviceService = {
  getDevices: function () {
    return api.get("Devices", WithToken.headersToken({}, configuration.cookieName));
  },
  getLog: function (options) {
    return api.get(
      `Devices/${options.imei}/Log` +
        `?dateStart=${options.dateStart}` +
        `&dateEnd=${options.dateEnd}` +
        `&byPacketTime=${options.byPacketTime}`,
      WithToken.headersToken({}, configuration.cookieName)
    );
  },
  update: function (options) {
    return api.put(`Devices/${options.id}`, options, WithToken.headersToken({}, configuration.cookieName));
  },
  command: function (options) {
    return api.put(`Devices/Command`, options, WithToken.headersToken({}, configuration.cookieName));
  },
  transfer: function (options) {
    return api.put(`Devices/Transfer`, options, WithToken.headersToken({}, configuration.cookieName));
  },
  getInfo: function (options) {
    return api.get(`Devices/${options.imei}/Information`, WithToken.headersToken({}, configuration.cookieName));
  },
  replace: function (id, otherId) {
    return api.put(
      `Devices/${id}/Replace?otherId=${otherId}`,
      {},
      WithToken.headersToken({}, configuration.cookieName)
    );
  },
};

export default DeviceService;
