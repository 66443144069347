import {
  REQUEST_EMPLOYEES_LAST_STATES,
  RESPONSE_EMPLOYEES_LAST_STATES,
  REQUEST_EMPLOYEES_LAST_STATES_FAILURE,
  RESET_REQUEST_EMPLOYEES_LAST_STATES,
} from "../actions/employeeLastStateAction";

import { states as queriesStates } from "./queriesStates";

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  employees: [],
};

const employeeLastStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_REQUEST_EMPLOYEES_LAST_STATES: {
      return Object.assign({}, state, initialState);
    }
    case REQUEST_EMPLOYEES_LAST_STATES: {
      return Object.assign({}, state, {
        dataState: queriesStates.REQUEST,
        errors: [],
      });
    }
    case RESPONSE_EMPLOYEES_LAST_STATES: {
      return Object.assign({}, state, {
        dataState: queriesStates.READY,
        employees: action.lastStates.employees,
      });
    }
    case REQUEST_EMPLOYEES_LAST_STATES_FAILURE: {
      return Object.assign({}, state, {
        dataState: queriesStates.FAILURE,
        errors: action.errors,
      });
    }
    default:
      return state;
  }
};

export default employeeLastStateReducer;
