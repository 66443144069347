import React from 'react';

import { withAppState } from '../../shared/containers/containerAppState'

import ButtonIcon from '../../shared/items/contols/buttonIcon'
import FirstTitle from '../../shared/items/static/firstTitle'
import Icon from '../../shared/items/static/icon'

import styles from './secondSideBarHeader.module.scss'

const SecondSideBarHeader = props => {
  const { title, handleClose, isEmployeesSecondSideBarOpen } = props

  return isEmployeesSecondSideBarOpen
    ? <div className={styles.container_secodSideBarTitle}>
      <div className={styles.container_secodSideBarTitleText}>
        <FirstTitle>{title}</FirstTitle>
      </div>

      {props.children}

      <div className={styles.container_closeSecodSideBar}>
        <ButtonIcon handleClick={handleClose}><Icon name="x" /></ButtonIcon>
      </div>
    </div>
    : null
}

export default withAppState(React.memo(SecondSideBarHeader))
