import NotificationServices from "../shared/services/notificationService";

const REQUEST_NOTIFICATION_TYPES = "REQUEST_NOTIFICATION_TYPES";
const RESPONSE_NOTIFICATION_TYPES = "RESPONSE_NOTIFICATION_TYPES";
const REQUES_NOTIFICATION_TYPES_FAILURE = "REQUES_NOTIFICATION_TYPES_FAILURE";

const REQUEST_NOTIFICATIONS = "REQUEST_NOTIFICATIONS";
const RESPONSE_NOTIFICATIONS = "RESPONSE_NOTIFICATIONS";
const REQUES_NOTIFICATIONS_FAILURE = "REQUES_NOTIFICATIONS_FAILURE";

const action = (type) => {
  return {
    type,
  };
};

const notificationTypes = (type, data) => {
  return {
    type,
    notificationTypes: data.notificationTypes,
  };
};

const notifications = (type, data) => {
  return {
    type,
    count: data.count,
    notifications: data.notifications,
  };
};

const getNotifications = () => {
  return (dispatch) => {
    dispatch(action(REQUEST_NOTIFICATIONS));

    NotificationServices.get()
      .then((response) => {
        dispatch(notifications(RESPONSE_NOTIFICATIONS, response.data));
      })
      .catch(() => {
        dispatch(action(REQUES_NOTIFICATIONS_FAILURE));
      });
  };
};

const readNotification = (id) => {
  return (dispatch) => {
    NotificationServices.read(id)
      .then(() => {
        dispatch(getNotifications());
      })
      .catch(() => {
        dispatch(action(REQUES_NOTIFICATIONS_FAILURE));
      });
  };
};

const readAllNotification = () => {
  return (dispatch) => {
    NotificationServices.readAll()
      .then(() => {
        dispatch(getNotifications());
      })
      .catch(() => {
        dispatch(action(REQUES_NOTIFICATIONS_FAILURE));
      });
  };
};

const getNotificationTypes = (getNotifi = false) => {
  return (dispatch) => {
    dispatch(action(REQUEST_NOTIFICATION_TYPES));

    NotificationServices.getTypes()
      .then((response) => {
        dispatch(notificationTypes(RESPONSE_NOTIFICATION_TYPES, response.data));
      //  if (getNotifi) dispatch(getNotifications());
      })
      .catch(() => {
        dispatch(action(REQUES_NOTIFICATION_TYPES_FAILURE));
      });
  };
};

const unsubscribes = (id) => {
  return (dispatch) => {
    NotificationServices.unsubscribes(id)
      .then(() => {
   //     dispatch(getNotificationTypes());
      })
      .catch(() => {});
  };
};

const subscribes = (id) => {
  return (dispatch) => {
    NotificationServices.subscribes(id)
      .then(() => {
     //   dispatch(getNotificationTypes());
      })
      .catch(() => {});
  };
};

export {
  getNotificationTypes,
  unsubscribes,
  subscribes,
  getNotifications,
  readNotification,
  readAllNotification,
  REQUEST_NOTIFICATION_TYPES,
  RESPONSE_NOTIFICATION_TYPES,
  REQUES_NOTIFICATION_TYPES_FAILURE,
  REQUEST_NOTIFICATIONS,
  RESPONSE_NOTIFICATIONS,
  REQUES_NOTIFICATIONS_FAILURE,
};
