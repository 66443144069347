import React from 'react';

import Icon from './items/static/icon'

import './collapsibleHeader.scss'

const CollapsibleHeader = props => {
  const { title } = props

  return (
    <div className="collapsibleHeader">
      <span>{title}</span>
      <Icon name="arr" />
    </div>
  )
}

export default React.memo(CollapsibleHeader)
