import React from 'react';

import Icon from '../../../shared/items/static/icon'

import styles from './deviceItem.module.scss'

const DeviceItem = props => {
  const { title, value, iconName } = props

  return (
    <div className={styles.deviceItem}>
      <span className={styles.deviceTitle}>{title}</span>
      <span className={styles.deviceValue}>{value}</span>
      {
        !!iconName
          ? <div className={styles.container_iconDevice}><Icon name={iconName} /></div>
          : null
      }
    </div>
  )
}

export default React.memo(DeviceItem)
