import React, { Component } from "react";
import "./objectivesBlock.modeule.scss";

class TabButton extends Component {
  render() {
    const { title, currentView, checkedView, handlerClick } = this.props;
    return (
      <button
        className={"tablinks" + (currentView === checkedView ? " checkedView" : "")}
        onClick={() => {
          handlerClick(currentView);
        }}
      >
        {title}
      </button>
    );
  }
}

export default TabButton;
