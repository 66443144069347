import React, { Component } from "react";
import UsersListItem from "./usersListItem";
import styles from "./usersListSelectedItem.module.scss";
import usersListStyles from "./usersList.module.scss";

class UsersListSelectedItem extends Component {
  componentDidMount() {
    const { handleScrollTo } = this.props;

    handleScrollTo(document.querySelector(`.${usersListStyles.container_usersListItem}.selected`).offsetTop);
  }

  render() {
    const { data } = this.props;

    return (
      <div className={styles.usersListSelectedItem}>
        <div className={styles.container_usersListItemWrapper}>
          <UsersListItem data={data} />
        </div>
      </div>
    );
  }
}

export default UsersListSelectedItem;
