import React from "react";

import styles from "./viewTableContentRow.module.scss";
import "./viewTableContentRow.scss";

const ViewTableContentRow = (props) => {
  const { isActive, items, responsive, handleClickRow, index, styleClass } = props;
  const style = !!responsive
    ? styles.ontainer_tableContentRowItem_responsive
    : styleClass !== undefined
    ? styleClass
    : styles.container_tableContentRowItem;

  return (
    <div className={"tableContentRowWrapper"}>
      <div
        className={isActive ? styles.viewTableContentRow_active : styles.viewTableContentRow}
        onClick={!!handleClickRow ? handleClickRow.bind(this, index) : null}
      >
        {items.map((item, itemIndex) => {
          return (
            <div className={style} key={itemIndex}>
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(ViewTableContentRow);
