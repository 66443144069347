import React from "react";
import { Popup, Polyline } from "react-leaflet";
import * as GeometryUtil from "leaflet-geometryutil";
import { withChoosedEmployee } from "./../../../shared/containers/containerChoosedEmployee";
import { withIntellegenceTimeRangePlayer } from "../../../shared/containers/containerIntellegenceTimeRangePlayer";
import { withIntelligenceTimeRangeMap } from "../../../shared/containers/containerIntelligenceTimeRangeMap";
import { withEmployeesLastState } from "../../../shared/containers/containerEmployeeLastState";
import { withEmployeeGeoZones } from "../../../shared/containers/containerEmployeeGeoZones";
import MapWrapper from "../../../shared/map/mapWrapper";
import MapMarker from "../../../shared/map/mapMarker";
import cloneDeep from "lodash/cloneDeep";
import * as L from "leaflet";
import GeoJsonBuilding from "../../../shared/map/geoJsonBuilding";
import EmployeeMapMoment from "./employeeMapMoment";
import { employeeStatuses } from "../../../components/sections/employees/static.employeeStatuses";
import dateTime from "../../../shared/services/date.service";
import statusService from "../../../shared/services/statusServise";
import PositionService from "../../../shared/services/positionsService";
import AntPath from "./AntPath";
import { v4 as uuid_v4 } from "uuid";
import MarkerClusterGroupWrapper from "./MarkerClusterGroupWrapper";
import mapLayerControl from "../../../shared/map/mapLayerControl";
import { func } from "prop-types";

let notProcessed = false;

class EmployeeMapTimeRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLayerControl: mapLayerControl,
      mapKey: uuid_v4(),
    };
  }

  layerControlChange(mapLayerControl) {
    this.setState({
      mapLayerControl: cloneDeep(mapLayerControl),
      mapKey: uuid_v4(),
    });
  }

  getRowIndex(data) {
    const { currentPointIndex } = this.props;
    let pointId;

    data.map((item) => {
      if (!!item.startIndex || item.startIndex === 0) {
        if (currentPointIndex >= item.startIndex && currentPointIndex <= item.finishIndex) {
          pointId = item.id;
        }
      } else {
        if (currentPointIndex === item.index) {
          pointId = item.id;
        }
      }

      return null;
    });

    return pointId;
  }

  getCircleIndex(location, pointsForCircles) {
    const index = pointsForCircles.findIndex((item) => {
      return item.location[0] === location[0] && item.location[1] === location[1];
    });

    return index;
  }

  polylineClick(e, points, locationNotProcessed = false) {
    const latLngs = e.target.getLatLngs();
    let content = null;
    let segment = this.getSegment(e.target._map, e.latlng, latLngs);

    if (segment.distance !== -1) {
      let coordinate = null;
      let distanceA = GeometryUtil.distance(e.target._map, e.latlng, latLngs[segment.indexA]);
      let distanceB = GeometryUtil.distance(e.target._map, e.latlng, latLngs[segment.indexB]);
      if (distanceA <= distanceB) {
        coordinate = latLngs[segment.indexA];
      } else {
        coordinate = latLngs[segment.indexB];
      }

      e.target._popup.setLatLng(coordinate);
      let pointClickPopup = this.findPointForCoordinate(coordinate, points, locationNotProcessed);
      if (pointClickPopup.status) {
        const status = statusService.getStatusById(pointClickPopup.status);

        content =
          '<span style="text-align: center;" class=\'' +
          statusService.getStyles(pointClickPopup.status) +
          "'>" +
          status.title +
          "</span> ";
        content += "<span>" + dateTime.toFormat3(dateTime.toLocal(pointClickPopup.date)) + "</span>";
      }
    }

    if (content) {
      e.target._popup.setContent(content);
      e.target._popup.update();
      if (!locationNotProcessed) {
        PositionService.Address(e.latlng).then((response) => {
          const address = response.data.address ? response.data.address : "";
          content = e.target._popup.getContent();
          if (address !== "") {
            content += "<br/><span> " + address + "</span>";
          }

          e.target._popup.setContent(content);
          e.target._popup.update();
        });
      }
    } else {
      e.target._map.closePopup();
    }
  }

  getSegment(map, latlng, latLngs) {
    let i = 0;
    const segment = {
      indexA: 0,
      indexB: 1,
      distance: -1,
    };
    while (latLngs[i + 1]) {
      const distance = GeometryUtil.distanceSegment(map, latlng, latLngs[i], latLngs[i + 1]);
      if (segment.distance === -1 || distance < segment.distance) {
        segment.indexA = i;
        segment.indexB = i + 1;
        segment.distance = distance;
      }
      i++;
    }

    return segment;
  }

  findPointForCoordinate(latlng, points, locationNotProcessed) {
    let point = null;

    function newFunction(item, locationNotProcessed) {
      if (locationNotProcessed ? item.locationNotProcessed : item.location) {
        const location = locationNotProcessed ? item.locationNotProcessed : item.location;

        if (point === null && compareLocation(location, locationNotProcessed)) {
          point = item;
        }
      } else if (item.lat) {
        if (point === null && item.lat === latlng.lat && item.lng === latlng.lng) {
          point = item;
        }
      }

      function compareLocation(location, locationNotProcessed) {
        if (!locationNotProcessed) {
          return location[0] === latlng.lat && location[1] === latlng.lng;
        } else {
          return location.lat === latlng.lat && location.lng === latlng.lng;
        }
      }
    }

    for (const item of points) {
      newFunction(item, locationNotProcessed);
    }

    return point;
  }

  getAntPathOptions(isBreak) {
    return {
      delay: 2000,
      dashArray: [30, 200],
      weight: 3,
      color: isBreak ? "red" : "#0080FF",
      pulseColor: "#FFFFFF",
      hardwareAccelerated: false,
      fillOpacity: 1,
      opacity: isBreak ? 0.45 : 1,
      stroke: true,
    };
  }

  getAntPaths(route, points) {
    const antPaths = [];

    let positions = [];
    let lastLatLng = null;
    let keyItem = "";
    // eslint-disable-next-line array-callback-return
    route.map((routePoint) => {
      const latLng = L.latLng(routePoint[0], routePoint[1]);
      if (positions.length === 0) {
        lastLatLng = L.latLng(routePoint[0], routePoint[1]);
        positions.push(lastLatLng);

        return null;
      }

      const distance = lastLatLng.distanceTo(latLng);

      keyItem = distance + "_" + positions[0] + "_" + latLng;
      if (distance > 5000) {
        antPaths.push(this.getAntPath(keyItem, positions, this.getAntPathOptions(false), points));
        antPaths.push(this.getAntPath("isBreak" + keyItem, [lastLatLng, latLng], this.getAntPathOptions(true), points));
        positions = [];
      }

      positions.push(latLng);
      lastLatLng = latLng;
    });

    if (positions.length > 1) {
      antPaths.push(this.getAntPath(uuid_v4(), positions, this.getAntPathOptions(false), points));
    }

    return antPaths;
  }

  getPolyline(positions, points) {
    return positions ? (
      <Polyline
        positions={positions}
        eventHandlers={{
          click: (e) => {
            this.polylineClick(e, points, true);
          },
        }}
        color="red"
        weight={2}
        opacity={0.7}
      >
        <Popup className={"black-popup"} />
      </Polyline>
    ) : null;
  }

  getAntPath(key, positions, options, points) {
    return (
      <li key={"li" + key}>
        {
          <AntPath
            key={key}
            positions={positions}
            options={options}
            eventHandlers={{
              click: (e) => {
                this.polylineClick(e, points);
              },
            }}
          >
            <Popup key={"li_AntPath_popup" + key} className={"black-popup"} />
          </AntPath>
        }
      </li>
    );
  }

  getBounds(route) {
    if (route === undefined || route.length === 0) {
      return [
        [54.85, 28.55],
        [51.9, 25.55],
      ];
    }

    var lats = [];
    var longs = [];

    route.map((point) => {
      lats.push(point[0]);
      longs.push(point[1]);

      return null;
    });

    const value = 100;

    const bounds = [
      [Math.round(Math.max.apply(Math, lats) * value) / value, Math.round(Math.min.apply(Math, longs) * value) / value],
      [Math.round(Math.min.apply(Math, lats) * value) / value, Math.round(Math.max.apply(Math, longs) * value) / value],
    ];

    bounds[0][0] = bounds[0][0] + 0.01;
    bounds[1][0] = bounds[1][0] - 0.01;

    bounds[0][1] = bounds[0][1] - 0.01;
    bounds[1][1] = bounds[1][1] + 0.01;

    return bounds;
  }

  EmployeeMap() {
    const { choosedEmployee, intelligenceTimeRangeMap, notProcessedRefresh, employeeGeoZones } = this.props;

    const { points, route, features } = intelligenceTimeRangeMap;
    const locationNotProcessed = document.getElementById("checkBox-locationNotProcessed");

    const onClickNotProcessed = (e) => {
      if (notProcessed !== e.target.checked) {
        notProcessedRefresh.collback("rangeType", notProcessedRefresh.name);
      }
    };

    notProcessed = locationNotProcessed && locationNotProcessed.checked;
    if (locationNotProcessed) {
      locationNotProcessed.removeEventListener("click", onClickNotProcessed);
      locationNotProcessed.addEventListener("click", onClickNotProcessed);
    }

    const notProcessedPoints = notProcessed
      ? points
          .map((point) => {
            return point.locationNotProcessed;
          })
          .filter((point) => {
            return point.lat !== 0 && point.lng !== 0;
          })
      : null;

    const currentPoint = points[this.getRowIndex(points)];
    const buildings = features.map((item) => <GeoJsonBuilding key={uuid_v4()} feature={item} />);
    let latLngs = [];
    var pointsForCircles = [];

    points.map((item) => {
      if (item.undiferendCoordinate) {
        if (this.getCircleIndex(item.location, pointsForCircles) === -1) {
          pointsForCircles.push({
            id: item.id,
            location: item.location,
          });
        }
      }

      latLngs.push(new L.latLng(item.location[0], item.location[1]));
      return null;
    });

    if (latLngs.length > 0) {
      mapLayerControl.bounds = L.polyline(latLngs).getBounds();
      mapLayerControl.updateBound = true;
    }

    const position = () => {
      if (points.length < 1) {
        return currentPoint.location;
      }

      if (points[0].location[0] === currentPoint.location[0] && points[0].location[1] === currentPoint.location[1]) {
        return points[points.length - 1].location;
      }

      return currentPoint.location;
    };

    return (
      <MapWrapper
        position={position()}
        key={this.state.mapKey}
        employeeGeoZones={employeeGeoZones.employeeGeoZones}
        employeeId={choosedEmployee.employeeId}
        mapLayerControl={this.state.mapLayerControl}
        onlayerControlChange={(mapLayerControl) => this.layerControlChange(mapLayerControl)}
      >
        <MapMarker
          id={choosedEmployee.employeeId}
          position={currentPoint.location}
          status={currentPoint.status}
          number={choosedEmployee.number}
          zIndex={1000}
        />
        , (
        {points.length > 1 ? (
          <MapMarker
            id={choosedEmployee.employeeId + "_start"}
            position={points[0].location}
            status={employeeStatuses.noData.id}
            poupupContent={"Начало маршрута в: " + dateTime.toFormat3(dateTime.toLocal(points[0].date)) + "<br/>"}
            zIndex={900}
            number={"A"}
          />
        ) : null}
        ,
        {points.length > 1 ? (
          <MapMarker
            key={uuid_v4()}
            id={choosedEmployee.employeeId + "_finish"}
            position={points[points.length - 1].location}
            status={employeeStatuses.noData.id}
            poupupContent={
              "Конец маршрута в: " + dateTime.toFormat3(dateTime.toLocal(points[points.length - 1].date)) + "<br/>"
            }
            zIndex={900}
            number={"B"}
          />
        ) : null}
        ,
        <MarkerClusterGroupWrapper
          zIndexOffset={200}
          maxClusterRadius={45}
          points={points}
          markerType={"stopAndJamming"}
        />
        <MarkerClusterGroupWrapper zIndexOffset={200} maxClusterRadius={50} points={points} markerType={"alarm"} />
        <MarkerClusterGroupWrapper zIndexOffset={200} maxClusterRadius={25} points={points} markerType={"button"} />
        <div>
          {!!route ? this.getPolyline(notProcessedPoints, points) : null}
          {!!route ? <ul>{this.getAntPaths(route, points)}</ul> : null},{buildings}
        </div>
      </MapWrapper>
    );
  }

  render() {
    const {
      choosedEmployee,
      intelligenceTimeRangeMap,
      lastStates,
      onGetEmployeesLastStates,
      employeeGeoZones,
      onGetEmployeeGeozones,
    } = this.props;

    if (!choosedEmployee.employeeId && lastStates.dataState === "STATE_NOT_REQUESTED") {
      onGetEmployeesLastStates();
    }

    if (employeeGeoZones.dataState === "STATE_NOT_REQUESTED") {
      onGetEmployeeGeozones();
    }

    const { points } = intelligenceTimeRangeMap;

    return points.length > 0 ? this.EmployeeMap() : <EmployeeMapMoment />;
  }
}

export default withIntelligenceTimeRangeMap(
  withIntellegenceTimeRangePlayer(
    withEmployeeGeoZones(withChoosedEmployee(withEmployeesLastState(React.memo(EmployeeMapTimeRange))))
  )
);
