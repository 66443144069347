import { connect } from "react-redux";
import get from "lodash/get";

import { multipleSelect as onEmployeeMultipleSelect } from "../../actions/employeeMultipleSelectAction";

const mapStateToProps = (state) => ({
  employeeMultipleSelect: {
    dataState: state.employeeMultipleSelect.dataState,
    id: get(state, ["employeeMultipleSelect", "id"], null),
    type: get(state, ["employeeMultipleSelect", "type"], null),
    selected: get(state, ["employeeMultipleSelect", "selected"], []),
  },
});

const mapDispatchToProps = {
  onEmployeeMultipleSelect,
};

const withEmployeeMultipleSelect = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withEmployeeMultipleSelect };
