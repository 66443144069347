import React, { Component } from 'react';

import { withFilterEmployees } from '../../../shared/containers/containerFilterEmployees'
import { withEmployeesWorkPositions } from '../../../shared/containers/containerEmployeesWorkPositions'

import MultiSelects from '../../../shared/items/contols/multiSelect'
import FilterEmployeesItems from './static.filterEmployeesItems'
import { getEmployeesWorkPositionsSelectObj } from '../../../shared/services/service.employeesWorkPositions'

class EmployeesWorkPositionsFilter extends Component {
  componentDidMount() {
    const { onGetEmployeesWorkPositions, employeesWorkPositions } = this.props

    if (employeesWorkPositions.dataState === 'STATE_NOT_REQUESTED') {
      onGetEmployeesWorkPositions()
    }
  }

  render() {
    const { onHandleChangeStore, filterEmployees, employeesWorkPositions } = this.props
    const name = FilterEmployeesItems.workPositions.name

    return (
      <MultiSelects
        name={name}
        valueStore={filterEmployees.filterItems}
        options={getEmployeesWorkPositionsSelectObj(employeesWorkPositions[name])}
        handleChange={onHandleChangeStore.bind(this, name)}
      />
    )
  }
}

export default withEmployeesWorkPositions(withFilterEmployees(EmployeesWorkPositionsFilter));
