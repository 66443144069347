import React from 'react';

import IntelligenceEmployeesNavbar from './intelligenceEmployeesNavbar'
import IntelligenceEmployeesContent from './intelligenceEmployeesContent'
import SideContentInner from '../../../shared/sideContentInner'

const IntelligenceEmployees = () => {
  return (
    <React.Fragment>
      <IntelligenceEmployeesNavbar />

      <SideContentInner>
        <IntelligenceEmployeesContent />
      </SideContentInner>
    </React.Fragment>
  )
}

export default React.memo(IntelligenceEmployees)
