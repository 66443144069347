import React from 'react'

import styles from './buttonMediumDefault.module.scss'

const ButtonMediumDefault = props => {
  const { handleClick, title, className } = props
  const styleName = !!className ? `buttonMediumDefault_${className}` : 'buttonMediumDefault'
  return (
    <button className={styles[styleName]} onClick={handleClick}>
      {!!props.children ? <span className={styles.wrapp}>{props.children}</span> : title}
    </button>
  )
}

export default React.memo(ButtonMediumDefault)