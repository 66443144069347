import React, { Component } from "react";
import { withObjectives } from "../../../shared/containers/containerObjectives";
import BuildingMapView from "./buildingsMapView";
import ButtonPrimary from "../../../shared/items/contols/buttonPrimary";
import ObjectiveSchedulesPanel from "./objectiveSchedulesPanel";
import { v4 as uuid_v4 } from "uuid";
import "@paraboly/react-osm-geocoding/dist/index.css";
import "./objectivesBlock.modeule.scss";

class ObjectivesBuildingPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objective: props.objective,
      selectBuilding: props.objective.building,
      updateDisabled: true,
    };
  }

  onSave(e) {
    const { selectBuilding, objective } = this.state;
    const { onUpdateObjective } = this.props;

    objective.objectiveType = 2;

    onUpdateObjective(objective, selectBuilding.osmId);
    this.setState({ updateDisabled: true });
  }

  onChekedEntry(e) {
    if (e.target.id === "objectiveEntry") {
      e.stopPropagation();
      const { objective } = this.state;
      objective.entry = e.target.checked;

      this.setState({ objective: objective });
    }
  }

  onRemove(e) {
    const { onDeleteObjective, objective } = this.props;
    objective.objectiveType = 2;

    onDeleteObjective(objective);
  }

  onChangeInformation(e) {
    if (e.target.id === "objectiveInformation") {
      e.stopPropagation();
      const { objective } = this.state;
      objective.information = e.target.value;

      this.setState({ objective: objective, updateDisabled: false });
    }
  }

  onChekedNoticeCompletion(e) {
    if (e.target.id === "objectiveNoticeCompletion") {
      e.stopPropagation();
      const { objective } = this.state;
      objective.noticeCompletion = e.target.checked;

      this.setState({ objective: objective, updateDisabled: false });
    }
  }

  onChekedNoticeNotCompletion(e) {
    if (e.target.id === "objectiveNoticeNotCompletion") {
      e.stopPropagation();
      const { objective } = this.state;
      objective.noticeNotCompletion = e.target.checked;

      this.setState({ objective: objective, updateDisabled: false });
    }
  }

  render() {
    const { selectBuilding, objective, updateDisabled } = this.state;

    return (
      <div>
        <div className={"objectiveCreatePanel "}>
          <div className={"contaunerCreateOptions"}>
            <div className={"buildingMapViewContainer"}>
              <BuildingMapView
                buildings={[selectBuilding]}
                selectBuilding={selectBuilding}
                onSelectBuilding={() => {}}
              />
            </div>

            <div>
              <textarea
                id={"objectiveInformation"}
                value={objective.information}
                onChange={this.onChangeInformation.bind(this)}
              />
              <label for={"objectiveInformation"}> Описание</label>
            </div>

            <div onClick={this.onChekedNoticeCompletion.bind(this)}>
              <input
                className="custom-checkbox"
                type={"checkbox"}
                checked={objective.noticeCompletion}
                id={"objectiveNoticeCompletion"}
                onChange={(e) => {
                  e.stopPropagation();
                }}
              ></input>
              <label for={"objectiveNoticeCompletion"}> Уведомлять об выполнении задания</label>
            </div>

            <div onClick={this.onChekedNoticeNotCompletion.bind(this)}>
              <input
                className="custom-checkbox"
                type={"checkbox"}
                onChange={(e) => {
                  e.stopPropagation();
                }}
                checked={objective.noticeNotCompletion}
                id={"objectiveNoticeNotCompletion"}
              ></input>
              <label for={"objectiveNoticeNotCompletion"}> Уведомлять об не выполнении задания</label>
            </div>

            <div className={"contaunerCreateOptions"}>
              <div className={"rowButtonWrapper"}>
                <div className={"objectiveButtonWrapper"}>
                  <ButtonPrimary
                    disabled={updateDisabled}
                    handleClick={this.onSave.bind(this)}
                    title={<span>Обновить</span>}
                  />
                </div>

                <div className={"objectiveRemoveButtonWrapper"}>
                  <ButtonPrimary handleClick={this.onRemove.bind(this)} title={"удалить всё"} />
                </div>
              </div>
            </div>
          </div>
          <div className="shedulesContainer">
            <ObjectiveSchedulesPanel key={uuid_v4()} objective={objective} objectiveType={2} />
          </div>
        </div>
      </div>
    );
  }
}

export default withObjectives(ObjectivesBuildingPanel);
