import {
    RESPONSE_EMPLOYEES_WORK_POSITIONS,
    REQUEST_EMPLOYEES_WORK_POSITIONS_FAILURE,
  } from '../actions/workPositionsActions'
  
  import { states as queriesStates } from './queriesStates'
  
  const initialState = {
    dataState: queriesStates.NOT_REQUESTED,
    workPositions: []
  }
  
  const employeesWorkPositionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case RESPONSE_EMPLOYEES_WORK_POSITIONS: {
        return Object.assign(
          {},
          state,
          {
            dataState: queriesStates.READY,
            workPositions: action.data
          }
        )
      }
      case REQUEST_EMPLOYEES_WORK_POSITIONS_FAILURE: {
        return Object.assign(
          {},
          state,
          {
            dataState: queriesStates.FAILURE,
            errors: action.errors,
          }
        )
      }
      default:
        return state
    }
  }
  
  export default employeesWorkPositionsReducer
  