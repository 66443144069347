import React, { Component } from "react";
import { withEmployeeSettings } from "../../../shared/containers/containerEmployeeSettings";
import Toggle from "react-toggle";
import InputTime from "../../../shared/items/contols/inputTime";
import styles from "./employeeSetting.module.scss";

let timeOuntId = null;

class EmployeeSettingItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      setting: props.setting,
      isGroup: props.isGroup === undefined ? false : props.isGroup,
    };
  }

  getInput(setting, id) {
    switch (setting.type) {
      default:
        return null;
      case 0:
        return (
          <input
            type={"number"}
            value={setting.value}
            onChange={(e) => {
              setting.value = e.target.value;
              this.setValue(setting, id);
            }}
          ></input>
        );
      case 2:
        return (
          <Toggle
            checked={setting.value === "1" ? true : false}
            onChange={(e) => {
              setting.value = e.target.checked ? "1" : "0";
              this.setValue(setting, id);
            }}
          />
        );
      case 3:
        const valueStore = {};
        valueStore[setting.id] = new Date(Number(setting.value) * 1000 + new Date().getTimezoneOffset() * 60000);
        return (
          <InputTime
            className={styles.inputTime}
            name={setting.id}
            onHandleChangeStore={(e, value) => {
              if (value) {
                const values = value.split(":");
                setting.value = values[0] * 60 * 60 + values[1] * 60;

                this.setValue(setting, id);
              }
            }}
            valueStore={valueStore}
          />
        );
      case 4:
        return (
          <input
            type={"number"}
            value={setting.value / 1000}
            onChange={(e) => {
              setting.value = e.target.value * 1000;
              this.setValue(setting, id);
            }}
          ></input>
        );
    }
  }

  setValue(setting, id) {
    this.setState({ setting: setting });

    clearTimeout(timeOuntId);
    timeOuntId = setTimeout(() => {
      this.change(setting, id, setting.value);
    }, 600);
  }

  change(item, id, value) {
    const { onAddEmployeeSettings, onUpdateEmployeeSettings } = this.props;
    const { isGroup } = this.state;

    if (item.isCustom) {
      onUpdateEmployeeSettings(id, item.id, value, isGroup);
    } else {
      onAddEmployeeSettings(id, item.id, value);
    }
  }

  render() {
    const { setting, id } = this.state;

    return (
      <div>
        <div >
          <strong style={{ padding: 0, margin: 0 }}>{setting.title}</strong>
        </div>
        <div>{setting.valueBefore}</div>
        <div className={styles.inputContainer}>
          {this.getInput(setting, id)} <label>{setting.valueAfter}</label>
        </div>
      </div>
    );
  }
}

export default withEmployeeSettings(EmployeeSettingItem);
