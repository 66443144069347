import React from 'react'

import styles from './phoneLink.module.scss'

const PhoneLink = function (props) {
  const { link, title } = props

  return (
    <a className={styles.phoneLink} href={`tel:${link}`}>{title}</a>
  )
}

export default React.memo(PhoneLink)