import React from "react";
import TitleBarWrapper from "../../../shared/titleBarWrapper";
import FirstTitle from "../../../shared/items/static/firstTitle";
import CompaniesCounterWrapper from "./companiesCounterWrapper";
import { withCompanies } from "../../../shared/containers/containerCompanies";

let timeoutId = null;

const CompaniesTitleBar = (props) => {
  const { onGetCompanies } = props;

  return (
    <TitleBarWrapper>
      <FirstTitle>
        {"Компании"} <CompaniesCounterWrapper name="count" />
      </FirstTitle>
      <p>
        Всего: <CompaniesCounterWrapper name="companyCount" />
      </p>
      <p>
        Всего устройств: <CompaniesCounterWrapper name="deviceCount" />
      </p>
      <p>
        Всего не активных устройств: <CompaniesCounterWrapper name="deviceInActiveCount" />
      </p>
      <input
        type="text"
        onChange={(e) => {
          clearTimeout(timeoutId);
          const options = {
            page: 0,
            count: -1,
            searchTerm: e.target.value,
          };
          onGetCompanies(options);
        }}
      ></input>
    </TitleBarWrapper>
  );
};

export default withCompanies(React.memo(CompaniesTitleBar));
