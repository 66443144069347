import intellegenceService from './../shared/services/intellegenceService'
import dateTime from './../shared/services/date.service'

const RESPONSE_SENSORS_PREVIEW = 'RESPONSE_SENSORS_PREVIEW'
const REQUEST_SENSOR_PREVIEW_FAILURE = 'REQUEST_SENSOR_PREVIEW_FAILURE'

const responseSensorPreviewAction = (data) => {
  return {
    type: RESPONSE_SENSORS_PREVIEW,
    data
  }
}

const requestSensorsPreviewFailureAction = (errors) => {
  return {
    type: REQUEST_SENSOR_PREVIEW_FAILURE,
    errors
  }
}

const getSensorsPreview = (options) => {
  return (dispatch) => {
    intellegenceService.getSensorsData({
      id: options.employeeId,
      date: dateTime.stringISODate(options.date),
      locationNotProcessed : options.locationNotProcessed
    })
      .then((result) => {
        dispatch(responseSensorPreviewAction({}))
        Object.keys(result.data.sensors).length > 0
          ? dispatch(responseSensorPreviewAction(result.data))
          : dispatch(responseSensorPreviewAction({}))
        // dispatch(responseSensorPreviewAction(result.data))
      })
      .catch((error) => {
        dispatch(requestSensorsPreviewFailureAction(error.response))
      })
  }
}

export {
  getSensorsPreview,
  responseSensorPreviewAction,
  requestSensorsPreviewFailureAction,
  RESPONSE_SENSORS_PREVIEW,
  REQUEST_SENSOR_PREVIEW_FAILURE
}