"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";

var EmployeeWorkPositionsService = {
  deleteEmployeeWorkPosition: function (employeeId) {
    return api.delete("/EmployeeWorkPositions?EmployeeId=" + employeeId, WithToken.headersToken({}, "authTNToken"));
  },
  addEmployeeWorkPosition: function (employeeId, workPositionId) {
    return api.post(
      "/EmployeeWorkPositions",
      {
        employeeId: employeeId,
        workPositionId: workPositionId,
      },
      WithToken.headersToken({}, "authTNToken")
    );
  },
};

export default EmployeeWorkPositionsService;
