import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import SecondSideBarInner from './../../shared/secondSideBarInner'
import SecondSideBarHeader from './../sides/secondSideBarHeader'
import ClearEmployeesFilter from './../sections/employees/clearEmployeesFilter'

import styles from './sideSecondBar.module.scss'

const SideSecondBar = props => {
  const { isOpen, title, handleClick } = props

  return (
    <div className={isOpen ? styles.sideSecondBar_open : styles.sideSecondBar_closed}>

      <SecondSideBarInner>
        <SecondSideBarHeader title={title} handleClose={handleClick}>

          <ClearEmployeesFilter />

        </SecondSideBarHeader>
      </SecondSideBarInner>

      <div className={styles.container_sideSecondContent}>
        <Scrollbars
          style={{ height: "100%" }}
          autoHide={true}
        >
          {props.children}
        </Scrollbars>
      </div>

    </div>
  )
}

export default React.memo(SideSecondBar)
