import React, { Component } from "react";
import daysWeekEnum from "./DaysWeekEnum";
import DayButton from "./dayButton";
import "./objectivesBlock.modeule.scss";

class ScheduleIntervalPanel extends Component {
  render() {
    const { dayCheked, dayClick, scheduleId } = this.props;
    const onDayClick = dayClick ? dayClick : () => {};

    return [
      <DayButton
        key={scheduleId + "_day_" + daysWeekEnum.MON}
        day={daysWeekEnum.MON}
        isCheck={dayCheked.MON}
        title={"Пн"}
        dayClick={onDayClick}
      />,
      <DayButton
        key={scheduleId + "_day_" + daysWeekEnum.TUE}
        day={daysWeekEnum.TUE}
        isCheck={dayCheked.TUE}
        title={"Вт"}
        dayClick={onDayClick}
      />,
      <DayButton
        key={scheduleId + "_day_" + daysWeekEnum.WED}
        day={daysWeekEnum.WED}
        isCheck={dayCheked.WED}
        title={"Ср"}
        dayClick={onDayClick}
      />,
      <DayButton
        key={scheduleId + "_day_" + daysWeekEnum.THU}
        day={daysWeekEnum.THU}
        isCheck={dayCheked.THU}
        title={"Чт"}
        dayClick={onDayClick}
      />,
      <DayButton
        key={scheduleId + "_day_" + daysWeekEnum.FRI}
        day={daysWeekEnum.FRI}
        isCheck={dayCheked.FRI}
        title={"Пт"}
        dayClick={onDayClick}
      />,
      <DayButton
        key={scheduleId + "_day_" + daysWeekEnum.SAT}
        day={daysWeekEnum.SAT}
        isCheck={dayCheked.SAT}
        title={"Сб"}
        dayClick={onDayClick}
      />,
      <DayButton
        key={scheduleId + "_day_" + daysWeekEnum.SUN}
        day={daysWeekEnum.SUN}
        isCheck={dayCheked.SUN}
        title={"Вс"}
        dayClick={onDayClick}
      />,
    ];
  }
}

export default ScheduleIntervalPanel;
