import React from "react";
import SideBarInner from "../../../shared/sideBarInner";
import UsersListWrapper from "../users/usersListWrapper";
import SideBar from "../../sides/sideBar";
//import UsersTitleClosedBar from '../users/usersTitleClosedBar'
import UsersTitleBar from "../users/usersTitleBar";
import styles from "./usersBar.module.scss";


const UsersBar = () => {
  return (
    <div className={styles.sideBarsWrapper}>
      <SideBar>
        <div className={styles.usersBar}>
          <SideBarInner>
            <UsersTitleBar />
          </SideBarInner>

          <SideBarInner>
            <div className={styles.container_usersList}>
              <UsersListWrapper />
            </div>
          </SideBarInner>
        </div>
      </SideBar>
    </div>
  );
};

export default React.memo(UsersBar);
