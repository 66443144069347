import React, { Component } from "react";
import { getEmployeesTypeName } from "./static.employeesTypes";
import dateTime from "../../../shared/services/date.service";
import styles from "./employeesListSelectedItem.module.scss";
import { v4 as uuid_v4 } from "uuid";

class EmployeeContent extends Component {
  getStatusStyles() {
    const { data } = this.props;

    switch (getEmployeesTypeName(data.status.id)) {
      case "noData":
        return styles.mDEmployeeItems_noData;
      case "sleep":
        return styles.mDEmployeeItems_inSleep;
      case "work":
        return styles.mDEmployeeItems_work;
      case "charging":
        return styles.mDEmployeeItems_charging;
      case "offSleep":
        return styles.mDEmployeeItems_offSleep;
      case "alarm":
        return styles.mDEmployeeItems_alarm;
      case "poweredFrom":
        return styles.mDEmployeeItems_poweredFrom;
      default:
        return null;
    }
  }

  render() {
    const { data } = this.props;

    return (
      <div key={uuid_v4()} className={this.getStatusStyles()}>
        <div className={styles.mDEmployeeItem}>
          <h4 className={styles.mDEmployeeItemTitle}>Статус трекера </h4>
          <p className={styles.mDEmployeeItemContent}> {data.status.title}</p>
        </div>

        <div className={styles.mDEmployeeItem}>
          <h4 className={styles.mDEmployeeItemTitle}>Заряд батареи</h4>
          <p className={styles.mDEmployeeItemContent}>{data.batteryCharge}%</p>
        </div>

        <div className={styles.mDEmployeeItem}>
          <h4 className={styles.mDEmployeeItemTitle}>Последняя активность</h4>
          <p className={styles.mDEmployeeItemContent}>{dateTime.toFormat4(dateTime.toLocal(data.lastActive))}</p>
        </div>

        {data.address !== null ? (
          <div className={styles.mDEmployeeItem}>
            <h4 className={styles.mDEmployeeItemTitle}>Адрес</h4>
            <p className={styles.mDEmployeeItemContent}>{data.address}</p>
          </div>
        ) : null}

        {data.workPosition ? (
          <div className={styles.mDEmployeeItem}>
            <h4 className={styles.mDEmployeeItemTitle}>Должность</h4>
            <p className={styles.mDEmployeeItemContent}>{data.workPosition.title}</p>
          </div>
        ) : null}

        {data.phone !== null && data.phone !== "" ? (
          <div className={styles.mDEmployeeItem}>
            <h4 className={styles.mDEmployeeItemTitle}>Контактный телефон</h4>
            <p className={styles.mDEmployeeItemContent}>{data.phone}</p>
          </div>
        ) : null}

        <div className={styles.mDEmployeeItem}>
          <h4 className={styles.mDEmployeeItemTitle}>IMEI</h4>
          <p className={styles.mDEmployeeItemContent}>{data.imei}</p>
        </div>

        {data.simPhone !== null && data.simPhone !== "" ? (
          <div className={styles.mDEmployeeItem}>
            <h4 className={styles.mDEmployeeItemTitle}>Номер SIM</h4>
            <p className={styles.mDEmployeeItemContent}>{data.simPhone}</p>
          </div>
        ) : null}

        {data.groups.length > 0 ? (
          <div className={styles.mDEmployeeItem}>
            <h4 className={styles.mDEmployeeItemTitle}>Группы:</h4>
            <p className={styles.mDEmployeeItemContent}>{data.groups.map((item) => item.title + "; ")}</p>
          </div>
        ) : null}
      </div>
    );
  }
}

export default EmployeeContent;
