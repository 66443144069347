import { connect } from "react-redux";
import get from "lodash/get";

import {
  getCompanies as onGetCompanies,
  enterCompany as onEnterCompany,
  updateCompanyDevice as onUpdateCompanyDevice,
  commandCompanyDevices as onCommandCompanyDevices,
  transferCompanyDevices as onTransferCompanyDevices,
  createOwnerUser as onCreateOwnerUser,
  infoDevice as onInfoDevice,
} from "../../actions/companiesActions";

const mapStateToProps = (state) => ({
  companies: {
    dataState: state.companies.dataState,
    enterCompanyId: get(state, ["companies", "enterCompanyId"], null),
    count: get(state, ["companies", "count"], 0),
    getCompanyOptions: get(state, ["companies", "getCompanyOptions"], { count: -1, page: 0, searchTerm: "" }),
    deviceInfo: get(state, ["companies", "deviceInfo"], null),
    companyCount: get(state, ["companies", "companyCount"], 0),
    deviceCount: get(state, ["companies", "deviceCount"], 0),
    deviceInActiveCount: get(state, ["companies", "deviceInActiveCount"], 0),
    companies: get(state, ["companies", "companies"], []),
  },
});

const mapDispatchToProps = {
  onGetCompanies,
  onEnterCompany,
  onUpdateCompanyDevice,
  onCommandCompanyDevices,
  onTransferCompanyDevices,
  onCreateOwnerUser,
  onInfoDevice,
};

const withCompanies = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withCompanies };
