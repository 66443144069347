import { REQUEST_PERMISSIONS, RESPONSE_PERMISSIONS, REQUEST_PERMISSIONS_FAILURE } from "../actions/permissionActions";

import { states as queriesStates } from "./queriesStates";

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  permissions: [],
  errors: null,
};

const permissionsReduser = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PERMISSIONS: {
      return Object.assign({}, state, {
        dataState: queriesStates.REQUEST,
        errors: [],
      });
    }
    case RESPONSE_PERMISSIONS: {
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY,
        },
        action.data
      );
    }
    case REQUEST_PERMISSIONS_FAILURE: {
      return Object.assign({}, state, {
        dataState: queriesStates.FAILURE,
        errors: action.errors,
      });
    }
    default:
      return state;
  }
};

export default permissionsReduser;
