import {
    REQUEST_DEVICES,
    RESPONSE_DEVICES,
    REQUEST_DEVICES_FAILURE,
  } from '../actions/devicesActions'
  
  import { states as queriesStates } from './queriesStates'
  
  const initialState = {
    dataState: queriesStates.NOT_REQUESTED,
    devices: [],
    otherId: null,
  }
  
  const devicesReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case REQUEST_DEVICES: {
        return Object.assign(
          {},
          state,
          {
            dataState: queriesStates.REQUEST,
            errors: [],
          }
        )
      }
      case RESPONSE_DEVICES: {
        return Object.assign(
          {},
          state,
          {
            dataState: queriesStates.READY,
          },
          action.data,
        )
      }
      case REQUEST_DEVICES_FAILURE: {
        return Object.assign(
          {},
          state,
          {
            dataState: queriesStates.FAILURE,
            errors: action.errors,
          }
        )
      }
      default:
        return state
    }
  }
  
  export default devicesReducer
  