import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import EmployeesList from './../employees/employeesList'
import EmployeesPaginationWrapper from './employeesPaginationWrapper'

class EmployeesListWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      scrollToEmployeesList: 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrollToEmployeesList } = this.state
    const { scrollbars } = this.refs

    if (scrollToEmployeesList !== prevState.scrollToEmployeesList) {
      scrollbars.scrollTop(scrollToEmployeesList)
    }
  }

  handleScrollTo(top) {
    this.setState({
      scrollToEmployeesList: top
    })
  }

  render() {
    return <Scrollbars
      style={{ height: "100%" }}
      autoHide={true}
      ref="scrollbars"
    >
      <EmployeesList
        handleScrollTo={this.handleScrollTo.bind(this)}
      />
      <EmployeesPaginationWrapper />
    </Scrollbars>
  }
}

export default EmployeesListWrapper


