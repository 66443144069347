import React, { Component } from "react";

import { withChoosedEmployee } from "../../../shared/containers/containerChoosedEmployee";
import { withEmployeeShortInfo } from "../../../shared/containers/containerEmployeeShortInfo";
import { withEmployeeUpdate } from "../../../shared/containers/containerEmployeeUpdate";
import { withEmployeesWorkPositions } from "../../../shared/containers/containerEmployeesWorkPositions";
import { withEmployeesGroups } from "../../../shared/containers/containerEmployeesGroups";
import { withEmployeeGroups } from "../../../shared/containers/containerEmployeeGroups";
import ContentInner from "../../../shared/contentInner";
import FormItemsInline from "../../../shared/items/static/formItemsInline";
import styles from "./employeeShortInformation.module.scss";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonPrimary from "../../../shared/items/contols/buttonPrimary";
import WorkPositionsService from "../../../shared/services/workPositionsService";
import EmployeesGroupsService from "../../../shared/services/employeesGroupsService";
import MultiSelects from "../../../shared/items/contols/multiSelect";
import { getSelectOptions } from "../../../shared/services/service.selectOptions";
import { v4 as uuid_v4 } from "uuid";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  TelegramShareButton,
  TelegramIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const STATE_READY = "STATE_READY";

class EmployeeShortInformation extends Component {
  componentDidMount() {
    const {
      unitShortInfo,
      onGetEmployeeShortInfo,
      choosedEmployee,
      employeesWorkPositions,
      onGetEmployeesWorkPositions,
      onGetEmployeesGroups,
      employeesGroups,
      onGetEmployeeGroups,
      employeeGroups,
    } = this.props;

    if (employeesWorkPositions.dataState !== STATE_READY) {
      onGetEmployeesWorkPositions();
    }

    if (employeesGroups.dataState !== STATE_READY) {
      onGetEmployeesGroups();
    }

    if (employeeGroups.dataState !== STATE_READY) {
      onGetEmployeeGroups(choosedEmployee.employeeId);
    }

    if (unitShortInfo.dataState !== STATE_READY || unitShortInfo.employeeId !== choosedEmployee.employeeId) {
      onGetEmployeeShortInfo(choosedEmployee.employeeId);
    }
  }

  componentDidUpdate(prevProps) {
    const { choosedEmployee, onGetEmployeeShortInfo, onGetEmployeesWorkPositions, onGetEmployeeGroups } = this.props;

    if (prevProps.choosedEmployee.employeeId !== choosedEmployee.employeeId) {
      onGetEmployeesWorkPositions();
      onGetEmployeeShortInfo(choosedEmployee.employeeId);
      onGetEmployeeGroups(choosedEmployee.employeeId);
    }
  }

  render() {
    const {
      choosedEmployee,
      unitShortInfo,
      onHandleChangeStore,
      onGetEmployeeShortInfo,
      onGetEmployeesWorkPositions,
      employeesWorkPositions,
      onGetEmployeesGroups,
      employeesGroups,
      onGetEmployeeGroups,
      onAddEmployeeToGroup,
      onRemoveEmployeeFromGroup,
      employeeGroups,
      onDeleteEmployeeWorkPosition,
      onAddEmployeeWorkPosition,
      onEmployeeSharedLink,
    } = this.props;

    let defaultSelectValue = { id: "null", title: "Должность не назначена" };
    const workPositions = [];

    workPositions.push(defaultSelectValue);

    employeesWorkPositions.workPositions.map((item) => {
      if (unitShortInfo.workPositionId === item.id) {
        defaultSelectValue = item;
      }
      workPositions.push(item);
    });

    const handleBlur = (event) => {
      const title = document.getElementById("outlined-title");
      const phone = document.getElementById("outlined-phone");
      if (title !== null && phone !== null) {
        onHandleChangeStore(choosedEmployee.employeeId, title.value, phone.value, false);

        handleUpdate();
      }
    };

    const handleWoekPositionChange = (event) => {
      if (event.target.value === "null") {
        onDeleteEmployeeWorkPosition(choosedEmployee.employeeId);
      } else {
        onAddEmployeeWorkPosition(choosedEmployee.employeeId, event.target.value);
      }

      handleUpdate();
    };

    const handleUpdate = () => {
      onGetEmployeesWorkPositions();
      onGetEmployeeShortInfo(choosedEmployee.employeeId);
      onGetEmployeeGroups(choosedEmployee.employeeId);
      onGetEmployeesGroups();
      this.componentDidMount();
    };

    const createWorkPosition = (event) => {
      const newWorkPositionTitle = document.getElementById("new-work-positions-text-field");
      if (newWorkPositionTitle.value.length > 0) {
        WorkPositionsService.createWorkPosition({
          title: newWorkPositionTitle.value,
        })
          .then((result) => {
            newWorkPositionTitle.value = null;
            handleUpdate();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };

    const createGroup = () => {
      const newGroup = document.getElementById("new-group-text-field");
      EmployeesGroupsService.createGroup({ title: newGroup.value })
        .then((result) => {
          newGroup.value = null;
          handleUpdate();
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const actionOnEmployeeGroups = (event) => {
      if (event === null) {
        event = [];
      }
      const add = event.length > employeeGroups.groups.length;
      const fromGroups = add ? event : getSelectOptions(employeeGroups.groups);
      const groups = !add ? event : getSelectOptions(employeeGroups.groups);

      let index = null;
      for (let i = 0; i < fromGroups.length; ++i) {
        let includes = false;
        for (let k = 0; k < groups.length; ++k) {
          if (fromGroups[i].value === groups[k].value) {
            includes = true;
            break;
          }
        }
        if (!includes) {
          index = i;
          break;
        }
      }

      return {
        add,
        group: {
          id: fromGroups[index].value,
          title: fromGroups[index].label,
        },
      };
    };

    const onHandleEmployeeGroups = (selectedItems) => {
      const action = actionOnEmployeeGroups(selectedItems);

      if (action.add) {
        onAddEmployeeToGroup(choosedEmployee.employeeId, action.group.id, handleUpdate);
      } else {
        onRemoveEmployeeFromGroup(choosedEmployee.employeeId, action.group.id, handleUpdate);
      }
    };

    const valueStore = { groups: getSelectOptions(employeeGroups.groups) };

    const employeeSharedLink = (createSharedLink) => {
      onEmployeeSharedLink(choosedEmployee.employeeId, createSharedLink);
    };

    return (
      <div className={styles.employeeShortInformation}>
        {unitShortInfo.dataState === "STATE_READY" ? (
          <ContentInner>
            <FormItemsInline gridCount={"2_4"}>
              <TextField
                margin="dense"
                style={{ height: 38, width: 450 }}
                id="outlined-title"
                label="ФИО"
                variant="outlined"
                defaultValue={unitShortInfo.title}
                onChange={handleBlur}
              />
              <TextField
                margin="dense"
                style={{ height: 38, width: 450 }}
                id="outlined-phone"
                label="Личный телефон"
                variant="outlined"
                defaultValue={unitShortInfo.phone}
                onChange={handleBlur}
              />
            </FormItemsInline>

            <FormItemsInline gridCount={"2_4"}>
              <TextField
                margin="dense"
                style={{ height: 38, width: 450 }}
                id="outlined-select-workPosition"
                select
                label="Должность"
                onChange={handleWoekPositionChange}
                defaultValue={defaultSelectValue.id}
                variant="outlined"
              >
                {workPositions.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                style={{ height: 38, width: 450 }}
                id="new-work-positions-text-field"
                label="Новая должность"
                variant="outlined"
              />
              <div style={{ marginTop: 9 }}>
                <ButtonPrimary handleClick={createWorkPosition} title="Добавить" />
              </div>
            </FormItemsInline>

            <FormItemsInline gridCount={"2_4"} style={{ alignItems: "normal" }}>
              <div style={{ width: "450px", paddingTop: 9 }}>
                <MultiSelects
                  style={{ height: 38, width: 450 }}
                  id="multiSelectGroup"
                  margin="dense"
                  name={"groups"}
                  valueStore={valueStore}
                  options={getSelectOptions(employeesGroups.groups)}
                  placeholder={"Группы"}
                  handleChange={onHandleEmployeeGroups}
                />
              </div>

              <TextField
                margin="dense"
                style={{ height: 38, width: 450 }}
                id="new-group-text-field"
                label="Новая группа"
                variant="outlined"
              />

              <div style={{ marginTop: 9 }}>
                <ButtonPrimary handleClick={(e) => createGroup(e)} title="Добавить" />
              </div>
            </FormItemsInline>

            <FormItemsInline  gridCount={"1"} style={{ alignItems: "normal", color: "rgb(0 0 0) !important" }}>
              <div style={{ position: "relative" }}>
                <TextField
                  key={uuid_v4()}
                  disabled={true}
                  margin="dense"
                  style={{ height: 38, width: "910px", color: "rgb(0 0 0) !important" }}
                  id="sharedLink-text-field"
                  label="Ссылка беcпарольного доступа"
                  variant="outlined"
                  defaultValue={unitShortInfo.sharedLink}
                />
                <div className={styles.sharedLinkButton}>
                  <CachedIcon
                    onClick={(e) => {
                      employeeSharedLink(true);
                    }}
                  />

                  <DeleteIcon
                    onClick={(e) => {
                      employeeSharedLink(false);
                    }}
                  />
                </div>
              </div>

              {unitShortInfo.sharedLink ? (
                <div style={{ paddingTop: "5px", position: "absolute" }}>
                  <TelegramShareButton
                    url={unitShortInfo.sharedLink}
                    title={`Доступ к данным ${unitShortInfo.title}`}
                    style={{ margin: "5px" }}
                  >
                    <TelegramIcon size={32} round={true} />
                  </TelegramShareButton>

                  <ViberShareButton
                    url={unitShortInfo.sharedLink}
                    title={`Доступ к данным ${unitShortInfo.title}`}
                    style={{ margin: "5px" }}
                  >
                    <ViberIcon size={32} round={true} />
                  </ViberShareButton>

                  <WhatsappShareButton
                    url={unitShortInfo.sharedLink}
                    title={`Доступ к данным ${unitShortInfo.title}`}
                    style={{ margin: "5px" }}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </div>
              ) : null}
            </FormItemsInline>

            {/* 
            <FormItemsInline gridCount={"2_4"}>
              <FormControlLabel
                id="checkBox-autoCreateGoeZone-form"
                control={
                  <CustomColorCheckbox
                    id="checkBox-autoCreateGoeZone"
                    checked={unitShortInfo.autoCreateGeoZone}
                    onChange={handleBlur}
                  />
                }
                label='Создание геозоны (круг радиусом 25м) по нажатию кнопки "✖"'
              />
            </FormItemsInline> */}
          </ContentInner>
        ) : null}
      </div>
    );
  }
}

export default withEmployeeGroups(
  withEmployeesGroups(
    withEmployeesWorkPositions(withChoosedEmployee(withEmployeeShortInfo(withEmployeeUpdate(EmployeeShortInformation))))
  )
);
