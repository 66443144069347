import React from "react";
import dateTime from "./date.service";

function tableEventObjectivesData(data, routeData) {
  const tableHeaders = ["Дата начала", "Крайняя дата завершения", "Дата завершения", "Статус", "Наименование"];

  const tableContent = data.map((item, index) => {
    const row = {
      index: routeData.findIndex((x) => {
        return x.time === item.schedule.timePoint;
      }),

      isActive: item.isActive ? item.isActive : false,
      items: [
        <span>
          <span style={{ fontSize: ".7rem" }}>{dateTime.toFormat3(dateTime.toLocal(item.schedule.timeStart))}</span>
        </span>,
        <span>
          <span style={{ fontSize: ".7rem" }}>{dateTime.toFormat3(dateTime.toLocal(item.schedule.timeEnd))}</span>
        </span>,
        <span>
          <span style={{ fontSize: ".7rem" }}>
            {item.state > 1 ? dateTime.toFormat3(dateTime.toLocal(item.schedule.timePoint)) : null}
          </span>
        </span>,
        <span>
          <span style={{ fontSize: ".7rem", color: getColor(item.state), textTransform: "uppercase", fontWeight: 900 }}>
            {getState(item.state)}
          </span>
        </span>,
        <span>
          <span style={{ fontSize: ".7rem" }}>{item.title}</span>
        </span>,
      ],
    };

    if (row.index === -1) {
      row.index = 0;
    }

    return row;
  });

  return {
    headItems: tableHeaders,
    contentItems: tableContent,
  };
}

const getState = (value) => {
  switch (value) {
    default:
      return "Отсутвие данных";
    case 1:
      return "В процессе";
    case 2:
      return "Выполнено";
    case 3:
      return "Не выполнено";
  }
};

const getColor = (value) => {
  switch (value) {
    default:
      return "grey";
    case 1:
      return "В процессе";
    case 2:
      return "green";
    case 3:
      return "red";
  }
};

export default tableEventObjectivesData;
