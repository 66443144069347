import React from "react";

import { withIntellegenceRangeType } from "../../../shared/containers/containerIntellegenceRangeType";
import EmployeesMainIntimerangeMapControls from "./employeesMainIntimerangeMapControls";

const EmployeesShortMapControls = (props) => {
  const { intellegenceRangeType } = props;

  return (
    <React.Fragment>
      <EmployeesMainIntimerangeMapControls />
    </React.Fragment>
  );
};

export default withIntellegenceRangeType(React.memo(EmployeesShortMapControls));
