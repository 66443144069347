const READY_CHANGE_STORE_SIGNIN = 'READY_CHANGE_STORE_SIGNIN'
const READY_CLEAR_STORE_SIGNIN = 'READY_CLEAR_STORE_SIGNIN'

const responseChangeStoreSignInAction = (name, value) => {
  return {
    type: READY_CHANGE_STORE_SIGNIN,
    payload: { name, value }
  }
}

const handleChangeStoreSignIn = (name, value) => {
  return (dispatch) => {
    dispatch(responseChangeStoreSignInAction(name, value))
  }
}

const responseClearStoreSingInAction = () => {
  return {
    type: READY_CLEAR_STORE_SIGNIN,
  }
}

const handleClearStoreSingIn = () => {
  return (dispatch) => {
    dispatch(responseClearStoreSingInAction())
  }
}

export {
  handleChangeStoreSignIn,
  responseChangeStoreSignInAction,
  READY_CHANGE_STORE_SIGNIN,

  handleClearStoreSingIn,
  responseClearStoreSingInAction,
  READY_CLEAR_STORE_SIGNIN,
}
