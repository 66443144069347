import React, { Component } from "react";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { v4 as uuid_v4 } from "uuid";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "none",
    border: "0px solid transparent",
    padding: "5px",
    borderRadius: "5px",
    maprginTop: "5px",
  },
}))(Tooltip);

class ObjectiveScheduleToggle extends Component {
  constructor(props) {
    super(props);

    this.state = { active: props.active };
  }

  onClick(e) {
    const { active } = this.state;
    const { onActiveSchedule, shceduleId } = this.props;
    e.stopPropagation();

    onActiveSchedule({ id: shceduleId, active });
    this.setState({ active: !active });
  }

  render() {
    const { active } = this.state;

    const style = {
      fontSize: "2.5rem",
      marginTop: "-5px",
    };

    if (active) {
      style["color"] = "hwb(126deg 10% 33%)";
    }

    return (
      <HtmlTooltip
        key={uuid_v4()}
        title={active ? "Расписание актиано" : "Расписание отключено"}
        placement="left-start"
      >
        {active ? (
          <ToggleOnIcon style={style} onClick={this.onClick.bind(this)} />
        ) : (
          <ToggleOffIcon style={style} onClick={this.onClick.bind(this)} />
        )}
      </HtmlTooltip>
    );
  }
}

export default ObjectiveScheduleToggle;
