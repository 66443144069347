"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";
import configuration from "../../config.json";

const EmployeeSettingsService = {
  getEmployeeSettings: function (id) {
    return api.get(`/Employees/${id}/Settings`, WithToken.headersToken({}, configuration.cookieName));
  },
  addEmployeeSettings: function (id, settingId, value) {
    return api.post(
      `/Employees/${id}/Settings/${settingId}?value=${value}`,{},
      WithToken.headersToken({}, configuration.cookieName)
    );
  },
  updateEmployeeSettings: function (id, settingId, value) {
    return api.put(
      `/Employees/${id}/Settings/${settingId}?value=${value}`, {},
      WithToken.headersToken({}, configuration.cookieName)
    );
  },
};

export default EmployeeSettingsService;
