import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  getEmployeesWorkPositions as onGetEmployeesWorkPositions,
  deleteEmployeeWorkPosition as onDeleteEmployeeWorkPosition,
  addEmployeeWorkPosition as onAddEmployeeWorkPosition
} from '../../actions/workPositionsActions'

const mapStateToProps = (state) => ({
  employeesWorkPositions: {
    dataState: state.employeesWorkPositions.dataState,
    workPositions: get(state, ['employeesWorkPositions', 'workPositions'], [])
  }
});

const mapDispatchToProps = {
  onGetEmployeesWorkPositions,
  onDeleteEmployeeWorkPosition,
  onAddEmployeeWorkPosition
}

const withEmployeesWorkPositions = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withEmployeesWorkPositions }