"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";

const UsersService = {
  getUsers: function (options) {
    return api.get("/users", WithToken.headersToken({}, "authTNToken"));
  },
  putUser: function (id, isBlocked, title) {
    return api.put(`/users/${id}?isBlocked=${isBlocked}&title=${title}`, {}, WithToken.headersToken({}, "authTNToken"));
  },
  newUser: function (options) {
    return api.post("/mails/InvitationRegister", options, WithToken.headersToken({}, "authTNToken"));
  },
  deleteUser: function (id) {
    return api.delete("/users/" + id, WithToken.headersToken({}, "authTNToken"));
  },
  createUserPermmision: function (userId, permissionId) {
    return api.post(
      `/users/${userId}/permissions?permissionId=${permissionId}`,
      {},
      WithToken.headersToken({}, "authTNToken")
    );
  },
  deleteUserPermmision: function (userId, permissionId) {
    return api.delete(
      `/users/${userId}/permissions?permissionId=${permissionId}`,
      WithToken.headersToken({}, "authTNToken")
    );
  },
};

export default UsersService;
