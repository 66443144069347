import {
  READY_CHANGE_STORE_SIGNIN,
  READY_CLEAR_STORE_SIGNIN,
} from '../actions/signInActions'

import { states } from './queriesStates'

const initialState = {
  dataState: states.NOT_REQUESTED,
  signInItems: {},
}

const signInReducer = (state = initialState, action) => {
  switch (action.type) {
    case READY_CHANGE_STORE_SIGNIN: {
      const { name, value } = action.payload
      var signInItems = Object.assign({}, state.signInItems)

      signInItems[name] = value

      return Object.assign(
        {},
        state,
        {
          dataState: states.READY,
          signInItems: signInItems
        }
      )
    }
    case READY_CLEAR_STORE_SIGNIN: {
      return Object.assign(
        {},
        state,
        {
          dataState: states.READY,
          signInItems: {}
        }
      )
    }
    default:
      return state
  }
}

export default signInReducer
