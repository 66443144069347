import React, { Component } from 'react'

import { withRegistration } from '../../shared/containers/containerRegistration'

import ButtonPrimary from '../../shared/items/contols/buttonPrimary'
import PreloaderLight from '../../shared/items/static/preloaderLight'
import { states } from "../../reducers/queriesStates"

import styles from './buttonRegistrationWrapper.module.scss'

const BUTTON_REGISTRATION_NAME = "Зарегистрироватся"

class ButtonRegistrationWrapper extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.clickEnter)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.clickEnter)
  }

  clickEnter = (e) => {
    if (e.keyCode === 13) {
   //   onRegistration(registrationItems)
    }
  }

  render() {
    const { registrationItems, onRegistration, dataState, token } = this.props

    registrationItems["token"] = token;
    return (
      <ButtonPrimary handleClick={onRegistration.bind(this, registrationItems, "token")}>
        {
          dataState === states.REQUEST
            ? <div className={styles.container_preloader}><PreloaderLight /></div>
            : BUTTON_REGISTRATION_NAME
        }
      </ButtonPrimary>
    )
  }
}

export default withRegistration(ButtonRegistrationWrapper)