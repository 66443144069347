import React from 'react';

import styles from './employeesBookmarkCounterWrapper.module.scss'

const employeesBookmarkCounterWrapper = props => {
  return (
    <div className={styles.employeesBookmarkCounterWrapper}>
      {props.children}
    </div>
  )
}

export default employeesBookmarkCounterWrapper