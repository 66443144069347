import {  
  RESPONSE_EMPLOYEE_GEO_ZONES,
  REQUEST_EMPLOYEE_GEO_ZONES_FAILURE,
  REQUEST_EMPLOYEE_GEO_ZONES,
  RESET_REQUEST_EMPLOYEE_GEO_ZONES,
} from "../actions/employeeGeoZonesAction";

import { states as queriesStates } from "./queriesStates";

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  employeeGeoZones: [],
};

const employeeGeoZonesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_REQUEST_EMPLOYEE_GEO_ZONES: {
      return Object.assign({}, state, initialState);
    }
    case REQUEST_EMPLOYEE_GEO_ZONES:{
      return  Object.assign({}, state, {
        dataState: queriesStates.REQUEST,
        employeeGeoZones: [],
      });
    }
    case RESPONSE_EMPLOYEE_GEO_ZONES: {
      return Object.assign({}, state, {
        dataState: queriesStates.READY,
        employeeGeoZones: action.lastStates.employeeGeoZones,
      });
    }
    case REQUEST_EMPLOYEE_GEO_ZONES_FAILURE: {
      return Object.assign({}, state, {
        dataState: queriesStates.FAILURE,
        errors: action.errors,
      });
    }
    default:
      return state;
  }
};

export default employeeGeoZonesReducer;
