import React, { Component } from "react";
import { withEmployeeSettings } from "../../../shared/containers/containerEmployeeSettings";
import { withChoosedEmployee } from "../../../shared/containers/containerChoosedEmployee";
import EmployeeReprocces from "./employeeReprocces";
import EmployeeSettingItem from "./employeeSettingItem";
import EmployeeSettingGroupItem from "./employeeSettingGroupItem";
import { states } from "../../../reducers/queriesStates";
import styles from "./employeeSetting.module.scss";
import { Scrollbars } from "react-custom-scrollbars";
import { v4 as uuid_v4 } from "uuid";

class EmployeeSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: [],
    };
  }

  componentDidMount() {
    const { choosedEmployee, onGetEmployeeSettings } = this.props;
    onGetEmployeeSettings(choosedEmployee.employeeId);
  }

  componentDidUpdate(prevProps) {
    const { employeeSettings, choosedEmployee, onGetEmployeeSettings } = this.props;

    if (choosedEmployee.employeeId !== prevProps.choosedEmployee.employeeId) {
      onGetEmployeeSettings(choosedEmployee.employeeId);
    }

    if (prevProps.employeeSettings.dataState === states.REQUEST && employeeSettings.dataState === states.READY) {
      this.setState({ settings: employeeSettings.settings });
    }
  }

  render() {
    const { settings } = this.state;
    const { choosedEmployee } = this.props;

    if (settings.length === 0) {
      return null;
    }

    return (
      <div>
        <EmployeeReprocces employeeId={choosedEmployee.employeeId} />
        {settings.length > 0 ? <h2>Настройки обработки данных:</h2> : null}

        <Scrollbars style={{ height: "350px" }} autoHide={true}>
          <div className={styles.employeeSettingContainer}>
            {settings.map((setting) => {
              return (
                <div key={uuid_v4()} className={styles.employeeSetting}>
                  {setting.dependents.length === 0 ? (
                    <EmployeeSettingItem setting={setting.item} id={choosedEmployee.employeeId} />
                  ) : (
                    <EmployeeSettingGroupItem setting={setting} id={choosedEmployee.employeeId} />
                  )}
                </div>
              );
            })}
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default withEmployeeSettings(withChoosedEmployee(EmployeeSettings));
