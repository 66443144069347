const daysWeekEnum = {
  NONE: 0,
  MON: 1,
  TUE: 2,
  WED: 4,
  THU: 8,
  FRI: 16,
  SAT: 32,
  SUN: 64,
};

export const getDays = () => {
  return daysWeekEnum;
};

export const getDaysWeekState = (schedule) => {
  if (!schedule)
    return {
      MON: false,
      TUE: false,
      WED: false,
      THU: false,
      FRI: false,
      SAT: false,
      SUN: false,
    };

  const { daysWeek } = schedule;

  return {
    MON: daysWeek & daysWeekEnum.MON,
    TUE: daysWeek & daysWeekEnum.TUE,
    WED: daysWeek & daysWeekEnum.WED,
    THU: daysWeek & daysWeekEnum.THU,
    FRI: daysWeek & daysWeekEnum.FRI,
    SAT: daysWeek & daysWeekEnum.SAT,
    SUN: daysWeek & daysWeekEnum.SUN,
  };
};

export const getDaysWeekLocal = (schedule) => {
  const { daysWeek } = schedule;
  const hour =
    (Number(schedule.timeStart.split(":")[0]) === 0 ? 24 : Number(schedule.timeStart.split(":")[0])) -
    Number(new Date().getTimezoneOffset() / 60);

  let days = 0;
  if (hour >= 24) {
    if (daysWeek & daysWeekEnum.SUN) {
      days +=daysWeekEnum.MON;
    }

    if (daysWeek & daysWeekEnum.MON) {
      days +=daysWeekEnum.TUE;
    }

    if (daysWeek & daysWeekEnum.TUE) {
      days +=daysWeekEnum.WED;
    }

    if (daysWeek & daysWeekEnum.WED) {
      days +=daysWeekEnum.THU;
    }

    if (daysWeek & daysWeekEnum.THU) {
      days +=daysWeekEnum.FRI;
    }

    if (daysWeek & daysWeekEnum.FRI) {
      days +=daysWeekEnum.SAT;
    }

    if (daysWeek & daysWeekEnum.SAT) {
      days +=daysWeekEnum.SUN;
    }
  } else {
    days = daysWeek;
  }

  return days;
};

export const getDaysWeekUTC = (schedule) => {
  const { daysWeek } = schedule;
  const hour =
    (Number(schedule.timeStart.split(":")[0]) === 0 ? 24 : Number(schedule.timeStart.split(":")[0])) -
    Number(new Date().getTimezoneOffset() / 60);

  let days = 0;
  if (hour >= 24) {
    if (daysWeek & daysWeekEnum.MON) {
      days +=daysWeekEnum.SUN;
    }

    if (daysWeek & daysWeekEnum.TUE) {
      days +=daysWeekEnum.MON;
    }

    if (daysWeek & daysWeekEnum.WED) {
      days +=daysWeekEnum.TUE;
    }

    if (daysWeek & daysWeekEnum.THU) {
      days +=daysWeekEnum.WED;
    }

    if (daysWeek & daysWeekEnum.FRI) {
      days +=daysWeekEnum.THU;
    }

    if (daysWeek & daysWeekEnum.SAT) {
      days +=daysWeekEnum.FRI;
    }

    if (daysWeek & daysWeekEnum.SUN) {
      days +=daysWeekEnum.SAT;
    }
  } else {
    days = daysWeek;
  }

  return days;
};

export const convertDayWeekName = (value) => {
  switch (value) {
    case "MON":
      return "Пн";
    case "TUE":
      return "Вт";
    case "WED":
      return "Ср";
    case "THU":
      return "Чт";
    case "FRI":
      return "Пт";
    case "SAT":
      return "Сб";
    case "SUN":
      return "Вс";
    default:
      return "";
  }
};

export default daysWeekEnum;
