import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  deleteEmployeeBookmark as onDeleteEmployeeBookmark
} from '../../actions/deleteEmployeeBookmarkActions'

const mapStateToProps = (state) => ({
  deleteEmployeeBookmark: {
    dataState: state.deleteEmployeeBookmark.dataState,
    errors: get(state, ['deleteEmployeeBookmark', 'errors', 'data', null])
  }
})

const mapDispatchToProps = {
  onDeleteEmployeeBookmark
}

const withDeleteEmployeeBookmark = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withDeleteEmployeeBookmark }