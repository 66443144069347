"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";
import configuration from "../../config.json";

const PositionService = {
  Address: function (latlng) {
    return api.get(
      `Positions/Address?lon=${latlng.lng}&lat=${latlng.lat}`,
      WithToken.headersToken({}, configuration.cookieName)
    );
  },
  Building: function (options) {
    return api.get(
      `Positions/Buildings?lon=${options.lon}&lat=${options.lat}&distance=${options.distance}`,
            WithToken.headersToken({}, configuration.cookieName)
    );
  },
};

export default PositionService;
