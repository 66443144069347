import React from "react";
import NavigationItem from "./navigationItem";
import styles from "./navigation.module.scss";
import configuration from "./../config.json";
import CookieService from "./../shared/services/cookiesService";

const menu = [
  {
    title: "Компании",
    link: "/Companies",
    permission: 10001,
  },
  {
    title: "Сотрудники",
    link: "/employees",
  },
  {
    title: "Задания",
    link: "/objectives",
    permission: 60001,
  },
  {
    title: "Пользователи",
    link: "/users",
    permission: 20004,
  },
  {
    title: "Скачать кофигуратор",
    link: "/",
  },
  {
    title: "Руководство по эксплуатации",
    link: "/",
  },
];

const Navigation = function () {
  const permissions = CookieService.getCookie(configuration.permissionsCookie);
  return (
    <div className={styles.navbarWrapper}>
      {menu.map((item, key) => {
        if (item.permission && !permissions.includes(item.permission)) {
          return null;
        }

        return <NavigationItem content={item} key={key} />;
      })}
    </div>
  );
};

export default Navigation;
