import React from "react";

import FormItemsInlineGroup from "../../../shared/items/static/formItemsInlineGroup";
import FormItemsInline from "../../../shared/items/static/formItemsInline";
import FormItem from "../../../shared/items/static/formItem";
import InputDate from "../../../shared/items/contols/inputDate";
import InputTime from "../../../shared/items/contols/inputTime";
import CookieService from "../../../shared/services/cookiesService";

const TimeRangeIntellegence = (props) => {
  const { onHandleChangeStore, valueStore } = props;

  const logPermmisions = CookieService.getPermissions("permissionTN").includes(30001);
  return (
    <React.Fragment>
      <FormItemsInlineGroup>
        <FormItemsInline>
          <div style={{ width: "130px" }}>
            <FormItem title="Дата начала">
              <InputDate
                name="rangeStartDate"
                onHandleChangeStore={onHandleChangeStore.bind(this, "rangeStartDate")}
                valueStore={valueStore}
                maxDate={new Date()}
              />
            </FormItem>
          </div>
          <div style={{ width: "110px" }}>
            <FormItem title="Время начала">
              <InputTime
                name="rangeStartTime"
                onHandleChangeStore={onHandleChangeStore.bind(this, "rangeStartTime")}
                valueStore={valueStore}
              />
            </FormItem>
          </div>
        </FormItemsInline>
        <FormItemsInline>
          <div style={{ width: "130px" }}>
            <FormItem title="Дата конца">
              <InputDate
                name="rangeEndDate"
                onHandleChangeStore={onHandleChangeStore.bind(this, "rangeEndDate")}
                valueStore={valueStore}
                minDate={valueStore.rangeStartDate}
              />
            </FormItem>
          </div>
          <div style={{ width: "110px" }}>
            <FormItem title="Время время">
              <InputTime
                name="rangeEndTime"
                onHandleChangeStore={onHandleChangeStore.bind(this, "rangeEndTime")}
                valueStore={valueStore}
              />
            </FormItem>
          </div>
        </FormItemsInline>
      </FormItemsInlineGroup>
      {logPermmisions ? (
        <div style={{ padding: "10px" }}>
          <input type="checkbox" id="checkBox-locationNotProcessed" checked={valueStore.locationNotProcessed}></input>
          <label id="checkBox-locationNotProcessedLabel" for="checkBox-locationNotProcessed">
            Не обработанные координаты
          </label>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default React.memo(TimeRangeIntellegence);
