import React, { Component } from "react";
import { withChoosedEmployee } from "../../../shared/containers/containerChoosedEmployee";
import { getEmployeesTypeName } from "./static.employeesTypes";
import EmployeesListItem from "./employeesListItem";
import EmployeeContent from "./employeListItemSelectContent";
import styles from "./employeesListSelectedItem.module.scss";
import employeeListStyles from "./employeesList.module.scss";
import { v4 as uuid_v4 } from "uuid";

class EmployeesListSelectedItem extends Component {
  componentDidMount() {
    const { handleScrollTo, onChooseEmployee, data } = this.props;
    const selector = document.querySelector(`.${employeeListStyles.container_employeesListItem}.selected`).offsetTop;
    handleScrollTo(selector);

    onChooseEmployee(data);
  }

  getStatusStyles() {
    const { data } = this.props;

    switch (getEmployeesTypeName(data.status.id)) {
      case "noData":
        return styles.mDEmployeeItems_noData;
      case "sleep":
        return styles.mDEmployeeItems_inSleep;
      case "work":
        return styles.mDEmployeeItems_work;
      case "charging":
        return styles.mDEmployeeItems_charging;
      case "offSleep":
        return styles.mDEmployeeItems_offSleep;
      case "alarm":
        return styles.mDEmployeeItems_alarm;
      case "poweredFrom":
        return styles.mDEmployeeItems_poweredFrom;
      default:
        return null;
    }
  }

  render() {
    const { data } = this.props;

    return (
      <div kye={uuid_v4()} className={styles.employeesListSelectedItem}>
        <div  kye={uuid_v4()} className={styles.container_employeesListItemWrapper}>
          <EmployeesListItem kye={uuid_v4()} data={data} />
        </div>
        <EmployeeContent kye={uuid_v4()} data={data} />
      </div>
    );
  }
}

export default withChoosedEmployee(EmployeesListSelectedItem);
