import React, { Component } from "react";
import { v4 as uuid_v4 } from "uuid";
import Logo from "./../../shared/logo";
import Navigation from "./../../shared/navigation";
import UserState from "./../../shared/userState";
import NotificationsHeader from "../../shared/notifications/notificationsHeader";
import styles from "./sideHead.module.scss";
import EmployeesLastStateService from "./../../components/sections/employees/employeesLastStatesService";
import ReactAudioPlayer from "react-audio-player";
import alarmSound from "./../../sounds/AlarmSound.ogg";
import { withEmployeesLastState } from "../../shared/containers/containerEmployeeLastState";
import { withFilterEmployees } from "../../shared/containers/containerFilterEmployees";
import { states } from "../../reducers/queriesStates";

let employeesLastStateService = null;

class SideHead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSideBarOpen: props.isSideBarOpen,
      isAlarm: false,
    };

    employeesLastStateService = EmployeesLastStateService.getInstance();
  }

  alarm() {
    const { isAlarm } = this.state;

    if (!isAlarm) {
      this.setState({ isAlarm: true });
    }
  }

  componentDidMount() {
    employeesLastStateService.setCallBack("alarm", this.alarm.bind(this));
    const { lastStates, onGetEmployeesLastStates, filterEmployees } = this.props;

    if (lastStates.dataState === states.NOT_REQUESTED || lastStates.dataState === states.READY) {
      onGetEmployeesLastStates(filterEmployees.filterItems);
    }
  }

  componentDidUpdate(prevProps) {
    const { onGetEmployeesLastStates, filterEmployees, lastStates } = this.props;

    if (
      lastStates.dataState === states.NOT_REQUESTED ||
      (filterEmployees.dataState === states.READY && prevProps.filterEmployees.dataState === states.REQUEST)
    ) {
      onGetEmployeesLastStates(filterEmployees.filterItems);
    }

    if (prevProps.lastStates.dataState === states.REQUEST && lastStates.dataState === states.READY) {
      employeesLastStateService.setEmployees(lastStates.employees);
    }
  }

  render() {
    const { isAlarm } = this.state;

    return (
      <div className={isAlarm ? styles.sideHeadAlarm : styles.sideHead}>
        <Logo />
        {isAlarm ? (
          <ReactAudioPlayer
            key={uuid_v4()}
            src={alarmSound}
            autoPlay={true}
            loop={true}
            muted={false}
            preload={"auto"}
            controls={false}
          />
        ) : null}
        <div className={styles.container_navigation}>
          <Navigation />
        </div>
        <div className={styles.container_userNotification}>
          <NotificationsHeader />
        </div>
        <div>
          <UserState />
        </div>
      </div>
    );
  }
}

export default withEmployeesLastState(withFilterEmployees(SideHead));
