import React from 'react'

import Logo from '../../shared/logo'
import SignInFooter from '../../shared/footer/signinFooter'
import SignInForm from './signInForm'
import ButtonLoginWrapper from './buttonLoginWrapper'
import ButtonDemoWrapper from './buttonDemoWrapper'
import SignInValidateResuld from './signInValidateResuld'

import styles from './signIn.module.scss'

const SignIn = () => {
  return (
    <div className={styles.signInWrapper}>
      <div className={styles.signInForm}>
        <div className={styles.logo_container}>
          <Logo />
        </div>

        <SignInForm />
        <div style={{textAlign: 'center', padding: '15px'}}>
          Забыли пароль? <b><a href={'/accounts/restore'}>Восстановить</a></b>
        </div>

        <div className={styles.button_container}>
          <ButtonLoginWrapper />
          <div style={{ marginTop: '5px' }}>
            <ButtonDemoWrapper />
          </div>
        </div>
        <SignInValidateResuld />

      </div>

      <SignInFooter />
    </div>
  )
}

export default React.memo(SignIn)