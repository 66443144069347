import React from "react";

import { withFilterEmployees } from "../../../shared/containers/containerFilterEmployees";

import FormHorisontItem from "../../../shared/items/static/formHorisontItem";
import Checkbox from "../../../shared/items/contols/checkbox";
import { employeeStatuses } from "./static.employeeStatuses";

const EmployeesStatusesFilter = (props) => {
  const { onHandleChangeStore, filterEmployees } = props;

  return (
    <React.Fragment>
      {/*   
      <FormHorisontItem title={employeeStatuses.offSleep.title}>
        <Checkbox
          name={employeeStatuses.offSleep.name}
          valueStore={filterEmployees.filterItems}
          handleChange={onHandleChangeStore}        
        />
      </FormHorisontItem> */}

      <FormHorisontItem title={employeeStatuses.alarm.title}>
        <Checkbox
          name={employeeStatuses.alarm.name}
          valueStore={filterEmployees.filterItems}
          handleChange={onHandleChangeStore}
        />
      </FormHorisontItem>

      <FormHorisontItem title={employeeStatuses.work.title}>
        <Checkbox
          name={employeeStatuses.work.name}
          valueStore={filterEmployees.filterItems}
          handleChange={onHandleChangeStore}
        />
      </FormHorisontItem>

      <FormHorisontItem title={"Батарея: зарядка/питание от сети (100% заряда)"}>
        <Checkbox
          name={employeeStatuses.charging.name}
          valueStore={filterEmployees.filterItems}
          handleChange={onHandleChangeStore}
        />
      </FormHorisontItem>

      <FormHorisontItem title={"Режем сна"}>
        <Checkbox
          name={employeeStatuses.inSleep.name}
          valueStore={filterEmployees.filterItems}
          handleChange={onHandleChangeStore}
        />
      </FormHorisontItem>

      <FormHorisontItem title={employeeStatuses.noData.title}>
        <Checkbox
          name={employeeStatuses.noData.name}
          valueStore={filterEmployees.filterItems}
          handleChange={onHandleChangeStore}
        />
      </FormHorisontItem>
    </React.Fragment>
  );
};

export default withFilterEmployees(React.memo(EmployeesStatusesFilter));
