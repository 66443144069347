import EmployeesCompaniesService from '../shared/services/employeesCompaniesService.js'

const RESPONSE_EMPLOYEES_COMPANIES = 'RESPONSE_EMPLOYEES_COMPANIES'
const REQUEST_EMPLOYEES_COMPANIES_FAILURE = 'REQUEST_EMPLOYEES_COMPANIES_FAILURE'
const STATE_NOT_REQUESTED = 'STATE_NOT_REQUESTED'

const responseGetEmployeesCompaniesAction = (data) => {
  return {
    type: RESPONSE_EMPLOYEES_COMPANIES,
    data,
  }
}
const requestFilialsNotRequestAction = () => {
  return {
    type: STATE_NOT_REQUESTED,
  };
};


const requestGetEmployeesCompaniesfailureAction = (errors) => {
  return {
    type: REQUEST_EMPLOYEES_COMPANIES_FAILURE,
    errors,
  }
}

const getEmployeesCompanies = () => {
  return (dispatch) => {
    EmployeesCompaniesService.getFilials()
      .then((result) => {     
        dispatch(responseGetEmployeesCompaniesAction(result.data.companies))
      })
      .catch((error) => {
        dispatch(requestGetEmployeesCompaniesfailureAction(error.response))

        console.error(error)
      })
  }
}

export {
  getEmployeesCompanies,
  responseGetEmployeesCompaniesAction,
  requestGetEmployeesCompaniesfailureAction,
  requestFilialsNotRequestAction,
  RESPONSE_EMPLOYEES_COMPANIES,
  REQUEST_EMPLOYEES_COMPANIES_FAILURE,
  STATE_NOT_REQUESTED
}
