import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  getEmployeesGroups as onGetEmployeesGroups
} from '../../actions/employeesGroupsActions'

const mapStateToProps = (state) => ({
  employeesGroups: {
    dataState: state.employeesGroups.dataState,
    groups: get(state, ['employeesGroups', 'groups'], [])
  }
});

const mapDispatchToProps = {
  onGetEmployeesGroups
}

const withEmployeesGroups = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withEmployeesGroups }