import intellegenceService from "../shared/services/intellegenceService";
import dateTime from "../shared/services/date.service";
import { clearTimerangeIntellegenceAction } from "./timerangeIntellegenceActions";

const REQUEST_GET_INMOMENT_INTELLEGENCE = "REQUEST_GET_INMOMENT_INTELLEGENCE";
const RESPONSE_GET_INMOMENT_INTELLEGENCE = "RESPONSE_GET_INMOMENT_INTELLEGENCE";
const REQUEST_GET_INMOMENT_INTELLEGENCE_FAILURE = "REQUEST_GET_INMOMENT_INTELLEGENCE_FAILURE";

const REQUEST_UPDATE_INMOMENT_INTELLEGENCE = "REQUEST_UPDATE_INMOMENT_INTELLEGENCE";
const RESPONSE_UPDATE_INMOMENT_INTELLEGENCE = "RESPONSE_UPDATE_INMOMENT_INTELLEGENCE";

const SET_INMOMENT_DATE = "SET_INMOMENT_DATE";

const CLEAR_INMOMENT_INTELLEGENCE = "CLEAR_INMOMENT_INTELLEGENCE";

const requestGetInmomentIntellegenceAction = () => {
  return {
    type: REQUEST_GET_INMOMENT_INTELLEGENCE,
  };
};

const responseGetInmomentIntellegenceAction = (data) => {
  return {
    type: RESPONSE_GET_INMOMENT_INTELLEGENCE,
    data,
  };
};

const requestGetInmomentIntellegenceFailureAction = (errors) => {
  return {
    type: REQUEST_GET_INMOMENT_INTELLEGENCE_FAILURE,
    errors,
  };
};

const getInmomentIntellegence = (options) => {
  return (dispatch) => {
    dispatch(requestGetInmomentIntellegenceAction());

    const date = new Date(options.date);

    intellegenceService
      .getIntellegencePoint({
        id: options.employeeId,
        date: dateTime.toUrlFormat(dateTime.withEmptyTime(date)),
        locationNotProcessed: options.locationNotProcessed,
      })
      .then((result) => {
        dispatch(responseGetInmomentIntellegenceAction(result.data));
      })
      .catch((error) => {
        dispatch(requestGetInmomentIntellegenceFailureAction(error.response));
      });
  };
};

const requestUpdateInmomentIntellegenceAction = () => {
  return {
    type: REQUEST_UPDATE_INMOMENT_INTELLEGENCE,
  };
};

const responseUpdateInmomentIntellegenceAction = () => {
  return {
    type: RESPONSE_UPDATE_INMOMENT_INTELLEGENCE,
  };
};

const updateInmomentIntellegence = () => {
  return (dispatch) => {
    dispatch(requestUpdateInmomentIntellegenceAction());

    setTimeout(() => {
      dispatch(responseUpdateInmomentIntellegenceAction());
    }, 10);
  };
};

const setInmomentDateAction = (data) => {
  return {
    type: SET_INMOMENT_DATE,
    data,
  };
};

const setInmomentDate = (data) => {
  return (dispatch) => {
    dispatch(setInmomentDateAction(data));
  };
};

const clearInmomentIntellegenceAction = () => {
  return {
    type: CLEAR_INMOMENT_INTELLEGENCE,
  };
};

const clearIntellegence = () => {
  return (dispatch) => {
    dispatch(clearInmomentIntellegenceAction());
    dispatch(clearTimerangeIntellegenceAction());
  };
};

export {
  getInmomentIntellegence,
  requestGetInmomentIntellegenceAction,
  responseGetInmomentIntellegenceAction,
  requestGetInmomentIntellegenceFailureAction,
  REQUEST_GET_INMOMENT_INTELLEGENCE,
  RESPONSE_GET_INMOMENT_INTELLEGENCE,
  REQUEST_GET_INMOMENT_INTELLEGENCE_FAILURE,
  updateInmomentIntellegence,
  requestUpdateInmomentIntellegenceAction,
  responseUpdateInmomentIntellegenceAction,
  REQUEST_UPDATE_INMOMENT_INTELLEGENCE,
  RESPONSE_UPDATE_INMOMENT_INTELLEGENCE,
  setInmomentDate,
  setInmomentDateAction,
  SET_INMOMENT_DATE,
  clearIntellegence,
  clearInmomentIntellegenceAction,
  CLEAR_INMOMENT_INTELLEGENCE,
};
