"use stric";

const intellegenceTabs = [

  {
    title: "Маршрут",
    name: "intellegence",
  },
  {
    title: "Информация о сотруднике",
    name: "information",
  },
  {
    title: "Настройки",
    name: "settings",
  },
];

export { intellegenceTabs };
