import {
  REQUEST_EMPLOYEES_SETTINGS,
  RESPONSE_EMPLOYEES_SETTINGS,
  REQUEST_EMPLOYEES_SETTINGS_FAILURE,
  REQUEST_EMPLOYEES_REPROCCES,
  RESPONSE_EMPLOYEES_REPROCCES,
  REQUEST_EMPLOYEES_REPROCCES_FAILURE,
} from "../actions/employeeSettingsAction";

import { states as queriesStates } from "./queriesStates";

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  reproccesDataState: queriesStates.NOT_REQUESTED,
  settings: [],
  reprocces: null,
};
const employeeSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESPONSE_EMPLOYEES_SETTINGS: {
      return Object.assign({}, state, {
        dataState: queriesStates.READY,
        settings: action.data,
        reprocces: null,
      });
    }
    case REQUEST_EMPLOYEES_SETTINGS: {
      return Object.assign({}, state, {
        dataState: queriesStates.REQUEST,
        reprocces: null,
      });
    }
    case REQUEST_EMPLOYEES_SETTINGS_FAILURE: {
      return Object.assign({}, state, {
        dataState: queriesStates.FAILURE,
        reprocces: null,
        errors: action.errors,
      });
    }
    case RESPONSE_EMPLOYEES_REPROCCES: {
      return Object.assign({}, state, {
        reproccesDataState: queriesStates.READY,
        reprocces: action.data,
      });
    }
    case REQUEST_EMPLOYEES_REPROCCES: {
      return Object.assign({}, state, {
        reproccesDataState: queriesStates.REQUEST,
        reprocces: null,
      });
    }
    case REQUEST_EMPLOYEES_REPROCCES_FAILURE: {
      return Object.assign({}, state, {
        reproccesDataState: queriesStates.FAILURE,
        reprocces: false,
        errors: action.errors,
      });
    }
    default:
      return state;
  }
};

export default employeeSettingsReducer;
