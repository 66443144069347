import {
  RESPONSE_OPEN_MODAL,
  RESPONSE_CLOSE_MODAL
} from './../actions/modalActions'

import { states as queriesStates } from './queriesStates'

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  isOpen: false
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESPONSE_OPEN_MODAL:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY + '_OPEN_MODAL',
          isOpen: true
        }
      )
    case RESPONSE_CLOSE_MODAL:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY + '_CLOSE_MODAL',
          isOpen: false
        }
      )
    default:
      return state
  }
}

export default modalReducer

