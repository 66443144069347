import React from 'react';

import { withFilterEmployees } from '../../../shared/containers/containerFilterEmployees'

import ButtonMediumDefault from '../../../shared/items/contols/buttonMediumDefault'

const ClearEmployeesFilter = props => {
  const { onHandleClearFilter, filterEmployees } = props

  return (
    filterEmployees.filterItems.filterCount !== 0
      ? <ButtonMediumDefault title="Сбросить" handleClick={onHandleClearFilter} />
      : null
  )
}

export default withFilterEmployees(React.memo(ClearEmployeesFilter))
