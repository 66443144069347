import { connect } from 'react-redux'
import get from 'lodash/get'

import { 
    sendMailRestorePasseord as onCodeForRestorePasseord,
    restorePassword as onRestorePasseord,
    requestCodeForRestorePasseordAction as onRequestCodeForRestorePasseordAction,
    responseCodeForRestorePasseordAction as onResponseCodeForRestorePasseordAction,
    requestCodeForRestorePasseordFailureAction as onRequestCodeForRestorePasseordFailureAction,
    changeStoreCodeForRestorePasseordAction as onHandleChangeStore,
} from '../../actions/restorePasswordActions'

const mapStateToProps = (state) => ({
    dataState: state.restorePassword.dataState,
    data: get(state, ['restorePassword', 'data'], []),
    restorePasswordItems: get(state, ['restorePassword', 'restorePasswordItems'], []),
    errors: get(state, ['restorePassword', 'errors', 'data'], null),
    errorsModel: get(state, ['restorePassword', 'errors', 'data', 'model'], null),
});

const mapDispatchToProps = {
    onCodeForRestorePasseord,
    onRequestCodeForRestorePasseordAction,
    onResponseCodeForRestorePasseordAction,
    onRequestCodeForRestorePasseordFailureAction,
    onHandleChangeStore, 
    onRestorePasseord
}

const withRestorePasseord = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withRestorePasseord }