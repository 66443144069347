import React from "react";

import { withEmployeeMapState } from "../../../shared/containers/containerEmployeeMapState";

import ButtonIconDefault from "../../../shared/items/contols/buttonIconDefault";
import Icon from "../../../shared/items/static/icon";
import { mapState } from "./static.employeeMapState";

import styles from "./employeesMainDevicesWrapper.module.scss";

const EmployeesMainIntimerangeMapControls = (props) => {
  const { onChangeEmployeeMapState, employeeMapState } = props;

  if (employeeMapState.mapSize === mapState.fullSize.name) {
    return (
      <React.Fragment>
        <div className={styles.container_controlItem}>
          <ButtonIconDefault>
            <Icon name="Union" />
          </ButtonIconDefault>
        </div>
        <div className={styles.container_controlItem}>
          <ButtonIconDefault handleClick={onChangeEmployeeMapState.bind(this, "mapSize", mapState.fullWidth.name)}>
            <Icon name="icon-2" />
          </ButtonIconDefault>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default withEmployeeMapState(React.memo(EmployeesMainIntimerangeMapControls));
