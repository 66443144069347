import React from 'react';

import { withFilterEmployees } from './containers/containerFilterEmployees'
import { withEmployees } from './containers/containerEmployees'

import styles from './countOnPage.module.scss'

const CountOnPage = props => {
  const { filterCount } = props.employees
  const { page, count } = props.filterEmployees.filterItems
  const maxPage = count * page
  const minPage = maxPage - count

  return (
    <div className={styles.countOnPage}>
      {
        count === -1
          ? <span>Показано <b>{filterCount}</b></span>
          : <span>Показаны <b>
            {
              filterCount === 0
                ? minPage
                : minPage + 1
            }
            -
          {
              maxPage >= filterCount
                ? filterCount
                : maxPage
            }
          </b>&nbsp;из&nbsp;<b>{filterCount}</b>
          </span>
      }
    </div>
  )
}

export default withFilterEmployees(withEmployees(React.memo(CountOnPage)))
