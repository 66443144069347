const EmployeeMultipleSelectService = {
  combine(employees, employeeMultipleSelect) {
    const data =
      employeeMultipleSelect.id !== null
        ? employeeMultipleSelect.selected.map((item) => {
            return {
              id: item.id,
              title: item.title,
              isCheck: true,
            };
          })
        : employees;

    const selectedIds = employeeMultipleSelect.selected.map((select) => {
      return select.id;
    });

    if (employeeMultipleSelect.id !== null) {
      for (const employee of employees.filter((item) => {
        return !selectedIds.includes(item.id);
      })) {
        data.push({
          id: employee.id,
          title: employee.title,
          isCheck: false,
        });
      }
    }

    return data;
  },
  type: {
    employeeGeozones: "employeeGeozones",
    objectiveShedules: "employeeObjectiveShedules",
  },
};

export default EmployeeMultipleSelectService;
