import {
  REQUEST_CHANGE_STORE_EMPLOYEE_UPDATE,
  READY_CHANGE_STORE_EMPLOYEE_UPDATE,
} from "../actions/employeeUpdateActions";

import { states } from "./queriesStates";

const initialState = {
  dataState: states.NOT_REQUESTED,
  properties: {},
};

const employeeUpdateReducer = (state = initialState, action) => {
  switch (action.type) {

    case REQUEST_CHANGE_STORE_EMPLOYEE_UPDATE: {
      return Object.assign({}, state, {
        dataState: states.REQUEST,
      });
    }
    case READY_CHANGE_STORE_EMPLOYEE_UPDATE: {
      const { name, value } = action.payload;
      var properties = Object.assign({}, state.properties);

      typeof name === "object" ? name.map((item, index) => {
        properties[item] = value[index];

        return null;
      }) : (properties[name] = value);

      return Object.assign({}, state, {
        dataState: states.READY,
        properties: properties,
      });
    }
    default:
      return state;
  }
};

export default employeeUpdateReducer;
