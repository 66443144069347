const HANDLE_CHANGE_INTELLEGENCE_TAB = 'HANDLE_CHANGE_INTELLEGENCE_TAB'

const responseChangeIntellegenceTab = (name) => {
  return {
    type: HANDLE_CHANGE_INTELLEGENCE_TAB,
    name
  }
}

const handleChangeIntellegenceTab = (name) => {
  return (dispatch) => {
    dispatch(responseChangeIntellegenceTab(name))
  }
}

export {
  handleChangeIntellegenceTab,
  responseChangeIntellegenceTab,
  HANDLE_CHANGE_INTELLEGENCE_TAB,
}
