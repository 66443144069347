const styles = {
  row: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      '& + .MuiFormControlLabel-root': {
        marginLeft: '-20px'
      }
    }
  }
}

export { styles }