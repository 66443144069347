import React, { Component } from 'react'
import Snackbar from '@material-ui/core/Snackbar'

class SnackbarWrapper extends Component {
  render() {
    const { isOpen, children, timer, isClose } = this.props

    return (
      <Snackbar
        open={isOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={timer !== undefined ? timer : null}
        onClose={isClose !== undefined ? isClose : null}
      >
        {children}
      </Snackbar>
    )
  }
}

export default SnackbarWrapper