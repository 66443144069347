import { connect } from 'react-redux'
import get from 'lodash/get'

import {
  getSensors as onGetSensors
} from './../../actions/sensorsActions'

const thisDate = new Date()

const mapStateToProps = (state) => ({
  sensors: {
    dataState: state.sensors.dataState,
    data: {
      date: get(state, ['sensors', 'data', 'date'], thisDate),
      location: get(state, ['sensors', 'data', 'location'], {
        lat: 0,
        long: 0
      }),
      state: get(state, ['sensors', 'data', 'state'], {
        status: 0,
        address: 0
      }),
      sensors: get(state, ['sensors', 'data', 'sensors'], {}),
      feature:  get(state, ['sensors', 'data', 'feature'], {}),
    },
 
    errors: state.sensors.errors,
  }
})

const mapDispatchToProps = {
  onGetSensors
}

const withSensors = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withSensors }