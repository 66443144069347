import React from 'react'

import styles from './formItem.module.scss'

const FormItem = props => {
  const { title } = props

  return (
    <div className={styles.formItem}>
      <span className={styles.formItemTitle}>{title}</span>
      <div className={styles.formItemWrapper}>
        {props.children}
      </div>
    </div>
  )
}

export default React.memo(FormItem)