import {
    REQUEST_USER_UPDATE,
    RESPONSE_USER_UPDATE,
    REQUEST_USER_UPDATE_FAILURE,
  } from '../actions/userUpdateActions'
  
  import { states as queriesStates } from './queriesStates'
  
  const initialState = {
    dataState: queriesStates.NOT_REQUESTED,
    users: [],
  }
  
  const userUpdateReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case REQUEST_USER_UPDATE: {
        return Object.assign(
          {},
          state,
          {
            dataState: queriesStates.REQUEST,
            errors: [],
          }
        )
      }
      case RESPONSE_USER_UPDATE: {
        return Object.assign(
          {},
          state,
          {
            dataState: queriesStates.READY,
          },
          action.data,
        )
      }
      case REQUEST_USER_UPDATE_FAILURE: {
        return Object.assign(
          {},
          state,
          {
            dataState: queriesStates.FAILURE,
            errors: action.errors,
          }
        )
      }
      default:
        return state
    }
  }
  
  export default userUpdateReducer
  