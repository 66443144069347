const REQUEST_CLEAR_FILTER = "REQUEST_CLEAR_FILTER";
const READY_CLEAR_FILTER = "READY_CLEAR_FILTER";
const REQUEST_CHANGE_STORE_EMPLOYEESFILTER = "REQUEST_CHANGE_STORE_EMPLOYEESFILTER";
const READY_CHANGE_STORE_EMPLOYEESFILTER = "READY_CHANGE_STORE_EMPLOYEESFILTER";

const requestClearFilterAction = () => {
  return {
    type: REQUEST_CLEAR_FILTER,
  };
};

const responseClearFilterAction = (clearItems) => {
  return {
    type: READY_CLEAR_FILTER,
    clearItems,
  };
};

const handleClearFilter = () => {
  return (dispatch) => {
    dispatch(requestClearFilterAction());

    setTimeout(() => {
      dispatch(
        responseClearFilterAction({
          inSleep: false,
          work: false,
          charging: false,
          offSleep: false,
          alarm: false,
          poweredFrom: false,
          noData: false,
          workPositions: [],
          groups: [],
          statuses: [],
          companies: [],
        })
      );
    }, 10);
  };
};

const requestChangeStoreEmployeesFilterAction = () => {
  return {
    type: REQUEST_CHANGE_STORE_EMPLOYEESFILTER,
  };
};

const responseChangeStoreEmployeesFilterAction = (name, value) => {
  return {
    type: READY_CHANGE_STORE_EMPLOYEESFILTER,
    payload: { name, value },
  };
};

const handleChangeStoreEmployeesFilter = (name, value) => {
  return (dispatch) => {
    dispatch(requestChangeStoreEmployeesFilterAction());
    setTimeout(() => {
      dispatch(responseChangeStoreEmployeesFilterAction(name, value));
    }, 10);
  };
};

export {
  handleClearFilter,
  requestClearFilterAction,
  responseClearFilterAction,
  REQUEST_CLEAR_FILTER,
  READY_CLEAR_FILTER,
  handleChangeStoreEmployeesFilter,
  requestChangeStoreEmployeesFilterAction,
  responseChangeStoreEmployeesFilterAction,
  REQUEST_CHANGE_STORE_EMPLOYEESFILTER,
  READY_CHANGE_STORE_EMPLOYEESFILTER,
};
