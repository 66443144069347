import React from 'react'

import { withSetEmployeeBookmark } from '../../../shared/containers/containerSetEmployeeBookmark'
import { withDeleteEmployeeBookmark } from '../../../shared/containers/containerDeleteEmployeeBookmark'

import Icon from '../../../shared/items/static/icon'

import styles from './employeesBookmark.module.scss'

const EmployeesBookmark = props => {
  const { isBookmark, employeeId, onSetEmployeeBookmark, onDeleteEmployeeBookmark } = props

  return (
    <div className={styles.employeesBookmark} onClick={!isBookmark ? onSetEmployeeBookmark.bind(this, employeeId) : onDeleteEmployeeBookmark.bind(this, employeeId)}>
      <Icon name="fav1" />
    </div>
  )
}

export default withDeleteEmployeeBookmark(withSetEmployeeBookmark(React.memo(EmployeesBookmark)))