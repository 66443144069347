import React from 'react'

import styles from './formHorisontItem.module.scss'

const FormHorisontItem = props => {
  const { title } = props

  return (
    <div className={styles.formHorisontItem}>
      <span className={styles.formHorisontItemTitle}>{title}</span>
      <div className={styles.formHorisontItemWrapper}>
        {props.children}
      </div>
    </div>
  )
}

export default React.memo(FormHorisontItem)