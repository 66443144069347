import { connect } from "react-redux";
import get from "lodash/get";

import { getPermissions as onGetPermissions } from "../../actions/permissionActions";

const mapStateToProps = (state) => ({
  permissions: {
    dataState: state.permissions.dataState,
    actionType: get(state, ["permissions", "actionType"], null),
    permissions: get(state, ["permissions", "permissions"], []),
  },
});

const mapDispatchToProps = {
  onGetPermissions,
};

const withPermissions = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withPermissions };
