import { isAlarmClastreing } from './isAlarmClastreing';

const employeesClasteringPosition = (employees, distanceBoundsDiaganal) => {
 
    const data = {
        alarmGroup: [],
        simpleGroup: []
    }  

    employees.map((employee) => {
        if (employee.status.id === 5) {
            data.alarmGroup.push(employee);
        }
        else {
            data.simpleGroup.push(employee);
        }

        return null;
    });

    data.simpleGroup.map((simpleItem, index) => {
        if (isAlarmClastreing(simpleItem, data.alarmGroup, distanceBoundsDiaganal)) {
            data.alarmGroup.push(simpleItem)
        }

        return null;
    })

    data.alarmGroup.map((alarmItem) => {
        var index = getIndex(alarmItem, data.simpleGroup)
        if (index !== -1) {
            data.simpleGroup.splice(index, 1);
        }

        return null;
    })

    return data;
}

function getIndex(employee, employees) {
    var index = employees.findIndex(v => {
        return v.id === employee.id;
    })

    return index;
}

export default employeesClasteringPosition