const READY_CHANGE_RANGE_TYPE = 'READY_CHANGE_RANGE_TYPE'

const responseChangeIntellegenceRangeType = (name, value) => {
  return {
    type: READY_CHANGE_RANGE_TYPE,
    name,
    value
  }
}

const changeIntellegenceRangeType = (name, value) => {
  return (dispatch) => {
    dispatch(responseChangeIntellegenceRangeType(name, value))
  }
}

export {
  changeIntellegenceRangeType,
  responseChangeIntellegenceRangeType,
  READY_CHANGE_RANGE_TYPE,
}
