import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  userUpdate as onUserUpdate
} from '../../actions/userUpdateActions'

const mapStateToProps = (state) => ({
 
  users: {
    dataState: state.userUpdate.dataState,    
    id: get(state, ['userUpdate', 'data', 'id'], []),
    isBlocked: get(state, ['userUpdate', 'data', 'isBlocked'], []),
    errors: get(state, ['userUpdate', 'errors', 'data', null])
  }
});

const mapDispatchToProps = {
  onUserUpdate
}

const withUserUpdate = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withUserUpdate }