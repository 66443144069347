import {
  REQUEST_NOTIFICATION_TYPES,
  RESPONSE_NOTIFICATION_TYPES,
  REQUES_NOTIFICATION_TYPES_FAILURE,
  REQUEST_NOTIFICATIONS,
  RESPONSE_NOTIFICATIONS,
  REQUES_NOTIFICATIONS_FAILURE,
} from "../actions/notificatiponsActions";

import { states as queriesStates } from "./queriesStates";

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  count: 0,
  notifications: [],
  notificationTypes: [],
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESPONSE_NOTIFICATION_TYPES: {
      return Object.assign({}, state, {
        dataState: queriesStates.READY,
        count: state.count,
        notifications: state.notifications,
        notificationTypes: action.notificationTypes,
      });
    }
    case RESPONSE_NOTIFICATIONS: {
      return Object.assign({}, state, {
        dataState: queriesStates.READY,
        count: action.count,
        notifications: action.notifications,
        notificationTypes: state.notificationTypes,
      });
    }
    case REQUEST_NOTIFICATION_TYPES:
      return Object.assign({}, state, {
        dataState: queriesStates.REQUEST,
      });
    case REQUEST_NOTIFICATIONS:
      return Object.assign({}, state, {
        dataState: queriesStates.REQUEST,
      });
    case REQUES_NOTIFICATION_TYPES_FAILURE:
      return state;
    case REQUES_NOTIFICATIONS_FAILURE:
      return state;
    default:
      return state;
  }
};

export default notificationsReducer;
