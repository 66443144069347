import intellegenceService from '../shared/services/intellegenceService'

const REQUEST_GET_DASHBOARDS = 'REQUEST_GET_DASHBOARDS'
const RESPONSE_GET_DASHBOARDS = 'RESPONSE_GET_DASHBOARDS'
const REQUSET_GET_DASHBOARDS_FAILURE = 'REQUSET_GET_DASHBOARDS_FAILURE'

const requestGetDashboardsAction = () => {
  return {
    type: REQUEST_GET_DASHBOARDS
  }
}

const responseGetDashboardsAction = (data) => {
  return {
    type: RESPONSE_GET_DASHBOARDS,
    data
  }
}

const requestGetDashboardsFailureAction = (errors) => {
  return {
    type: REQUSET_GET_DASHBOARDS_FAILURE,
    errors
  }
}

const getDashboards = () => {
  return (dispatch) => {
    dispatch(requestGetDashboardsAction())

    intellegenceService.getDashboards()
      .then((result) => {
        dispatch(responseGetDashboardsAction(result.data))
      })
      .catch((error) => {
        dispatch(requestGetDashboardsFailureAction(error.response))
      })
  }
}

export {
  getDashboards,
  requestGetDashboardsAction,
  responseGetDashboardsAction,
  requestGetDashboardsFailureAction,
  REQUEST_GET_DASHBOARDS,
  RESPONSE_GET_DASHBOARDS,
  REQUSET_GET_DASHBOARDS_FAILURE
}