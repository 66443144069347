import React, { Component } from "react";
import Control from "react-leaflet-custom-control";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import "./geoZoneParametrsContainer.css";
import { List as ListIcon } from "@mui/icons-material";
import "leaflet/dist/leaflet.css";
import "leaflet-loading/src/Control.Loading.css";
import {
  MapContainer,
  MapConsumer,
  TileLayer,
  LayersControl,
  FeatureGroup,
  Polygon,
  Circle,
  Tooltip as TooltipLayer,
} from "react-leaflet";
import L from "leaflet";
import { EditControl } from "./../../react-leaflet-draw/src/index";
import "leaflet-loading";
import MapLastPositionMarkers from "./mapLastStatesMarkers";
import GeoJsonBuildings from "./geoJsonBuildings";
import config from "./../../shared/services/apiConfig";
import WithToken from "./../../shared/services/tokenService";
import "leaflet-loading";
import styles from "./employeesMap.module.scss";
import { CustomLeafletControl } from "./customLeafletControl";
import BaseLayersControlWrapper from "./baseLayersControlWrapper";
import { withEmployeesLastState } from "./../../shared/containers/containerEmployeeLastState";
import { withFilterEmployees } from "./../../shared/containers/containerFilterEmployees";
import { withEmployeeGeoZones } from "./../../shared/containers/containerEmployeeGeoZones";
import { withEmployeeMultipleSelect } from "./../../shared/containers/containerEmployeeMultipleSelect";
import { states } from "./../../reducers/queriesStates";
import mapLayerControl from "./../../shared/map/mapLayerControl";
import { v4 as uuid_v4 } from "uuid";
import configuration from "./../../config.json";
import CookieService from "./../../shared/services/cookiesService";
import EmployeesLastStateService from "./../../components/sections/employees/employeesLastStatesService";
import EmployeeMultipleSelectService from "./../../shared/services/employeeMultipleSelectService";

const circleToPolygon = require("circle-to-polygon");
const multipleSelectGeozone = EmployeeMultipleSelectService.type.employeeGeozones;
const baseURL = config.get("baseURL");

let employeesLastStateService = null;

class EmployeesMapLastStates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employees: props.lastStates.employees,
      filterFilialsCount: 0,
      employeeMultipleSelect: props.employeeMultipleSelect,
      employeeGeoZones: props.employeeGeoZones.employeeGeoZones,
      onBaselayerChange: this.baselayerChange.bind(this),
      onOverlayAdd: this.overlayAdd.bind(this),
      onOverlayRemove: this.overlayerRemove.bind(this),
      onSelectGeozone: this.onSelectGeizone.bind(this),
      crs: mapLayerControl.layerControl.crs,
      openGeozoneList: false,
      _key: uuid_v4(),
      mapKey: uuid_v4(),
    };

    employeesLastStateService = EmployeesLastStateService.getInstance();
  }

  get viewGeozones() {
    this._viewGeozones = mapLayerControl.layerControl.overlayers[mapLayerControl.overlayerNames.geoZones];
    return this._viewGeozones;
  }

  set viewGeozones(value) {
    mapLayerControl.layerControl.overlayers[mapLayerControl.overlayerNames.custom] = value;
    this._viewGeozones = value;
  }

  get viewCustomLayers() {
    return mapLayerControl.layerControl.overlayers[mapLayerControl.overlayerNames.custom];
  }

  render() {
    return this.mapLastStates();
  }

  componentDidMount() {
    const { onGetEmployeeGeozones, employeeGeoZones } = this.props;

    employeesLastStateService.setCallBack("lastStates", this.reRender.bind(this));
    if (employeeGeoZones.dataState === states.NOT_REQUESTED) {
      onGetEmployeeGeozones(null);
    }
  }

  componentDidUpdate(prevProps) {
    const { employeeGeoZones, lastStates, employeeMultipleSelect } = this.props;

    if (prevProps.employeeGeoZones.dataState === states.REQUEST && employeeGeoZones.dataState === states.READY) {
      const employees = employeesLastStateService.getEmployees();

      this.setState({
        employees: employees,
        employeeGeoZones: this.stateGeozones(employeeGeoZones.employeeGeoZones, employees),
        openGeozoneList: false,
        _key: uuid_v4(),
      });
    }

    if (prevProps.lastStates.dataState === states.REQUEST && lastStates.dataState === states.READY) {
      employeesLastStateService.setEmployees(lastStates.employees);

      const employees = employeesLastStateService.getEmployees();
      this.setState({
        employees: employees,
        employeeGeoZones: this.stateGeozones(employeeGeoZones.employeeGeoZones, employees),
        _key: uuid_v4(),
      });
    }

    if (
      prevProps.employeeMultipleSelect.dataState === states.REQUEST &&
      employeeMultipleSelect.dataState === states.READY
    ) {
      this.setState({
        employeeMultipleSelect: employeeMultipleSelect,
        _key: uuid_v4(),
      });
    }
  }

  getSeletedGeozoneId() {
    const { employeeMultipleSelect } = this.props;
    return employeeMultipleSelect.type !== null && employeeMultipleSelect.type === multipleSelectGeozone
      ? employeeMultipleSelect.id
      : null;
  }

  getGeoZoneColor(geoZone, employees) {
    if (employees.length === 0 || geoZone.employees.length === 0) {
      return geoZone.color;
    }

    let change = false;
    const employeesForGeozone = geoZone.employees.map((item) => {
      return item.id;
    });

    const geoZoneEmployess = employees.filter((item) => {
      return employeesForGeozone.includes(item.id);
    });

    if (!geoZone.insideAll && geoZone.ifInside) {
      ///Хотябы один в геозоне
      change = geoZoneEmployess.some((employee) => {
        return employee.geoZones.includes(geoZone.id);
      });
    }

    if (!geoZone.insideAll && !geoZone.ifInside) {
      ///Хотябы один вне геозоне
      change = geoZoneEmployess.some((employee) => {
        return !employee.geoZones.includes(geoZone.id);
      });
    }

    if (geoZone.insideAll && geoZone.ifInside) {
      //все в геозоне
      change = geoZoneEmployess.every((employee) => {
        return employee.geoZones.includes(geoZone.id);
      });
    }

    if (geoZone.insideAll && !geoZone.ifInside) {
      //все вне геозоны
      change = geoZoneEmployess.every((employee) => {
        return !employee.geoZones.includes(geoZone.id);
      });
    }

    return change ? geoZone.changeColor : geoZone.color;
  }

  stateGeozones(employeeGeoZones, employees) {
    for (const geozone of employeeGeoZones) {
      geozone.stateColor = this.getGeoZoneColor(geozone, employees);
    }

    return employeeGeoZones;
  }

  reRender(employees) {
    console.log("rerender");

    const { employeeGeoZones } = this.state;

    this.setState({
      employees: employees,
      employeeGeoZones: this.stateGeozones(employeeGeoZones, employees),
      _key: uuid_v4(),
    });
  }

  onSelectGeizone(id) {
    const { onEmployeeMultipleSelect } = this.props;
    const { employeeGeoZones } = this.state;
    onEmployeeMultipleSelect(id, employeeGeoZones, multipleSelectGeozone);
  }

  onDeleted(e) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Удалить геозону(-ы)")) {
      const { onDeleteGeoZone } = this.props;
      e.layers.eachLayer((layer) => {
        console.log(layer.options.id);
        onDeleteGeoZone(layer.options.id);
      });
    }
  }

  onClickGeozoneLis(e) {
    e.stopPropagation();
    const { openGeozoneList, employeeGeoZones } = this.state;
    if (employeeGeoZones.length > 0) {
      this.setState({ openGeozoneList: !openGeozoneList, _key: uuid_v4() });
    }
  }

  onEdited(e) {
    const { onUpdateGeoZone } = this.props;
    const { employeeGeoZones } = this.state;
    e.layers.eachLayer((layer) => {
      const options = this.createGeozoneOptions(layer);
      const geoZone = employeeGeoZones.filter((item) => {
        return layer.options.id === item.id;
      })[0];

      options.color = geoZone.color;
      options.changeColor = geoZone.changeColor;
      options.ifInside = geoZone.ifInside;
      options.insideAll = geoZone.insideAll;
      options.opacity = geoZone.opacity;
      options.isWork = geoZone.isWork;
      options.title = layer.options.title;
      options.geometryUpdate = true;

      onUpdateGeoZone(options);
    });
  }

  onCreated(e) {
    const { onCreateGeoZone } = this.props;
    const options = this.createGeozoneOptions(e.layer);
    onCreateGeoZone(options);
    e.layer.remove();
  }

  getCountPointCircle(radius) {
    radius = Math.round((radius / 1000) * 1000) / 1000;

    if (radius >= 10) {
      return 512;
    }

    if (radius >= 5) {
      return 256;
    }

    if (radius >= 1) {
      return 128;
    }

    if (radius >= 0.5) {
      return 64;
    }

    return 32;
  }

  createGeozoneOptions(layer) {
    let geometry;
    const properties = {};

    if (layer._mRadius) {
      const radius = Math.round(layer._mRadius);
      geometry = JSON.stringify(
        circleToPolygon([layer._latlng.lng, layer._latlng.lat], radius, this.getCountPointCircle(radius))
      );
      properties["TYPE"] = "circle";
      properties["RADIUS"] = radius + "";
      properties["SQA"] = Math.round(Math.PI * Math.round(radius * radius)) + "";
      properties["PERI"] = 2 * Math.PI * radius + "";
      properties["CPOI"] = JSON.stringify([
        Math.round(layer._latlng.lng * 100000) / 100000,
        Math.round(layer._latlng.lat * 100000) / 100000,
      ]);
    } else {
      const geoJsonGeometry = {
        type: "Polygon",
        coordinates: [],
      };

      properties["TYPE"] = "polygon";
      geoJsonGeometry.coordinates.push(
        layer._latlngs[0].map((item) => {
          return [item.lng, item.lat];
        })
      );
      geoJsonGeometry.coordinates[0].push(geoJsonGeometry.coordinates[0][0]);

      geometry = JSON.stringify(geoJsonGeometry);
      properties["SQA"] = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) + "";

      let distance = 0;
      for (let i = 1; i < layer._latlngs[0].length; i++) {
        distance += layer._latlngs[0][i - 1].distanceTo(layer._latlngs[0][i]);
        //  distance += L.GeometryUtil.length(layer._map, layer._latlngs[0][i - 1], layer._latlngs[0][i]);
      }

      distance += layer._latlngs[0][layer._latlngs[0].length - 1].distanceTo(layer._latlngs[0][0]);

      properties["PERI"] = distance + "";
    }

    return {
      id: layer.options.id,
      geometry,
      color: "#3388ff",
      changeColor: "#00ff00",
      ifInside: true,
      insideAll: false,
      opacity: 0.2,
      properties,
    };
  }

  getCircle(geoZone, onSelectGeizone) {
    const center = JSON.parse(geoZone.properties["CPOI"]);
    const color = geoZone.stateColor ? geoZone.stateColor : geoZone.color;

    return (
      <Circle
        key={uuid_v4()}
        center={[center[1], center[0]]}
        id={geoZone.id}
        title={geoZone.title}
        employees={geoZone.employees}
        radius={Number(geoZone.properties["RADIUS"])}
        opacity={geoZone.opacity}
        fillOpacity={geoZone.opacity}
        color={color}
        fillColor={color}
        eventHandlers={{
          dblclick: (e) => {
            onSelectGeizone(e);
          },
        }}
      >
        {this.getToolTip()}
      </Circle>
    );
  }

  getPolygon(geoZone, onSelectGeizone) {
    const geoJson = JSON.parse(geoZone.geometry);
    const color = geoZone.stateColor ? geoZone.stateColor : geoZone.color;

    geoJson.coordinates.slice(geoJson.coordinates.length - 1, 1);
    const latLangs = [];
    latLangs.push(
      geoJson.coordinates[0].map((item) => {
        const LatLng = new L.LatLng(item[1], item[0]);
        return LatLng;
      })
    );

    return (
      <Polygon
        key={uuid_v4()}
        positions={latLangs}
        id={geoZone.id}
        title={geoZone.title}
        employees={geoZone.employees}
        opacity={geoZone.opacity}
        fillOpacity={geoZone.opacity}
        color={color}
        fillColor={color}
        onClick={(e) => {
          console.log("p cl");
        }}
        eventHandlers={{
          dblclick: (e) => {
            onSelectGeizone(e);
          },
        }}
      >
        {this.getToolTip()}
      </Polygon>
    );
  }

  getToolTip() {
    return <TooltipLayer>Двойной щелчок мыши - открыть настройки</TooltipLayer>;
  }

  getGeoZones() {
    const { employeeGeoZones } = this.state;

    return employeeGeoZones.map((geozone) => {
      const selectGeizone = (e) => {
        this.onSelectGeizone(geozone.id);
      };
      return geozone.properties["TYPE"] === "circle"
        ? this.getCircle(geozone, selectGeizone)
        : this.getPolygon(geozone, selectGeizone);
    });
  }

  baselayerChange(e) {
    mapLayerControl.baselayerchange(e);

    this.setState({
      crs: mapLayerControl.layerControl.crs,
      mapKey: uuid_v4(),
      _key: uuid_v4(),
    });
  }

  overlayAdd(e) {
    mapLayerControl.overlayadd(e);
    this.setState({
      crs: mapLayerControl.layerControl.crs,
      mapKey: uuid_v4(),
      _key: uuid_v4(),
    });
  }

  overlayerRemove(e) {
    mapLayerControl.overlayremove(e);
    this.setState({
      crs: mapLayerControl.layerControl.crs,
      mapKey: uuid_v4(),
      _key: uuid_v4(),
    });
  }

  mapLastStates() {
    const {
      employeeGeoZones,
      employees,
      mapKey,
      _key,
      crs,
      onBaselayerChange,
      onOverlayAdd,
      onOverlayRemove,
      openGeozoneList,
    } = this.state;

    const { onUpdateGeoZone, onAddEmployee, onDeleteEmployee, onResetEmployeeGeozones } = this.props;

    const onSelectGeoZone = (id) => {
      this.onSelectGeizone(id);
    };

    const onCloseGeozoneSetting = (e) => {
      e.stopPropagation();
      this.onSelectGeizone(null);
    };

    const onCloseGeozoneList = (e) => {
      this.onClickGeozoneLis(e);
    };

    const handlers = {
      onCloseGeozoneSetting,
      onCloseGeozoneList,
      onUpdateGeoZone,
      onAddEmployee,
      onDeleteEmployee,
      onResetEmployeeGeozones,
      onSelectGeoZone,
    };

    const selectedGeoZoneId = this.getSeletedGeozoneId();
    const selectGeoZone = selectedGeoZoneId
      ? employeeGeoZones.filter((item) => {
          return item.id === selectedGeoZoneId;
        })[0]
      : null;

    const getFeatures = function (employees) {
      return employees
        .map((employee) => {
          return employee.feature;
        })
        .filter((item) => {
          return item !== null;
        });
    };

    const features = getFeatures(employees);
    const baseLayers = mapLayerControl.layerControl.baseLayers;
    const layerNames = mapLayerControl.baselayerNames;
    const authTNToken = WithToken.getToken("authTNToken");
    const IsMapOverlayer =
      (WithToken.IsMapOverlayer("authTNToken") ||
        CookieService.getCookie(configuration.permissionsCookie).includes(50001)) &&
      !(baseLayers[layerNames.yandex] || baseLayers[layerNames.yandexSat] || baseLayers[layerNames.yandexHybrid]);

    const alarmEmployees = getAlarmEmployyes(employees);
    const alarmEmployeesId = alarmEmployees.map((employee) => {
      return employee.id;
    });

    const isAlarm = alarmEmployees.length > 0;
    if (isAlarm) {
      mapLayerControl.bounds = getBounds(alarmEmployees);
    }

    return (
      <MapContainer
        key={mapKey}
        zoomControl={true}
        maxZoom={mapLayerControl.maxZoom()}
        minZoom={4}
        attributionControl={false}
        doubleClickZoom={false}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        className={styles.employeesMap}
        loadingControl={true}
        crs={crs}
        drawControlTooltips={true}
        bounds={mapLayerControl.bounds ?? getBounds(employees)}
      >
        <MapConsumer>
          {(map) => {
            map.on("zoom", (e) => {
              mapLayerControl.bounds = map.getBounds();
            });

            map.once("baselayerchange", onBaselayerChange);
            map.on("overlayadd", onOverlayAdd);
            map.on("overlayremove", onOverlayRemove);

            return null;
          }}
        </MapConsumer>

        <LayersControl position="bottomright">
          <BaseLayersControlWrapper mapLayerControl={mapLayerControl} />

          {IsMapOverlayer ? (
            <LayersControl.Overlay checked={this.viewCustomLayers} name={mapLayerControl.overlayerNames.custom}>
              <TileLayer opacity={0.65} url={baseURL + "/MapOverlayers/Tiles?X={x}&Y={y}&Z={z}&token=" + authTNToken} />
            </LayersControl.Overlay>
          ) : null}

          <LayersControl.Overlay checked={this.viewGeozones} name={mapLayerControl.overlayerNames.geoZones}>
            <FeatureGroup>
              <EditControl
                position="topright"
                onEditStart={(e) => {
                  //     this.onEditStart(e);
                }}
                onEditStop={(e) => {
                  //     this.onEditStop(e);
                }}
                onEdited={(e) => {
                  this.onEdited(e);
                }}
                onCreated={(e) => {
                  this.onCreated(e);
                }}
                onDeleted={(e) => {
                  this.onDeleted(e);
                }}
                draw={{
                  polyline: false,
                  polygon: {
                    showArea: true,
                    showLength: true,
                    precision: {
                      km: 3,
                    },
                  },
                  rectangle: {
                    showArea: true,
                  },
                  circle: {
                    showRadius: true,
                    metric: true,
                    feet: false,
                    nautic: false,
                  },
                  marker: false,
                  circlemarker: false,
                }}
              />
              {this.getGeoZones()}
            </FeatureGroup>
          </LayersControl.Overlay>
        </LayersControl>

        {employeeGeoZones.length > 0 ? (
          <Control position="topright">
            <ButtonGroup orientation="vertical" variant="contained">
              <Tooltip placement="left" title="Список геозон">
                <Button onClick={(e) => this.onClickGeozoneLis(e)} color="inherit">
                  <ListIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Control>
        ) : null}

        <CustomLeafletControl
          key={"CustomLeafletControl" + _key}
          openGeozoneList={openGeozoneList}
          employeeGeoZones={employeeGeoZones}
          isAlarm={isAlarm}
          alarmEmployees={alarmEmployeesId}
          selectGeoZone={selectGeoZone}
          handlers={handlers}
          position="bottomleft"
        />

        <MapLastPositionMarkers
          key={"MapLastPositionMarkers" + _key}
          employees={employees}
          bounds={mapLayerControl.bounds}
        />
        <GeoJsonBuildings key={"GeoJsonBuildings" + _key} features={features} />
      </MapContainer>
    );
  }
}

function getAlarmEmployyes(employees) {
  return employees.filter((item) => {
    return item.alarmTime !== null;
  });
}

function getBounds(employees) {
  if (employees === undefined || employees.length === 0) {
    return [
      [54.85, 28.55],
      [51.9, 25.55],
    ];
  }

  let lats = [];
  let longs = [];

  employees.map((employee) => {
    lats.push(employee.lastLocation.lat);
    longs.push(employee.lastLocation.long);

    return null;
  });

  return [
    [
      Math.round(Math.min.apply(Math, lats) * 1000) / 1000 - 0.05,
      Math.round(Math.max.apply(Math, longs) * 1000) / 1000 + 0.05,
    ],
    [
      Math.round(Math.max.apply(Math, lats) * 1000) / 1000 + 0.05,
      Math.round(Math.min.apply(Math, longs) * 1000) / 1000 - 0.05,
    ],
  ];
}

export default withEmployeeMultipleSelect(
  withEmployeeGeoZones(withEmployeesLastState(withFilterEmployees(EmployeesMapLastStates)))
);
