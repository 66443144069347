"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";

const PermissionsService = {
  get: function () {
    return api.get("/Permissions", WithToken.headersToken({}, "authTNToken"));
  },
};

export default PermissionsService;
