import EmployeesService from "../shared/services/employeesService";

const RESET_REQUEST_EMPLOYEES_LAST_STATES = "RESET_REQUEST_EMPLOYEES_LAST_STATES";
const REQUEST_EMPLOYEES_LAST_STATES = "REQUEST_EMPLOYEES_LAST_STATES";
const RESPONSE_EMPLOYEES_LAST_STATES = "RESPONSE_EMPLOYEES_LAST_STATES";
const REQUEST_EMPLOYEES_LAST_STATES_FAILURE = "REQUEST_EMPLOYEES_LAST_STATES_FAILURE";


const requestEmployeesLastStatesAction = () => {
  return {
    type: REQUEST_EMPLOYEES_LAST_STATES,
  };
};

const responseEmployeesLastStatesAction = (data) => {
  return {
    type: RESPONSE_EMPLOYEES_LAST_STATES,
    lastStates: {
      employees: data.employees,
    },
  };
};

const requestEmployeesLastStatesFailureAction = (errors) => {
  return {
    type: REQUEST_EMPLOYEES_LAST_STATES_FAILURE,
    errors,
  };
};

const getEmployeesLastStates = (options) => {
  const lastateOptions = {
    companies: options && options.companies ? options.companies.map((item) => item.value) : [],
  };

  return (dispatch) => {
    dispatch(requestEmployeesLastStatesAction());

    EmployeesService.getEmployeesLastStates(lastateOptions)
      .then((result) => {
        dispatch(responseEmployeesLastStatesAction(result.data));
      })
      .catch((error) => {
        dispatch(requestEmployeesLastStatesFailureAction(error.response));
      });
  };
};

const requestReset = () => {
  return {
    type: RESET_REQUEST_EMPLOYEES_LAST_STATES,
  };
};

const reset = () => {
  return (dispatch) => {
    dispatch(requestReset());
  };
};

export {
  reset,
  RESET_REQUEST_EMPLOYEES_LAST_STATES,
  getEmployeesLastStates,
  REQUEST_EMPLOYEES_LAST_STATES,
  requestEmployeesLastStatesAction,
  RESPONSE_EMPLOYEES_LAST_STATES,
  responseEmployeesLastStatesAction,
  REQUEST_EMPLOYEES_LAST_STATES_FAILURE,
  requestEmployeesLastStatesFailureAction,
};
