import EmployeesService from "../shared/services/employeesService";

const RESPONSE_EMPLOYEE_SHORT_INFO = "RESPONSE_EMPLOYEE_SHORT_INFO";
const REQUEST_EMPLOYEE_SHORT_INFO_FAILURE = "REQUEST_EMPLOYEE_SHORT_INFO_FAILURE";

const responseGetEmployeeShortInfoAction = (data) => {
  return {
    type: RESPONSE_EMPLOYEE_SHORT_INFO,
    data,
  };
};

const requestGetEmployeeShortInfofailureAction = (errors) => {
  return {
    type: REQUEST_EMPLOYEE_SHORT_INFO_FAILURE,
    errors,
  };
};

const getEmployeeShortInfo = (id) => {
  return (dispatch) => {
    EmployeesService.getEmployeeShortInfo(id)
      .then((result) => {
        dispatch(responseGetEmployeeShortInfoAction(Object.assign(result.data, { employeeId: id })));
      })
      .catch((error) => {
        dispatch(requestGetEmployeeShortInfofailureAction(error.response));
      });
  };
};

const employeeSharedLink = (id, createSharedLink) => {
  return (dispatch) => {
    EmployeesService.employeeSharedLink(id, createSharedLink)
      .then((result) => {
        dispatch(getEmployeeShortInfo(id));
      })
      .catch((error) => {});
  };
};

export {
  getEmployeeShortInfo,
  responseGetEmployeeShortInfoAction,
  requestGetEmployeeShortInfofailureAction,
  employeeSharedLink,
  RESPONSE_EMPLOYEE_SHORT_INFO,
  REQUEST_EMPLOYEE_SHORT_INFO_FAILURE,
};
