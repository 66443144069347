import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

import SideContentBlock from "../../../shared/sideContentBlock";
import EmployeesMainDevicesWrapper from "./../employees/employeesMainDevicesWrapper";
import IntelligenceEmployees from "./../intellegence/intelligenceEmployees";

import styles from "./employeeData.module.scss";

const EmployeeData = () => {
  return (
    <Scrollbars style={{ height: "100%" }} autoHide={true}>
      <div className={styles.employeeData}>
        <SideContentBlock>
          <EmployeesMainDevicesWrapper />
        </SideContentBlock>
      </div>

      <SideContentBlock style={{ height: "100px!important" }}>
        <IntelligenceEmployees />
      </SideContentBlock>
    </Scrollbars>
  );
};

export default React.memo(EmployeeData);
