import { connect } from 'react-redux'
import get from 'lodash/get'

import {
  playRoute as onPlayRoute,
  pauseRoute as onPauseRoute,
  setRouteLength as onSetRouteLength,
  setRouteDates as onSetRouteDates,
  setCurrentPoint as onSetCurrentPoint,
  clearPlayerData as onClearPlayerData
} from './../../actions/intellegenceTimeRangePlayerActions'

const mapStateToProps = (state) => ({
  isPlay: get(state, ['intellegenceTimeRangePlayer', 'isPlay'], false),
  routeLength: get(state, ['intellegenceTimeRangePlayer', 'routeLength'], 0),
  routeDates: get(state, ['intellegenceTimeRangePlayer', 'routeDates'], []),
  currentPointIndex: get(state, ['intellegenceTimeRangePlayer', 'currentPointIndex'], 0)
})

const mapDispatchToProps = {
  onPlayRoute,
  onPauseRoute,
  onSetRouteLength,
  onSetRouteDates,
  onSetCurrentPoint,
  onClearPlayerData
}

const withIntellegenceTimeRangePlayer = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withIntellegenceTimeRangePlayer }