import React from 'react';

import CountOnPage from '../../../shared/countOnPage'
import ControlCountOnPage from '../../../shared/controlCountOnPage'

import styles from './employeesPaginationSettingsPanel.module.scss'

const employeesPaginationSettingsPanel = () => {
  return (
    <div className={styles.employeesPaginationSettingsPanel}>
      <div className={styles.container_count}>
        <CountOnPage />
      </div>
      <div className={styles.container_viewCount}>
        <ControlCountOnPage />
      </div>
    </div>
  )
}

export default React.memo(employeesPaginationSettingsPanel)
