import {
  REQUEST_SET_EMPLOYEE_BOOKMARK,
  RESPONSE_SET_EMPLOYEE_BOOKMARK,
  REQUEST_SET_EMPLOYEE_BOOKMARK_FAILURE
} from './../actions/setEmployeeBookmarkActions'

import { states as queriesStates } from './queriesStates'

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  errors: [],
}

const setEmployeeBookmarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SET_EMPLOYEE_BOOKMARK:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.REQUEST
        }
      )
    case RESPONSE_SET_EMPLOYEE_BOOKMARK:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY
        }
      )
    case REQUEST_SET_EMPLOYEE_BOOKMARK_FAILURE:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.FAILURE,
          errors: action.errors
        }
      )
    default:
      return state
  }
}

export default setEmployeeBookmarkReducer