import React from 'react'
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './static.radioItem';

const RadioItem = props => {
  const { classes, disabled } = props

  return (
    <Radio
      {...props}
      className={classes.root}
      disableRipple
      disabled={!!disabled ? disabled : false}
      checkedIcon={<span className="Mui-icon Mui-icon-checked"></span>}
      icon={<span className="Mui-icon"></span>}
    />
  )
}

export default withStyles(styles)(React.memo(RadioItem))