import { connect } from 'react-redux'

import {
  handleChangeIntellegenceNodalForm as onHandleChangeStore
} from './../../actions/intellegenceModalFormActions'

const mapStateToProps = (state) => ({
  intellegenceModalForm: {
    dataState: state.intellegenceModalForm.dataState,
    intellegenceModalForm: state.intellegenceModalForm.intellegenceModalForm
  }
})

const mapDispatchToProps = {
  onHandleChangeStore
}

const withIntellegenceModalForm = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withIntellegenceModalForm }