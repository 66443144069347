import {
  RESPONSE_SENSORS_PREVIEW,
  REQUEST_SENSOR_PREVIEW_FAILURE
} from './../actions/sensorsPreviewActions'

import { states } from './queriesStates'

const initialState = {
  dataState: states.NOT_REQUESTED,
  data: {},
  errors: {},
}

const sensorsPreviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESPONSE_SENSORS_PREVIEW:
      return Object.assign(
        {},
        state,
        {
          dataState: states.READY,
          data: action.data,
          errors: {},
        }
      )
    case REQUEST_SENSOR_PREVIEW_FAILURE:
      return Object.assign(
        {},
        state,
        {
          dataState: states.FAILURE,
          errors: action.errors,
        }
      )
    default:
      return state
  }
}

export default sensorsPreviewReducer