import AccountService from '../shared/services/accountService'

const REQUEST_CODEFORREPASSWORD = 'REQUEST_CODEFORREPASSWORD'
const RESPONSE_CODEFORREPASSWORD = 'RESPONSE_CODEFORREPASSWORD'
const RESPONSE_CODEFORREPASSWORD_FAILURE = 'RESPONSE_CODEFORREPASSWORD_FAILURE'
const READY_CHANGE_STORE_CODEFORREPASSWORD = 'READY_CHANGE_STORE_CODEFORREPASSWORD'
const SET_CODEFORREPASSWORD = 'SET_CODEFORREPASSWORD'

const changeStoreCodeForRestorePasseordAction = (name, value) => {
  return {
    type: READY_CHANGE_STORE_CODEFORREPASSWORD,
    payload: { name, value }
  }
}

const requestCodeForRestorePasseordAction = () => {
  return {
    type: REQUEST_CODEFORREPASSWORD,
  }
}

const responseCodeForRestorePasseordAction = (data) => {
  return {
    type: RESPONSE_CODEFORREPASSWORD,
    data,
  }
}

const requestCodeForRestorePasseordFailureAction = (errors) => {
  return {
    type: RESPONSE_CODEFORREPASSWORD_FAILURE,
    errors,
  }
}


const setForRepasscwordAction = (data) => {
  return {
    type: SET_CODEFORREPASSWORD,
    data
  }
}

const setForRepasscword = (options) => {
  return (dispatch) => {
    dispatch(setForRepasscwordAction(options))
  }
}


const sendMailRestorePasseord = (data) => {

  return (dispatch) => {
    dispatch(requestCodeForRestorePasseordAction())

    AccountService.sendMailRestore(data)
      .then((result) => {
        dispatch(responseCodeForRestorePasseordAction(result.data))
      })
      .catch((error) => {
        dispatch(requestCodeForRestorePasseordFailureAction(error.response))
      })
  }
}

const restorePassword = (data) => {

  return (dispatch) => {
    dispatch(requestCodeForRestorePasseordAction())

    AccountService.restorePassword(data)
      .then((result) => {
        dispatch(responseCodeForRestorePasseordAction({
          message: 'Пароль успешно изменен'
        }))
      })
      .catch((error) => {
        dispatch(requestCodeForRestorePasseordFailureAction(error.response))
      })
  }
}

export {
  restorePassword,
  sendMailRestorePasseord,
  requestCodeForRestorePasseordAction,
  responseCodeForRestorePasseordAction,
  requestCodeForRestorePasseordFailureAction,
  changeStoreCodeForRestorePasseordAction,
  setForRepasscword,
  setForRepasscwordAction,
  REQUEST_CODEFORREPASSWORD,
  RESPONSE_CODEFORREPASSWORD,
  RESPONSE_CODEFORREPASSWORD_FAILURE,
  READY_CHANGE_STORE_CODEFORREPASSWORD,
  SET_CODEFORREPASSWORD
}