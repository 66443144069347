import { createControlComponent } from "@react-leaflet/core";
import { Control, DomUtil } from "leaflet";
import L from "leaflet";
import EmployeeService from "../services/employeesService";
import "./geoZoneParametrsContainer.css";

Control.Watermark = Control.extend({
  contaier: null,
  closeButton: function (onClose) {
    const div = DomUtil.create("div");
    div.classList.add("closeButtonDiv");

    const closeButton = DomUtil.create("button");
    closeButton.classList.add("closeButton");
    closeButton.textContent = "Закрыть";
    closeButton.addEventListener("click", (e) => {
      onClose(e);
    });

    div.append(closeButton);

    return div;
  },
  titleInput(selectGeoZone, updateGeoZone) {
    const div = DomUtil.create("div");
    div.classList.add("inputTitleDiv");

    const titleInput = DomUtil.create("input");
    titleInput.value = selectGeoZone.title;
    titleInput.setAttribute("placeholder", "Введите наименование геозоны");

    titleInput.addEventListener("change", (e) => {
      e.stopPropagation();
      selectGeoZone.title = e.target.value;
      setTimeout(updateGeoZone, 100);
    });

    titleInput.addEventListener("click", (e) => {
      e.stopPropagation();
    });

    div.append(titleInput);

    return div;
  },
  colorPicker: function (selectGeoZone, updateGeoZone) {
    const div = DomUtil.create("div");
    const colorPicker = DomUtil.create("input");

    colorPicker.classList.add("colorPicker");
    colorPicker.setAttribute("id", "inputColorId");
    colorPicker.setAttribute("type", "color");
    colorPicker.value = selectGeoZone.color;

    colorPicker.addEventListener("change", (e) => {
      e.stopPropagation();
      selectGeoZone.color = e.target.value;
      updateGeoZone();
    });

    colorPicker.addEventListener("click", (e) => {
      e.stopPropagation();
    });

    const label = DomUtil.create("label");
    label.setAttribute("for", "inputColorId");
    label.innerText = "Цвет геозоны по умочанию";

    div.append(colorPicker);
    div.append(label);

    return div;
  },
  changeColorPicker: function (selectGeoZone, updateGeoZone) {
    const div = DomUtil.create("div");
    const colorPicker = DomUtil.create("input");

    colorPicker.classList.add("colorPicker");
    colorPicker.setAttribute("id", "inputChangeColorId");
    colorPicker.setAttribute("type", "color");
    colorPicker.value = selectGeoZone.changeColor;

    colorPicker.addEventListener("change", (e) => {
      e.stopPropagation();
      selectGeoZone.changeColor = e.target.value;
      updateGeoZone();
    });

    colorPicker.addEventListener("click", (e) => {
      e.stopPropagation();
    });

    const label = DomUtil.create("label");
    label.setAttribute("for", "inputChangeColorId");
    label.innerText = "Цвет события геозоны";

    div.append(colorPicker);
    div.append(label);

    return div;
  },
  opacity: function (selectGeoZone, updateGeoZone) {
    const div = DomUtil.create("div");
    div.classList.add("opacityDiv");

    const opacityPicker = DomUtil.create("input");
    opacityPicker.classList.add("opacityPicker");
    opacityPicker.setAttribute("id", "inputOpacityId");
    opacityPicker.setAttribute("type", "range");
    opacityPicker.min = 15;
    opacityPicker.max = 100;
    opacityPicker.value = Number(selectGeoZone.opacity * 100);

    const label = DomUtil.create("label");
    label.setAttribute("for", "inputOpacityId");
    label.innerText = `Непрозрачность геозоны: ${opacityPicker.value}%`;

    opacityPicker.addEventListener("change", (e) => {
      selectGeoZone.opacity = Number(e.target.value) / 100;
      updateGeoZone();
    });

    opacityPicker.addEventListener("input", (e) => {
      label.innerText = `Непрозрачность геозоны: ${e.target.value}%`;
    });

    div.append(label);
    div.append(opacityPicker);

    return div;
  },
  parametrConvert(item) {
    // eslint-disable-next-line default-case
    switch (item[0]) {
      case "SQA":
        item[0] = "Площадь";
        item[1] = Math.round((item[1] / 1000) * 1000) / 1000 + " км²";
        break;
      case "RADIUS":
        item[0] = "Радиус";
        item[1] = Math.round((item[1] / 1000) * 1000) / 1000 + " км";
        break;
      case "TYPE":
        item[0] = "Тип";
        item[1] = item[1] === "circle" ? "Круг" : "Область";
        break;
      case "CPOI":
        item[0] = "Центр";
        break;
      case "PERI":
        item[0] = "Периметр";
        item[1] = Math.round((item[1] / 1000) * 1000) / 1000 + " км";
        break;
    }

    return item;
  },
  propertiesInfo(selectGeoZone) {
    const div = DomUtil.create("div");
    div.classList.add("listProperies");
    const paragraph = DomUtil.create("p");
    paragraph.textContent = "Параметры геозоны:";

    const list = DomUtil.create("ul");

    for (const item of Object.entries(selectGeoZone.properties)) {
      const li = DomUtil.create("li");

      const convertValue = this.parametrConvert(item);
      li.innerText = `${convertValue[0]}: ${convertValue[1]}`;
      list.append(li);
    }

    div.append(paragraph);
    div.append(list);

    return div;
  },
  isWorkControl: function (selectGeoZone, updateGeoZone) {
    const container = DomUtil.create("div");
    const div = DomUtil.create("div");
    div.classList.add("checkboxDiv");

    const ifInsideInput = DomUtil.create("input");
    ifInsideInput.setAttribute("id", "ifInsideId");
    ifInsideInput.setAttribute("type", "checkbox");
    ifInsideInput.checked = selectGeoZone.isWork;

    ifInsideInput.addEventListener("change", (e) => {
      selectGeoZone.isWork = e.target.checked;
      e.stopPropagation();
      updateGeoZone();
    });

    const label = DomUtil.create("label");
    label.setAttribute("for", "ifInsideId");
    label.innerHTML = "Как рабочая область, добавленые сотрудники будут автоматически назначены к расписанию задания";

    div.append(ifInsideInput);
    div.append(label);

    container.append(div);

    return container;
  },
  insideControl: function (selectGeoZone, updateGeoZone) {
    const container = DomUtil.create("div");
    const div = DomUtil.create("div");
    div.classList.add("checkboxDiv");
    const paragraph = DomUtil.create("p");
    paragraph.textContent = "События геозоны:";
    div.append(paragraph);

    const ifInsideInput = DomUtil.create("input");
    ifInsideInput.setAttribute("id", "ifInsideId");
    ifInsideInput.setAttribute("type", "checkbox");
    ifInsideInput.checked = selectGeoZone.ifInside;

    ifInsideInput.addEventListener("change", (e) => {
      selectGeoZone.ifInside = e.target.checked;
      e.stopPropagation();
      updateGeoZone();
    });

    ifInsideInput.addEventListener("click", (e) => {
      e.stopPropagation();
    });

    const label = DomUtil.create("label");
    label.setAttribute("for", "ifInsideId");
    label.innerHTML = `Если выбрано - изменять цвет, когда сотрудник в геозоне, иначе, когда вне её.`;

    div.append(ifInsideInput);
    div.append(label);

    container.append(div);
    const divinsideAll = DomUtil.create("div");
    divinsideAll.classList.add("checkboxDiv");

    const insideAllInput = DomUtil.create("input");
    insideAllInput.setAttribute("id", "insideAllIId");
    insideAllInput.setAttribute("type", "checkbox");

    insideAllInput.checked = selectGeoZone.insideAll;

    insideAllInput.addEventListener("change", (e) => {
      selectGeoZone.insideAll = e.target.checked;
      e.stopPropagation();
      updateGeoZone();
    });

    insideAllInput.addEventListener("click", (e) => {
      e.stopPropagation();
    });

    const labelAll = DomUtil.create("label");
    labelAll.setAttribute("for", "ifInsideId");
    labelAll.innerText = `Если выбрано - изменять цвет, когда все сотрудники (в геозоне / вне её) иначе, когда хоть один.`;

    divinsideAll.append(insideAllInput);
    divinsideAll.append(labelAll);

    container.append(divinsideAll);

    return container;
  },
  crateGeozoneParametControl() {
    const { selectGeoZone, handlers } = this.options;
    const { onCloseGeozoneSetting, onUpdateGeoZone } = handlers;

    const updateGeoZoneReload = () => {
      onUpdateGeoZone(selectGeoZone);
    };

    const updateGeoZone = () => {
      onUpdateGeoZone(selectGeoZone);
    };

    this.divParamets = DomUtil.create("div");
    this.divParamets.classList.add("parametrsDiv");

    this.divOnlineParamet = DomUtil.create("div");
    this.divOnlineParamet.classList.add("geozoneControlContainer");

    this.divParamets.append(this.titleInput(selectGeoZone, updateGeoZone));
    this.divParamets.append(this.isWorkControl(selectGeoZone, updateGeoZone));
    this.divParamets.append(this.propertiesInfo(selectGeoZone));

    this.pararaph = DomUtil.create("p");
    this.pararaph.innerText =
      "Настройка цветовой индикации геозоны, на основе последних данных от устройств(-а) сотрудника(-ов)";
    this.divParamets.append(this.pararaph);

    this.divParamets.append(this.colorPicker(selectGeoZone, updateGeoZoneReload));
    this.divParamets.append(this.changeColorPicker(selectGeoZone, updateGeoZoneReload));
    this.divParamets.append(this.opacity(selectGeoZone, updateGeoZoneReload));
    this.divParamets.append(this.insideControl(selectGeoZone, updateGeoZone));

    this.divOnlineParamet.append(this.closeButton(onCloseGeozoneSetting));

    this.divContent = DomUtil.create("div");
    this.divContent.classList.add("divContentGeoZone");

    this.divContent.append(this.divParamets);
    this.divOnlineParamet.append(this.divContent);

    this.container.append(this.divOnlineParamet);
  },
  takeNoteAlarm: function (emplouees) {
    Promise.all(
      emplouees.map((id) => {
        return EmployeeService.takeNoteAlarm(id);
      })
    ).then(() => {
      document.location.reload();
    });
  },
  crateAlarmButton() {
    const { alarmEmployees } = this.options;
    const div = DomUtil.create("div");
    div.classList.add("alarmButtonContainer");
    const input = DomUtil.create("input");
    input.type = "button";
    input.classList.add("btn-danger");
    input.value = `Тревога! Нажмите, что бы снять и принять к сведению${
      alarmEmployees.length > 1 ? `, для ${alarmEmployees.length} сотрудников` : ""
    }.`;

    input.addEventListener("click", (e) => {
      e.stopPropagation();
      this.takeNoteAlarm(alarmEmployees);
    });
    div.append(input);

    this.container.append(div);
  },

  createGeozoneList() {
    const { employeeGeoZones, handlers } = this.options;
    const { onSelectGeoZone } = handlers;

    this.geozoneListContainer = DomUtil.create("div");
    this.geozoneListContainer.classList.add("geozoneListContainer");

    const container = DomUtil.create("div");
    container.classList.add("scrollContainer");

    const span = DomUtil.create("span");
    span.innerHTML = "Одиночный клик по наименованию - показать геозону<br/>Двойной - открыть настройки<br/><br/>";
    container.append(span);

    const paragraph = DomUtil.create("p");
    paragraph.textContent = "Геозоны:";
    container.append(paragraph);

    const div = DomUtil.create("div");
    div.classList.add("box");
    div.classList.add("section");

    const list = DomUtil.create("ul");
    for (const i in employeeGeoZones) {
      const li = DomUtil.create("li");

      li.innerText =
        Number(i) +
        1 +
        ". " +
        (employeeGeoZones[i].title === null || employeeGeoZones[i].title === "" || employeeGeoZones[i].title === " "
          ? "Без названия"
          : employeeGeoZones[i].title);

      li.addEventListener("click", (e) => {
        e.stopPropagation();
        this._map.fitBounds(new L.GeoJSON(JSON.parse(employeeGeoZones[i].geometry)).getBounds());
      });

      li.addEventListener("dblclick", (e) => {
        e.stopPropagation();
        onSelectGeoZone(employeeGeoZones[i].id);
      });

      list.append(li);
    }

    div.append(list);
    container.append(div);

    this.geozoneListContainer.append(this.closeButton(handlers.onCloseGeozoneList));
    this.geozoneListContainer.append(container);

    this.container.append(this.geozoneListContainer);
  },
  onAdd: function (map) {
    this.container = DomUtil.create("div");
    this.container.classList.add("customLeafletControl");
    this._disableClickPropagation(this.container);

    if (this.options.selectGeoZone !== undefined && this.options.selectGeoZone !== null) {
      this.crateGeozoneParametControl();
    } else if (this.options.openGeozoneList) {
      this.createGeozoneList();
    }

    if (this.options.isAlarm) {
      this.crateAlarmButton();
    }

    return this.container;
  },
  _disableClickPropagation: function (element) {
    if (L.Browser.touch || L.Browser.ie) {
      L.DomEvent.disableClickPropagation(element);
      L.DomEvent.on(element, "mousewheel", L.DomEvent.stopPropagation);
    }
  },

  onRemove: function (map) {},
});

export const CustomLeafletControl = createControlComponent((props) => new Control.Watermark(props));
