import React from 'react'

import styles from './buttonIcon.module.scss'

const ButtonIcon = props => {
  const { handleClick, title } = props

  return (
    <button className={styles.buttonIcon} onClick={handleClick}>
      {!!props.children ? <span className={styles.wrapp}>{props.children}</span> : title}
    </button>
  )
}

export default React.memo(ButtonIcon)