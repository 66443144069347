import React from 'react';

import styles from './sideContentInner.module.scss'

const SideContentInner = props => {
  return (
    <div className={styles.container_sideContentInner}>
      {props.children}
    </div>
  )
}

export default React.memo(SideContentInner)
