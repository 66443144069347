import {
    REQUEST_CODEFORREPASSWORD,
    RESPONSE_CODEFORREPASSWORD,
    RESPONSE_CODEFORREPASSWORD_FAILURE,
    READY_CHANGE_STORE_CODEFORREPASSWORD,
} from '../actions/restorePasswordActions.js'

import { states as queriesStates } from './queriesStates'

const initialState = {
    dataState: queriesStates.NOT_REQUESTED,
    data: {
        message: null,
    },
    restorePasswordItems: {},
    restorePasswordState: false,
    errors: []
}

const restorePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
           case READY_CHANGE_STORE_CODEFORREPASSWORD: {
             const { name, value } = action.payload
             var restorePasswordItems = Object.assign({}, state.restorePasswordItems)
       
             restorePasswordItems[name] = value
       
             return Object.assign(
               {},
               state,
               {
                 dataState: queriesStates.READY,
                 restorePasswordItems: restorePasswordItems
               }
             )
           }
        /*    case SET_REGISTRATION:
             return Object.assign(
               {},
               state,
               {
                 dataState: queriesStates.NOT_REQUESTED,
                 data: action.data,
                 restorePasswordState: false,
                 errors: []
               }
             ) */
        case REQUEST_CODEFORREPASSWORD:
            return Object.assign(
                {},
                state,
                {
                    dataState: queriesStates.REQUEST,
                }
            )
        case RESPONSE_CODEFORREPASSWORD:
            return Object.assign(
                {},
                state,
                {
                    dataState: queriesStates.READY,
                    data: action.data,
                    restorePasswordState: true,
                    errors: []
                }
            )
        case RESPONSE_CODEFORREPASSWORD_FAILURE:
            return Object.assign(
                {},
                state,
                {
                    dataState: queriesStates.FAILURE,
                    restorePasswordState: false,
                    errors: action.errors
                }
            )
        /*   case CLEAR_REGISTRATION_ERRORS:
            return Object.assign(
              {},
              state,
              {
                errors: []
              }
            ) */
        default:
            return state
    }
}

export default restorePasswordReducer
