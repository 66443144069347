import {
  RESPONSE_EMPLOYEES_GROUPS,
  REQUEST_EMPLOYEES_GROUPS_FAILURE,
} from '../actions/employeesGroupsActions'

import { states as queriesStates } from './queriesStates'

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  groups: []
}

const employeesGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESPONSE_EMPLOYEES_GROUPS: {
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY,
          groups: action.data
        }
      )
    }
    case REQUEST_EMPLOYEES_GROUPS_FAILURE: {
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.FAILURE,
          errors: action.errors,
        }
      )
    }
    default:
      return state
  }
}

export default employeesGroupsReducer
