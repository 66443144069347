import EmployeesService from "../shared/services/employeesService";
import EmployeesGroupsService from "../shared/services/employeesGroupsService";

const RESPONSE_EMPLOYEE_GROUPS = "RESPONSE_EMPLOYEE_GROUPS";
const REQUEST_EMPLOYEE_GROUPS_FAILURE = "REQUEST_EMPLOYEE_GROUPS_FAILURE";

const responseGetEmployeeGroupsAction = (data) => {
  return {
    type: RESPONSE_EMPLOYEE_GROUPS,
    data,
  };
};

const requestGetEmployeeGroupsfailureAction = (errors) => {
  return {
    type: REQUEST_EMPLOYEE_GROUPS_FAILURE,
    errors,
  };
};

const getEmployeeGroups = (emplpoyeeId) => {
  return (dispatch) => {
    EmployeesService.getGroups(emplpoyeeId)
      .then((result) => {
        dispatch(responseGetEmployeeGroupsAction(result.data.groups));
      })
      .catch((error) => {
        dispatch(requestGetEmployeeGroupsfailureAction(error.response));
      });
  };
};

const addEmployeeToGroup = (employeeId, groupId, handleUpdate) => {
  return (dispatch) => {
    EmployeesGroupsService.addEmployeeToGroup({ employeeId, groupId })
      .then((result) => {
        handleUpdate();
      })
      .catch((error) => {
        dispatch(requestGetEmployeeGroupsfailureAction(error.response));
      });
  };
};

const removeEmployeeFromGroup = (employeeId, groupId, handleUpdate) => {
  return (dispatch) => {
    EmployeesGroupsService.removeEmployeeFromGroup({ employeeId, groupId })
      .then((result) => {
        handleUpdate();
      })
      .catch((error) => {
        dispatch(requestGetEmployeeGroupsfailureAction(error.response));
      });
  };
};

export {
  getEmployeeGroups,
  addEmployeeToGroup,
  removeEmployeeFromGroup,
  responseGetEmployeeGroupsAction,
  requestGetEmployeeGroupsfailureAction,
  RESPONSE_EMPLOYEE_GROUPS,
  REQUEST_EMPLOYEE_GROUPS_FAILURE,
};
