import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import Icon from './../static/icon'

import styles from './dropDown.module.scss'

class DropDown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }

    this.handleDropDownToggleOut = this.handleDropDownToggleOut.bind(this)
  }

  componentDidUpdate() {

    if (!this.state.open) {
      document.addEventListener('click', this.handleDropDownToggleOut, false);
    } else {
      document.removeEventListener('click', this.handleDropDownToggleOut, false);
    }
  }

  handleDropDownToggle() {
    this.setState({ open: !this.state.open })
  }

  handleDropDownToggleOut(event) {
    const domNode = ReactDOM.findDOMNode(this.refs.logout);
    if ((!domNode || !domNode.contains(event.target))) {
      this.setState({
        open: false
      });
    }
  }

  render() {
    const { button, content } = this.props
    return (
      <div className={styles.dropDown}>
        <button
          className={this.state.open ? styles.dropDownButton_open : styles.dropDownButton_close}
          onClick={this.handleDropDownToggle.bind(this)}
        >
          {button}
          <span className={styles.icon}>
            <Icon name="direction-desc" />
          </span>
        </button>

        <div ref='logout' className={this.state.open ? styles.dropDownItems_open : styles.dropDownItems_close}>
          {content}
        </div>
      </div>
    )
  }
}

export default DropDown