import React from "react";
import { GeoJSON, Popup } from "react-leaflet";
import dateTime from "../services/date.service";
import "./popupStyles.scss";

const GeoJsonBuilding = (props) => {
  const { feature } = props;
  var geometry = JSON.parse(feature.geoJson);

  var popup =
    feature.timeInFeatures !== undefined ? (
      <table className={"tableInPopup"}>
        <th className={"thInPopup1"}>Время посещения </th>
        <th className={"thInPopup2"}>Длительность</th>
        {feature.timeInFeatures.map((item, index) => {
          return (
            <tr key={index}>
              <td className={"tdInPopup"}>
                <b>{dateTime.toFormat5(dateTime.toLocal(item.time))}</b>
              </td>
              <td className={"tdInPopup"}>{getTextDuration(item.duration)}</td>
            </tr>
          );
        })}
      </table>
    ) : null;

  return (
    <GeoJSON data={geometry} color={"#FF626C"}>
      {!!feature.timeInFeatures ? (
        <Popup className={"black-popup"}>
          <span style={{ textAlign: "center" }}>
            <p>
              <b>{feature.address}</b>
            </p>
          </span>
          {popup.props.children}
        </Popup>
      ) : (
        <Popup className={"black-popup"}>
          <span style={{ textAlign: "center" }}>
            <p>
              <b>{feature.address}</b>
            </p>
          </span>
        </Popup>
      )}
    </GeoJSON>
  );
};

function getTextDuration(duration) {
  var textDuration = "";
  var values = duration.split(":");
  var h = Number(values[0]);
  var m = Number(values[1]);
  var s = Number(values[2]);

  if (h !== 0) {
    textDuration += h + " ч.";
  }

  if (m !== 0) {
    if (textDuration !== "") {
      textDuration += " ";
    }
    textDuration += m + " мин.";
  }

  if (s !== 0) {
    textDuration += " " + s + " сек.";
  }

  return textDuration;
}

export default React.memo(GeoJsonBuilding);
