import { connect } from "react-redux";
import get from "lodash/get";

import {
  getObjectives as onGetObjectives,
  createObjective as onCreateObjective,
  deleteObjective as onDeleteObjective,
  updateObjective as onUpdateObjective,
  createSchedule as onCreateSchedule,
  deleteSchedule as onDeleteSchedule,
  addScheduleEmployee as onAddScheduleEmployee,
  deleteScheduleEmployee as onDeleteScheduleEmployee,
  activeSchedule as onActiveSchedule,
} from "../../actions/objectiveActions";

const mapStateToProps = (state) => ({
  objectives: {
    dataState: state.objectives.dataState,
    actionType: get(state, ["objectives", "actionType"], null),
    count: get(state, ["objectives", "count"], 0),
    objectives: get(state, ["objectives", "objectives"], []),
    objectiveType: get(state, ["objectives", "objectiveType"], 0),
    errors: get(state, ["objectives", "errors"], null),
  },
});

const mapDispatchToProps = {
  onGetObjectives,
  onCreateObjective,
  onDeleteObjective,
  onUpdateObjective,
  onCreateSchedule,
  onDeleteSchedule,
  onAddScheduleEmployee,
  onDeleteScheduleEmployee,
  onActiveSchedule,
};

const withObjectives = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withObjectives };
