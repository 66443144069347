import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { withCompanies } from "../../../shared/containers/containerCompanies";
import styles from "../employees/employeesListItem.module.scss";
import dateTime from "../../../shared/services/date.service";

const CompaniesListItem = (props) => {
  const { data, onEnterCompany, companies, enterCompany } = props;

  const getTitleCompany = () => {
    return (
      <div className={styles.container_name} style={{ backgroundColor: "#D8EBFF" }}>
        <strong>{data.title}</strong>
      </div>
    );
  };

  const enterCompanyLink = () => {
    return enterCompany ? (
      <Link
        className={styles.linkWrapper}
        to={`/companies/${data.id}`}
        onClick={() => {
          onEnterCompany({ id: data.id, title: data.title }, companies.getCompanyOptions);
        }}
      >
        {getTitleCompany()}
      </Link>
    ) : (
      getTitleCompany()
    );
  };

  return (
    <div className={styles.container_employeeContent}>
      {enterCompanyLink()}
      <div style={{ paddingBottom: "1rem", fontSize: ".75rem" }}>
        <p>
          Дата создания: <b>{dateTime.toFormat2(data.dateCreation)}</b>
        </p>
        <p>
          Количество устройсв: <b>{data.deviceCount}</b>
        </p>
        <p>
          Количество не активных устройсв: <b>{data.deviceInActiveCount}</b>
        </p>
      </div>
    </div>
  );
};

export default withRouter(withCompanies(React.memo(CompaniesListItem)));
