"use stric";

const employeesTypes = [
  { id: -1, title: "Выбранный", name: "selected" },
  {
    id: 6,
    title: "Питание от сети",
    name: "poweredFrom",
  },
  {
    id: 0,
    title: "Нет данных",
    name: "noData",
  },
  {
    id: 1,
    title: "Режим сна",
    name: "sleep",
  },
  {
    id: 2,
    title: "Рабочий режим",
    name: "work",
  },
  {
    id: 3,
    title: "Зарядка батареи",
    name: "charging",
  },
  {
    id: 4,
    title: "Выход из сна",
    name: "offSleep",
  },
  {
    id: 5,
    title: "Тревога",
    name: "alarm",
  },
  {
    id: 6,
    title: "Питание от сети",
    name: "poweredFrom",
  },
];

const getEmployeesTypeTitle = (id) => {
  return employeesTypes.filter((item) => item.id === id)[0].title;
};

const getEmployeesTypeName = (id) => {
  return employeesTypes.filter((item) => item.id === id)[0].name;
};

export { employeesTypes, getEmployeesTypeTitle, getEmployeesTypeName };
