import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  logIn as onLogIn,
  logInDemo as onLogInDemo,
  logOut as onLogOut,
  setLogin as onSetLogin,
  setLogout as onSetLogout,
  clearAuthErrors as onClearAuthErrors,
  setRegistration as onSetRegistration
} from './../../actions/authActions'

const mapStateToProps = (state) => ({
  dataState: state.auth.dataState,
  data: get(state, ['auth', 'data'], []),
  permissions: get(state, ['auth', 'data', 'permissions'], []),
  authState: get(state, ['auth', 'authState'], false),
  errors: get(state, ['auth', 'errors', 'data'], null),
  errorsModel: get(state, ['auth', 'errors', 'data', 'model'], null),
  errorsHeaders: {
    config: get(state, ['auth', 'errors', 'config'], {}),
    headers: get(state, ['auth', 'errors', 'headers'], {}),
    status: get(state, ['auth', 'errors', 'status'], {}),
    statusText: get(state, ['auth', 'errors', 'statusText'], {}),
    request: get(state, ['auth', 'errors', 'request'], {}),
  },
});

const mapDispatchToProps = {
  onLogIn,
  onLogInDemo,
  onLogOut,
  onSetLogin,
  onSetLogout,
  onClearAuthErrors,
  onSetRegistration
}

const withAuth = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withAuth }