import variables from './../../../values.scss'

const styles = {
  root: {
    padding: '0px 0px',
    border: 0,
    '& .MuiInputBase-input': {
    },
    '& .MuiInputBase-root': {
      paddingRight: 0,
      '&.Mui-error': {
        '& .MuiInputBase-input': {
          borderColor: variables.inputError
        }
      }
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '100%',
      marginTop: '5px',
      marginLeft: 0,
      fontSize: '0.625rem',
      color: variables.inputError
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiInputAdornment-root': {
      marginLeft: '-35px',
      '& .MuiButtonBase-root': {
        fontSize: '0.875rem',
        color: variables.inputIcon
      }
    }
  },
}

export { styles }