"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";
import configuration from "../../config.json";

const SharedContentService = {
  getShcedules: function () {
    return api.get("Shareds/Shcedules", WithToken.headersToken({}, "shared"));
  },
};

export default SharedContentService;
