import React, { Component } from "react";

import Registration from "./registration/registration";
import RestorePasseord from "./restorePassword/restorePassword";
import SignIn from "./signIn/signIn";
import Content from "./content";
import SharedContent from "./sharedContent";
import configuration from "./../config.json";
import CookieService from "./../shared/services/cookiesService";
import { withAuth } from "../shared/containers/containerAuth";
import "./app.scss";

class App extends Component {
  constructor(props) {
    super(props);

    const shared = window.location.pathname.toLowerCase().includes("/shareds/");
    let token = null;
    if (shared) {
      token = window.location.pathname.split("/")[2];
      CookieService.setCookie("shared", JSON.stringify({ token }));
      CookieService.updateExpires("shared");
    }

    this.state = {
      shared: shared,
    };

    if (!!CookieService.getCookie(configuration.cookieName)) {
      this.props.onSetLogin({
        authState: shared ? false : true,
      });
    }

    this.setLoginOnFocusBrowserTab = this.setLoginOnFocusBrowserTab.bind(this);
    window.onfocus = this.setLoginOnFocusBrowserTab;
  }

  setLoginOnFocusBrowserTab() {
    if (this.props.authState && !!CookieService.getCookie(configuration.cookieName) === false) {
      this.props.onLogOut();
    } else if (!this.props.authState && !!CookieService.getCookie(configuration.cookieName)) {
      this.props.onSetLogin({
        authState: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { dataState, errors, onClearAuthErrors } = this.props;

    if (!!errors) {
      var isLogin = prevProps.dataState === "STATE_REQUEST_LOGIN" && dataState === "STATE_READY_LOGIN";
      var isLogout = prevProps.dataState === "STATE_REQUEST_LOGOUT" && dataState === "STATE_READY_LOGOUT";
      if (isLogin || isLogout) {
        onClearAuthErrors();
      }
    }
  }

  render() {
    const { authState } = this.props;
    const { shared } = this.state;
    return (
      <div>
        {thisUrlRegistration() ? (
          <Registration />
        ) : thisUrlRestor() ? (
          <RestorePasseord />
        ) : !authState ? (
          shared ? (
            <SharedContent />
          ) : (
            <SignIn />
          )
        ) : (
          <Content />
        )}
      </div>
    );
  }
}

function thisUrlRegistration() {
  var arrayOfStrings = window.location.href.split("/");

  return arrayOfStrings.length === 6 && arrayOfStrings[3] === "accounts" && arrayOfStrings[5] === "registration";
}

function thisUrlRestor() {
  var arrayOfStrings = window.location.href.split("/");

  return arrayOfStrings.length === 5 && arrayOfStrings[3] === "accounts" && arrayOfStrings[4] === "restore";
}

export default withAuth(App);
