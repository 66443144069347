"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";

const CompaniesService = {
  getCompanies: function (options) {
    return api.get(
      `/companies?count=${options.count}&page=${options.page}&searchTerm=${options.searchTerm}`,
      WithToken.headersToken({}, "authTNToken")
    );
  },

  enterCompany: function (options) {
    return api.post("/Accounts/EnterCompany", { id: options.id }, WithToken.headersToken({}, "authTNToken"));
  },
};

export default CompaniesService;
