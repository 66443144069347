import React, { Component } from "react";
import Kalend, { CalendarView } from "kalend";
// import "kalend/dist/styles/index.css";

class SharedKalendar extends Component {
  render() {
    const { onScheduleClick, events } = this.props;


    return (
      <div>
        <div className="kalendContainer">
          <Kalend
            disableMobileDropdown={false}
            isDark={true}
            showTimeLine={true}
            autoScroll={true}
            onEventClick={onScheduleClick.bind(this)}
            events={events}
            initialDate={new Date().toISOString()}
            hourHeight={60}
            initialView={CalendarView.AGENDA}
            disabledViews={[CalendarView.DAY, CalendarView.MONTH, CalendarView.THREE_DAYS, CalendarView.WEEK]}
            timeFormat={"24"}
            weekDayStart={"Monday"}
            language={"ru"}
          />
        </div>
      </div>
    );
  }
}

export default SharedKalendar;
