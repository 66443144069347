import React from 'react'

import styles from './dropDownItem.module.scss'

const DropDownItem = props => {
  return (
    <div className={styles.dropDownItem}>
      {props.children}
    </div>
  )
}

export default React.memo(DropDownItem)