import React from "react";
import { withCompanies } from "../../../shared/containers/containerCompanies";
import Counter from "../../../shared/items/static/counter";

const CompaniesCounterWrapper = (props) => {
  const { companies, name } = props;

  // eslint-disable-next-line default-case
  switch (name) {
    case "сount":
      return <Counter count={companies.count} />;
    case "companyCount":
      return <Counter count={companies.companyCount} />;
    case "deviceCount":
      return <Counter count={companies.deviceCount} />;
    case "deviceInActiveCount":
      return <Counter count={companies.deviceInActiveCount} />;
  }

  return null;
};

export default withCompanies(React.memo(CompaniesCounterWrapper));
