import React from 'react';

import { withModal } from '../../../shared/containers/containerModal'

import ModalWrapper from '../../../shared/items/static/modalWrapper'
import IntellegenceEmployeesModal from './intelleganceEmployeesModal'

const ModalIntellegence = props => {
  const { modal, onCloseModal } = props

  return (
    <ModalWrapper
      open={modal.isOpen}
      onClose={onCloseModal.bind(this)}
      title="Сведения всех датчиков"
    >
      <IntellegenceEmployeesModal />
    </ModalWrapper>
  )
}

export default withModal(React.memo(ModalIntellegence))