import {
  REQUEST_SHARED_SHEDULES,
  RESPONSE_SHARED_SHEDULES,
  REQUEST_SHARED_SHEDULES_FAILURE,
} from "../actions/sharedContentActions.js";

import { states as queriesStates } from "./queriesStates";

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  actionType: null,
  schedules: [],
  errors: null,
};

const sharedContentReduser = (state = initialState, action) => {
  if (action.type === REQUEST_SHARED_SHEDULES) {
    return Object.assign({}, state, {
      dataState: queriesStates.REQUEST,
      actionType: action.type,
    });
  }

  if (action.type === RESPONSE_SHARED_SHEDULES) {
    return Object.assign({}, state, {
      dataState: queriesStates.READY,
      actionType: action.type,
      schedules: action.schedules,
    });
  }

  if (action.type === REQUEST_SHARED_SHEDULES_FAILURE) {
    return Object.assign({}, state, {
      dataState: queriesStates.FAILURE,
      actionType: action.type,
      errors: action.errors,
    });
  }

  return state;
};

export default sharedContentReduser;
