import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  handleClearFilter as onHandleClearFilter,
  handleChangeStoreEmployeesFilter as onHandleChangeStore
} from '../../actions/filterEmployeesActions'

const mapStateToProps = (state) => ({
  filterEmployees: {
    dataState: state.filterEmployees.dataState,
    clearDataState: state.filterEmployees.clearDataState,
    filterItems: get(state, ['filterEmployees', 'filterItems'], {}),
  }
});

const mapDispatchToProps = {
  onHandleChangeStore,
  onHandleClearFilter
}

const withFilterEmployees = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withFilterEmployees }