import React from "react";

import Select, { components } from "react-select";
import { Scrollbars } from "react-custom-scrollbars";

import Icon from "./../static/icon";
import ButtonMediumDefault from "./buttonMediumDefault";

import variables from "./../../../values.scss";
import "./multiSelect.scss";

const NO_ITEMS = "Вариантов больше нет";

const NoOptionsMessage = (props) => {
  return <components.NoOptionsMessage {...props}>{NO_ITEMS}</components.NoOptionsMessage>;
};

const MultiValueContainer = (props) => {
  return (
    <components.MultiValueContainer {...props} style={{ paddingTop: "4px" }}>
      <ButtonMediumDefault>{props.children}</ButtonMediumDefault>
    </components.MultiValueContainer>
  );
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon name="x" />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name="x" />
    </components.MultiValueRemove>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="direction-desc" />
    </components.DropdownIndicator>
  );
};

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {props.children.length > 5 ? (
        <Scrollbars style={{ height: "195px" }} autoHide={true}>
          {props.children}
        </Scrollbars>
      ) : (
        props.children
      )}
    </components.MenuList>
  );
};

const MultiSelect = (props) => {
  const { id, name, valueStore, options, handleChange, placeholder } = props;

  const colourStyles = {
    clearIndicator: (styles) => {
      return {
        ...styles,
        color: variables.multiDropDownArrow,
        fontSize: "10px",
        cursor: "pointer",
      };
    },
    dropdownIndicator: (styles) => {
      return {
        ...styles,

        color: variables.multiDropDownArrow,
        fontSize: "6px",
        cursor: "pointer",
      };
    },
    multiValueLabel: (styles) => {
      return {
        ...styles,
        fontSize: "11px",
        fontWeight: "normal",
        padding: "0px !important",
        backgroundColor: "none",
        color: variables.multiSelectLabel,
        textTransform: "normal",
      };
    },
    multiValueRemove: (styles) => {
      return {
        ...styles,
        backgroundColor: "none",
        paddingRight: "0px",
        fontSize: "8px",
        ":hover": {
          backgroundColor: "none",
        },
      };
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? variables.multiSelectFocusOption : null,
        color: isFocused ? variables.multiSelectFocusColorOption : variables.multiSelectColorOption,
        cursor: "pointer",
      };
    },
  };

  return (
    <Select
      id={!!id ? id : name}
      name={name}
      value={valueStore[name]}
      options={options}
      isMulti={true}
      captureMenuScroll={false}
      onChange={handleChange}
      placeholder={placeholder}
      className="container-multiSelect"
      classNamePrefix="container-multiSelectInner"
      components={{
        DropdownIndicator,
        MenuList,
        MultiValueRemove,
        MultiValueContainer,
        NoOptionsMessage,
        ClearIndicator,
      }}
      styles={colourStyles}
   // isClearable={true}
      closeMenuOnSelect={true}
    />
  );
};

export default React.memo(MultiSelect);
