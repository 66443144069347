import { connect } from "react-redux";
import get from "lodash/get";

import { getShcedules as onGetSchedules } from "../../actions/sharedContentActions";

const mapStateToProps = (state) => ({
  sharedContent: {
    dataState: state.sharedContent.dataState,
    schedules: get(state, ["sharedContent", "schedules"], []),
    errors: get(state, ["sharedContent", "errors"], null),
  },
});

const mapDispatchToProps = {
  onGetSchedules,
};

const withSharedContent = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withSharedContent };
