import { sortEmployeesTypes } from "../components/sections/employees/static.sortEmployeesTypes";
import { sortDirections } from "../components/sections/employees/static.sortDirections";
import FilterEmployeesItems from "./../components/sections/employees/static.filterEmployeesItems";

import {
  REQUEST_CLEAR_FILTER,
  READY_CLEAR_FILTER,
  REQUEST_CHANGE_STORE_EMPLOYEESFILTER,
  READY_CHANGE_STORE_EMPLOYEESFILTER,
} from "../actions/filterEmployeesActions";

import { states } from "./queriesStates";

const initialState = {
  dataState: states.NOT_REQUESTED,
  clearDataState: states.NOT_REQUESTED,
  filterItems: {
    companies: [],
    orderBy: sortEmployeesTypes[0].type,
    orderDesc: sortDirections.asc,
    count: -1, // countOnPage[0].value,
    filterCount: 0,
    page: 1,
  },
};

const setFilterEmployeesCount = (filterItems) => {
  var counter = 0;

  Object.keys(filterItems).map((filterItem) => {
    switch (filterItem) {
      case FilterEmployeesItems.companies.name:
      case FilterEmployeesItems.groups.name:
      case FilterEmployeesItems.workPositions.name:
      case FilterEmployeesItems.inSleep.name:
      case FilterEmployeesItems.work.name:
      case FilterEmployeesItems.charging.name:
      case FilterEmployeesItems.offSleep.name:
      case FilterEmployeesItems.alarm.name:
      case FilterEmployeesItems.poweredFrom.name:
      case FilterEmployeesItems.noData.name:
      default:
        return null;
    }
  });

  return filterItems.filterCount !== counter ? counter : filterItems.filterCount;
};

const filterEmployeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CLEAR_FILTER: {
      return Object.assign({}, state, {
        clearDataState: states.REQUEST,
      });
    }
    case READY_CLEAR_FILTER: {
      var nextFilterItems = Object.assign({}, state.filterItems, action.clearItems);

      nextFilterItems["filterCount"] = setFilterEmployeesCount(nextFilterItems);

      return Object.assign({}, state, {
        clearDataState: states.READY,
        filterItems: nextFilterItems,
      });
    }
    case REQUEST_CHANGE_STORE_EMPLOYEESFILTER: {
      return Object.assign({}, state, {
        dataState: states.REQUEST,
      });
    }
    case READY_CHANGE_STORE_EMPLOYEESFILTER: {
      const { name, value } = action.payload;
      var filterItems = Object.assign({}, state.filterItems);

      typeof name === "object"
        ? name.map((item, index) => {
            filterItems[item] = value[index];
            return null;
          })
        : (filterItems[name] = value);

      if (name !== "page" && state.filterItems.page === filterItems.page) {
        filterItems.page = initialState.filterItems.page;
      }

      filterItems["filterCount"] = setFilterEmployeesCount(filterItems);

      return Object.assign({}, state, {
        dataState: states.READY,
        filterItems: filterItems,
      });
    }
    default:
      return state;
  }
};

export default filterEmployeesReducer;
