import { connect } from "react-redux";
import get from "lodash/get";

import {
  getEmployeeGroups as onGetEmployeeGroups,
  addEmployeeToGroup as onAddEmployeeToGroup,
  removeEmployeeFromGroup as onRemoveEmployeeFromGroup,
} from "../../actions/employeeGroupsActions";

const mapStateToProps = (state) => ({
  employeeGroups: {
    dataState: state.employeeGroups.dataState,
    groups: get(state, ["employeeGroups", "groups"], []),
  },
});

const mapDispatchToProps = {
  onGetEmployeeGroups,
  onAddEmployeeToGroup,
  onRemoveEmployeeFromGroup,
};

const withEmployeeGroups = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withEmployeeGroups };
