import { connect } from "react-redux";
import get from "lodash/get";

import {
  setRouteData as onSetRouteData,
  clearMapRoute as onClearMapRoute,
} from "./../../actions/intelligenceTimeRangeMapActions";

const mapStateToProps = (state) => ({
  intelligenceTimeRangeMap: {
    points: get(state, ["intelligenceTimaRangeMap", "points"], []),
    route: get(state, ["intelligenceTimaRangeMap", "route"], []),
    features: get(state, ["intelligenceTimaRangeMap", "features"], []),
  },
});

const mapDispatchToProps = {
  onSetRouteData,
  onClearMapRoute,
};

const withIntelligenceTimeRangeMap = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);

export { withIntelligenceTimeRangeMap };
