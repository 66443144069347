import { connect } from 'react-redux'
import get from 'lodash/get';

import TimeRange from './../../components/sections/employees/static.timeRange'
import {
  changeIntellegenceRangeType as onChangeIntellegenceRangeType
} from '../../actions/intellegenceRangeTypeActions'

const mapStateToProps = (state) => ({
  intellegenceRangeType: {
    rangeType: get(state, ['intellegenceRangeType', 'rangeType'], TimeRange.intimerange.value),
  }
})

const mapDispatchToProps = {
  onChangeIntellegenceRangeType
}

const withIntellegenceRangeType = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withIntellegenceRangeType }