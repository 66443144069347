import api from "./apiService.js";
import WithToken from "./tokenService";
import configuration from "../../config.json";

const GeoZoneServices = {
  create: function (options) {
    return api.post("geozones", options, WithToken.headersToken({}, configuration.cookieName));
  },
  delete: function (id) {
    return api.delete("/geozones/" + id, WithToken.headersToken({}, configuration.cookieName));
  },
  update: function (options) {
    return api.put("/geozones/" + options.id, options, WithToken.headersToken({}, configuration.cookieName));
  },
  addEmployee: function (id, employeeId) {
    return api.post(`/geozones/${id}/employees/${employeeId}`, {}, WithToken.headersToken({}, configuration.cookieName));
  },
  deleteEmployee: function (id, employeeId) {
    return api.delete(`/geozones/${id}/employees/${employeeId}`, WithToken.headersToken({}, configuration.cookieName));
  },
  getEmployeeGeozones: function () {
    return api.get("EmployeeGeozones", WithToken.headersToken({}, configuration.cookieName));
  },
};

export default GeoZoneServices;
