"use stric";
import configuration from "../../config.json";
import CookieService from "./cookiesService";
///Удалить этот тупой класс
const TOKEN_HEDER_HAME = "x-token";

var WithToken = {
  getCookie: function (name) {
    return CookieService.getCookie(name);
  },

  getEmail: function (name) {
    return !!CookieService.getCookie(name) ? JSON.parse(CookieService.getCookie(name)).email : false;
  },
  getToken: function (name) {
    return !!CookieService.getCookie(name) ? JSON.parse(CookieService.getCookie(name)).token : false;
  },
  getCompanyTitle: function (name) {
    return !!CookieService.getCookie(name) ? JSON.parse(CookieService.getCookie(name)).title : false;
  },
  getAccessToUsers: function (name) {
    const accessToUsers = CookieService.getCookie(name);

    return !!accessToUsers ? JSON.parse(accessToUsers).accessToUsers : false;
  },
  IsMapOverlayer: function (name) {
    if (!!CookieService.getCookie(name)) {
      const mapOverlayer = CookieService.getCookie(name).mapOverlayer;
      const enterCompany = CookieService.getCookie(configuration.permissionsCookie).includes(50001);

      return enterCompany || !!mapOverlayer;
    }

    return false;
  },
  isToken: function (name) {
    return !!WithToken.getToken(name) ? true : false;
  },
  headersToken: function (object, name) {
    if (!object.headers) {
      object.headers = {};
    }
    object.headers[TOKEN_HEDER_HAME] = WithToken.getToken(name);
    return object;
  },
};

export default WithToken;
