import {
  HANDLE_CHANGE_INTELLEGENCE_TAB
} from '../actions/intellegenceTabActions'

import { intellegenceTabs } from '../components/sections/employees/static.intellegenceTabs'

const initialState = {
  activeTabName: intellegenceTabs[0].name,
}

const intellegenceTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_CHANGE_INTELLEGENCE_TAB: {
      return Object.assign(
        {},
        state,
        {
          activeTabName: action.name
        }
      )
    }
    default:
      return state
  }
}

export default intellegenceTabReducer
