import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  handleChangeStoreSignIn as onHandleChangeStore
} from '../../actions/signInActions'

const mapStateToProps = (state) => ({
  signInForm: {
    dataState: state.signInForm.dataState,
    signInItems: get(state, ['signInForm', 'signInItems'], {})
  }
});

const mapDispatchToProps = {
  onHandleChangeStore
}

const withSignInForm = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withSignInForm }