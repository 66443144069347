import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  chooseEmployee as onChooseEmployee
} from '../../actions/choosedEmployeeActions'

const thisDate = new Date()

const mapStateToProps = (state) => ({
  choosedEmployee: {
    dataState: state.choosedEmployee.dataState,   
    employeeId: get(state, ['choosedEmployee', 'data', 'id'], null),
    imei: get(state, ['choosedEmployee', 'data', 'imei'], null),
    isBookmark: get(state, ['choosedEmployee', 'data', 'isBookmark'], false),
    lastActive: get(state, ['choosedEmployee', 'data', 'lastActive'], thisDate),
    geoZones: get(state, ['choosedEmployee', 'data', 'geoZones'], []),   
    number: get(state, ['choosedEmployee', 'data', 'number'], ''),
    alarmTime: get(state, ['choosedEmployee', 'data', 'alarmTime'], null),
    status: {
      id: get(state, ['choosedEmployee', 'data', 'status', 'id'], 0),
      title: get(state, ['choosedEmployee', 'data', 'status', 'title'], ''),
    },
    unit: {    
      groups: get(state, ['choosedEmployee', 'data', 'unit', 'groups'], []),   
    }
  }
});

const mapDispatchToProps = {
  onChooseEmployee
}

const withChoosedEmployee = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withChoosedEmployee }