import { combineReducers } from "redux";

import { reducer as notifReducer } from "redux-notifications";

import appStateReducer from "./appStateReducer";
import authReducer from "./authReducer";
import registrationReducer from "./registrationReducer";
import restorePasswordReducer from "./restorePasswordReducer";
import signInReducer from "./signInReducer";
import employeeUpdateReduser from "./employeeUpdateReducer";
import filterEmployeesReducer from "./filterEmployeeReducer";
import employeesReducer from "./employeesReducer";
import usersReducer from "./usersReducer";
import companiesReducer from "./companiesReducer";
import userUpdateReducer from "./userUpdateReducer";
import userDeleteReducer from "./userDeleteReducer";
import newUserReducer from "./newUserReducer";
import lastStatesReducer from "./employeeLastStateReducer";
import employeeGeoZonesReducer from "./employeeGeoZonesReducer";
import employeeMultipleReducer from "./employeeMultipleReducer";
import employeeSettingsReducer from "./employeeSettingsReduser";
import employeeShortInfoReducer from "./employeeShortInfoReducer";
import objectivesReduser from "./objectivesReduser";
import sharedContentReduser from "./sharedContentReduser";
import employeesWorkPositionsReducer from "./employeesWorkPositionsReducer";
import employeesGroupsReducer from "./employeesGroupsReducer";
import employeeGroupsReducer from "./employeeGroupsReducer";
import employeesCompanies from "./employeesCompaniesReducer";
import choosedEmployeeReducer from "./choosedEmployeeReducer";
import modalReducer from "./modalReducer";
import inmomentIntellegenceReducer from "./inmomentIntellegenceReducer";
import itemsIntellegenceReducer from "./itemsIntellegenceReducer";
import intellegenceTabReducer from "./intellegenceTabReducer";
import setEmployeeBookmarkReducer from "./setEmployeeBookmarkReducer";
import deleteEmployeeBookmarkReducer from "./deleteEmployeeBookmarkReducer";
import intellegenceModalFormReducer from "./intellegenceModalFormReducer";
import intellegenceRangeTypeReducer from "./intellegenceRangeTypeReducer";
import sensorsPreviewReducer from "./sensorsPreviewReducer";
import employeeMapStateReducer from "./employeeMapStateReducer";
import sensorsReducer from "./sensorsReducer";
import timerangeIntellegenceReducer from "./timerangeIntellegenceReducer";
import intellegenceTimeRangePlayerReducer from "./intellegenceTimeRangePlayerReducer";
import dashboardsReducer from "./dashboardsReducer";
import dashboardReducer from "./dashboardReducer";
import timerangeIntelligenceDataReducer from "./timerangeIntelligenceDataReducer";
import intelligenceTimeRangeMapReducer from "./intelligenceTimeRangeMapReducer";
import routeModalReducer from "./routeModalReducer";
import notificationsReducer from "./notificationReducer";
import permissionsReduser from "./permissionsReduser";
import devicesReducer from "./devicesReducer";

const reducers = combineReducers({
  notif: notifReducer,

  appState: appStateReducer,
  auth: authReducer,
  signInForm: signInReducer,
  registration: registrationReducer,
  restorePassword: restorePasswordReducer,
  employeeUpdate: employeeUpdateReduser,
  filterEmployees: filterEmployeesReducer,
  employees: employeesReducer,
  users: usersReducer,
  companies: companiesReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,
  newUser: newUserReducer,
  lastStates: lastStatesReducer,
  employeeGeoZones: employeeGeoZonesReducer,
  employeeMultipleSelect: employeeMultipleReducer,
  employeeSettings: employeeSettingsReducer,
  objectives: objectivesReduser,
  sharedContent: sharedContentReduser,
  unitShortInfo: employeeShortInfoReducer,
  employeesWorkPositions: employeesWorkPositionsReducer,
  employeesGroups: employeesGroupsReducer,
  employeeGroups: employeeGroupsReducer,
  employeesCompanies: employeesCompanies,
  choosedEmployee: choosedEmployeeReducer,
  modal: modalReducer,
  inmomentIntellegence: inmomentIntellegenceReducer,
  itemsIntellegence: itemsIntellegenceReducer,
  intellegenceTab: intellegenceTabReducer,
  setEmployeeBookmark: setEmployeeBookmarkReducer,
  deleteEmployeeBookmark: deleteEmployeeBookmarkReducer,
  intellegenceModalForm: intellegenceModalFormReducer,
  intellegenceRangeType: intellegenceRangeTypeReducer,
  sensorsPreview: sensorsPreviewReducer,
  employeeMapState: employeeMapStateReducer,
  sensors: sensorsReducer,
  timerangeIntellegence: timerangeIntellegenceReducer,
  intellegenceTimeRangePlayer: intellegenceTimeRangePlayerReducer,
  dashboards: dashboardsReducer,
  dashboard: dashboardReducer,
  timerangeIntelligenceData: timerangeIntelligenceDataReducer,
  intelligenceTimaRangeMap: intelligenceTimeRangeMapReducer,
  routeModal: routeModalReducer,
  notifications: notificationsReducer,
  permissions: permissionsReduser,
  devices: devicesReducer,
});

export default reducers;
