import React from "react";
import { withUserUpdate } from "../../../shared/containers/containerUserUpdate";
import Icon from "../../../shared/items/static/icon";

const UserBlock = (props) => {
  const { isBlocked, id, onUserUpdate, title } = props;

  return (
    <div onClick={onUserUpdate.bind(this, id, !isBlocked, title)}>
      <Icon name={isBlocked ? "lock" : "lock-open"} title={isBlocked ? "заблокирован " : "активен "} />
    </div>
  );
};

export default withUserUpdate(React.memo(UserBlock));
