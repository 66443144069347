import  {
  REQUEST_SENSORS,
  RESPONSE_SENSORS,
  REQUEST_SENSORS_FAILURE
} from './../actions/sensorsActions'

import { states } from './queriesStates'

const initialState = {
  dataState: states.NOT_REQUESTED,
  data: {},
  feature:{},
  errors: {},
}

const sensorsReducer = (state = initialState, action) => {
  switch(action.type) {
    case REQUEST_SENSORS:
      return Object.assign(
        {},
        state, 
        {
          dataState: states.REQUEST
        }
      )
    case RESPONSE_SENSORS:
      return Object.assign(
        {},
        state,
        {
          dataState: states.READY,
          data: action.data,
          feature: action.feature,
          errors: {}
        }
      )
    case REQUEST_SENSORS_FAILURE:
      return Object.assign(
        {},
        state, 
        {
          dataState: states.FAILURE,
          errors: action.errors
        }
      )
    default:
      return state
  }
}

export default sensorsReducer