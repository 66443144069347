import React, { Component } from "react";
import { withObjectives } from "../../../shared/containers/containerObjectives";
import { states } from "../../../reducers/queriesStates";
import { withEmployeeMultipleSelect } from "../../../shared/containers/containerEmployeeMultipleSelect";
import ObjectivesBuildingPanel from "./objectivesBuildingPanel";
import ObjectivesBuildingCreatePanel from "./objectivesBuildingCreatePanel";
import CloseIcon from "@mui/icons-material/Close";
import { RESPONSE_OBJECTIVES } from "../../../actions/objectiveActions";
import ButtonPrimary from "../../../shared/items/contols/buttonPrimary";
import dateTime from "../../../shared/services/date.service";
import { Scrollbars } from "react-custom-scrollbars";
import "./objectivesBlock.modeule.scss";
import { v4 as uuid_v4 } from "uuid";

class ObjectivesBuildingsContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createPanel: false,
      objectives: props.objectives,
      objectiveType: props.objectives.objectiveType,
      selectObjective: { id: null },
    };
  }

  componentDidMount() {
    const { onGetObjectives } = this.props;

    onGetObjectives({ objectiveType: 2 });
  }

  componentDidUpdate(preProps) {
    const { objectives } = this.props;
    const { selectObjective } = this.state;

    if (
      preProps.objectives.dataState === states.REQUEST &&
      objectives.dataState === states.READY &&
      objectives.actionType === RESPONSE_OBJECTIVES &&
      objectives.objectiveType === 2
    ) {
      this.setState({ objectives: objectives, createPanel: false, selectObjective });
    }
  }

  onObjectiveClick(objective) {
    const { onEmployeeMultipleSelect } = this.props;
    onEmployeeMultipleSelect(null);

    this.setState({ selectObjective: objective });
  }

  objectivesList() {
    const { objectives } = this.props;
    const { selectObjective } = this.state;
    const checkmark = "✔";

    return objectives.objectives.map((objective) => {
      const objectiveClick = (e) => {
        e.stopPropagation();
        selectObjective.id !== objective.id ? this.onObjectiveClick(objective) : this.onObjectiveClick({ id: null });
      };

      const title = objective.entry
        ? `Посещение адреса "${objective.building.address}"`
        : `Пребывание по адресу "${objective.building.address}"`;

      if ((selectObjective.id !== null && selectObjective.id === objective.id) || selectObjective.id === null) {
        if (selectObjective.id === objective.id) {
          return (
            <div className={"tableObjectivesRowConcat"}>
              <div className={"tableObjectivesOpenPanel"}>
                <b>{title}</b>
                <CloseIcon
                  className={"tableObjectivesRowConcat_close"}
                  onClick={(e) => {
                    e.stopPropagation();
                    objectiveClick(e);
                  }}
                />
              </div>

              <ObjectivesBuildingPanel key={objective.id + "_panel"} objective={selectObjective} />
            </div>
          );
        }

        return this.getObjectiveRow(objective, title, objectiveClick, checkmark);
      } else {
        return null;
      }
    });
  }

  getObjectiveRow(objective, title, objectiveClick, checkmark) {
    return (
      <div className="tableObjectivesRow" key={objective.id} onClick={objectiveClick.bind(this)}>
        <div>
          <span>
            {objective.entry
              ? `Посещение адреса "${objective.building.address}"`
              : `Пребывание по адресу "${objective.building.address}"`}
          </span>
        </div>
        <div>
          <span>{objective.information} </span>
        </div>
        <div>
          <span>{dateTime.toFormat7(dateTime.toLocal(objective.date))} </span>
        </div>
        <div>
          <span>{objective.noticeCompletion ? checkmark : ""} </span>
        </div>
        <div>
          <span>{objective.noticeNotCompletion ? checkmark : ""} </span>
        </div>
      </div>
    );
  }

  openCreatePanel(e) {
    this.setState({ createPanel: true });
  }

  render() {
    const { createPanel, objectives, selectObjective } = this.state;
    const { objectiveType } = this.state.objectives;

    if (objectiveType !== 2) {
      return null;
    }

    return (
      <div key={uuid_v4()}>
        {createPanel ? (
          <ObjectivesBuildingCreatePanel />
        ) : (
          <div className={"tabObjectivesContent"}>
            <div className={"objectiveButtonWrapper"}>
              <ButtonPrimary handleClick={this.openCreatePanel.bind(this)} title="Новое задание" />
            </div>
            {selectObjective.id === null ? (
              <div className={"tableObjectivesRowHeader"}>
                <div>Задание</div>
                <div>Описание</div>
                <div>Создано</div>
                <div>Уведомлять об выполнении</div>
                <div>Уведомлять об не выполнении</div>
              </div>
            ) : null}
            {objectives.count > 0 ? (
              <Scrollbars style={{ minHeight: "650px" }} autoHide={true}>
                {this.objectivesList()}
              </Scrollbars>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default withObjectives(withEmployeeMultipleSelect(ObjectivesBuildingsContent));
