import { READY_CHANGE_RANGE_TYPE } from '../actions/intellegenceRangeTypeActions'

const initialState = {}

const intellegenceRangeTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case READY_CHANGE_RANGE_TYPE: {
      var nextState = Object.assign({}, state)

      nextState[action.name] = action.value

      return Object.assign(
        {},
        state,
        nextState
      )
    }
    default:
      return state
  }
}

export default intellegenceRangeTypeReducer
