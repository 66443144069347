import React from "react";

import ModalIntellegence from "./modalIntellegence";
import ModalRoute from "./modalRoute";
import IntelligenceTimeRangeTableWrapper from "./intelligenceTimeRangeTableWrapper";

import styles from "./intelligenceInTimeRangeContent.module.scss";

const IntelligenceInTimeRangeContent = () => {
  return (
    <div className={styles.intellegenceTimeRangeContent}>
      <IntelligenceTimeRangeTableWrapper />
      <ModalIntellegence />
      <ModalRoute />
    </div>
  );
};

export default IntelligenceInTimeRangeContent;
