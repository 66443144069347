import React from "react";

import DropDown from "./items/contols/dropDown";
import UserStateDropDownMenu from "./userStateDropDownMenu";
import WithToken from "./services/tokenService";
import { withCompanies } from "../shared/containers/containerCompanies";
import styles from "./userState.module.scss";

const authTNToken = "authTNToken";

const UserState = function (props) {
  const { companies } = props;

  function getCompanyTitle(companies) {
    if (!companies.enterCompanyId) {
      return WithToken.getCompanyTitle(authTNToken);
    }

    for (const company of companies.companies) {
      if (company.id === companies.enterCompanyId) {
        return company.title;
      }
    }
  }

  return (
    <div className={styles.userState}>
      <div className={styles.container_userStateData}></div>
      <div className={styles.container_userStateData}>
        <p>{getCompanyTitle(companies)}</p>
        <DropDown
          button={
            <div>
              <span className={styles.userName}>
                <b>{WithToken.getEmail(authTNToken)}</b>
              </span>
            </div>
          }
          content={<UserStateDropDownMenu />}
        />
      </div>
    </div>
  );
};

export default withCompanies(React.memo(UserState));
