import React, { Component } from 'react'

import Logo from '../../shared/logo'
import SignInFooter from '../../shared/footer/signinFooter'
import RestorePasseordForm from './restorePasswordForm'
import ButtonSendMailWrapper from './buttonSendMailWrapper'

import styles from './restorePassword.module.scss'
class RestorePasseord extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    return (
      <div className={styles.restorePasswordWrapper}>
        <div className={styles.restorePasswordForm}>
          <div className={styles.logo_container}>
            <Logo />
          </div>
          <RestorePasseordForm />
          {
            <div className={styles.button_container}>
              <ButtonSendMailWrapper token={this.state.token} />
            </div>          
          }
          {
             <div style={{ textAlign: 'center', padding: '15px' }}>
             <b><a href={'/'}>Вход в систему</a></b>
           </div>
          }
        </div>

        <SignInFooter />
      </div>
    )
  }
}

export default RestorePasseord