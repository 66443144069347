import EmployeeSettingsSetvice from "../shared/services/employeeSettingsSetvice";
import EmployeesService from "../shared/services/employeesService";

const REQUEST_EMPLOYEES_SETTINGS = "REQUEST_EMPLOYEES_SETTINGS_FAILURE";
const RESPONSE_EMPLOYEES_SETTINGS = "RESPONSE_EMPLOYEES_SETTINGS";
const REQUEST_EMPLOYEES_SETTINGS_FAILURE = "REQUEST_EMPLOYEES_SETTINGS_FAILURE";

const REQUEST_EMPLOYEES_REPROCCES = "REQUEST_EMPLOYEES_REPROCCES_FAILURE";
const RESPONSE_EMPLOYEES_REPROCCES = "RESPONSE_EMPLOYEES_REPROCCES";
const REQUEST_EMPLOYEES_REPROCCES_FAILURE = "REQUEST_EMPLOYEES_REPROCCES_FAILURE";

const requestGetEmployeeSettingsAction = () => {
  return {
    type: REQUEST_EMPLOYEES_SETTINGS,
  };
};

const responseGetEmployeeSettingsAction = (data) => {
  return {
    type: RESPONSE_EMPLOYEES_SETTINGS,
    data,
  };
};

const requestGetEmployeeSettingsFailureAction = (errors) => {
  return {
    type: REQUEST_EMPLOYEES_SETTINGS_FAILURE,
    errors,
  };
};

const requestEmployeeReprocceAction = () => {
  return {
    type: REQUEST_EMPLOYEES_REPROCCES,
  };
};

const responseEmployeeReprocceAction = (data) => {
  return {
    type: RESPONSE_EMPLOYEES_REPROCCES,
    data,
  };
};

const requestEmployeeReprocceFailureAction = (errors) => {
  return {
    type: REQUEST_EMPLOYEES_REPROCCES_FAILURE,
    errors,
  };
};

const buildSettingItems = (data) => {
  const settingItems = [];

  for (const item of data.settings) {
    const settingItem = {
      item,
      dependents: [],
    };

    if (item.requiredSettingId === null) {
      settingItems.push(settingItem);
    } else {
      const indx = settingItems.findIndex((setting) => {
        return setting.item.id === item.requiredSettingId;
      });

      if (indx !== -1) {
        settingItems[indx].dependents.push(item);
      }
    }
  }

  return settingItems;
};

const getEmployeeSettings = (id) => {
  return (dispatch) => {
    dispatch(requestGetEmployeeSettingsAction());

    EmployeeSettingsSetvice.getEmployeeSettings(id)
      .then((result) => {
        const settingItems = buildSettingItems(result.data);

        dispatch(responseGetEmployeeSettingsAction(settingItems));
      })
      .catch((error) => {
        dispatch(requestGetEmployeeSettingsFailureAction(error.response));
      });
  };
};

const addEmployeeSettings = (id, settingsId, value) => {
  return (dispatch) => {
    EmployeeSettingsSetvice.addEmployeeSettings(id, settingsId, value)
      .then((result) => {
        dispatch(getEmployeeSettings(id));
      })
      .catch((error) => {
        dispatch(requestGetEmployeeSettingsFailureAction(error.response));
      });
  };
};

const updateEmployeeSettings = (id, settingsId, value, isGroup = false) => {
  return (dispatch) => {
    EmployeeSettingsSetvice.updateEmployeeSettings(id, settingsId, value)
      .then((result) => {
        if (isGroup) dispatch(getEmployeeSettings(id));
      })
      .catch((error) => {
        dispatch(requestGetEmployeeSettingsFailureAction(error.response));
      });
  };
};

const employeeReprocces = (id, time) => {
  return (dispatch) => {
    dispatch(requestEmployeeReprocceAction());

    EmployeesService.reprocces(id, time)
      .then((result) => {
        dispatch(responseEmployeeReprocceAction(result.status === 204));
      })
      .catch((error) => {
        dispatch(requestEmployeeReprocceFailureAction(error));
      });
  };
};

export {
  getEmployeeSettings,
  addEmployeeSettings,
  updateEmployeeSettings,
  REQUEST_EMPLOYEES_SETTINGS,
  RESPONSE_EMPLOYEES_SETTINGS,
  REQUEST_EMPLOYEES_SETTINGS_FAILURE,
  employeeReprocces,
  REQUEST_EMPLOYEES_REPROCCES,
  RESPONSE_EMPLOYEES_REPROCCES,
  REQUEST_EMPLOYEES_REPROCCES_FAILURE,
};
