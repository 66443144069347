import React from 'react'

import { withAuth } from '../../shared/containers/containerAuth'
import { withSignInForm } from '../../shared/containers/containerSignInForm'

import LoginInput from '../../shared/items/contols/loginInput'

import styles from './signInForm.module.scss'
import { StylesProvider } from '@material-ui/core'

const SignInForm = props => {
  const { onHandleChangeStore, signInForm, errors } = props

  return (
    <React.Fragment>
      <div className={StylesProvider.login_container}>
        <LoginInput
          name="email"
          type="text"
          store={signInForm.signInItems}
          handleChangeStore={onHandleChangeStore}
          placeholder="Логин"
          errors={errors}
        />
      </div>

      <div className={styles.password_container}>
        <LoginInput
          name="password"
          type="password"
          store={signInForm.signInItems}
          handleChangeStore={onHandleChangeStore}
          placeholder="Пароль"
          errors={errors}
        />
      </div>
    </React.Fragment>
  )
}

export default withAuth(withSignInForm(React.memo(SignInForm)))