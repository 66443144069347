import { connect } from "react-redux";

import {
  getTimerangeIntellegence as onGetTimerangeIntellegence,
  setTimerangeDates as onSetTimerangeDates,
  clearIntellegence as onClearIntellegence,
  getEventObjectives as onGetEventObjectives,
} from "./../../actions/timerangeIntellegenceActions";

function mapStateToProps(state) {
  console.log("timerangeIntellegence", state.timerangeIntellegence);

  return {
    timerangeIntellegence: {
      dataState: state.timerangeIntellegence.dataState,
      actionType: state.timerangeIntellegence.actionType,
      data: state.timerangeIntellegence.data,
      features: state.timerangeIntellegence.features,
      distance: state.timerangeIntellegence.distance      ,
      errors: state.timerangeIntellegence.errors,
      rangeStartDate: state.timerangeIntellegence.timerangeDates.rangeStartDate,
      rangeEndDate: state.timerangeIntellegence.timerangeDates.rangeEndDate,
      currentDate: state.timerangeIntellegence.timerangeDates.currentDate,
      groupStatus: state.timerangeIntellegence.groupStatus,
      eventObjectives: state.timerangeIntellegence.eventObjectives,
    },
  };
}

const mapDispatchToProps = {
  onGetTimerangeIntellegence,
  onSetTimerangeDates,
  onClearIntellegence,
  onGetEventObjectives,
};

const withTimerangeIntellegence = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withTimerangeIntellegence };
