import React from 'react'

const Logo = function () {
  return (
    <div>
      <img src="/img/logo.svg" alt="logo"  style={{width:"180px"}}/>
    </div>
  )
}

export default Logo