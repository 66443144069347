const RESPONSE_OPEN_MODAL = 'RESPONSE_OPEN_MODAL'
const RESPONSE_CLOSE_MODAL = 'RESPONSE_CLOSE_MODAL'

const responseOpenModalAction = () => {
  return {
    type: RESPONSE_OPEN_MODAL
  }
}

const openModal = () => {
  return (dispatch) => {
    dispatch(responseOpenModalAction())
  }
}

const responseCloseModalAction = () => {
  return {
    type: RESPONSE_CLOSE_MODAL
  }
}

const closeModal = () => {
  return (dispatch) => {
    dispatch(responseCloseModalAction())
  }
}

export {
  openModal,
  responseOpenModalAction,
  RESPONSE_OPEN_MODAL,

  closeModal,
  responseCloseModalAction,
  RESPONSE_CLOSE_MODAL
}

