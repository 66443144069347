import { connect } from "react-redux";
import get from "lodash/get";

import {
  getEmployeesLastStates as onGetEmployeesLastStates,
  responseEmployeesLastStatesAction as onResponseEmployeesLastStatesAction,
  reset as onResetEmployeesLastStates,
} from "../../actions/employeeLastStateAction";

const mapStateToProps = (state) => ({
  lastStates: {
    dataState: state.lastStates.dataState,
    employees: get(state, ["lastStates", "employees"], {}),
  },
});

const mapDispatchToProps = {
  onGetEmployeesLastStates,
  onResponseEmployeesLastStatesAction,
  onResetEmployeesLastStates,
};

const withEmployeesLastState = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withEmployeesLastState };
