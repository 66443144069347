import dateTime from "../../../shared/services/date.service";
import configuration from "../../../config.json";
import CookieService from "../../../shared/services/cookiesService";
import { SignalRService } from "../../../components/sections/employees/signalRService.js";

const EmployeesLastStateService = (function () {
  const RECIVE_LAST_STATE = "RecieveEmployeeLastState";
  const RECIVE_NOTIFICATION = "RecieveNotifiction";
  const employees = [];
  const callBacks = {};
  let instance;
  let isDataReceived = false;
  let logging = false;
  let timeOutId = null;

  const dataReceived = function () {
    return isDataReceived;
  };

  const setEmployees = function (newEnployess) {
    employees.length = 0;

    for (const employee of newEnployess) {
      employees.push(employee);
    }

    checkAlarm(employees, callBacks);
  };

  const onReceive = async function (employee) {
   // if (logging) log(employee);

    const index = employees.findIndex((v) => {
      return v.id === employee.id;
    });

    if (index !== -1 && dateTime.fromUtc(employee.lastActive) > dateTime.fromUtc(employees[index].lastActive)) {
      isDataReceived = true;

      employees[index] = employee;
    }
  };

  const onRecieveNotifiction = function (notification) {
    if (logging) log(notification);

    callBacks["addNotification"](notification);
  };

  const lastState = () => {
    if (isDataReceived) {
      if (callBacks["lastStates"]) {
        callBacks["lastStates"](employees);
      }

      if (callBacks["employeeList"]) {
        callBacks["employeeList"](employees);
      }

      isDataReceived = false;

      if (employees.some((x) => x.alarmTime) && callBacks["alarm"]) {
        callBacks["alarm"]();
      }
    }

    clearTimeout(timeOutId);
    timeOutId = setTimeout(lastState, 5000);
  };

  const createInstance = function () {
    logging = CookieService.getCookie(configuration.permissionsCookie).includes(30001);

    SignalRService.start();
    SignalRService.hubConnection.on(RECIVE_LAST_STATE, onReceive);
    SignalRService.hubConnection.on(RECIVE_NOTIFICATION, onRecieveNotifiction);

    clearTimeout(timeOutId);
    timeOutId = setTimeout(lastState, 5000);

    return {
      dataReceived: dataReceived,
      getEmployees: function () {
        isDataReceived = false;

        return employees;
      },
      setEmployees: setEmployees,
      setCallBack: function (name, callBack) {
        callBacks[name] = callBack;
      },
    };
  };

  return {
    getInstance: function () {
      return instance || (instance = createInstance());
    },
  };
})();

export default EmployeesLastStateService;

function checkAlarm(employees, callBacks) {
  if (employees.some((x) => x.alarmTime) && callBacks["alarm"]) {
    callBacks["alarm"]();
  }
}

async function log(employee) {
  const now = new Date(Date.now());
  const stringTime = now.toLocaleDateString() + " " + now.toLocaleString();
  console.log(`[${stringTime}] ${employee.title}  ${employee.lastActive}`);
}
