import React from "react";
import { withItemsIntellegence } from "../../../shared/containers/containerItemsIntellegence";
import IntelligenceInTimeRangeButtonWrapper from "./intelligenceInTimeRangeButtonWrapper";
import TimeRangeIntellegence from "./timeRangeIntellegence";
import SnackbarWrapper from "../../../shared/items/static/snackbarWrapper";
import Notification from "../../../shared/items/static/notification";
import dateTime from "../../../shared/services/date.service";

const DeviceLogForm = (props) => {
  const { onHandleChangeStore, itemsIntellegence } = props;

  const valueStore = itemsIntellegence.itemsIntellegence;
  const rangeStartDate = itemsIntellegence.itemsIntellegence.rangeStartDate;
  const rangeStartTime = itemsIntellegence.itemsIntellegence.rangeStartTime;
  const rangeEndDate = itemsIntellegence.itemsIntellegence.rangeEndDate;
  const rangeEndTime = itemsIntellegence.itemsIntellegence.rangeEndTime;

  const startDate = dateTime.withEmptyTimeUtcNumber(rangeStartDate);
  const endDate = dateTime.withEmptyTimeUtcNumber(rangeEndDate);
  const currentDate = dateTime.withEmptyTimeUtcNumber(new Date());

  const isValidTime =
    startDate === endDate && dateTime.toUtcNumber(rangeStartTime) > dateTime.toUtcNumber(rangeEndTime);

  const isButtonDisabled =
    isNaN(rangeStartDate) ||
    isNaN(rangeStartTime) ||
    isNaN(rangeEndDate) ||
    isNaN(rangeEndTime) ||
    startDate > currentDate ||
    startDate > endDate ||
    isValidTime;
  const checkBox = document.getElementById("checkBox-locationNotProcessed");

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <TimeRangeIntellegence onHandleChangeStore={onHandleChangeStore} valueStore={valueStore} />
        <IntelligenceInTimeRangeButtonWrapper disabled={isButtonDisabled} logByPacketTime={checkBox.checked} />
      </div>

      <SnackbarWrapper isOpen={isValidTime}>
        <Notification type="error">
          <p>{`Конечное время (${dateTime.toTimeFormat1(
            rangeEndTime
          )}) не должно быть раньше начального (${dateTime.toTimeFormat1(rangeStartTime)})`}</p>
        </Notification>
      </SnackbarWrapper>
      <div
        id={"deviceLogContainerId"}
        style={{
          fontSize: "12px",
          padding: "10px",
          margin: "10px",
          background: "black",
          color: "greenyellow",
        }}
      ></div>
    </React.Fragment>
  );
};

export default withItemsIntellegence(React.memo(DeviceLogForm));
