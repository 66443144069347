import intellegenceService from "../shared/services/intellegenceService";
import { dataPerDay, parseFeaturesIntellegence } from "./../shared/services/service.intellegence";
import dateTime from "../shared/services/date.service";
import { clearInmomentIntellegenceAction } from "./inmomentIntellegenceActions";
import { clearTimeRangeIntelligenceData } from "./timerangeIntelligenceDataActions";
import { clearPlayerData } from "./intellegenceTimeRangePlayerActions";
import { clearMapRoute } from "./intelligenceTimeRangeMapActions";

const REQUEST_GET_TIMERANGE_INTELLEGENCE = "REQUEST_GET_TIMERANGE_INTELLEGENCE";
const RESPONSE_GET_TIMERANGE_INTELLEGENCE = "RESPONSE_GET_TIMERANGE_INTELLEGENCE";
const REQUEST_GET_TIMERANGE_INTELLEGENCE_FAILURE = "REQUEST_GET_TIMERANGE_INTELLEGENCE_FAILURE";

const REQUEST_EVENT_OBJECTIVES = "REQUEST_EVENT_OBJECTIVES";
const RESPONSE_EVENT_OBJECTIVES = "RESPONSE_EVENT_OBJECTIVES";
const REQUEST_EVENT_OBJECTIVES_FAILURE = "REQUEST_EVENT_OBJECTIVES_FAILURE";

const SET_TIMERANGE_DATES = "SET_TIMERANGE_DATES";
const CLEAR_TIMERANGE_INTELLEGENCE = "CLEAR_TIMERANGE_INTELLEGENCE";

const requestGetTimerangeIntellegenceAction = () => {
  return {
    type: REQUEST_GET_TIMERANGE_INTELLEGENCE,
  };
};
const requestGetEventObjectivesAction = () => {
  return {
    type: REQUEST_EVENT_OBJECTIVES,
  };
};

const responseGetTimerangeIntellegenceAction = (data, features, distance, groupStatus,) => {
  return {
    type: RESPONSE_GET_TIMERANGE_INTELLEGENCE,
    data,
    features,
    distance,
    groupStatus,
    actionType: null,
  };
};

const responseGetEventObjectivesAction = (eventObjectives) => {
  return {
    type: RESPONSE_EVENT_OBJECTIVES,
    eventObjectives,
    actionType: "eventObjectives",
  };
};

const requestGetEventObjectivesFailureAction = (errors) => {
  return {
    type: REQUEST_EVENT_OBJECTIVES_FAILURE,
    errors,
  };
};

const requestGetTimerangeIntellegenceFailureAction = (errors) => {
  return {
    type: REQUEST_GET_TIMERANGE_INTELLEGENCE_FAILURE,
    errors,
  };
};

const getEventObjectives = (options) => {
  return (dispatch) => {
    dispatch(requestGetEventObjectivesAction());

    const parametrs = {
      id: options.employeeId,
      startDate: dateTime.stringISODate(options.startDate),
      endDate: dateTime.stringISODate(options.endDate),
    };

    intellegenceService
      .getEventObjectives(parametrs)
      .then((eventObjectives) => {
        dispatch(responseGetEventObjectivesAction(eventObjectives.data.employeeEventObjectives));
      })
      .catch((error) => {
        dispatch(requestGetEventObjectivesFailureAction(error));
      });
  };
};

const getTimerangeIntellegence = (options) => {
  return (dispatch) => {
    dispatch(requestGetTimerangeIntellegenceAction());

    const parametrs = {
      id: options.employeeId,
      startDate: dateTime.stringISODate(options.rangeStartDate),
      endDate: dateTime.stringISODate(options.rangeEndDate),
      locationNotProcessed: options.locationNotProcessed,
    };

    intellegenceService
      .getPositions(parametrs)
      .then((result) => {
        const data = dataPerDay(result.data.positions, options);
        const features = parseFeaturesIntellegence(result.data.features, options);
        const distance = result.data.distance;

        dispatch(responseGetTimerangeIntellegenceAction(data, features, distance));
      })
      .catch((error) => {
        dispatch(requestGetTimerangeIntellegenceFailureAction(error.response));
      });
  };
};

const setTimerangeDatesAction = (data) => {
  return {
    type: SET_TIMERANGE_DATES,
    data,
  };
};

const setTimerangeDates = (data) => {
  return (dispatch) => {
    dispatch(setTimerangeDatesAction(data));
  };
};

const clearTimerangeIntellegenceAction = () => {
  return {
    type: CLEAR_TIMERANGE_INTELLEGENCE,
  };
};

const clearIntellegence = () => {
  return (dispatch) => {
    dispatch(clearInmomentIntellegenceAction());
    dispatch(clearTimerangeIntellegenceAction());
    dispatch(clearTimeRangeIntelligenceData());
    dispatch(clearPlayerData());
    dispatch(clearMapRoute());
  };
};

export {
  getTimerangeIntellegence,
  requestGetTimerangeIntellegenceAction,
  responseGetTimerangeIntellegenceAction,
  requestGetTimerangeIntellegenceFailureAction,
  REQUEST_GET_TIMERANGE_INTELLEGENCE,
  RESPONSE_GET_TIMERANGE_INTELLEGENCE,
  REQUEST_GET_TIMERANGE_INTELLEGENCE_FAILURE,
  setTimerangeDates,
  setTimerangeDatesAction,
  SET_TIMERANGE_DATES,
  clearIntellegence,
  clearTimerangeIntellegenceAction,
  CLEAR_TIMERANGE_INTELLEGENCE,
  getEventObjectives,
  REQUEST_EVENT_OBJECTIVES,
  RESPONSE_EVENT_OBJECTIVES,
  REQUEST_EVENT_OBJECTIVES_FAILURE,
};
