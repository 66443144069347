import React from 'react';

import { withAppState } from '../../../shared/containers/containerAppState'

import EmployeesCounterWrapper from './employeesCounterWrapper'
import FirstTitle from '../../../shared/items/static/firstTitle'
import TitleClosedBarWrapper from '../../../shared/titleClosedBarWrapper'

import styles from './employeesTitleClosedBar.module.scss'

const EMPLOYEES_BAR_TITLE = "Сотрудники"

const EmployeesTitleClosedBar = props => {
  return !props.isSideBarOpen
    ? <TitleClosedBarWrapper>
      <FirstTitle>
        {EMPLOYEES_BAR_TITLE}
        <div className={styles.container_titleCounter}>
          <EmployeesCounterWrapper />
        </div>
      </FirstTitle>
    </TitleClosedBarWrapper>
    : null
}

export default withAppState(React.memo(EmployeesTitleClosedBar))
