import React, { Component } from 'react'

import { withRestorePasseord } from '../../shared/containers/containerRestorePasseord'

import ButtonPrimary from '../../shared/items/contols/buttonPrimary'
import PreloaderLight from '../../shared/items/static/preloaderLight'
import { states } from "../../reducers/queriesStates"

import styles from './buttonSendMailWrapper.module.scss'

var BUTTON_LOGIN_NAME = "Послать код на Email"

class ButtonSendMailWrapper extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.clickEnter)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.clickEnter)
  }

  clickEnter = (e) => {
    // const { restorePasswordItems, onCodeForRestorePasseord, data, onRestorePasseord } = this.props

    if (e.keyCode === 13) {
   /*    if (data.message !== null) {
        onRestorePasseord(restorePasswordItems)
      }
      else {
        onCodeForRestorePasseord(restorePasswordItems)
      } */
    }
  }

  render() {
    const { restorePasswordItems, onCodeForRestorePasseord, dataState, data, onRestorePasseord } = this.props
    if (data.message !== null) {
      BUTTON_LOGIN_NAME = 'Новый пароль'

      return (
        <ButtonPrimary handleClick={onRestorePasseord.bind(this, restorePasswordItems)}>
          {
            dataState === states.REQUEST
              ? <div className={styles.container_preloader}><PreloaderLight /></div>
              : BUTTON_LOGIN_NAME
          }
        </ButtonPrimary>
      )
    }
    else {
      return (
        <ButtonPrimary handleClick={onCodeForRestorePasseord.bind(this, restorePasswordItems)}>
          {
            dataState === states.REQUEST
              ? <div className={styles.container_preloader}><PreloaderLight /></div>
              : BUTTON_LOGIN_NAME
          }
        </ButtonPrimary>
      )
    }

  }
}

export default withRestorePasseord(ButtonSendMailWrapper)