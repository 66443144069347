import React from "react";
import { withTimerangeIntelligenceData } from "./containers/containerTimerangeIntelligenceData";
import { withTimerangeIntellegence } from "./containers/containerTimerangeIntellegence";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import styles from "./viewTableHead.module.scss";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "none",
    color: "none",
    border: "0px solid transparent",
    padding: "0px",
    borderRadius: "none",
  },
}))(Tooltip);

const ViewTableHead = (props) => {
  const {
    headItems,
    responsive,
    timerangeIntellegence,
    timerangeIntelligenceData,
    onSetTimeRangeIntelligenceData,
    objective,
  } = props;

  const { data, features } = timerangeIntellegence;
  const { groupStatus } = timerangeIntelligenceData;

  let className = "";

  if (objective) {
    className = styles.container_tableHeadItem_objective;
  } else {
    className = !!responsive ? styles.container_tableHeadItem_responsive : styles.container_tableHeadItem;
  }

  return (
    <div className={styles.viewTableHead}>
      {headItems.map((item, index) => {
        return (
          <div className={className} key={index}>
            {item === "Статус и заряд" ? (
              <HtmlTooltip
                title={
                  <div className={styles.tooltipTemplate}>
                    Учитывать изменение статуса для формирования новой строки
                  </div>
                }
                placement="top"
              >
                <div>
                  <input
                    type="checkbox"
                    id="stateCheckBox"
                    checked={groupStatus}
                    onChange={(e) => {
                      onSetTimeRangeIntelligenceData(data, features, !groupStatus);
                    }}
                  />
                  <label for="stateCheckBox">{item}</label>
                </div>
              </HtmlTooltip>
            ) : (
              item
            )}
          </div>
        );
      })}
    </div>
  );
};

export default withTimerangeIntelligenceData(withTimerangeIntellegence(React.memo(ViewTableHead)));
