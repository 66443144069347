import { Component } from 'react';
import ResponsiveService from './services/responsive.service'

class ResponsiveWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: ResponsiveService.getDevice()
    }
  }

  componentDidMount() {
    ResponsiveService.addResizeListener(this, "device")
  }

  showComponent() {
    const { device } = this.props
    let show = false

    if (Array.isArray(device)) {
      device.map((item) => {
        if (item === this.state.device) {
          show = true
        }

        return null
      })
    }
    else if (device === this.state.device) {
      show = true
    }

    return show
  }

  render() {
    const { invert } = this.props

    return (
      this.showComponent() && !invert
        ? this.props.children
        : !this.showComponent() && !!invert
          ? this.props.children
          : null
    )
  }
}

export default ResponsiveWrapper