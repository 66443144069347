import React, { Component } from "react";

class DayButton extends Component {
  render() {
    const { isCheck, day, title, dayClick } = this.props;

    return (
      <div
        className={"containerDayButton" + (isCheck ? " chekced" : "")}
        onClick={() => {
          dayClick(day, !isCheck);
        }}
      >
        {title}
      </div>
    );
  }
}

export default DayButton;
