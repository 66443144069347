import React, { Component } from "react";
import GeoZoneMapView from "./goeZoneMapView";
import ObjectiveSchedulesPanel from "./objectiveSchedulesPanel";
import { withObjectives } from "../../../shared/containers/containerObjectives";
import { withEmployeeGeoZones } from "../../../shared/containers/containerEmployeeGeoZones";
import { states } from "../../../reducers/queriesStates";
import "./objectivesBlock.modeule.scss";
import { v4 as uuid_v4 } from "uuid";
import ButtonPrimary from "../../../shared/items/contols/buttonPrimary";

class ObjectiveGeozonePanel extends Component {
  constructor(props) {
    super(props);
    const selectGeozone = props.objective.geoZone === undefined ? null : props.objective.geoZone;

    this.state = {
      objectiveType: props.objectiveType ? props.objectiveType : 1,
      objective: props.objective.id ? props.objective : { entry: true },
      selectGeozone: selectGeozone,
      employeeGeoZones: props.employeeGeoZones.employeeGeoZones,
      employeeMultipleSelect: props.employeeMultipleSelect,
      updateDisabled: true,
    };
  }

  componentDidMount() {
    const { employeeGeoZones, onGetEmployeeGeozones, objective } = this.props;

    if (employeeGeoZones.dataState === states.NOT_REQUESTED || employeeGeoZones.dataState === states.READY) {
      if (!objective.id) onGetEmployeeGeozones();
    }
  }

  componentDidUpdate(prevProps) {
    const { employeeGeoZones } = this.props;
    const { objective, selectGeozone } = this.state;

    if (prevProps.employeeGeoZones.dataState === states.REQUEST && employeeGeoZones.dataState === states.READY) {
      this.setState({
        employeeGeoZones: employeeGeoZones.employeeGeoZones,
        createPanel: false,
        objective,
        selectGeozone,
      });
    }
  }

  onSelectGeozone(e) {
    console.log(e.target.value);
    const { employeeGeoZones, objective } = this.state;

    const selectGeozone =
      e.target.value !== "- - -"
        ? employeeGeoZones.filter((x) => {
            return x.id === e.target.value;
          })[0]
        : null;

    if (selectGeozone === null) {
      this.setState({ selectGeozone: null, objective: { entry: objective.entry } });
    } else {
      objective.information = "";
      objective.noticeCompletion = false;
      objective.noticeNotCompletion = true;

      this.setState({ selectGeozone: selectGeozone, objective: objective });
    }
  }

  getGeozoneSelectItems() {
    const { employeeGeoZones } = this.state;
    const items = employeeGeoZones.map((geozone) => {
      return (
        <option key={geozone.id} value={geozone.id}>
          {geozone.title}
        </option>
      );
    });

    return [
      <option key={null} value={null}>
        {"- - -"}
      </option>,
    ].concat(items);
  }

  onChangeInformation(e) {
    if (e.target.id === "objectiveInformation") {
      e.stopPropagation();
      const { objective } = this.state;
      objective.information = e.target.value;

      this.setState({ objective: objective, updateDisabled: false });
    }
  }

  onChekedEntry(e) {
    if (e.target.id === "objectiveEntry") {
      e.stopPropagation();
      const { objective } = this.state;
      objective.entry = e.target.checked;

      this.setState({ objective: objective });
    }
  }

  onChekedNoticeCompletion(e) {
    if (e.target.id === "objectiveNoticeCompletion") {
      e.stopPropagation();
      const { objective } = this.state;
      objective.noticeCompletion = e.target.checked;

      this.setState({ objective: objective, updateDisabled: false });
    }
  }

  onChekedNoticeNotCompletion(e) {
    if (e.target.id === "objectiveNoticeNotCompletion") {
      e.stopPropagation();
      const { objective } = this.state;
      objective.noticeNotCompletion = e.target.checked;

      this.setState({ objective: objective, updateDisabled: false });
    }
  }

  onSave(e) {
    const { objective, selectGeozone, objectiveType } = this.state;
    const { onCreateObjective, onUpdateObjective } = this.props;
    objective.objectiveType = objectiveType;

    if (!objective.id) {
      onCreateObjective(objective, selectGeozone.id);
    } else {
      onUpdateObjective(objective);

      this.setState({ objective: objective, updateDisabled: true });
    }
  }

  onRemove(e) {
    const { onDeleteObjective, objective } = this.props;
    const { objectiveType } = this.state;
    objective.objectiveType = objectiveType;

    onDeleteObjective(objective);
    this.setState({ objective: { entry: true } });
  }

  render() {
    const { selectGeozone, objectiveType, updateDisabled, objective } = this.state;

    let disabledButton = selectGeozone === null;
    if (selectGeozone !== null) {
      if (objective.id) {
        disabledButton = updateDisabled;
      } else {
        disabledButton = false;
      }
    }

    return (
      <div className={"tabObjectivesContent"}>
        <div className={"objectiveCreatePanel"}>
          <div>
            <div className={"contaunerCreateOptions"}>
              <div>
                {objective.id === undefined ? (
                  <div onClick={this.onChekedEntry.bind(this)}>
                    <input
                      disabled={objective.id !== undefined}
                      className="custom-checkbox"
                      type={"checkbox"}
                      checked={objective.entry}
                      id={"objectiveEntry"}
                      onChange={(e) => {
                        e.stopPropagation();
                      }}
                    ></input>
                    <label for={"objectiveEntry"}>
                      {" "}
                      Если выбранно - задание на посещение геозоны, иначе на пребывание в геозоне
                    </label>
                  </div>
                ) : null}

                {objective.id !== undefined ? (
                  <div>
                    <b>Геозона: </b>
                    {selectGeozone.title}
                  </div>
                ) : (
                  <div style={{ margin: "0px" }}>
                    <label style={{ margin: "0px" }} for="selectGoezone">
                      Выбор геозоны{" "}
                    </label>
                    <select style={{ margin: "0px" }} id="selectGoezone" onChange={this.onSelectGeozone.bind(this)}>
                      {this.getGeozoneSelectItems()}
                    </select>
                  </div>
                )}

                <GeoZoneMapView geozone={selectGeozone} />

                {selectGeozone !== null ? (
                  <div>
                    <textarea
                      id={"objectiveInformation"}
                      value={objective.information}
                      onChange={this.onChangeInformation.bind(this)}
                    />
                    <label for={"objectiveInformation"}> Описание</label>
                  </div>
                ) : null}

                {selectGeozone !== null ? (
                  <div onClick={this.onChekedNoticeCompletion.bind(this)}>
                    <input
                      className="custom-checkbox"
                      type={"checkbox"}
                      checked={objective.noticeCompletion}
                      id={"objectiveNoticeCompletion"}
                      onChange={(e) => {
                        e.stopPropagation();
                      }}
                    ></input>
                    <label for={"objectiveNoticeCompletion"}> Уведомлять об выполнении задания</label>
                  </div>
                ) : null}

                {selectGeozone !== null ? (
                  <div onClick={this.onChekedNoticeNotCompletion.bind(this)}>
                    <input
                      className="custom-checkbox"
                      type={"checkbox"}
                      onChange={(e) => {
                        e.stopPropagation();
                      }}
                      checked={objective.noticeNotCompletion}
                      id={"objectiveNoticeNotCompletion"}
                    ></input>
                    <label for={"objectiveNoticeNotCompletion"}> Уведомлять об не выполнении задания</label>
                  </div>
                ) : null}

                <div className={"rowButtonWrapper"}>
                  <div className={"objectiveButtonWrapper"}>
                    <ButtonPrimary
                      disabled={disabledButton}
                      handleClick={this.onSave.bind(this)}
                      title={objective.id ? <span>Обновить</span> : <span>Сохранить</span>}
                    />
                  </div>
                  {objective.id ? (
                    <div className={"objectiveRemoveButtonWrapper"}>
                      <ButtonPrimary handleClick={this.onRemove.bind(this)} title={"удалить всё"} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {selectGeozone !== null ? (
            <div className="shedulesContainer">
              <ObjectiveSchedulesPanel key={uuid_v4()} objective={objective} objectiveType={objectiveType} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withObjectives(withEmployeeGeoZones(ObjectiveGeozonePanel));
