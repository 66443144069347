"use stric";

import api from "./apiService.js";
import WithToken from "./tokenService";
import configuration from "./../../config.json";

const intellegenceService = {
  getIntellegencePoint: function (options) {
    return api.get(
      `/employees/${options.id}/date/${options.date}/positions?LocationNotProcessed=${options.locationNotProcessed}`,
      WithToken.headersToken(
        {
          params: options,
        },
        "authTNToken"
      )
    );
  },
  getPositions: function (options) {
    return api.get(
      `/employees/${options.id}/positions?LocationNotProcessed=${options.locationNotProcessed}
      &startDate=${options.startDate}&endDate=${options.endDate}`,
      WithToken.headersToken(
        {
          params: options,
        },
        "authTNToken"
      )
    );
  },
  getEventObjectives: function (options) {
    return api.get(
      `/employees/${options.id}/EventObjectives?startDate=${options.startDate}&endDate=${options.endDate}`,
      WithToken.headersToken(
        {
          params: options,
        },
        "authTNToken"
      )
    );
  },
  getSensorsData: function (options) {
    return api.get(
      `/employees/${options.id}/Date/${options.date}/Sensors?LocationNotProcessed=${options.locationNotProcessed}`,
      WithToken.headersToken(
        {
          params: options,
        },
        "authTNToken"
      )
    );
  },
  getDashboards: function () {
    return api.get("/Dashboards", WithToken.headersToken({}, configuration.cookieName));
  },
  getDashboard: function (employeeId) {
    return api.get("/Employees/" + employeeId + "/Dashboard", WithToken.headersToken({}, configuration.cookieName));
  },
};

export default intellegenceService;
