import React, { Component } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, MapConsumer, TileLayer, Polygon } from "react-leaflet";
import L from "leaflet";
import { v4 as uuid_v4 } from "uuid";

class BuildingMapView extends Component {
  getPolygon(item, onSelect) {
    const geoJson = JSON.parse(item.geometry);
    geoJson.coordinates.slice(geoJson.coordinates.length - 1, 1);
    const latLangs = [];
    latLangs.push(
      geoJson.coordinates[0].map((item) => {
        const LatLng = new L.LatLng(item[1], item[0]);
        return LatLng;
      })
    );

    const { selectBuilding } = this.props;

    return (
      <Polygon
        key={uuid_v4()}
        osmId={item.osmId}
        building={true}
        positions={latLangs}
        fillColor={selectBuilding !== null && selectBuilding.osmId === item.osmId ? "red" : null}
        eventHandlers={{
          click: (e) => {
            onSelect(e.target.options.osmId);
          },
        }}
      />
    );
  }

  getBuildingPolygons() {
    const { buildings, onSelectBuilding } = this.props;

    if (!buildings) {
      return null;
    }

    return buildings.map((item) => {
      return this.getPolygon(item, (osmId) => {
        onSelectBuilding(osmId);
      });
    });
  }

  render() {
    const { selectBuilding } = this.props;

    return (
      <MapContainer
        zoom={8}
        center={[53, 27]}
        fadeAnimation={true}
        dragging={false}
        attributionControl={false}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        zoomControl={!!selectBuilding}
      >
        <MapConsumer>
          {(map) => {
            map.on("layeradd", (e) => {
              if (e.layer.options.building) {
                map.fitBounds(e.layer.getBounds());
              }
            });

            return null;
          }}
        </MapConsumer>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {this.getBuildingPolygons()}
      </MapContainer>
    );
  }
}

export default BuildingMapView;
