import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  getEmployeesCompanies as onGetEmployeesCompanies
} from '../../actions/employeesCompaniesActions.js'

const mapStateToProps = (state) => ({
  employeesCompanies: {
    dataState: state.employeesCompanies.dataState,
    companies: get(state, ['employeesCompanies', 'companies'], [])
  }
});

const mapDispatchToProps = {
  onGetEmployeesCompanies
}

const withEmployeesCompanies = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withEmployeesCompanies }