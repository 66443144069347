import React, { Component } from "react";

import { withAppState } from "../../../shared/containers/containerAppState";

import CollapsibleWrapper from "../../../shared/collapsibleWrapper";
import SecondSideBarInner from "../../../shared/secondSideBarInner";
import SideSecondBar from "../../sides/sideSecondBar";
import FormItem from "../../../shared/items/static/formItem";
import EmployeesGroupsFilter from "./../employees/employeesGroupsFilter";
import EmployeesWorkPositionsFilter from "./../employees/employeesWorkPositionsFilter";
import EmployeesStatusesFilter from "../employees/employeesStatusesFilter";
import EmployeesCompaniesFilter from "../employees/filialsFilter";

import variables from "./../../../values.scss";

const TITLE = "Фильтры";

class EmployeesSecondSideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewItems: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isEmployeesSecondSideBarOpen } = this.props;

    if (prevProps.isEmployeesSecondSideBarOpen && !isEmployeesSecondSideBarOpen) {
      setTimeout(
        function () {
          this.setState({ viewItems: false });
        }.bind(this),
        parseInt(variables.animateTransition) * 1000
      );
    } else if (isEmployeesSecondSideBarOpen && !this.state.viewItems) {
      this.setState({ viewItems: true });
    }
  }

  render() {
    const { isEmployeesSecondSideBarOpen, onCloseEmployeesSecondSideBar } = this.props;

    return (
      <SideSecondBar isOpen={isEmployeesSecondSideBarOpen} title={TITLE} handleClick={onCloseEmployeesSecondSideBar}>
        {this.state.viewItems ? (
          <React.Fragment>
            <SecondSideBarInner>
              <CollapsibleWrapper title="Основные">
                <FormItem title="Филиалы">
                  <EmployeesCompaniesFilter />
                </FormItem>

                <FormItem title="Группы">
                  <EmployeesGroupsFilter />
                </FormItem>

                <FormItem title="Должность">
                  <EmployeesWorkPositionsFilter />
                </FormItem>
              </CollapsibleWrapper>
            </SecondSideBarInner>

            <SecondSideBarInner>
              <CollapsibleWrapper title="Статус трекера">
                <EmployeesStatusesFilter />
              </CollapsibleWrapper>
            </SecondSideBarInner>
          </React.Fragment>
        ) : null}
      </SideSecondBar>
    );
  }
}

export default withAppState(EmployeesSecondSideBar);
