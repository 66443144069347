import { connect } from 'react-redux'

import {
  openModal as onOpenModal,
  closeModal as onCloseModal
} from './../../actions/modalActions'

const mapStateToProps = (state) => ({
  modal: {
    dataState: state.modal.dataState,
    isOpen: state.modal.isOpen
  }
})

const mapDispatchToProps = {
  onOpenModal,
  onCloseModal
}

const withModal = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withModal }