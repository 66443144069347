import React, { Component } from 'react';

import SideContentBlock from '../../../shared/sideContentBlock'

import './employeeInfoData.scss'

class EmployeeInfoData extends Component {
  render() {
    return (
      <div className="employeeIncoData">
        <SideContentBlock>
        EmployeeInfoData
        </SideContentBlock>
      </div>
    )
  }
}

export default EmployeeInfoData;
