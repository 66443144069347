import { REQUEST_USERS, RESPONSE_USERS, REQUEST_USERS_FAILURE, SELECT_USERS } from "../actions/usersActions";

import { states as queriesStates } from "./queriesStates";

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  selectUserId: null,
  users: [],
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_USERS: {
      return Object.assign({}, state, {
        dataState: queriesStates.REQUEST,
        errors: [],
      });
    }
    case RESPONSE_USERS: {
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY,
        },
        action.data
      );
    }
    case SELECT_USERS: {
      const obj = Object.assign({}, state, {
        dataState: queriesStates.READY,
        selectUserId: action.selectUserId,
      });

      return obj;
    }
    case REQUEST_USERS_FAILURE: {
      return Object.assign({}, state, {
        dataState: queriesStates.FAILURE,
        errors: action.errors,
      });
    }
    default:
      return state;
  }
};

export default usersReducer;
