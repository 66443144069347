import React from 'react';
import { TileLayer, LayersControl } from 'react-leaflet';
import L from 'leaflet';

const BaseLayersControlWrapper = (props) => {
  const { mapLayerControl } = props;

  return (
    <div>

      <LayersControl.BaseLayer checked={mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.osm]}
        name={mapLayerControl.baselayerNames.osm} >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer checked={mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.yandex]}
        name={mapLayerControl.baselayerNames.yandex}>
        <TileLayer url="https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.12.02-1-b211103130830&x={x}&y={y}&z={z}&lang=ru_RU" />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer checked={mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.yandexSat]}
        name={mapLayerControl.baselayerNames.yandexSat}>
        <TileLayer url="https://core-sat.maps.yandex.net/tiles?l=sat&v=3.928.0&x={x}&y={y}&z={z}&lang=ru_RU" />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer checked={mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.yandexHybrid]}
        name={mapLayerControl.baselayerNames.yandexHybrid}>
        <TileLayer url="https://core-renderer-tiles.maps.yandex.net/tiles?l=skl&v=21.12.02-1-b211103130830&x={x}&y={y}&z={z}&lang=ru_RU" />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer checked={mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.arcGisSat]}
        name={mapLayerControl.baselayerNames.arcGisSat}>
        <TileLayer
          maxZoom={17}
          url={"https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"} />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer checked={mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.arcGisSreet]}
        name={mapLayerControl.baselayerNames.arcGisSreet}>
        <TileLayer
          maxZoom={16}
          url={"https://server.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}"} />
      </LayersControl.BaseLayer>

    {/*   <LayersControl.BaseLayer checked={mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.wcs]}
        name={mapLayerControl.baselayerNames.wcs}>
        <TileLayer url="https://tiles.wmflabs.org/osm/{z}/{x}/{y}.png" />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer checked={mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.wcsWB]}
        name={mapLayerControl.baselayerNames.wcsWB}>
        <TileLayer url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png" />
      </LayersControl.BaseLayer>

      <LayersControl.BaseLayer checked={mapLayerControl.layerControl.baseLayers[mapLayerControl.baselayerNames.lwcsPedestrian]}
        name={mapLayerControl.baselayerNames.lwcsPedestrian}>
        <TileLayer url="https://{s}.tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png" />
      </LayersControl.BaseLayer>
 */}
      {
        mapLayerControl.layerControl.crs === L.CRS.EPSG900913 ?
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            updateWhenIdle={true}
            maxZoom={19}
          /> : null
      }
      ,
    </div>
  );
};

export default BaseLayersControlWrapper;