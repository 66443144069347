import React from "react";
import SideBarInner from "../../../shared/sideBarInner";
import CompaniesListWrapper from "../users/companiesListWrapper";
import SideBar from "../../sides/sideBar";
import CompaniesTitleBar from "../users/companiesTitleBar";
import styles from "./employeesBar.module.scss";

const CompaniesBar = () => {
  return (
    <div className={styles.sideBarsWrapper}>
      <SideBar>
        <div className={styles.employeesBar}>
          <SideBarInner>
            <CompaniesTitleBar />
          </SideBarInner>

          <SideBarInner>
            <div className={styles.container_employeesList}>
              <CompaniesListWrapper />
            </div>
          </SideBarInner>
        </div>
      </SideBar>
    </div>
  );
};

export default React.memo(CompaniesBar);
