import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import variables from './../../../values.scss'

const StyledCircularProgress = withStyles({
  root: {
    color: variables.preloaderLightColor,
  },
})(CircularProgress);

const PreloaderLight = () => {
  return (
    <StyledCircularProgress />
  )
}

export default PreloaderLight