import { connect } from 'react-redux'
import get from 'lodash/get';

import {
  newUser as onNewUser
} from '../../actions/newUserActions'

const mapStateToProps = (state) => ({

  newUser: {
    dataState: state.newUser.dataState,
    newUserState: get(state, ['newUser', 'newUserState'], false),
    errors: get(state, ['newUser', 'errors', 'data'], {}),
  }
});

const mapDispatchToProps = {
  onNewUser
}

const withNewUser = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withNewUser }