import {
  RESPONSE_OPEN_ROUTE_MODAL,
  RESPONSE_CLOSE_ROUTE_MODAL,
  SET_ROUTE_CURRENT_POINT
} from './../actions/routeModalActions'

const initialState = {
  isOpen: false,
  data: [],
  currentPointIndex: 0
}

const routeModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESPONSE_OPEN_ROUTE_MODAL:
      return Object.assign(
        {},
        state,
        {
          isOpen: true,
          data: action.data,
          currentPointIndex: 0
        }
      )
    case RESPONSE_CLOSE_ROUTE_MODAL:
      return Object.assign(
        {},
        state,
        {
          isOpen: false
        }
      )
    case SET_ROUTE_CURRENT_POINT:
      return Object.assign(
        {},
        state,
        {
          currentPointIndex: action.data
        }
      )
    default:
      return state
  }
}

export default routeModalReducer