import React, { Component } from "react";
import { withObjectives } from "../../../shared/containers/containerObjectives";
import PositionsService from "./../../../shared/services/positionsService";
import BuildingMapView from "./buildingsMapView";
import { ReactOsmGeocoding } from "@paraboly/react-osm-geocoding";
import ButtonPrimary from "../../../shared/items/contols/buttonPrimary";

import "@paraboly/react-osm-geocoding/dist/index.css";
import "./objectivesBlock.modeule.scss";

class ObjectivesBuildingCreatePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objective: { entry: true },
      buildings: props.buildings,
      onSetBuildings: this.setBuildings.bind(this),
      onSelectBuilding: this.selectBuilding.bind(this),
      selectBuilding: null,
    };
  }

  componentDidMount() {
    this.setState({ objective: { entry: true } });
  }

  setBuildings(buildings) {
    this.setState({ buildings });
  }

  selectBuilding(id) {
    const { buildings } = this.state;

    const building = buildings.filter((x) => {
      return x.osmId === id;
    })[0];

    this.setState({ selectBuilding: building });
  }

  onSave(e) {
    const { selectBuilding, objective } = this.state;
    const { onCreateObjective } = this.props;
    objective.id = selectBuilding.osmId;
    objective.objectiveType = 2;
    objective.noticeNotCompletion = true;

    onCreateObjective(objective, selectBuilding.osmId);
  }

  render() {
    const { buildings, onSetBuildings, selectBuilding, onSelectBuilding, objective } = this.state;

    return (
      <div>       
        <div className={"objectiveCreatePanel"}>
          <div className={"serchAddresContainer"}>
            <ReactOsmGeocoding
              iconUrl={null}
              countrycodes="by"
              acceptLanguage="ru"
              placeholder="Населенный пункт, улица номер дома"
              callback={(data) => {
                console.log(data);
                PositionsService.Building({
                  lon: data.lon,
                  lat: data.lat,
                  distance: 35,
                })
                  .then((result) => {
                    console.log(result.data);
                    onSetBuildings(result.data.buildings);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }}
            />
          </div>

          <div className={"buildingMapViewContainer"}>
            <BuildingMapView
              buildings={buildings}
              selectBuilding={selectBuilding}
              onSelectBuilding={onSelectBuilding.bind(this)}
            />
          </div>

          <div className={"contaunerCreateOptions"}>
            <div className={"rowButtonWrapper"}>
              <div className={"objectiveButtonWrapper"}>
                <ButtonPrimary
                  disabled={selectBuilding === null}
                  handleClick={this.onSave.bind(this)}
                  title={<span>Сохранить</span>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withObjectives(ObjectivesBuildingCreatePanel);
