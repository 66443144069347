const READY_CHANGE_STORE_ITEMSINTELLEGENCE = 'READY_CHANGE_STORE_ITEMSINTELLEGENCE'

const responseChangeStoreItemsIntellegenceAction = (name, value) => {
  return {
    type: READY_CHANGE_STORE_ITEMSINTELLEGENCE,
    payload: { name, value }
  }
}

const handleChangeStoreItemsIntellegence = (name, value) => {
  return (dispatch) => {
    dispatch(responseChangeStoreItemsIntellegenceAction(name, value))
  }
}

export {
  handleChangeStoreItemsIntellegence,
  responseChangeStoreItemsIntellegenceAction,
  READY_CHANGE_STORE_ITEMSINTELLEGENCE
}
