'use stric';
import FilterEmployeesItems from './static.filterEmployeesItems';

const employeeStatuses = {
  noData: {
    id: 0,
    title: 'Нет данных',
    name: FilterEmployeesItems.noData.name,
  },
  inSleep: {
    id: 1,
    title: 'Режим сна',
    name: FilterEmployeesItems.inSleep.name,
  },
  work: {
    id: 2,
    title: 'Рабочий режим',
    name: FilterEmployeesItems.work.name,
  },
  charging: {
    id: 3,
    title: 'Зарядка батареи',
    name: FilterEmployeesItems.charging.name,
  },
  offSleep: {
    id: 4,
    title: 'Выход из сна',
    name: FilterEmployeesItems.offSleep.name,
  },
  alarm: {
    id: 5,
    title: 'Тревога',
    name: FilterEmployeesItems.alarm.name,
  },
  poweredFrom: {
    id: 6,
    title: 'Питание от сети',
    name: FilterEmployeesItems.poweredFrom.name,
  }
};

export { employeeStatuses };
