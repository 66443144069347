import { createTheme } from "@material-ui/core";

import variables from './../../../values.scss'

const datepickerTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: variables.datepickerColor,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: variables.datepickerColor,
        '&:hover': {
          backgroundColor: variables.datepickerColor,
        }
      },
      current: {
        color: variables.datepickerColor,
      },
    },
    MuiButton: {
      textPrimary: {
        color: variables.datepickerColor,
        '&:hover': {
          backgroundColor: variables.datepickerHoverColor
        }
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: variables.datepickerColor,
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: variables.datepickerColor,
      },
      noPoint: {
        backgroundColor: variables.datepickerColor,
      },
      thumb: {
        borderColor: variables.datepickerColor,
      }
    }
  },
});

export { datepickerTheme }