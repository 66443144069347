'use stric'

const sortEmployeesTypes = [
  {
    title: 'Порядковый номер',
    type: 'number',
  },
  {
    title: 'Должность',
    type: 'workPosition'
  },
  {
    title: 'Последняя активность',
    type: 'lastActive'
  }
]

const getSortEmployeesTypeTitle = (type) => {
  return sortEmployeesTypes.filter((item) => item.type === type)[0].title
}

export {
  sortEmployeesTypes,
  getSortEmployeesTypeTitle
}