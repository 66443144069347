import SharedContentService from "../shared/services/sharedContentService";

const REQUEST_SHARED_SHEDULES = "REQUEST_SHARED_SHEDULES";
const RESPONSE_SHARED_SHEDULES = "RESPONSE_SHARED_SHEDULES";
const REQUEST_SHARED_SHEDULES_FAILURE = "REQUEST_SHARED_SHEDULES_FAILURE";

const action = (type) => {
  return {
    type,
  };
};

const schedules = (schedules, errors = null) => {
  return {
    type: RESPONSE_SHARED_SHEDULES,
    schedules,
    errors: errors,
  };
};

const getShcedules = () => {
  return (dispatch) => {
    dispatch(action(REQUEST_SHARED_SHEDULES));

    SharedContentService.getShcedules()
      .then((response) => {
        return dispatch(schedules(response.data.schedules));
      })
      .catch((error) => {
        dispatch(schedules([], error));
      });
  };
};

export { getShcedules, REQUEST_SHARED_SHEDULES, RESPONSE_SHARED_SHEDULES, REQUEST_SHARED_SHEDULES_FAILURE };
