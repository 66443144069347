import React, { Component } from "react";
import { withCompanies } from "../../../shared/containers/containerCompanies";
import { Link } from "react-router-dom";
import dateTime from "../../../shared/services/date.service";
import configuration from "../../../config.json";
import CookieService from "../../../shared/services/cookiesService";
import { uuid } from "uuidv4";
import { states } from "../../../reducers/queriesStates";

let deviceSelected = [];
let getCompanyOptions = null;
let colapsedChildrenCompanies = true;

class CompanyInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCompany: getCurrentCompany(props.companies),
    };
  }

  componentDidUpdate(prevProps) {
    const { companies } = this.props;

    if (prevProps.companies.dataState !== states.READY && companies.dataState === states.READY) {
      this.setState({ currentCompany: getCurrentCompany(companies) });
    }
  }

  render() {
    const {
      companies,
      onGetCompanies,
      onEnterCompany,
      onUpdateCompanyDevice,
      onCommandCompanyDevices,
      onTransferCompanyDevices,
      onCreateOwnerUser,
      onInfoDevice,
    } = this.props;

    getCompanyOptions = companies.getCompanyOptions;

    const company = getCurrentCompany(companies);
    const enterCompany = () => {
      onGetCompanies({ count: -1, page: 0, searchTerm: "" });
      onEnterCompany({ id: company.id }, getCompanyOptions);
    };

    let transferCompany = [{ id: "", title: "- - -" }];

    for (const item of companies.companies) {
      transferCompany.push({
        id: item.id,
        title: item.title,
      });
    }

    if (!company) {
      return null;
    }

    return (
      <div kye={uuid()} style={{ margin: "30px", maxHeight: "1000px" }}>
        <div kye={uuid()} style={{ padding: "10px", margin: "0px", background: "white" }}>
          <b> Выслать ссылку для регистрации на почту: </b>

          <input type="email" id="inputEmailid"></input>
          <input
            type="button"
            disabled={CookieService.getCookie(configuration.permissionsCookie).includes(20001) ? "" : "disabled"}
            onClick={(e) => {
              const inputEmail = document.getElementById("inputEmailid");
              onCreateOwnerUser({ email: inputEmail.value }, getCompanyOptions);
            }}
            value={"Выслать"}
          ></input>
          {companies.error ? <b style={{ color: "red" }}> {companies.error} </b> : null}
        </div>

        <div kye={uuid()} style={{ padding: "20px", background: "white" }}>
          <div kye={uuid()} style={{ display: "flex" }}>
            {colapsedChildrenCompanies ? (
              <h3>{company.title}</h3>
            ) : (
              <div>{companyCildren(companies.companies, company.id, company.title, onEnterCompany)}</div>
            )}
            <div style={{ padding: "1px" }}>
              <input
                type="button"
                style={{ height: "22px", margin: "2px", border: "none", backgroundColor: "white" }}
                onClick={(e) => {
                  colapsedChildrenCompanies = !colapsedChildrenCompanies;
                  enterCompany();
                }}
                value={colapsedChildrenCompanies ? String.fromCharCode(9660) : String.fromCharCode(9650)}
              ></input>
            </div>
          </div>

          <div kye={uuid()} style={{ margin: "10px" }}>
            <label for="companiesTransferId">Перенести в компанию: </label>
            <select name="cars" id="companiesTransferId" style={{ borderRadius: "5px" }}>
              {transferCompany.map((item) => {
                return <option value={item.id}>{item.title}</option>;
              })}
            </select>

            <input
              style={{ margin: "10px" }}
              disabled={CookieService.getCookie(configuration.permissionsCookie).includes(30003) ? "" : "disabled"}
              type="button"
              value="Перенести"
              onClick={(e) => {
                const companiesTransfer = document.getElementById("companiesTransferId");
                if (companiesTransfer.value !== "" && deviceSelected.length > 0) {
                  const options = {
                    id: companiesTransfer.value,
                    devices: deviceSelected.map((id) => {
                      return id;
                    }),
                  };

                  deviceSelected = [];
                  companiesTransfer.value = "";
                  onTransferCompanyDevices(options);
                  window.location.reload();
                }
              }}
            />
          </div>

          <div style={{ margin: "10px", marginLeft: "10px" }}>
            <label for="savedPeriod"> Срок хранения данных компании с момента приема пакета </label>
            <b>{company.savedingPeriod}</b> месяцев
          </div>

          <div style={{ display: "flex", paddingTop: "5px", borderBottom: ".01rem solid black" }}>
            <div style={{ width: "60px" }}>
              <input
                type="checkbox"
                id="selectAllDeviceCheckBox"
                checked={deviceSelected.length > 0 && deviceSelected.length === company.devices.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    deviceSelected = company.devices.map((device) => {
                      return device.id;
                    });
                  } else {
                    deviceSelected = [];
                  }

                  enterCompany();
                }}
              />
              <label for="selectAllDeviceCheckBox">
                <b>Все</b>
              </label>
            </div>

            <div style={{ width: "150px" }}>
              <b>IMEI</b>
            </div>
            <div style={{ width: "140px" }}>
              <b>Создан</b>
            </div>

            <div style={{ width: "200px" }}>
              <b>Номер SIM</b>
            </div>

            <div style={{ width: "140px" }}>
              <b>Состояние</b>
            </div>

            <div>
              <b>Команда: </b>
              <input id="inputCommandId"></input>
              <input
                type="button"
                disabled={CookieService.getCookie(configuration.permissionsCookie).includes(30002) ? "" : "disabled"}
                style={{ height: "26px", margin: "4px" }}
                onClick={(e) => {
                  if (deviceSelected.length > 0) {
                    const inputCommand = document.getElementById("inputCommandId");
                    const options = {
                      command: inputCommand.value,
                      devices: deviceSelected.map((id) => {
                        return id;
                      }),
                    };
                    inputCommand.value = "";
                    deviceSelected = [];
                    onCommandCompanyDevices(options);
                  }
                }}
                value={"Задать"}
              ></input>
            </div>
          </div>
          <div kye={uuid()} style={{ paddingTop: "20px", background: "white", maxHeight: "600px", overflow: "auto" }}>
            {company.devices.map((device) => {
              return getDeviceInfo(device, onUpdateCompanyDevice, enterCompany, companies.deviceInfo, onInfoDevice);
            })}
          </div>
        </div>
      </div>
    );
  }
}

function getCurrentCompany(companies) {
  for (const company of companies.companies) {
    if (company.id === companies.enterCompanyId) {
      return company;
    }
  }

  return null;
}

function getTitleLevel(title, level) {
  // eslint-disable-next-line default-case
  switch (level) {
    case 1:
      return <h3>{title}</h3>;
    case 2:
      return <h4>{title}</h4>;
  }

  return <h5>{title}</h5>;
}

function companyCildren(companies, id, title, onEnterCompany, level = 1) {
  const childrens = companies.filter((company) => {
    return company.parentCompanyId === id;
  });

  return (
    <div style={{ marginLeft: level * 0.5 + "em" }}>
      <Link
        to={`/companies/${id}`}
        onClick={() => {
          onEnterCompany({ id: id }, getCompanyOptions);
        }}
      >
        {getTitleLevel(title, level)}
      </Link>
      {childrens.map((children) => {
        return companyCildren(companies, children.id, children.title, onEnterCompany, level + 1);
      })}
    </div>
  );
}

function getDeviceInfo(device, onUpdateCompanyDevice, enterCompany, deviceInfo, onInfoDevice) {
  const updateDevice = () => {
    onUpdateCompanyDevice({ id: device.id, inActive: device.inActive, phone: device.phone }, getCompanyOptions);
  };

  const isSearchTerm =
    getCompanyOptions.searchTerm !== "" &&
    (device.imei.includes(getCompanyOptions.searchTerm) ||
      device.phone.replaceAll(" ", "").includes(getCompanyOptions.searchTerm.replaceAll(" ", "")));

  return (
    <div
      kye={uuid()}
      style={{
        display: "flex",
        padding: "5px",
        borderBottom: ".01rem solid black",
        backgroundColor: isSearchTerm ? "#4da1ff2b" : "white",
      }}
      key={device.imei}
    >
      <div style={{ width: "50px" }}>
        <input
          type="checkbox"
          id="selectDeviceCheckBox"
          checked={deviceSelected.includes(device.id)}
          onChange={(e) => {
            if (e.target.checked && !deviceSelected.includes(device.id)) {
              deviceSelected.push(device.id);
            }

            if (!e.target.checked && deviceSelected.includes(device.id)) {
              deviceSelected = deviceSelected.filter((item) => {
                return item !== device.id;
              });
            }

            enterCompany();
          }}
        />
      </div>

      <div style={{ width: "160px" }}>
        <b> {device.imei}</b>
      </div>
      <div style={{ width: "140px" }}>{dateTime.toFormat2(device.dateCreation)}</div>

      <div style={{ width: "200px" }}>
        <input
          type="text"
          disabled={CookieService.getCookie(configuration.permissionsCookie).includes(30004) ? "" : "disabled"}
          id={`simInput_${device.id}`}
          value={device.phone}
          onChange={(e) => {
            device.phone = e.target.value;
            updateDevice();
          }}
        />
      </div>

      <div style={{ width: "140px", fontSize: ".7rem" }}>
        <input
          type="checkbox"
          disabled={CookieService.getCookie(configuration.permissionsCookie).includes(30005) ? "" : "disabled"}
          id={`inActiveCheckBox_${device.id}`}
          checked={device.inActive}
          onChange={(e) => {
            device.inActive = e.target.checked;
            updateDevice();
          }}
        />
        <label for="inActiveCheckBox">
          <span style={{ fontSize: ".7rem" }}>{device.inActive ? "Не активен" : <b>Активен</b>}</span>
        </label>
      </div>

      <div style={{ width: "70px" }}>
        <b>{device.command ? device.command.toUpperCase() : " - - - "}</b>
      </div>

      <div>
        {deviceInfo !== null && deviceInfo.imei === device.imei ? (
          <div>
            <span>Время получения последнего пакета : {dateTime.toFormat3(deviceInfo.timeStamp)}</span>
            <br />
            <span>HardVersion : {deviceInfo.hardVersion}</span>
            <br />
            <span>SoftVersion : {deviceInfo.softVersion}</span>
            <br />
            <span>DevId : {deviceInfo.devId}</span>
            <br />
          </div>
        ) : (
          <input
            type="button"
            disabled={CookieService.getCookie(configuration.permissionsCookie).includes(30005) ? "" : "disabled"}
            value="Подробнее"
            onClick={(e) => {
              console.log(device.imei);
              onInfoDevice({ imei: device.imei }, getCompanyOptions);
            }}
          ></input>
        )}
      </div>
    </div>
  );
}

export default withCompanies(CompanyInfo);
