import intellegenceService from "../shared/services/intellegenceService";

const REQUEST_GET_DASHBOARD = "REQUEST_GET_DASHBOARD";
const RESPONSE_GET_DASHBOARD = "RESPONSE_GET_DASHBOARD";
const REQUSET_GET_DASHBOARD_FAILURE = "REQUSET_GET_DASHBOARD_FAILURE";
const SET_DASHBOARD = "SET_DASHBOARD";

const requestGetDashboardAction = () => {
  return {
    type: REQUEST_GET_DASHBOARD,
  };
};

const responseGetDashboardAction = (data) => {
  return {
    type: RESPONSE_GET_DASHBOARD,
    data
  };
};

const requestGetDashboardFailureAction = (errors) => {
  return {
    type: REQUSET_GET_DASHBOARD_FAILURE,
    errors,
  };
};

const getDashboard = (employeeId) => {
  return (dispatch) => {
    dispatch(requestGetDashboardAction());

    intellegenceService
      .getDashboard(employeeId)
      .then((result) => {
        dispatch(
          responseGetDashboardAction(result.data.items, result.data.feature)
        );
      })
      .catch((error) => {
        dispatch(requestGetDashboardFailureAction(error.response));
      });
  };
};

const setDashboardAction = (data,feature) => {
  return {
    type: SET_DASHBOARD,
    data,
    feature,
  };
};

const setDashboard = (data, feature) => {
  return (dispatch) => {
    dispatch(setDashboardAction(data, feature));
  };
};

export {
  getDashboard,
  requestGetDashboardAction,
  responseGetDashboardAction,
  requestGetDashboardFailureAction,
  REQUEST_GET_DASHBOARD,
  RESPONSE_GET_DASHBOARD,
  REQUSET_GET_DASHBOARD_FAILURE,
  setDashboard,
  setDashboardAction,
  SET_DASHBOARD,
};
