import intellegenceService from './../shared/services/intellegenceService'
import dateTime from './../shared/services/date.service'

const REQUEST_SENSORS = 'REQUEST_SENSORS'
const RESPONSE_SENSORS = 'RESPONSE_SENSORS'
const REQUEST_SENSORS_FAILURE = 'REQUEST_SENSORS_FAILURE'

const requestSensorsAction = () => {
  return {
    type: REQUEST_SENSORS
  }
}

const responseSensorsAction = (data, feature) => {
  return {
    type: RESPONSE_SENSORS,
    data,
    feature
  }
}

const requestSensorsFailureAction = (errors) => {
  return {
    type: REQUEST_SENSORS_FAILURE,
    errors
  }
}

const getSensors = (options) => {
  return (dispatch) => {
    dispatch(requestSensorsAction())

    intellegenceService.getSensorsData({
      id: options.employeeId,
      date: dateTime.stringISODate(options.date),
      locationNotProcessed: options.locationNotProcessed    
    })
      .then((result) => {
        // dispatch(responseSensorsAction({}))
        dispatch(responseSensorsAction(result.data, result.data.feature))
      })
      .catch((error) => {
        dispatch(requestSensorsFailureAction(error.response))
      })
  }
}

export {
  getSensors,
  requestSensorsAction,
  responseSensorsAction,
  requestSensorsFailureAction,
  REQUEST_SENSORS,
  RESPONSE_SENSORS,
  REQUEST_SENSORS_FAILURE
}