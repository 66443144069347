import React, { Component } from "react";
import EmployeeSettingItem from "./employeeSettingItem";
import styles from "./employeeSetting.module.scss";

class EmployeeSettingGroupItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { setting, id } = this.props;

    return (
      <div>
        <div className={styles.groupItem}>
          <div style={{ width: "35%" }}>
            <EmployeeSettingItem setting={setting.item} id={id} isGroup={true} />
          </div>

          {setting.item.value === "1" ? (
            <div>
              {setting.dependents.map((item) => {
                return <EmployeeSettingItem setting={item} id={id} />;
              })}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default React.memo(EmployeeSettingGroupItem);
