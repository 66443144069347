import React from "react";
import EmployeeStatusTitle from "../items/static/employeeStatusTitle";
import dateTime from "./date.service";
import CookieService from "./cookiesService";

const JAMGSM = "JAMGSM";
const JAMGPS = "JAMGPS";

const getTextButtonIcon = (param) => {
  // eslint-disable-next-line default-case
  switch (param) {
    case "X":
      return "✖";
    case "1":
      return "1";
    case "2":
      return "2";
    case "Включение":
      return "☀";
    case "Выключение":
      return "☼";
    case "Сброс":
      return "♺";
    case "SOS":
      return "SOS";
  }

  return "";
};

const colorTextButton = (value) => {
  // eslint-disable-next-line default-case
  switch (value) {
    case "X":
      return "#4da1ff";
    case "1":
      return "#4da1ff";
    case "2":
      return "#4da1ff";
    case "Включение":
      return "#54cb64";
    case "Выключение":
      return "#818698";
    case "Сброс":
      return "♺#ff9100";
    case "SOS":
      return "red";
  }

  return "black";
};

function intellegenceTableData(data) {
  const tableHeaders = ["Дата", "Длительность", "Статус и заряд", "Кнопка", "Адрес"];
  const logPermmisions = CookieService.getPermissions("permissionTN").includes(30001);
  if (logPermmisions) {
    tableHeaders.push("");
  }

  for (var i = 1; i < data.length; i++) {
    data[i - 1].state.duration = (new Date(data[i].time) - new Date(data[i - 1].time)) / 1000;
  }

  const tableContent = data.map((item, index) => {
    if (item.jammingValue === undefined) {
      item.jammingValue = getJammingValue(item.data[JAMGSM], item.data[JAMGPS]);
    }

    const duration = getDurationInformation(item.state.duration);

    const row = {
      index: item.index ? item.index : index,
      isActive: item.isActive ? item.isActive : false,
      items: [
        <span>
          <span style={{ fontSize: ".7rem" }}>{dateTime.toFormat3(dateTime.toLocal(item.time))}</span>
        </span>,
        <span>
          <b style={{ fontSize: ".9rem" }}>{`${duration.hour}:${duration.minutes}`}</b>:{duration.seconds}
        </span>,
        <span>
          {item.jammingValue == null ? (
            <span style={{ display: "flex" }}>
              <EmployeeStatusTitle status={item.state.status} />
              <span style={{ paddingLeft: "5px", marginLeft: "auto" }}>{item.state.batteryCharge}%</span>
            </span>
          ) : (
            <span style={{ paddingLeft: "5px", color: "red", fontWeight: "bold" }}>{item.jammingValue}</span>
          )}
        </span>,
        <b style={{ color: colorTextButton(item.state.button) }}>{getTextButtonIcon(item.state.button)}</b>,
        <span>
          {item.state.address} {item.state.address ? <br /> : null}
          {item.geozoneNames !== undefined &&
          item.geozoneNames !== null &&
          item.geozoneNames !== " " &&
          item.geozoneNames !== ""
            ? "Геозоны: " + item.geozoneNames
            : null}
        </span>,
      ],
    };
    if (logPermmisions) {
      row.items.push(<span>{item.state.snr}</span>);
    }

    return row;
  });

  return {
    headItems: tableHeaders,
    contentItems: tableContent,
  };
}

function isNullOrEmptyStringOrUndefined(value) {
  if (value === undefined) {
    return true;
  }

  if (value === null) {
    return true;
  }

  if (value === "") {
    return true;
  }
}

function getJammingValue(jammingGSM, jammingGPS) {
  let jammingValue = null;
  if (!isNullOrEmptyStringOrUndefined(jammingGSM)) {
    jammingValue = jammingGSM;
  }

  if (!isNullOrEmptyStringOrUndefined(jammingGPS)) {
    jammingValue = jammingValue === null ? jammingGPS : "; " + jammingGPS;
  }

  return jammingValue;
}

const getDurationInformation = (data) => {
  if (data === undefined) {
    return "00:00:00";
  }

  const h = Math.floor(data / 3600);
  const m = Math.floor((data - h * 3600) / 60);
  const s = data - h * 3600 - m * 60;

  const duration = {
    hour: lessTen(h),
    minutes: lessTen(m),
    seconds: lessTen(s),
  };

  return duration;
};

const lessTen = (n) => {
  return n < 10 ? "0" + n : n;
};

export default intellegenceTableData;
