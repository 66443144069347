import React, { PureComponent } from "react";
import { Polyline } from "react-leaflet";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuid_v4 } from "uuid";
import { withRouteModal } from "./../../../shared/containers/containerRouteModal";
import { withSensors } from "../../../shared/containers/containerSensors";
import { withChoosedEmployee } from "../../../shared/containers/containerChoosedEmployee";
import Slider from "@material-ui/core/Slider";
import MapWrapper from "./../../../shared/map/mapWrapper";
import MapMarker from "./../../../shared/map/mapMarker";
import ViewTable from "./../../../shared/viewTable";
import intellegenceTableData from "../../../shared/services/intellegence.data.service";
import dateTime from "../../../shared/services/date.service";
import { getRouteCoordinates, getPoints } from "./../../../shared/services/service.intellegence";
import mapLayerControl from "../../../shared/map/mapLayerControl";
import styles from "./modalRoute.module.scss";
import "./intellegenceTimeRangePlayer.scss";
import CookieService from "../../../shared/services/cookiesService";

class ViewModalRoute extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pointIndex: 0,
      mapLayerControl: mapLayerControl,
      mapKey: uuid_v4(),
    };
  }

  getSensors(index) {
    const { onGetSensors, choosedEmployee, sensors, routeModal, onSetRouteCurrentPoint } = this.props;
    const sensorsDate = dateTime.withEmptyMseconds(sensors.data.date);
    const pointDate = dateTime.withEmptyMseconds(routeModal.data[index]);

    if (sensorsDate !== pointDate) {
      const logPermmisions = CookieService.getPermissions("permissionTN").includes(30001);
      onGetSensors({
        date: dateTime.toLocal(routeModal.data[index].time),
        employeeId: choosedEmployee.employeeId,
        locationNotProcessed: logPermmisions,
      });

      onSetRouteCurrentPoint(index);
      this.setState({ pointIndex: index });
    }
  }

  setActivePoint(data, current) {
    data.map((item, index) => {
      item.isActive = current === index;

      return null;
    });

    return data;
  }

  layerControlChange(mapLayerControl) {
    this.setState({ mapLayerControl: cloneDeep(mapLayerControl), mapKey: uuid_v4() });
  }

  render() {
    const { routeModal, choosedEmployee, onSetRouteCurrentPoint, employeeGeoZones } = this.props;
    const { currentPointIndex, data } = routeModal;
    const { pointIndex } = this.state;
    const routeData = this.setActivePoint(data, currentPointIndex);
    const routeTableData = intellegenceTableData(routeData);

    const points = getPoints(routeData);
    const currentPoint = points[currentPointIndex];
    const route = getRouteCoordinates(points, [points[0].location], 0);

    return (
      <div className={styles.container_modalRoute}>
        <div className={styles.container_routeMap}>
          <MapWrapper
            key={this.state.mapKey}
            employeeGeoZones={employeeGeoZones}
            position={currentPoint.location}
            mapLayerControl={this.state.mapLayerControl}
            onlayerControlChange={(mapLayerControl) => this.layerControlChange(mapLayerControl)}
          >
            <MapMarker
              id={choosedEmployee.employeeId}
              position={currentPoint.location}
              status={currentPoint.status}
              number={choosedEmployee.number}
            />
            {!!route ? <Polyline positions={route} /> : null}
          </MapWrapper>
        </div>
        <div className={styles.container_routeSlider}>
          <div className="slider-container">
            <Slider
              step={1}
              min={0}
              max={data.length !== 0 ? data.length - 1 : 1}
              value={pointIndex}
              onChange={(event, value) => this.setState({ pointIndex: value })}
              onChangeCommitted={(event, value) => {
                onSetRouteCurrentPoint(value);
              }}
            />
          </div>
          <div className={styles.container_pointDate}>
            <span className={styles.pointDate}>{dateTime.toFormat3(dateTime.toLocal(points[pointIndex].date))}</span>
          </div>
        </div>
        <div className={styles.container_routeTable}>
          <div className="modalRouteTableWrapper">        
            <ViewTable
              headItems={routeTableData.headItems}
              contentItems={routeTableData.contentItems}
              handleClickRow={this.getSensors.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withSensors(withRouteModal(withChoosedEmployee(React.memo(ViewModalRoute))));
