import TimeRange from './../components/sections/employees/static.timeRange'
import {
  READY_CHANGE_STORE_ITEMSINTELLEGENCE
} from '../actions/itemsIntellegenceActions'

import { states } from './queriesStates'
import dateTime from './../shared/services/date.service'

let momentTimeValue = new Date()
momentTimeValue.setSeconds(0)
momentTimeValue.setMilliseconds(0)

const initialState = {
  dataState: states.NOT_REQUESTED,
  itemsIntellegence: {
    timeRange: TimeRange.intimerange.value,
    momentDate: new Date(),
    momentTime: momentTimeValue,
    rangeStartDate: dateTime.withEmptyTime(new Date()),
    rangeEndDate: dateTime.withEmptyTime(new Date()),
    rangeStartTime: dateTime.withEmptyTime(new Date()),
    rangeEndTime: dateTime.getEndDayTimeDate(new Date()),
  }
}

const itemsIntellegenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case READY_CHANGE_STORE_ITEMSINTELLEGENCE: {
      const { name, value } = action.payload
      var itemsIntellegence = Object.assign({}, state.itemsIntellegence)

      typeof (name) === 'object'
        ? name.map((item, index) => {
          itemsIntellegence[item] = value[index]
          return null;
        })
        : itemsIntellegence[name] = value

      return Object.assign(
        {},
        state,
        {
          dataState: states.READY,
          itemsIntellegence: itemsIntellegence
        }
      )
    }
    default:
      return state
  }
}

export default itemsIntellegenceReducer
