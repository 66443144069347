import { connect } from 'react-redux'
import get from 'lodash/get';

import { 
  handleChangeStoreEmployeeUpdate as onHandleChangeStore
} from '../../actions/employeeUpdateActions'

const mapStateToProps = (state) => ({
  employeeUpdate: {
    dataState: state.employeeUpdate.dataState,
    properties: get(state, [' employeeUpdate', 'properties'], {}),
  }
});

const mapDispatchToProps = {
  onHandleChangeStore
}

const withEmployeeUpdate = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export { withEmployeeUpdate }