import React, { Component } from "react";

import { withEmployees } from "../../../shared/containers/containerEmployees";
import { withFilterEmployees } from "../../../shared/containers/containerFilterEmployees";
import { withSetEmployeeBookmark } from "../../../shared/containers/containerSetEmployeeBookmark";
import { withDeleteEmployeeBookmark } from "../../../shared/containers/containerDeleteEmployeeBookmark";
import { withObjectives } from "../../../shared/containers/containerObjectives";
import { withEmployeeMultipleSelect } from "../../../shared/containers/containerEmployeeMultipleSelect";
import Preloader from "../../../shared/items/static/preloader";
import Notification from "../../../shared/items/static/notification";
import EmployeesListItems from "./employeesListItems";
import EmployeeMultipleSelectService from "../../../shared/services/employeeMultipleSelectService";
import { states } from "../../../reducers/queriesStates";
import EmployeesLastStateService from "../../../components/sections/employees/employeesLastStatesService";
import { v4 as uuid_v4 } from "uuid";

import "./employeesList.module.scss";
import styles from "./employeesList.module.scss";
import employeesBarStyles from "./../sideBars/employeesBar.module.scss";

let employeesLastStateService = null;

class EmployeesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employeeMultipleSelect: props.employeeMultipleSelect,
      employees: props.employees.employees,
    };

    employeesLastStateService = EmployeesLastStateService.getInstance();
  }

  componentDidMount() {
    const { onGetEmployees } = this.props;
    const { dataState } = this.props.employees;
    const { filterItems } = this.props.filterEmployees;

    employeesLastStateService.setCallBack("employeeList", this.employeeList.bind(this));

    if (dataState === states.NOT_REQUESTED) {
      onGetEmployees(filterItems);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      onGetEmployees,
      filterEmployees,
      employees,
      setEmployeeBookmark,
      deleteEmployeeBookmark,
      handleScrollTo,
      employeeMultipleSelect,
    } = this.props;
    const { filterItems } = filterEmployees;

    if (prevProps.employees.dataState !== states.READY && employees.dataState === states.READY) {
      handleScrollTo(0);
      this.setState({ employees: employees.employees });
    }

    if (prevProps.filterEmployees.dataState !== states.READY && filterEmployees.dataState === states.READY) {
      onGetEmployees(filterItems);
    }

    if (prevProps.filterEmployees.clearDataState !== states.READY && filterEmployees.clearDataState === states.READY) {
      onGetEmployees(filterItems);
    }

    if (prevProps.setEmployeeBookmark.dataState !== states.READY && setEmployeeBookmark.dataState === states.READY) {
      onGetEmployees(filterItems);
    }

    if (
      prevProps.deleteEmployeeBookmark.dataState !== states.READY &&
      deleteEmployeeBookmark.dataState === states.READY
    ) {
      onGetEmployees(filterItems);
    }

    if (
      prevProps.employeeMultipleSelect.dataState !== states.READY &&
      employeeMultipleSelect.dataState === states.READY
    ) {
      this.setState({ employeeMultipleSelect: employeeMultipleSelect });
    }
  }

  employeeList(lastStates) {
    const { employees } = this.props.employees;

    for (const employee of lastStates) {
      const i = employees.findIndex((v) => {
        return v.id === employee.id;
      });

      if (i !== -1 && employees[i].lastActive !== employee.lastActive) {
        employees[i].lastActive = employee.lastActive;
        employees[i].status = employee.status;
        employees[i].lastLocation = employee.lastLocation;
        employees[i].batteryCharge = employee.batteryCharge;

        if (employee.feature !== null) {
          employees[i].address = employee.feature.address;
        }
      }
    }

    this.setState({ employees: employees });
  }

  render() {
    const { handleScrollTo } = this.props;
    const { dataState } = this.props.employees;
    const { employeeMultipleSelect } = this.props;
    const { employees } = this.state;

    if (dataState === states.READY) {
      const data =
        employeeMultipleSelect.dataState === states.READY
          ? EmployeeMultipleSelectService.combine(employees, employeeMultipleSelect)
          : employees;

      const isMultipleSelect = employeeMultipleSelect.id !== null;

      return data.length > 0 ? (
        <div kye={uuid_v4()} className={styles.employeesList}>
          <EmployeesListItems
            kye={uuid_v4()}
            data={data}
            handleScrollTo={handleScrollTo}
            isMultipleSelect={isMultipleSelect}
          />
        </div>
      ) : (
        <Notification type="warning">
          <p>По данным параметрам поиска сотрудники не найдены.</p>
        </Notification>
      );
    } else if (dataState !== states.READY) {
      return (
        <div className={employeesBarStyles.container_preloader}>
          <Preloader />
        </div>
      );
    }
  }
}

export default withEmployees(
  withObjectives(
    withFilterEmployees(withSetEmployeeBookmark(withDeleteEmployeeBookmark(withEmployeeMultipleSelect(EmployeesList))))
  )
);
