import React, { PureComponent } from 'react';

import { withRouteModal } from './../../../shared/containers/containerRouteModal'
import { withSensors } from '../../../shared/containers/containerSensors'
import { withChoosedEmployee } from '../../../shared/containers/containerChoosedEmployee'

import ModalWrapper from '../../../shared/items/static/modalWrapper'
import ViewModalRoute from './viewModalRoute';
import dateTime from '../../../shared/services/date.service'

import variables from './../../../values.scss'

class ModalRoute extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      viewModal: false
    }
  }

  componentDidUpdate(prevProps) {
    const { routeModal } = this.props
    const { isOpen } = routeModal

    if (prevProps.routeModal.isOpen && !isOpen) {
      setTimeout(function () {
        this.setState({ viewModal: false })
      }
        .bind(this), parseInt(variables.animateTransition) * 1000)
    }
    else if (isOpen && !this.state.viewModal) {
      this.setState({ viewModal: true })
    }
  }

  getSensors(index) {
    const { onGetSensors, choosedEmployee, sensors, routeModal, onSetRouteCurrentPoint } = this.props
    const sensorsDate = dateTime.withEmptyMseconds(sensors.data.date)
    const pointDate = dateTime.withEmptyMseconds(routeModal.data[index])

    if (sensorsDate !== pointDate) {
      onGetSensors({
        date: dateTime.toLocal(routeModal.data[index].time),
        employeeId: choosedEmployee.employeeId
      })

      onSetRouteCurrentPoint(index)
      this.setState({ pointIndex: index })
    }
  }

  render() {
    const { routeModal, onCloseRouteModal } = this.props

    return (
      this.state.viewModal
        ? <ModalWrapper
          open={routeModal.isOpen}
          onClose={onCloseRouteModal.bind(this)}
          title="Сведения маршрута"
        >
          <ViewModalRoute />
        </ModalWrapper>
        : null
    )
  }
}

export default withSensors(withRouteModal(withChoosedEmployee(React.memo(ModalRoute))))