import { connect } from "react-redux";
import get from "lodash/get";

import {
  getEmployeeGeoZones as onGetEmployeeGeozones,
  createGeoZone as onCreateGeoZone,
  updateGeoZone as onUpdateGeoZone,
  deleteGeoZone as onDeleteGeoZone,
  addEmployee as onAddEmployee,
  deleteEmployee as onDeleteEmployee,
  requestEmployeeGeoZonesAction as onRequestEmployeeGeoZonesAction,
  reset as onResetEmployeeGeozones,
} from "../../actions/employeeGeoZonesAction";

const mapStateToProps = (state) => ({
  employeeGeoZones: {
    dataState: state.employeeGeoZones.dataState,
    employeeGeoZones: get(state, ["employeeGeoZones", "employeeGeoZones"], []),
  },
});

const mapDispatchToProps = {
  onGetEmployeeGeozones,
  onCreateGeoZone,
  onUpdateGeoZone,
  onDeleteGeoZone,
  onAddEmployee,
  onDeleteEmployee,
  onRequestEmployeeGeoZonesAction,
  onResetEmployeeGeozones,
};

const withEmployeeGeoZones = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withEmployeeGeoZones };
