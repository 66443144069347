import React from 'react'

import styles from './buttonPrimary.module.scss'

const ButtonPrimary = props => {
  const { handleClick, title, disabled } = props

  return (
    <button className={styles.buttonPrimary} onClick={handleClick} disabled={!!disabled ? true : false}>
      {!!props.children ? <span className={styles.wrapp}>{props.children}</span> : title}
    </button>
  )
}

export default React.memo(ButtonPrimary)