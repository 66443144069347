const READY_CHANGE_STATE = 'READY_CHANGE_STATE'

const responseChangeEmployeeMapState = (name, value) => {
  return {
    type: READY_CHANGE_STATE,
    name,
    value
  }
}

const changeEmployeeMapState = (name, value) => {
  return (dispatch) => {
    dispatch(responseChangeEmployeeMapState(name, value))
  }
}

export {
  changeEmployeeMapState,
  responseChangeEmployeeMapState,
  READY_CHANGE_STATE,
}
