import { connect } from "react-redux";
import get from "lodash/get";

import dateTime from "./../services/date.service";
import { handleChangeStoreItemsIntellegence as onHandleChangeStore } from "../../actions/itemsIntellegenceActions";

let momentTimeValue = new Date();
momentTimeValue.setSeconds(0);
momentTimeValue.setMilliseconds(0);

const mapStateToProps = (state) => ({
  itemsIntellegence: {
    dataState: state.itemsIntellegence.dataState,
    itemsIntellegence: {
      momentDate: get(state, ["itemsIntellegence", "itemsIntellegence", "momentDate"], new Date()),
      momentTime: get(state, ["itemsIntellegence", "itemsIntellegence", "momentTime"], momentTimeValue),
      rangeStartDate: get(
        state,
        ["itemsIntellegence", "itemsIntellegence", "rangeStartDate"],
        dateTime.withEmptyTime(new Date())
      ),
      rangeEndDate: get(
        state,
        ["itemsIntellegence", "itemsIntellegence", "rangeEndDate"],
        dateTime.withEmptyTime(new Date())
      ),
      rangeStartTime: get(
        state,
        ["itemsIntellegence", "itemsIntellegence", "rangeStartTime"],
        dateTime.withEmptyTime(new Date())
      ),
      rangeEndTime: get(
        state,
        ["itemsIntellegence", "itemsIntellegence", "rangeEndTime"],
        dateTime.getEndDayTimeDate(new Date())
      ),
      timeRange: get(state, ["itemsIntellegence", "itemsIntellegence", "timeRange"]),
      groupStatus: get(state, ["itemsIntellegence", "itemsIntellegence", "groupStatus"], false),
    },
  },
});

const mapDispatchToProps = {
  onHandleChangeStore,
};

const withItemsIntellegence = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withItemsIntellegence };
