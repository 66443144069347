import {
  REQUEST_REGISTRATION,
  RESPONSE_REGISTRATION,
  REQUEST_REGISTRATION_FAILURE,
  SET_REGISTRATION,
  CLEAR_REGISTRATION_ERRORS,
  READY_CHANGE_STORE_REGISTRATION
} from '../actions/registrationActions.js'

import { states as queriesStates } from './queriesStates'

const initialState = {
  dataState: queriesStates.NOT_REQUESTED,
  data: {},
  registrationItems: {},
  registrationState: false,
  errors: []
}

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case READY_CHANGE_STORE_REGISTRATION: {
      const { name, value } = action.payload
      var registrationItems = Object.assign({}, state.registrationItems)

      registrationItems[name] = value

      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY,
          registrationItems: registrationItems
        }
      )
    }
    case SET_REGISTRATION:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.NOT_REQUESTED,
          data: action.data,
          registrationState: false,
          errors: []
        }
      )
    case REQUEST_REGISTRATION:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.REQUEST,
        }
      )
    case RESPONSE_REGISTRATION:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.READY,
          data: action.data,
          registrationState: true,
          errors: []
        }
      )
    case REQUEST_REGISTRATION_FAILURE:
      return Object.assign(
        {},
        state,
        {
          dataState: queriesStates.FAILURE,
          registrationState: false,
          errors: action.errors
        }
      )
    case CLEAR_REGISTRATION_ERRORS:
      return Object.assign(
        {},
        state,
        {
          errors: []
        }
      )
    default:
      return state
  }
}

export default registrationReducer
