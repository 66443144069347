import { connect } from "react-redux";
import get from "lodash/get";

import {
  setTimeRangeIntelligenceData as onSetTimeRangeIntelligenceData,
  clearTimeRangeIntelligenceData as onClearTimeRangeIntelligenceData,
} from "./../../actions/timerangeIntelligenceDataActions";

const mapStateToProps = (state) => ({
  timerangeIntelligenceData: {
    data: get(state, ["timerangeIntelligenceData", "data"], []),
    features: get(state, ["timerangeIntelligenceData", "features"], []),
    groupStatus: get(state, ["timerangeIntelligenceData", "groupStatus"], false),
    eventObjectives: get(state, ["timerangeIntellegence", "eventObjectives"], []),
  },
});

const mapDispatchToProps = {
  onSetTimeRangeIntelligenceData,
  onClearTimeRangeIntelligenceData,
};

const withTimerangeIntelligenceData = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { withTimerangeIntelligenceData };
